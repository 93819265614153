import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";
import Footer from "../../../Components/footer";
import LazyLoader from "../../../Components/lazyLoader";
import { routes } from "../../../_Constants/routes";
import temple1 from "../../../Assets/Images/Temple/temple1.png";
import ambajitemple from "../../../Assets/Images/Home_page/linear.png";
import PrayIcon from "../../../Assets/Images/Home_page/PrayIcon.svg";
import "./onlineShopping.scss";
import OnlineShopService from "../../../Api_config/E_store/onlineShop";
import Arasuri from "../../../Components/arasuri";

const OnlineShopping = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState([]);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    OnlineShopService.getShopAll()
      .then((res) => {
        // console.log(res.data);
        setProductData(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, [5000]);
      })
      .catch((error) => {
        // console.log(error);
        navigate(routes?.error500);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Buy Ambaji Temple Merchandise Online</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content=" Buy Ambaji Temples Incense Burners, Notebook, Four Vedas, Calendar "
        />
        <meta
          name="keywords"
          content="Buy Ambaji Temples Incense Burners , Buy Ambaji Temple Calendar , Buy Ambaji Temple Notebook, Buy Ambaji Temple Four Vedas "
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner shopOnline-banner">
            <div className="container">
              <h1>ઓનલાઇન ખરીદી કરો</h1>
            </div>
          </section>
          <section className="shop-page">
            <div className="container">
              <div className="row">
                {productData?.map((item) => {
                  return (
                    <div className="col-md-6 col-lg-4">
                      <div
                        className="societybox"
                        // onClick={() =>
                        //   navigate(
                        //     `/online-shopping-detail/${encodeURIComponent(
                        //       item?.Product?.name
                        //     )}`,
                        //     {
                        //       state: { id: item?.Product?.name },
                        //     }
                        //   )
                        // }
                      >
                        <div className="societybox-img">
                          <img
                            src={item.thumbnail_image}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <div className="societybox-body">
                          <h5>{item.name}</h5>
                          <p>
                            {item?.description?.length > 80
                              ? item?.description.substr(0, 80) + "..."
                              : item?.description}
                          </p>
                          <button
                            className="orangebtn"
                            // onClick={() =>
                            //   navigate(`/online-shopping-detail/${item?.id}`, {
                            //     state: { id: item?.id },
                            //   })
                            // }
                            onClick={() =>
                              navigate(
                                `/online-shopping-detail/${item?.name}/${item.id}`,
                                {
                                  state: { id: item?.id },
                                },
                              )
                            }
                          >
                            ખરીદી કરો
                          </button>
                          {/* <button className="orangebtn">
                            ટૂંક સમયમાં આવી રહ્યું છે
                          </button> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default OnlineShopping;
