import React, { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../Components/footer';
import Arasuri from '../../../Components/arasuri';
import './sanghBookingReceipt.scss';
import { useReactToPrint } from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatNumberWithCommas } from '../../Donation/Converter/numberWithCommas';
import { formatDate } from '../../Donation/Converter/date';
import SignatureService from '../../../Api_config/Home_page/signature';
import SanghService from '../../../Api_config/Bhadarvi_poonam/sangh';
import SevaCampService from '../../../Api_config/Bhadarvi_poonam/sevaCamp'; // Adjust the path based on your project structure
import { routes } from '../../../_Constants/routes';
import { useHeading } from '../../Bhadarvi_poonam/header'; // Import the custom hook

const SanghBookingReceipt = () => {
  const componentRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [sanghData, setSanghData] = useState();
  const [termsConditions, setTermsConditions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [signatureData, setSignatureData] = useState();
  const formattedDate = formatDate(sanghData?.createdAt);
  const formattedAmount = formatNumberWithCommas(sanghData?.amount);
  const heading = useHeading(); // Call the custom hook

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Sangh Booking Receipt',
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    }
  });
  useEffect(() => {
    const fetchTermsConditions = async () => {
      try {
        const res = await SevaCampService.getTC();
        // Adjust mapping based on actual response structure
        setTermsConditions(res.data.data); // Assuming response.data.data is an array of terms
        setLoading(false);
      } catch (e) {
        setError('Failed to fetch terms and conditions.');
        setLoading(false);
      }
    };

    fetchTermsConditions();
    const id = location?.pathname?.split('/').pop();
    // console.log(location, Number(id));
    SanghService.getAllSangh(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setSanghData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        // console.log(e);
      });
    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);
  const qrUrl = sanghData?.qr_url;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  // / Filter terms and conditions to only include those where sangh is true
  const filteredTermsConditions = termsConditions.filter(
    (term) => term.sangh === true
  );

  let statusMessage = '';
  if (sanghData?.Status?.name === 'Approved') {
    statusMessage = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration.
            <br />
            Your Sangh details have been approved.
          </p>
        </td>
      </tr>
    );
  } else if (sanghData?.Status?.name === 'Rejected') {
    statusMessage = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration. <br />
            Unfortunately, your Sangh details have been rejected.
          </p>
        </td>
      </tr>
    );
  } else {
    statusMessage = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration.
            <br /> Your Sangh details are under review.
          </p>
        </td>
      </tr>
    );
  }
  return (
    <>
      {/* helmet  */}
      <Helmet>
        <title>Sangh Booking | Ambaji Bhadarvi Poonam 2024</title>
        <meta
          name="description"
          content="Register Your Pad Yatri Sangh for Ambaji Bhadarvi Poonam Fair 2024"
        />
        <meta
          name="keywords"
          content="Ambaji Bharavi Poonam 2024, Ambaji Temple, Bharavi Poonam festival, Ambaji pilgrimage, spiritual events, religious festivals India, Ambaji celebrations, Hindu festivals, Ambaji Poonam schedule, visit Ambaji Temple, divine rituals, cultural festivities, sacred events Gujarat, Ambaji event details, Seva Camp Registration, Sangh Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* helmet  */}
      <div className="booking" style={{ backgroundColor: '#f5f5f5' }}>
        <section className="header-banner Sangh-bg">
          <div className="container">{heading}</div>
        </section>
        <div className="print-container" ref={componentRef}>
          <div className="pdf-btn">
            <div className="container">
              <div className="row">
                <div className="detail-container">
                  <table width="100%">
                    <tr className="header-main">
                      <td
                        width="17%"
                        rowSpan="3"
                        align="center"
                        valign="middle"
                      >
                        <div className="main-logo">
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                            width="100"
                            height="100"
                          />
                        </div>
                      </td>
                      <td width="83%">
                        <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                      </td>
                    </tr>

                    <tr className="header-main">
                      <td>
                        <p>
                          <strong>Ta.</strong> Danta, &nbsp;
                          <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                          385110.
                        </p>
                        <p>
                          <strong>Phone No</strong> : 02749-262236 / +91 87996
                          00890
                        </p>
                        {/* <p>
                          <strong>PAN No </strong>: AACTS5457M &nbsp;
                          <strong>GST No</strong> : 24AACTS5457M2Z0
                        </p> */}
                        <p>
                          <strong>Email </strong>:
                          ambajitempletrust@gmail.com,&nbsp;
                          <strong>Website </strong>: www.ambajitemple.in
                        </p>
                      </td>
                    </tr>

                    <tr className="header-main"></tr>
                    <tr className="headig-text-1">
                      <td colSpan="2" align="center" valign="middle">
                        <h2>SANGH BOOKING RECEIPT</h2>
                      </td>
                    </tr>
                    <tr className="rs-note-service">
                      <td colSpan="2" align="center">
                        <p>
                          ભાદરવી પૂનમ મહામેળો 2024
                          <span>Date: 12/09/2024 to 18/09/2024</span>
                        </p>
                      </td>
                    </tr>
                    <tr className="rs-note">
                      <td colspan="2" align="center">
                        {statusMessage}
                      </td>
                    </tr>
                    {/* Status Message */}
                    <tr className="header-main">
                      <td colSpan="2" align="right">
                        <p>
                          <strong>Booking Date</strong> : {formattedDate}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="center">
                        <table width="100%" className="images-section-1">
                          <tr>
                            <td>
                              <strong>Sangh Booking Number</strong>
                            </td>
                            <td style={{ color: '#a30018' }}>
                              <b> {sanghData?.registration_number}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Booking Status</strong>
                            </td>
                            <td>{sanghData?.Status?.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Organization Name</strong>
                            </td>
                            <td>{sanghData?.organization_name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Organizer Name</strong>
                            </td>
                            <td>{sanghData?.organizer_name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Phone Number</strong>
                            </td>
                            <td>{sanghData?.primary_contact_number}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Arrival Date</strong>
                            </td>
                            <td>{sanghData?.arrival_date}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Number of People</strong>
                            </td>
                            <td>{sanghData?.no_of_people || '-'} </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Route of Arrival</strong>
                            </td>
                            <td>{sanghData?.ArrivalRoute?.name}</td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    {sanghData?.Status?.name === 'Approved' && (
                      <>
                        <tr className="headig-text-1">
                          <td colSpan="2" align="center" valign="middle">
                            <h2>Vehicle Details</h2>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" align="center" valign="middle">
                            <table width="100%" className="images-section-1">
                              <tr>
                                <td>
                                  <strong>Sr. No.</strong>
                                </td>
                                <td>
                                  <strong>Vehicle Type</strong>
                                </td>
                                <td>
                                  <strong>Vehicle Number</strong>
                                </td>
                              </tr>
                              {sanghData?.Vehicle1Category && (
                                <tr>
                                  <td>1.</td>
                                  <td>{sanghData.Vehicle1Category.name}</td>
                                  <td>{sanghData.vehicle1_number}</td>
                                </tr>
                              )}
                              {sanghData?.Vehicle2Category && (
                                <tr>
                                  <td>2.</td>
                                  <td>{sanghData.Vehicle2Category.name}</td>
                                  <td>{sanghData.vehicle2_number}</td>
                                </tr>
                              )}
                              {sanghData?.Vehicle3Category && (
                                <tr>
                                  <td>3.</td>
                                  <td>{sanghData.Vehicle3Category.name}</td>
                                  <td>{sanghData.vehicle3_number}</td>
                                </tr>
                              )}
                              {sanghData?.Vehicle4Category && (
                                <tr>
                                  <td>4.</td>
                                  <td>{sanghData.Vehicle4Category.name}</td>
                                  <td>{sanghData.vehicle4_number}</td>
                                </tr>
                              )}
                            </table>
                          </td>
                        </tr>
                      </>
                    )}
                    <tr align="center" valign="middle" className="teg-line">
                      <td colSpan="2">
                        <table
                          cellSpacing="0"
                          cellPadding="0"
                          border="0"
                          width="100%"
                        >
                          <tbody>
                            <tr align="center">
                              <td
                                width="40%"
                                valign="middle"
                                className="barcode"
                              >
                                <img src={qrUrl} width="110" />
                              </td>
                              <td width="60%" valign="middle" className="sign">
                                {signatureData?.map((item) => {
                                  return (
                                    <>
                                      <img
                                        src={item.signature_image}
                                        // src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716962038848_MicrosoftTeams-image(7).png"
                                        width="120"
                                        height="100"
                                      />
                                      <br />
                                      <p>
                                        {item.name}
                                        <br />
                                        {item.designation}
                                      </p>
                                    </>
                                  );
                                })}
                                <p>
                                  Shree Arasuri Ambaji Mata Devasthan Trust
                                  Ambaji
                                  {/* વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                                  શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                                  અંબાજી */}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>

                <div className="page-break"></div>

                <div
                  className="detail-container"
                  style={{ paddingTop: '50px' }}
                >
                  <table width="100%">
                    <tr>
                      <td colSpan="2">
                        {/* <div className="note-introduction"></div> */}
                        <div className="notice">
                          <h2>Instructions / સંઘ માટેની સૂચનાઓ</h2>
                          <div className="terms-conditions-content">
                            {filteredTermsConditions.length ? (
                              <ol>
                                {filteredTermsConditions.map((term, index) => (
                                  <li key={index}>{term.name}</li> // Adjust based on actual field name
                                ))}
                              </ol>
                            ) : (
                              <p>No terms and conditions available.</p>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <button className="btn orangebtn my-2" onClick={handlePrint}>
            Receipt Download
          </button>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};

export default SanghBookingReceipt;
