import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import "./payment-status.css";
import GoldDonationService from "../../../Api_config/Donations/goldDonation";
import UtilsService from "../../../Api_config/Home_page/utils";
import Arasuri from "../../../Components/arasuri";
import Footer from "../../../Components/footer";
import { routes } from "../../../_Constants/routes";

const GoldDonationSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState();

  useEffect(() => {
    const id = location?.pathname?.split("/").pop();
    // console.log(location, Number(id))
    GoldDonationService.getTransactionDetails(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setTransactionData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        //  console.log(e)
      });
  }, []);

  const printPaymentReciept = () => {
    const id = location?.pathname?.split("/").pop();
    navigate(`/donation-gold-receipt/${id}`, {
      state: { data: transactionData },
    });
  };

  return (
    <>
      <section className="header-banner gold-banner">
        <div className="container">
          <h1>Payment Status</h1>
        </div>
      </section>
      <section className="about-page">
        <div className="container">
          <div className="money-donation-success">
            <div className="check-icon">
              <FaCheck />
            </div>
            <br />
            <h3 className="text-center">Payment Successfull</h3>
            <br />
            <div className="row" style={{ rowGap: "15px" }}>
              <div className="col-5">
                <b>Transaction Id :</b>
              </div>
              <div className="col-7" style={{ wordBreak: "break-all" }}>
                {transactionData?.donation_number}
              </div>
              {transactionData?.user_identity !== "hidden" && (
                <>
                  <div className="col-5">
                    <b>Person Name :</b>
                  </div>
                  <div className="col-7">
                    {transactionData?.User?.first_name ||
                    transactionData?.User?.last_name
                      ? `${transactionData?.User?.first_name || ""} ${
                          transactionData?.User?.last_name || ""
                        }`
                      : "-"}
                  </div>
                  {/* <div className="col-5">{`${transactionData?.User?.first_name} ${transactionData?.User?.last_name}`}</div> */}
                </>
              )}
              {/* <div className="col-5">Payment Status</div>
          <div className="col-7">
            {transactionData?.Razorpay?.payment_link_status}
          </div> */}
              <div className="col-5">
                <b>Date of Transaction : </b>
              </div>
              <div className="col-7">
                {UtilsService.formatDate(transactionData?.createdAt)}
              </div>
              <div className="col-5">
                <b>Amount :</b>
              </div>
              <div className="col-7">Rs. {transactionData?.amount}</div>
              {/* <div className="col-5">Donation Type:</div>
          <div className="col-7">{transactionData?.DonationCategory?.name}</div> */}
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn btn-success"
                onClick={() => printPaymentReciept()}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </section>
      <Arasuri />
      <Footer />
    </>
  );
};
export default GoldDonationSuccess;
