import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";
import Footer from "../../../Components/footer";
import LazyLoader from "../../../Components/lazyLoader";
import ParkingArea from "../../../Assets/Images/51_shaktipith/parking-area.svg";
import yagna from "../../../Assets/Images/E_store/yagna.svg";
import SpotlightLight from "../../../Assets/Images/51_shaktipith/SpotlightLight.svg";
import calendar from "../../../Assets/Images/51_shaktipith/calendar.svg";
import MasalaRed from "../../../Assets/Images/51_shaktipith/MasalaRed.svg";
import festival from "../../../Assets/Images/51_shaktipith/festival.svg";
import Arasuri from "../../../Components/arasuri";
import Error404 from "../../../Pages/error404";

const ShaktipithMahotsav = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  //-----page-close-404-error-logic-p-----
  if (true) {
    return <Error404 />;
  }
  //-----page-close-404-error-logic-p-----
  return (
    <>
      <Helmet>
        <title>Ambaji Temple - 51 Shaktipith Mahotsav</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Get detailed information of 51 Shaktipith Mahotsav."
        />
        <meta name="keywords" content="51 Shaktipith Mahotsav Ambaji" />
        <meta name="author" content="51 Shaktipith Mahotsav" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner shaktipith-banner">
            <div className="container">
              <h1>
                પ૧ શક્તિપીઠ મહોત્સવ-૨૦૨૪
                <br />
                (૧૨/૨/૨૦૨૪ થી ૧૬/૨/૨૦૨૪)
              </h1>
            </div>
          </section>
          <section className="about-page">
            <div className="container">
              <div className="about-main">
                <div className="templetop">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="templeimg">
                        <img
                          src="https://storage.googleapis.com/ambaji-temple.appspot.com/1692249532794_Gabbar.jpeg "
                          alt="Ambaji Temple Banaskatha Gujarat"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <h2>
                        51 શક્તિપીઠ <span>મહોત્સવ</span>
                      </h2>
                      <p>
                        ગુજરાતીમાં ગબ્બરનો શાબ્દિક અર્થ થાય છે પહાડી. ગબ્બર
                        "અખંડ જ્યોત જે જમીનના સ્તરથી 1.5 કિલોમીટર ઉપર આવેલું છે,
                        ચોક્કસ 999 પગથિયાં માટે પ્રસિદ્ધ છે. દર વર્ષે લગભગ 30
                        લાખ ભક્તો ગબ્બરના દર્શન માટે આવે છે." "માતાજી કા ઝુલા",
                        "કાલ ભૈરવ મંદિર" છે. ગબ્બર પરિક્રમા સાથેના આકર્ષણના
                        મુખ્ય બિંદુઓ છે. અંબાજી નગરની ટેકરી પરથી ભવ્ય નજારો,
                        અરવલ્લી ના જંગલો જોવા લાયક છે. રોપ-વે સેવાઓ પણ 1998ના
                        વર્ષમાં શરૂ કરવામાં આવી હતી. રોપ-વે માટેનું બુકિંગ
                        ઓફિસમાંથી સરળતાથી કરી શકાય છે. ભારતીય ધર્મગ્રંથો અનુસાર
                        ગબ્બર તીર્થ, વૈદિક નદી સરસ્વતીના ઉત્પત્તિના કિનારે,
                        અંબિકા જંગલમાં આરાસુરની ટેકરીઓમાં, દક્ષિણ-પશ્ચિમ તરફ
                        અરવલ્લીની જૂની ટેકરીઓ (51) શક્તિપીઠો માં ની એક છે . દેવી
                        ભાગવતની દંતકથા અનુસાર, મહિશાસુર સમગ્ર બ્રહ્માંડ માટે
                        ખતરનાક રાક્ષસ હતો, તેથી ત્રિદેવ બ્રહ્મા (સૃષ્ટિના
                        ભગવાન), વિષ્ણુ (જાળવણીના ભગવાન) અને મહેશ (વિનાશના ભગવાન)
                        ના નેતૃત્વ હેઠળના તમામ દેવો આખરે ગયા.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section
            className="service-section"
            style={{ margin: "0px 0px", padding: "0px 0px" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <h2 style={{ margin: "0px 0px" }}>
                    ભક્તો <span>માટે ની સુવિધા</span>
                  </h2>
                  <div className="service-list">
                    <div className="row">
                      {/* DAILY ROUTINE  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <a href="/daily-routine">
                          <div
                            className="servicebox"
                            style={{ margin: "10px 0px" }}
                          >
                            <div className="service-icon">
                              <img
                                src={calendar}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>દૈનિક મહોત્સવો</h5>
                            <p>દિવસ મુજબનું શેડ્યૂલ</p>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}

                      {/* YAGNA BOOKING  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <a href="/51-shaktipeeth-parikrama-yagna-booking">
                          <div
                            className="servicebox"
                            style={{ margin: "10px 0px" }}
                          >
                            <div className="service-icon">
                              <img
                                src={yagna}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>યજ્ઞનું બુકિંગ</h5>
                            <p>પરિક્રમા માટે યજ્ઞ બુકિંગ</p>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}

                      {/* PARKING  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <a href="/parking-solution">
                          <div
                            className="servicebox"
                            style={{ margin: "10px 0px" }}
                          >
                            <div className="service-icon">
                              <img
                                src={ParkingArea}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h5>પાર્કિંગ</h5>
                            <p>
                              {/* મંદિરો દ્વારા ઓનલાઈન પાર્કિંગ સુવિધા તમને તમારી
                              કાર માટે જગ્યા પ્રીબુક કરવામાં મદદ કરે છે. */}
                              ૫૧ શક્તિપીઠ પરિક્રમા મહોત્સવ દરમિયાન વિનામૂલ્યે
                              પાર્કિંગ માટેના સ્થળ
                            </p>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}

                      {/* BHOJAN VYAVSTHA  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div
                          className="servicebox"
                          style={{ margin: "10px 0px" }}
                        >
                          <div className="service-icon">
                            <img
                              src={MasalaRed}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>ભોજન વ્યવસ્થા</h5>
                          <p>
                            મા આરાસુરી અંબાના મંદિરે આવતા તમામ ભક્તોને દરરોજ
                            ભોજન પ્રસાદ પીરસવો.
                          </p>
                        </div>
                      </div>
                      {/* ------------- */}

                      {/* SANSKRUTIK  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div
                          className="servicebox"
                          style={{ margin: "10px 0px" }}
                        >
                          <div className="service-icon">
                            <img
                              src={festival}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>સાંસ્કૃતિક કાર્યક્રમ</h5>
                          <p>સાંસ્કૃતિક કાર્યક્રમો</p>
                        </div>
                      </div>
                      {/* ------------- */}

                      {/* LIGHT ANS SOUND SHOW  */}
                      <div className="col-lg-4 col-md-6 col-sm-12">
                        <div
                          className="servicebox"
                          style={{ margin: "10px 0px" }}
                        >
                          <div className="service-icon">
                            <img
                              src={SpotlightLight}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <h5>લાઇટ એન્ડ સાઉન્ડ શો</h5>
                          <p>
                            અંબાજી મંદિર ખાતે મનમોહક લાઇટ એન્ડ સાઉન્ડ શોનો અનુભવ
                            કરો, જે ઇતિહાસ અને આધ્યાત્મિકતા દ્વારા મંત્રમુગ્ધ
                            કરી દેનારી યાત્રા છે.
                          </p>
                        </div>
                      </div>
                      {/* ------------- */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default ShaktipithMahotsav;
