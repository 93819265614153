import { useNavigate } from "react-router";
import "./pages.css";

import ambaji_temple_image from "../Assets/Images/pages/linear.png";
import Footer from "../Components/footer";

const Error404 = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="error_404">
        <div className="container">
          <img
            src={ambaji_temple_image}
            alt="Ambaji Temple"
            className="ambaji-temple-image"
          />
          <h1 className="heading">જય અંબે</h1>

          <h3>404-PAGE NOT FOUND</h3>
          <p className="d-block d-md-none">
            The page you are looking for might have been removed had its name
            changed or is temporarily unavailable{" "}
          </p>
          <p className="d-none d-md-block">
            The page you are looking for might have been removed <br />
            had its name changed or is temporarily unavailable{" "}
          </p>

          <br />
          <button
            type="button"
            className="orangebtn"
            onClick={() => navigate("/")}
          >
            Go TO HOMEPAGE
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Error404;
