import React, { useState, useEffect } from 'react';
import './bhadarvipoonamdonation.scss';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../_Constants/routes';
import UtilsService from '../../../Api_config/Home_page/utils';
import DonationService from '../../../Api_config/Bhadarvi_poonam/donation';
import Footer from '../../../Components/footer';
import Arasuri from '../../../Components/arasuri';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { BhadarviPoonamDonationSchema } from '../../../_Constants/validations';
const BhadarviPoonamDonation = (props) => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  const navigate = useNavigate();
  // const { data } = props;
  // MASTERS VARIABLES
  const [countryList, setCountryList] = useState([]);

  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [donationCategoryList, setDonationCategoryList] = useState([]);
  // const [phoneCodeList, setPhoneCodeList] = useState([]);
  const [isProcessing, setProcessing] = useState(false);

  const [backendErrors, setBackendErrors] = useState([]);
  // const validatePAN = (value) => {
  //   let regex = new RegExp();
  //   let error;
  //   if (regex.test(value) != true) {
  //     error = 'Invalid Pan Number';
  //   }
  //   return error;
  // };

  const validate = (values) => {
    const errors = {};

    if (String(values.amount).trim() !== '' && Number(values.amount) <= 0) {
      errors.amount = 'Amount should be more than 0.';
    }
    return errors;
  };
  // MASTER'S USEEFFECT
  useEffect(() => {
    DonationService.getAllDonationCategory()
      .then((res) => {
        const categories = res.data.data.map((category) => ({
          value: category.id,
          label: category.name
        }));
        setDonationCategoryList(categories);
        const storedDonationName = localStorage.getItem('donationName');
        if (storedDonationName) {
          const matchingCategory = categories.find(
            (category) => category.label === storedDonationName
          );
          if (matchingCategory) {
            // Set the default donation_category_id in formik
            formik.setFieldValue(
              'donation_category_id',
              matchingCategory.value
            );
          }
        }
      })
      .catch((e) => {
        // Handle error
        console.error(e);
      });

    // UtilsService.getAllCountry()
    //   .then((res) => {
    //     let countryList = res.data.data.map(function (country) {
    //       return { value: country.id, label: country.name };
    //     });
    //     // console.log(countryList);
    //     setCountryList(countryList);
    //     setPhoneCodeList(res.data.data);
    //   })
    //   .catch((e) => {
    //     navigate(routes?.error500);

    //     // console.log(e);
    //   });
    UtilsService.getCountry().then(
      (res) => {
        if (res.data.success) {
          setCountryList(res.data.data);
          setLoading(false);
          // setPhoneCodeList(res.data.data);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
    // UtilsService.getAllProvince()
    //   .then((res) => {
    //     let provinceList = res.data.data.map(function (province) {
    //       return { value: province.id, label: province.name };
    //     });
    //     // console.log(provinceList);
    //     setProvinceList(provinceList);
    //     setLoading(false);
    //   })
    //   .catch((e) => {
    //     navigate(routes?.error500);

    //     // console.log(e);
    //   });

    // UtilsService.getAllZipcode()
    //   .then((res) => {
    //     let zipcodeList = res.data.data.map(function (zipcode_id) {
    //       return { value: zipcode_id.id, label: zipcode_id.name };
    //     });
    //     // console.log(zipcodeList);
    //     setZipcodeList(zipcodeList);
    //   })
    //   .catch((e) => {
    //     navigate(routes?.error500);
    //     // console.log(e);
    //   });
  }, [
    setCityList,
    setCountryList,
    setProvinceList,
    setZipcodeList,
    setDonationCategoryList
  ]);

  //  FORMIK LOGIC WITH ONSUBMIT FUNCTION

  const formik = useFormik({
    initialValues: {
      donation_category_id: props.data?.donation_category_id,
      user_identity: 'show',
      primary_contact_code_id: props.data?.primary_contact_code_id ?? 1,
      primary_contact_number: props.data?.primary_contact_number ?? '',
      first_name: props.data?.first_name ?? '',
      last_name: props.data?.last_name ?? '',
      email_id: props.data?.email_id ?? '',
      address_line_1: props.data?.address_line_1 ?? '',
      address_line_2: props.data?.address_line_2 ?? '',
      city_id: props.data?.city_id ?? '',
      country_id: props.data?.country_id ?? '',
      province_id: props.data?.province ?? '',
      zipcode_id: props.data?.zipcode_id ?? '',
      amount: props.data?.amount ?? '',
      pan_number: props.data?.pan_number ?? ''
    },
    validationSchema: BhadarviPoonamDonationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit: (values) => {
      console.log(values);
      setProcessing(true);

      const requestData = {
        user_identity: 'show',

        amount: Number(values?.amount),
        donation_category_id: values?.donation_category_id,
        first_name: values?.first_name,
        last_name: values?.last_name,
        email_id: values?.email_id,
        primary_contact_code_id: 1,
        primary_contact_number: values?.primary_contact_number,

        address_line_1: values?.address_line_1,
        address_line_2: values?.address_line_2,
        city_id: values?.city_id,
        country_id: values?.country_id,
        province_id: values?.province_id,
        zipcode_id: values?.zipcode_id,
        pan_number: values?.pan_number
      };
      DonationService.checkoutDonation(requestData)
        .then((res) => {
          console.log(res);

          if (res.data.success) {
            setProcessing(false);
            window.open(
              res.data.data?.short_url,
              '_blank',
              'rel=noopener noreferrer'
            );
            // navigate(res.data.data?.short_url)
          }
        })
        .catch((error) => {
          let errorMessage = 'An error occurred. Please try again later.';
          if (error.response && error.response.data) {
            const responseData = error.response.data;
            if (responseData.error) {
              const validationErrors = responseData.error;
              errorMessage = Object.values(validationErrors)
                .map((err) => err.message)
                .join(', ');
            } else if (responseData.message) {
              errorMessage = responseData.message;
            }
          }
          setBackendErrors(errorMessage);
          toast.error(errorMessage, { autoClose: 1500 });
          navigate(routes.moneyDonationFalied);
        });
    }
  });
  // GET PROVINCE API INTEGRATION
  const getProvince = (event) => {
    UtilsService.getProvince(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  };

  // GET CITY API INTEGRATION
  const getCity = (event) => {
    UtilsService.getCity(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setCityList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error)
      });
  };

  // GET ZIPCODE BY CITY API INTEGRATION

  const getZipcodeByCityDeliverable = (event) => {
    UtilsService.getZipcodeByCityDeliverable(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setZipcodeList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  };
  const { handleChange, handleSubmit } = formik;

  return (
    <>
      <div className="main-wrapper">
        <section className="header-banner bhadarvi-poonam-banner">
          <div className="container">
            <h1>દાન </h1>
          </div>
        </section>
        {/* -------- */}

        {/* FIELDS SECTION  */}
        <section className="bhadarvi-poonam">
          <div className="container">
            <div className="row">
              <div className="col-xl-12">
                <div className="card">
                  <div className="row">
                    <div className="col-lg-12">
                      <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <div className="row">
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label>
                                Donation Purpose / દાન હેતુ
                                <span className="text-red">*</span>
                              </label>
                              <select
                                name="donation_category_id"
                                className={`form-control ${
                                  formik.touched.donation_category_id &&
                                  formik.errors.donation_category_id
                                    ? 'is-invalid'
                                    : 'valid'
                                }`}
                                value={formik?.values?.donation_category_id}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              >
                                <option value="" selected disabled>
                                  Select Donation Category
                                </option>
                                {donationCategoryList?.map((item) => (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                              {formik.touched.donation_category_id &&
                                formik.errors.donation_category_id && (
                                  <div style={{ color: '#ae0015' }}>
                                    {formik.errors.donation_category_id}
                                  </div>
                                )}
                            </div>
                          </div>

                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label>
                                First Name / પ્રથમ નામ{' '}
                                <span className="text-red">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  formik.touched.first_name &&
                                  formik.errors.first_name
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="first_name"
                                value={formik?.values.first_name}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.first_name &&
                                formik.errors.first_name && (
                                  <div style={{ color: '#ae0015' }}>
                                    {formik.errors.first_name}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label>
                                Last Name / અટક{' '}
                                <span className="text-red">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  formik.touched.last_name &&
                                  formik.errors.last_name
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="last_name"
                                value={formik?.values.last_name}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.last_name &&
                                formik.errors.last_name && (
                                  <div style={{ color: '#ae0015' }}>
                                    {formik.errors.last_name}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label>
                                Mobile Number / મોબાઈલ નંબર{' '}
                                <span className="text-red">*</span>
                              </label>
                              <input
                                type="text"
                                inputMode="numeric"
                                className={`form-control ${
                                  formik.touched.primary_contact_number &&
                                  formik.errors.primary_contact_number
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="primary_contact_number"
                                value={formik?.values.primary_contact_number}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^\d{0,10}$/.test(value)) {
                                    formik.handleChange(e);
                                  }
                                }}
                                onBlur={formik.handleBlur}
                                maxLength={10}
                              />
                              {formik.touched.primary_contact_number &&
                                formik.errors.primary_contact_number && (
                                  <div style={{ color: '#ae0015' }}>
                                    {formik.errors.primary_contact_number}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label>
                                Email Id / ઈમેલ આઈડી{' '}
                                <span className="text-red">*</span>
                              </label>
                              <input
                                type="email"
                                className={`form-control ${
                                  formik.touched.email_id &&
                                  formik.errors.email_id
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="email_id"
                                value={formik?.values.email_id}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                              />

                              {formik.touched.email_id &&
                                formik.errors.email_id && (
                                  <div style={{ color: '#ae0015' }}>
                                    {formik.errors.email_id}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label>PAN Number / પાન કાર્ડ નંબર</label>
                              <input
                                type="text"
                                className={`form-control ${
                                  formik.touched.pan_number &&
                                  formik.errors.pan_number
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="pan_number"
                                style={{ textTransform: 'uppercase' }}
                                value={formik.values.pan_number}
                                onChange={formik.handleChange}
                              />
                              {/* {formik.touched.pan_number &&
                                formik.errors.pan_number && (
                                  <div style={{ color: '#ae0015' }}>
                                    {formik.errors.pan_number}
                                  </div>
                                )} */}
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label>
                                Address Line 1 / એડ્રેસ લાઇન 1{' '}
                                <span className="text-red">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  formik.touched.address_line_1 &&
                                  formik.errors.address_line_1
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="address_line_1"
                                value={formik?.values.address_line_1}
                                onChange={handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.address_line_1 &&
                                formik.errors.address_line_1 && (
                                  <div style={{ color: '#ae0015' }}>
                                    {formik.errors.address_line_1}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label>Address Line 2 / એડ્રેસ લાઇન 2</label>
                              <input
                                type="text"
                                className="form-control"
                                name="address_line_2"
                                value={formik?.values.address_line_2}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label htmlFor="country_id">
                                Country/દેશ
                                <span className="text-red">*</span>
                              </label>
                              <select
                                name="country_id"
                                className={`form-control ${
                                  formik.touched.country_id &&
                                  formik.errors.country_id
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                value={formik?.values?.country_id}
                                onChange={(event) => [
                                  formik.setFieldValue(
                                    'country_id',
                                    event.target.value
                                  ),
                                  getProvince(event)
                                ]}
                                onBlur={formik.handleBlur}
                              >
                                <option value="" selected disabled>
                                  Select Country
                                </option>
                                {countryList?.map((item, i) => {
                                  return (
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {formik.errors.country_id &&
                                formik.touched.country_id && (
                                  <div style={{ color: '#ae0015' }}>
                                    {formik.errors.country_id}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label>
                                Province/રાજ્ય
                                <span className="text-red">*</span>
                              </label>
                              <select
                                name="province_id"
                                className="form-control"
                                value={formik?.values?.province_id}
                                onChange={(event) => [
                                  formik.setFieldValue(
                                    'province_id',
                                    event.target.value
                                  ),
                                  getCity(event)
                                ]}
                                onBlur={formik.handleBlur}
                              >
                                <option value="">Select State</option>
                                {provinceList?.map((item, i) => {
                                  return (
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {formik?.touched?.province_id &&
                                formik?.errors?.province_id && (
                                  <div style={{ color: '#ae0015' }}>
                                    {' '}
                                    {formik?.errors?.province_id}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label htmlFor="city_id">
                                City/શહેર
                                <span className="text-red">*</span>
                              </label>
                              <select
                                name="city_id"
                                className="form-control"
                                value={formik?.values?.city_id}
                                onChange={(event) => [
                                  formik.setFieldValue(
                                    'city_id',
                                    event.target.value
                                  )
                                  // getZipcodeByCityDeliverable(event)
                                ]}
                                onBlur={formik.handleBlur}

                                // onChange={formik.handleChange}
                              >
                                <option value="" selected disabled>
                                  Select City
                                </option>
                                {console.log(cityList)}
                                {cityList?.map((item, i) => {
                                  return (
                                    <option value={item?.id}>
                                      {item?.name}
                                    </option>
                                  );
                                })}
                              </select>
                              {formik?.touched?.city_id &&
                                formik?.errors?.city_id && (
                                  <div style={{ color: '#ae0015' }}>
                                    {formik?.errors?.city_id}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="form-group">
                              <label htmlFor="zipcode_id">
                                Zipcode/પિનકોડ
                                <span className="text-red">*</span>{' '}
                                <small>
                                  (Below listed zipcodes are deliverable)
                                </small>
                              </label>
                              <input
                                name="zipcode_id"
                                className="form-control"
                                value={formik?.values?.zipcode_id || ''}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                maxLength={6}
                              />
                              {formik?.touched?.zipcode_id &&
                                formik?.errors?.zipcode_id && (
                                  <div style={{ color: '#ae0015' }}>
                                    {formik?.errors?.zipcode_id}
                                  </div>
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="form-group">
                          <label>
                            Amount / રકમ <span className="text-red">*</span>
                          </label>
                          <input
                            type="text"
                            inputMode="numeric"
                            className={`form-control ${
                              formik.touched.amount && formik.errors.amount
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="amount"
                            value={formik?.values.amount}
                            // onChange={handleChange}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            onKeyDown={(e) => {
                              // Allow only digits (0-9), backspace, and delete
                              const allowedKeys = [
                                '0',
                                '1',
                                '2',
                                '3',
                                '4',
                                '5',
                                '6',
                                '7',
                                '8',
                                '9',
                                'Backspace',
                                'Delete'
                              ];
                              if (!allowedKeys.includes(e.key)) {
                                e.preventDefault();
                              }
                            }}
                          />
                          {formik.touched.amount && formik.errors.amount && (
                            <div style={{ color: '#ae0015' }}>
                              {formik.errors.amount}
                            </div>
                          )}
                        </div>
                        <button
                          className="orangebtn"
                          type="submit"
                          style={{ width: '100%' }}
                          // onClick={() => {
                          //   navigate('/bhadarvi-poonam-donation-success');
                          // }}
                        >
                          Donate
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Arasuri />
        <Footer />
      </div>
    </>
  );

  // END RETURN
};

export default BhadarviPoonamDonation;
