import React, { useState, useEffect, useRef } from 'react';
import UtilsService from '../../../Api_config/Home_page/utils';
import MoneyDonationService from '../../../Api_config/Donations/moneyDonation';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import '../Donation_money/donationMoney.scss';
import Select from 'react-select';
import { Helmet } from 'react-helmet-async';
import ambajiTemple2 from '../../../Assets/Images/Temple/ambaji-temple2.jpg';
import ambajiTemple3 from '../../../Assets/Images/Temple/ambaji-temple3.jpg';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../../../_Constants/routes';
import Footer from '../../../Components/footer';
import LazyLoader from '../../../Components/lazyLoader';
import { payu_api_url, payu_key } from '../../../_Constants/api_urls';
import Modal from 'react-modal';
import Arasuri from '../../../Components/arasuri';
import { MoneyDonationSchema } from '../../../_Constants/validations';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '20px '
  }
};

const DonationMoney = (props) => {
  // MASTERS VARIABLES
  const [countryList, setCountryList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [phoneCodeList, setPhoneCodeList] = useState([]);
  const [causeList, setCauseList] = useState([]);

  // ---------

  // OTP VARIABLES
  const [otp, setOTP] = useState();
  const [isOTPSend, setOTPSend] = useState(false);
  const [isResend, setResend] = useState(false);
  const [seconds, setSeconds] = useState(30);
  // ---------
  const [userData, setUserData] = useState();
  const [userAddress, setUserAddress] = useState();
  const navigate = useNavigate();

  const { data } = props;
  const myButtonRef = useRef(null);
  const [isLoading, setLoading] = useState(true);
  const [isProcessing, setProcessing] = useState(false);
  const [isNumberRegistered, setNumberRegistered] = useState(false);
  const [selectedCity, setSelectedCity] = useState();
  const [isIdentityHidden, setIdentityHidden] = useState('show');
  const [isShowForm, setShowForm] = useState(false);
  const [isNumberEditable, setNumberEditable] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [payUData, setPayUData] = useState();
  const [accepted, setAccepted] = useState(false);
  const validate = (values) => {
    const errors = {};
    if (values.is_80g_tax_opted && !values.pan_number) {
      errors.pan_number =
        'PAN card is required when claiming IT 80(G) deduction.';
    }
    if (values?.amount !== '' && values.amount <= 0) {
      errors.amount = 'Amount should be more than 0.';
    }
    return errors;
  };
  // --------------------------------------------------------------------------------

  // MASTER'S USEEFFECT
  useEffect(() => {
    MoneyDonationService.getAllCause()
      .then((res) => {
        let causeList = res.data.data.map(function (cause) {
          return { value: cause.id, label: cause.name };
        });
        // console.log(causeList);
        setCauseList(causeList);
      })
      .catch((e) => {
        navigate(routes?.error500);

        // console.log(e);
      });

    // UtilsService.getAllCity()
    //   .then((res) => {
    //     let cityList = res.data.data.map(function (city) {
    //       return { value: city.id, label: city.name };
    //     });
    //     // console.log(cityList);
    //     setCityList(cityList);
    //   })
    //   .catch((e) => {
    //     navigate(routes?.error500);
    //     // console.log(e);
    //   });

    // UtilsService.getAllCountry()
    //   .then((res) => {
    //     let countryList = res.data.data.map(function (country) {
    //       return { value: country.id, label: country.name };
    //     });
    //     // console.log(countryList);
    //     setCountryList(countryList);
    //     setPhoneCodeList(res.data.data);
    //   })
    //   .catch((e) => {
    //     navigate(routes?.error500);

    //     // console.log(e);
    //   });
    UtilsService.getCountry().then(
      (res) => {
        if (res.data.success) {
          setCountryList(res.data.data);
          setLoading(false);
          setPhoneCodeList(res.data.data);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
    // UtilsService.getAllProvince()
    //   .then((res) => {
    //     let provinceList = res.data.data.map(function (province) {
    //       return { value: province.id, label: province.name };
    //     });
    //     // console.log(provinceList);
    //     setProvinceList(provinceList);
    //     setLoading(false);
    //   })
    //   .catch((e) => {
    //     navigate(routes?.error500);

    //     // console.log(e);
    //   });

    // UtilsService.getAllZipcode()
    //   .then((res) => {
    //     let zipcodeList = res.data.data.map(function (zipcode_id) {
    //       return { value: zipcode_id.id, label: zipcode_id.name };
    //     });
    //     // console.log(zipcodeList);
    //     setZipcodeList(zipcodeList);
    //   })
    //   .catch((e) => {
    //     navigate(routes?.error500);
    //     // console.log(e);
    //   });
  }, [
    setCityList,
    setCountryList,
    setProvinceList,
    setZipcodeList,
    setCauseList
  ]);

  const getCity = (event) => {
    UtilsService.getCity(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setCityList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error)
      });
  };
  // ----------
  // GET PROVINCE API INTEGRATION
  const getProvince = (event) => {
    UtilsService.getProvince(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  };
  const getZipcodeByCityDeliverable = (event) => {
    UtilsService.getZipcodeByCityDeliverable(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setZipcodeList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  };

  // SHOW IDENTITY FORMIK LOGIC WITH ONSUBMIT FUNCTION
  const formik = useFormik({
    initialValues: {
      user_identity: 'show',
      payment_type: 'online',
      money_donation_type_id: props.data?.money_donation_type_id ?? '',
      primary_contact_code_id: props.data?.primary_contact_code_id ?? 1,
      primary_contact_number: props.data?.primary_contact_number ?? '',
      first_name: props.data?.first_name ?? '',
      last_name: props.data?.last_name ?? '',
      email_id: props.data?.email_id ?? '',
      address_line_1: props.data?.address_line_1 ?? '',
      address_line_2: props.data?.address_line_2 ?? '',
      city_id: props.data?.city_id ?? '',
      country_id: props.data?.country_id ?? '',
      province_id: props.data?.province ?? '',
      zipcode_id: props.data?.zipcode_id ?? '',
      amount: props.data?.amount ?? '',
      category: 'website',
      is_80g_tax_opted: false,
      is_identity_hidden: '',
      pan_number: props.data?.pan_number ?? ''
    },
    validationSchema: MoneyDonationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    // ONSUBMIT LOGIC
    onSubmit: (values) => {
      // console.log(values);
      setProcessing(true);
      if (userData) {
        const requestData = {
          user_identity: 'show',
          payment_type: 'online',
          is_identity_hidden: false,
          amount: Number(values?.amount),
          category: 'website',
          user_id: userData?.id,
          first_name: values?.first_name,
          last_name: values?.last_name,
          email_id: values?.email_id,
          primary_contact_code_id: 1,
          primary_contact_number: values?.primary_contact_number,
          secondary_contact_code_id: 1,
          secondary_contact_number: null,
          address_line_1: values?.address_line_1,
          address_line_2: values?.address_line_2,
          city_id: values?.city_id,
          country_id: values?.country_id,
          province_id: values?.province_id,
          zipcode_id: values?.zipcode_id,
          pan_number: values?.pan_number,
          is_80g_tax_opted: values?.is_80g_tax_opted,
          money_donation_type_id: values?.money_donation_type_id
        };
        MoneyDonationService.payU(requestData)
          .then((res) => {
            if (res.data.success) {
              setPayUData(res.data.data);
              setTimeout(() => {
                setProcessing(false);
                if (myButtonRef.current) {
                  myButtonRef.current.click();
                }
              }, 200);
              // navigate(routes.moneyDonationSuccess);
            }
          })
          .catch((error) => {
            toast.error(error.response?.data?.message, { autoClose: 1500 });
            navigate(routes.moneyDonationFalied);
          });
      } else {
        const userData = {
          // user_identity: "show",

          first_name: values?.first_name,
          last_name: values?.last_name,
          email_id: values?.email_id,
          primary_contact_code_id: 1,
          primary_contact_number: values?.primary_contact_number,
          secondary_contact_code_id: null,
          secondary_contact_number: null,
          address_line_1: values?.address_line_1,
          address_line_2: values?.address_line_2,
          city_id: values?.city_id,
          country_id: values?.country_id,
          province_id: values?.province_id,
          zipcode_id: values?.zipcode_id,
          pan_number: values?.pan_number,
          is_80g_tax_opted: values?.is_80g_tax_opted,
          money_donation_type_id: values?.money_donation_type_id,
          password: `${values?.first_name}@ambaji`,
          user_role_id: 1,
          user_status_id: 1
        };
        MoneyDonationService.saveUser(userData).then((res) => {
          if (res.data.success) {
            const requestData = {
              user_identity: 'show',
              amount: Number(values?.amount),
              user_id: res.data.data?.id,
              category: 'website',
              payment_type: 'online',
              first_name: values?.first_name,
              last_name: values?.last_name,
              email_id: values?.email_id,
              primary_contact_code_id: 1,
              primary_contact_number: values?.primary_contact_number,
              secondary_contact_code_id: 1,
              secondary_contact_number: null,
              address_line_1: values?.address_line_1,
              address_line_2: values?.address_line_2,
              city_id: values?.city_id,
              country_id: values?.country_id,
              province_id: values?.province_id,
              zipcode_id: values?.zipcode_id,
              pan_number: values?.pan_number,
              is_80g_tax_opted: values?.is_80g_tax_opted,
              money_donation_type_id: values?.money_donation_type_id
              // },
            };
            MoneyDonationService.payU(requestData)
              .then((res) => {
                if (res.data.success) {
                  setPayUData(res.data.data);
                  setTimeout(() => {
                    setProcessing(false);
                    if (myButtonRef.current) {
                      myButtonRef.current.click();
                    }
                  }, 200);
                  // navigate(routes.moneyDonationSuccess);
                }
              })
              .catch((error) => {
                setProcessing(false);
                toast.error(error.response?.data?.message, {
                  autoClose: 1500
                });
                navigate(routes.moneyDonationFalied);
              });
          }
        });
      }
    }
    // --------
  });

  //////////////////////////////////////////////////////////////////////////////
  useEffect(() => {}, [formik?.values?.country_id]);
  // ----------

  // HIDE IDENTITY FORMIK LOGIC
  const hideIndentityFormik = useFormik({
    initialValues: {
      amount: data?.amount ?? '',
      category: 'website',
      payment_type: 'online',
      user_identity: 'hidden',
      user_id: userData?.id,
      money_donation_type_id: data?.money_donation_type_id ?? ''

      // is_identity_hidden: "",
    },
    validate,

    // validationSchema: MoneyDonationSchema,
    onSubmit: (values) => {
      setProcessing(true);
      const requestData = {
        // is_identity_hidden: true,
        user_identity: 'hidden',
        payment_type: 'online',
        amount: Number(values?.amount),
        // category: values?.category,
        money_donation_type_id: values?.money_donation_type_id ?? '',
        category: 'website',
        user_id: 1
      };
      MoneyDonationService.payU(requestData)
        .then((res) => {
          if (res.data.success) {
            setPayUData(res.data.data);
            setTimeout(() => {
              setProcessing(false);
              if (myButtonRef.current) {
                myButtonRef.current.click();
              }
            }, 200);
            // navigate(routes.moneyDonationSuccess);
          }
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, { autoClose: 1500 });
          navigate(routes.moneyDonationFalied);
        });
    }
  });
  // -----------

  // CHECK IF USER REGISTER OR NOT
  const checkForContactNumber = (event) => {
    if (formik?.values?.primary_contact_number) {
      MoneyDonationService.checkRegisteredNumber(
        formik?.values?.primary_contact_number
      ).then((res) => {
        if (res.data.success) {
          setNumberRegistered(true);
          setNumberEditable(false);
          setUserData(res.data.data[0]);
          // console.log(res.data.data[0].UserAddresses);
          if (res?.data?.data[0]?.UserAddresses?.length > 0) {
            setUserAddress(res?.data?.data[0]?.UserAddresses[0]);
          }
          MoneyDonationService.sendOTP(formik?.values?.primary_contact_number)
            .then((res) => {
              if (res.data.success) {
                setOTPSend(true);
                setSeconds(30);
              }
            })
            .catch((error) => {
              toast.error(error.response?.data?.message, { autoClose: 1500 });
              navigate(routes?.error500);
            });
        }
      });
    }
  };
  // ----------
  // console.log(userAddress);
  /**
   * Resend OTP
   * @param primary_contact_number
   */
  const resendOTP = () => {
    MoneyDonationService.sendOTP(formik?.values?.primary_contact_number)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message, { autoClose: 1500 });
        navigate(routes?.error500);
      });
  };
  // ----------

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  /**
   * Verify OTP
   * @param primary_contact_number
   */
  const verifyOTP = () => {
    if (otp?.length === 6) {
      formik.setFieldValue('first_name', '');
      formik.setFieldValue('last_name', '');
      formik.setFieldValue('email_id', '');
      formik.setFieldValue('address_line_1', '');
      formik.setFieldValue('address_line_2', '');
      formik.setFieldValue('city_id', '');
      formik.setFieldValue('country_id', '');
      formik.setFieldValue('province_id', '');
      formik.setFieldValue('zipcode_id', '');
      formik.setFieldValue('pan_number', '');
      // formik?.resetForm()

      MoneyDonationService.verifyOTP(
        formik?.values?.primary_contact_number,
        otp
      )
        .then((res) => {
          if (res.data.success) {
            if (userData) {
              setOpenModal(true);
            }
            setShowForm(true);
            // setOTPVerified(true);
          }
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, { autoClose: 1500 });
          navigate(routes?.error500);
        });
    }
  };
  // ----------

  // DATA BINDIG FOR USERDATA
  const bindData = () => {
    getProvince(userAddress.country_id);
    getCity(userAddress.province_id);
    getZipcodeByCityDeliverable(userAddress.city_id);
    formik.setFieldValue('first_name', userData?.first_name);
    formik.setFieldValue('last_name', userData?.last_name);
    formik.setFieldValue('email_id', userData?.email_id);
    formik.setFieldValue('address_line_1', userAddress?.address_line_1);
    formik.setFieldValue('address_line_2', userAddress?.address_line_2);
    formik.setFieldValue('city_id', userAddress?.city_id);
    // formik.setFieldValue("country_id", userAddress?.country_id ?? 1);
    // formik.setFieldValue("province_id", userAddress?.province_id ?? 11);
    formik.setFieldValue('country_id', userAddress?.country_id);
    formik.setFieldValue('province_id', userAddress?.province_id);
    formik.setFieldValue('zipcode_id', userAddress?.zipcode_id);
    formik.setFieldValue('pan_number', userData?.pan_number);
    let index = cityList.findIndex((city) => city.value === userData?.city_id);
    setSelectedCity(index);
    setOpenModal(false);
  };
  // --------
  const { handleChange, handleSubmit } = formik;

  // Find the default value for "General Donation"
  const [defaultSet, setDefaultSet] = useState(false);

  useEffect(() => {
    if (
      !formik.values.money_donation_type_id &&
      causeList.length > 0 &&
      !defaultSet
    ) {
      const generalDonation = causeList.find(
        (option) => option.label === 'General Donation'
      );
      if (generalDonation) {
        formik.setFieldValue('money_donation_type_id', generalDonation.value);
      }
      setDefaultSet(true); // Ensure this runs only once
    }
  }, [causeList, formik, defaultSet]);

  useEffect(() => {
    if (
      !hideIndentityFormik.values.money_donation_type_id &&
      causeList.length > 0 &&
      !defaultSet
    ) {
      const generalDonation = causeList.find(
        (option) => option.label === 'General Donation'
      );
      if (generalDonation) {
        hideIndentityFormik.setFieldValue(
          'money_donation_type_id',
          generalDonation.value
        );
      }
      setDefaultSet(true); // Ensure this runs only once
    }
  }, [
    causeList,
    hideIndentityFormik.values.money_donation_type_id,
    defaultSet
  ]);

  // const handleCheckboxChange = (event) => {
  //   setAccepted(event.target.checked);
  //   localStorage.setItem("accepted", event.target.checked);
  // };

  // const handleNextButtonClick = () => {
  //   if (accepted) {
  //     window.location.href = "sang";
  //   } else {
  //     alert("You must accept the terms and conditions.");
  //   }
  // };

  // START RETURN

  return (
    <>
      {/* HELMET TAG  */}
      <Helmet>
        <title>Ambaji Temple - Money Donation Receipt</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details, Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji, Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* -------- */}

      <div className="main-wrapper">
        {/* DONATION MONEY BANNER  */}
        <section className="header-banner money-banner">
          <div className="container">
            <h1>Donation - Money</h1>
          </div>
        </section>
        {/* -------- */}

        {/* FIELDS SECTION  */}
        <section className="gold-donation">
          <div className="container">
            <div className="row">
              <div className="col-xl-12 ">
                <div className="card">
                  <div className="row">
                    <div className="col-lg-7">
                      <div className="donationform mt-3">
                        <div className="identity-option form-group">
                          <label>Donation Identity</label>
                          <br />
                          <div
                            className="flexinner"
                            style={{ display: 'inline-block' }}
                          >
                            <div
                              className="show"
                              style={{
                                cursor: 'pointer',
                                marginBottom: '10px'
                              }}
                              onClick={() => [
                                setIdentityHidden('show'),
                                formik?.setFieldValue('user_identity', false)
                              ]}
                            >
                              <input
                                type="radio"
                                value={false}
                                name="user_identity"
                                style={{ marginRight: '5px' }}
                                checked={isIdentityHidden === 'show'}
                                //   onClick={() => setIdentityHidden("show")}
                              />
                              <span>Show My Identity (Enter Details)</span>
                            </div>

                            <div
                              className="hide"
                              style={{ cursor: 'pointer' }}
                              onClick={() => [
                                setIdentityHidden('hide'),
                                formik?.setFieldValue('user_identity', true)
                              ]}
                            >
                              <input
                                type="radio"
                                value={true}
                                name="user_identity"
                                checked={isIdentityHidden === 'hide'}
                                //   onClick={() => setIdentityHidden("hide")}
                              />{' '}
                              <span style={{ marginRight: '10px' }}>
                                Hide My Identity (Guest Donation)
                              </span>
                            </div>
                          </div>
                        </div>
                        {isIdentityHidden === 'show' ? (
                          <form
                            onSubmit={formik.handleSubmit}
                            autoComplete="off"
                          >
                            <div className="row align-items-center mb-3">
                              <div className="form-group col-12 col-md-8">
                                <div className="row">
                                  <div className="col-4">
                                    <label htmlFor="primary_contact_code_id">
                                      Code / કોડ
                                    </label>
                                    <select
                                      name="primary_contact_code_id"
                                      id="primary_contact_code_id"
                                      className="form-control"
                                      disabled={!isNumberEditable}
                                    >
                                      <option value={0} disabled>
                                        Select
                                      </option>
                                      {phoneCodeList?.map((item, i) => {
                                        return (
                                          <option
                                            value={item?.id}
                                            defaultValue={
                                              item?.phone_code == '91'
                                            }
                                            key={i}
                                          >
                                            +{item?.phone_code}
                                          </option>
                                        );
                                      })}
                                    </select>
                                  </div>
                                  <div className="col-8">
                                    <label>Mobile Number / મોબાઈલ નંબર</label>
                                    <input
                                      type="text"
                                      inputMode="numeric"
                                      className="form-control"
                                      name="primary_contact_number"
                                      value={
                                        formik?.values.primary_contact_number
                                      }
                                      onChange={handleChange}
                                      disabled={!isNumberEditable}
                                      required
                                    />
                                    <p className="mb-1 red-text">
                                      {formik?.errors?.primary_contact_number}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-8 col-md-4">
                                {isNumberEditable ? (
                                  <button
                                    type="button"
                                    className="whitebtn"
                                    onClick={() => checkForContactNumber()}
                                  >
                                    Verify Number
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="whitebtn"
                                    onClick={() => [
                                      setNumberEditable(true),
                                      setNumberRegistered(false),
                                      setShowForm(false)
                                    ]}
                                  >
                                    Edit Number
                                  </button>
                                )}
                              </div>
                            </div>

                            {isNumberRegistered &&
                              !isNumberEditable &&
                              !isShowForm && (
                                <div className="row align-items-center mb-3">
                                  <div className="col-md-5 col-6 form-group">
                                    <label htmlFor="otp">OTP</label>

                                    <input
                                      type="text"
                                      inputMode="numeric"
                                      minLength="6"
                                      maxLength="6"
                                      pattern="[0-9]*" // Only allow numeric input
                                      name="otp"
                                      id="otp"
                                      className="form-control"
                                      placeholder="_ _ _ _ _ _"
                                      value={otp}
                                      onChange={(event) => {
                                        const inputValue =
                                          event.target.value.replace(/\D/g, ''); // Remove non-digit characters
                                        setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                                        setResend(false); // Reset the resend state if needed
                                      }}
                                    />
                                  </div>

                                  <div className="col-md-7 col-12">
                                    {/* VERIFY OTP FIELD  */}
                                    <button
                                      type="button"
                                      className="orangebtn"
                                      disabled={otp?.length !== 6}
                                      onClick={() => verifyOTP()}
                                    >
                                      Verify OTP
                                    </button>

                                    {/* RESEND OTP FIELD  */}
                                    {isOTPSend && seconds == '00' && (
                                      <button
                                        type="button"
                                        className="whitebtn ms-3"
                                        onClick={() => [
                                          resendOTP(),
                                          setOTP(''),
                                          setResend(true)
                                        ]}
                                      >
                                        Resend OTP
                                      </button>
                                    )}
                                  </div>

                                  {/* OTP TIMER LOGIC  */}
                                  {isOTPSend && seconds > 0 ? (
                                    <p>
                                      Resend OTP in
                                      {seconds < 10
                                        ? ` 0${seconds}`
                                        : ` ${seconds}`}{' '}
                                      sec
                                    </p>
                                  ) : (
                                    <p></p>
                                  )}
                                  {!isResend &&
                                    (otp?.length > 6 || otp?.length < 6) && (
                                      <p className="red-text">
                                        OTP must be a 6 digits number
                                      </p>
                                    )}
                                </div>
                              )}

                            {/* ALL FIELDS AFTER VERIFY OTP  */}
                            {isShowForm && !isNumberEditable ? (
                              <>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <label>Donation Purpose / દાન હેતુ</label>
                                    <Select
                                      name="money_donation_type_id"
                                      className={
                                        formik?.errors.money_donation_type_id &&
                                        formik?.touched.money_donation_type_id
                                          ? 'is-invalid'
                                          : 'valid'
                                      }
                                      options={causeList}
                                      // onBlur={() =>
                                      //   formik?.setFieldTouched(
                                      //     "money_donation_type_id",
                                      //     true
                                      //   )
                                      // }
                                      onBlur={formik.handleBlur}
                                      value={causeList.find(
                                        (option) =>
                                          option.value ===
                                          formik.values.money_donation_type_id
                                      )}
                                      onChange={(option) =>
                                        formik.setFieldValue(
                                          'money_donation_type_id',
                                          option.value
                                        )
                                      }
                                      // defaultValue={
                                      //   userData?.money_donation_type_id
                                      //     ? causeList.find(
                                      //         (option) =>
                                      //           option.value ===
                                      //           userData.money_donation_type_id
                                      //       )
                                      //     : null
                                      // }

                                      isSearchable={true}
                                      required
                                    />
                                    {/* {formik.errors.country &&
                                          formik.touched.country && (
                                            <div className="invalid-feedback">
                                              {formik.errors.country}
                                            </div>
                                          )} */}
                                  </div>
                                </div>

                                <div className="row mt-3">
                                  {/* FIRST NAME FIELD  */}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>First Name / પ્રથમ નામ</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="first_name"
                                        value={formik?.values.first_name}
                                        onChange={handleChange}
                                        required
                                      />
                                      {/* {formik?.touched?.first_name &&
                                          formik?.errors?.first_name && (
                                            <p className="mb-1 red-text">
                                              {formik?.errors?.first_name}
                                            </p>
                                          )} */}
                                    </div>
                                  </div>

                                  {/* LAST NAME FIELD */}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>Last Name / અટક</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="last_name"
                                        value={formik?.values.last_name}
                                        onChange={handleChange}
                                        required
                                      />
                                      {/* {formik?.touched?.last_name &&
                                          formik?.errors?.last_name && (
                                            <p className="mb-1 red-text">
                                              {formik?.errors?.last_name}
                                            </p>
                                          )} */}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  {/* EMAIL ID FIELD */}
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>Email Id / ઈમેલ આઈડી</label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        name="email_id"
                                        value={formik?.values.email_id}
                                        onChange={handleChange}
                                        required
                                      />
                                      {/* {formik?.touched?.email_id &&
                                          formik?.errors?.email_id && (
                                            <p className="mb-1 red-text">
                                              {formik?.errors?.email_id}
                                            </p>
                                          )} */}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  {/* PAN CARD FIELD */}
                                  <div className="col-12">
                                    <div className="form-group">
                                      <label>
                                        PAN Number (Required to claim TAX) / પાન
                                        કાર્ડ નંબર
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="pan_number"
                                        value={formik?.values.pan_number}
                                        onChange={handleChange}
                                        style={{ textTransform: 'uppercase' }}
                                      />
                                      {formik?.errors?.pan_number && (
                                        <p className="mb-1 red-text">
                                          {formik?.errors?.pan_number}{' '}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <label
                                    style={{
                                      marginBottom: '25px'
                                    }}
                                  >
                                    <input
                                      style={{
                                        width: '16px',
                                        height: '16px',
                                        marginRight: '10px'
                                      }}
                                      type="checkbox"
                                      id="accept-checkbox"
                                      // onChange={handleCheckboxChange}
                                      name="is_80g_tax_opted"
                                      checked={formik.values.is_80g_tax_opted}
                                      onChange={formik.handleChange}
                                      value={formik?.values.is_80g_tax_opted}
                                    />
                                    <b>
                                      Do you require IT 80(G) deduction claims?
                                    </b>
                                  </label>
                                  {/* <button
                              className="orangebtn"
                              type="next"
                              style={{ width: "100%" }}
                              onClick={handleNextButtonClick}
                            >
                              Next
                            </button> */}
                                </div>
                                <div className="row">
                                  {/* ADDRESS FIELD 1 */}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Address Line 1 / એડ્રેસ લાઇન 1
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="address_line_1"
                                        value={formik?.values.address_line_1}
                                        onChange={handleChange}
                                        required
                                      />
                                      {/* {formik?.errors?.address_line_1 && (
                                          <p className="mb-1 red-text">
                                            {formik?.errors?.address_line_1}
                                          </p>
                                        )} */}
                                    </div>
                                  </div>

                                  {/* ADDRESS FIELD 2 */}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Address Line 2 / એડ્રેસ લાઇન 2
                                      </label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        name="address_line_2"
                                        value={formik?.values.address_line_2}
                                        onChange={handleChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  {/* COUNTRY FIELD  */}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label htmlFor="country_id">
                                        Country/દેશ
                                        <span className="text-red">*</span>
                                      </label>
                                      <select
                                        name="country_id"
                                        className="form-control"
                                        value={formik?.values?.country_id}
                                        required
                                        onChange={(event) => [
                                          formik.setFieldValue(
                                            'country_id',
                                            event.target.value
                                          ),
                                          getProvince(event)
                                        ]}
                                      >
                                        <option value="" selected disabled>
                                          Select Country
                                        </option>
                                        {countryList?.map((item, i) => {
                                          return (
                                            <option value={item?.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {/* {formik.errors.country &&
                                          formik.touched.country && (
                                            <div className="invalid-feedback">
                                              {formik.errors.country}
                                            </div>
                                          )} */}
                                    </div>
                                  </div>

                                  {/* PROVINCE FIELD  */}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label>
                                        Province/રાજ્ય
                                        <span className="text-red">*</span>
                                      </label>
                                      <select
                                        name="province_id"
                                        className="form-control"
                                        required
                                        value={formik?.values?.province_id}
                                        onChange={(event) => [
                                          formik.setFieldValue(
                                            'province_id',
                                            event.target.value
                                          ),
                                          getCity(event)
                                        ]}
                                      >
                                        <option value="">Select State</option>
                                        {provinceList?.map((item, i) => {
                                          return (
                                            <option value={item?.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {formik?.touched?.province_id &&
                                        formik?.errors?.province_id && (
                                          <p className="red-text">
                                            {formik?.errors?.province_id}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  {/* CITY FIELD  */}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label htmlFor="city_id">
                                        City/શહેર
                                        <span className="text-red">*</span>
                                      </label>
                                      <select
                                        name="city_id"
                                        className="form-control"
                                        required
                                        value={formik?.values?.city_id}
                                        onChange={(event) => [
                                          formik.setFieldValue(
                                            'city_id',
                                            event.target.value
                                          ),
                                          getZipcodeByCityDeliverable(event)
                                        ]}
                                        // onChange={formik.handleChange}
                                      >
                                        <option value="" selected disabled>
                                          Select City
                                        </option>
                                        {console.log(cityList)}
                                        {cityList?.map((item, i) => {
                                          return (
                                            <option value={item?.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {formik?.touched?.city_id &&
                                        formik?.errors?.city_id && (
                                          <p className="red-text">
                                            {formik?.errors?.city_id}
                                          </p>
                                        )}
                                    </div>
                                  </div>

                                  {/* ZIPCODE FIELD  */}
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <label htmlFor="zipcode_id">
                                        Zipcode/પિનકોડ
                                        <span className="text-red">*</span>{' '}
                                        <small>
                                          (Below listed zipcodes are
                                          deliverable)
                                        </small>
                                      </label>
                                      <select
                                        name="zipcode_id"
                                        required
                                        className="form-control"
                                        value={formik?.values?.zipcode_id || ''}
                                        onChange={formik.handleChange}
                                      >
                                        <option value="" selected disabled>
                                          Select Zipcode
                                        </option>
                                        {zipcodeList?.map((item, i) => {
                                          return (
                                            <option
                                              key={item?.id}
                                              value={item?.id}
                                            >
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                      {formik?.touched?.zipcode_id &&
                                        formik?.errors?.zipcode_id && (
                                          <p className="red-text">
                                            {formik?.errors?.zipcode_id}
                                          </p>
                                        )}
                                    </div>
                                  </div>
                                </div>

                                {/* AMOUNT  */}
                                <div className="form-group">
                                  <label>Amount / રકમ</label>
                                  <input
                                    type="text"
                                    inputMode="numeric"
                                    // className="form-control"
                                    className={`form-control ${
                                      formik.touched.amount &&
                                      formik.errors.amount
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    name="amount"
                                    value={formik?.values.amount}
                                    // onChange={handleChange}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    onKeyDown={(e) => {
                                      // Allow only digits (0-9), backspace, and delete
                                      const allowedKeys = [
                                        '0',
                                        '1',
                                        '2',
                                        '3',
                                        '4',
                                        '5',
                                        '6',
                                        '7',
                                        '8',
                                        '9',
                                        'Backspace',
                                        'Delete'
                                      ];
                                      if (!allowedKeys.includes(e.key)) {
                                        e.preventDefault();
                                      }
                                    }}
                                  />
                                  {formik.touched.amount &&
                                    formik.errors.amount && (
                                      <p className="mb-1 red-text">
                                        {formik.errors.amount}
                                      </p>
                                    )}
                                </div>

                                {/* BUTTONS FIELD  */}
                                <button
                                  className="orangebtn"
                                  type="submit"
                                  style={{ width: '100%' }}
                                  disabled={isProcessing}
                                  // disabled={!formik?.isValid}
                                  // onClick={handleNextButtonClick}
                                >
                                  {/* Donate Money */}
                                  {isProcessing
                                    ? 'Processing...'
                                    : 'Donate Money'}
                                </button>
                              </>
                            ) : null}
                          </form>
                        ) : isIdentityHidden === 'hide' ? (
                          <form onSubmit={hideIndentityFormik?.handleSubmit}>
                            {/* DONATION PURPOSE FIELD */}
                            <div className="form-group">
                              <label>Donation Purpose / દાન હેતુ</label>
                              <Select
                                name="money_donation_type_id"
                                className={
                                  hideIndentityFormik?.errors
                                    .money_donation_type_id &&
                                  hideIndentityFormik?.touched
                                    .money_donation_type_id
                                    ? 'is-invalid'
                                    : 'valid'
                                }
                                options={causeList}
                                onBlur={hideIndentityFormik.handleBlur}
                                value={causeList.find(
                                  (option) =>
                                    option.value ===
                                    hideIndentityFormik.values
                                      .money_donation_type_id
                                )}
                                onChange={(option) =>
                                  hideIndentityFormik.setFieldValue(
                                    'money_donation_type_id',
                                    option.value || ''
                                  )
                                }
                                isSearchable={true}
                                required
                              />
                              <p className="mb-1 red-text">
                                {
                                  hideIndentityFormik?.errors
                                    ?.money_donation_type_id
                                }
                              </p>
                            </div>

                            {/* AMOUNT FIELD  */}
                            <div className="form-group">
                              <label>Amount / રકમ</label>
                              <input
                                type="text"
                                inputMode="numeric"
                                // className="form-control"
                                className={`form-control ${
                                  hideIndentityFormik.touched.amount &&
                                  hideIndentityFormik.errors.amount
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="amount"
                                value={hideIndentityFormik?.values.amount}
                                // onChange={handleChange}
                                onChange={hideIndentityFormik.handleChange}
                                onKeyDown={(e) => {
                                  // Allow only digits (0-9), backspace, and delete
                                  const allowedKeys = [
                                    '0',
                                    '1',
                                    '2',
                                    '3',
                                    '4',
                                    '5',
                                    '6',
                                    '7',
                                    '8',
                                    '9',
                                    'Backspace'
                                  ];
                                  if (!allowedKeys.includes(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                              />
                              {hideIndentityFormik.touched.amount &&
                                hideIndentityFormik.errors.amount && (
                                  <p className="mb-1 red-text">
                                    {hideIndentityFormik.errors.amount}
                                  </p>
                                )}
                            </div>

                            {/* DONATION MONEY FIELD BUTTON  */}
                            <button
                              className="orangebtn"
                              type="submit"
                              style={{ width: '100%' }}
                              // disabled={!formik?.isValid}
                              // onClick={handleNextButtonClick}
                            >
                              Donate Money
                            </button>
                          </form>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-lg-5 imgorder">
                      <div className="donateimg">
                        <img src={ambajiTemple2} alt="Site Title"></img>
                        <div className="donateimg-inner">
                          <img src={ambajiTemple3} alt="Site Title"></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* -------- */}

        {/* ARASURI AND FOOTER  */}
        <Arasuri />
        <Footer />
        {/* -------- */}

        {/* MODAL IF USER REGISTERED  */}
        <Modal
          isOpen={openModal}
          onRequestClose={() => setOpenModal(false)}
          style={customStyles}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <h4>Use the following details as registred with us?</h4>
          {userData && (
            <div className="row">
              <div className="col-4">
                <b>First Name:</b>{' '}
              </div>
              <div className="col-6">{userData?.first_name || '-'}</div>

              <div className="col-4">
                <b>Last Name:</b>
              </div>
              <div className="col-6">{userData?.last_name || '-'}</div>

              <div className="col-4">
                <b>Email Id:</b>
              </div>
              <div className="col-6">{userData?.email_id || '-'}</div>

              <div className="col-4">
                <b>PAN Number:</b>
              </div>
              <div className="col-6">{userData?.pan_number || '-'}</div>

              <div className="col-4">
                <b>Address Line 1:</b>
              </div>
              <div className="col-6">{userAddress?.address_line_1 || '-'}</div>

              <div className="col-4">
                <b>Address Line 2:</b>
              </div>
              <div className="col-6">{userAddress?.address_line_2 || '-'}</div>

              <div className="col-4">
                <b>Country:</b>
              </div>
              <div className="col-6">{userAddress?.Country?.name || '-'}</div>

              <div className="col-4">
                <b>Province:</b>
              </div>
              <div className="col-6">{userAddress?.Province?.name || '-'}</div>

              <div className="col-4">
                <b>City:</b>
              </div>
              <div className="col-6">{userAddress?.City?.name || '-'}</div>

              <div className="col-4">
                <b>Zipcode:</b>
              </div>
              <div className="col-6">{userAddress?.ZipCode?.name || '-'}</div>
            </div>
          )}
          <br />
          <div className="d-flex justify-content-between">
            <button
              type="button"
              className="whitebtn"
              onClick={() => setOpenModal(false)}
            >
              No
            </button>
            <button
              type="button"
              className="orangebtn"
              onClick={() => bindData()}
            >
              Yes
            </button>
          </div>
        </Modal>
        {/* --------- */}

        {/* PAYMENT GATEWAY FORM  */}
        <form method="POST" action={payu_api_url}>
          <input type="hidden" name="key" value={payu_key} />
          <input type="hidden" name="txnid" value={payUData?.txnid} />
          <input type="hidden" name=" amount" value={payUData?.amount} />
          <input
            type="hidden"
            name="productinfo"
            value={payUData?.productinfo}
          />
          <input type="hidden" name=" firstname" value={payUData?.firstname} />
          <input type="hidden" name="lastname" value={payUData?.lastname} />
          <input type="hidden" name="email" value={payUData?.email} />
          <input type="hidden" name="phone" value="9198672387" />
          <input type="hidden" name="surl" value={payUData?.surl} />
          <input type="hidden" name="furl" value={payUData?.furl} />
          <input type="hidden" name="curl" value={payUData?.curl} />
          <input type="hidden" name="hash" value={payUData?.hash} />
          <input type="hidden" name="udf1" value={payUData?.udf1} />
          <input type="hidden" name="udf2" value={payUData?.udf2} />
          <input type="hidden" name="udf3" value={payUData?.udf3} />
          <input type="hidden" name="udf4" value={payUData?.udf4} />
          <input type="hidden" name="udf5" value={payUData?.udf5} />
          <button hidden={true} ref={myButtonRef}>
            Submit
          </button>
        </form>
        {/* --------- */}
      </div>
      {isLoading && <LazyLoader />}
    </>
  );

  // END RETURN
};

export default DonationMoney;
