import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import CartService from "../../../Api_config/E_store/cart";
import Footer from "../../../Components/footer";
import Gallery from "../../../Components/gallery/gallery";
import LazyLoader from "../../../Components/lazyLoader";
import { ReactComponent as Cart } from "../../../Assets/Images/E_store/icon-cart.svg";

import "./onlinePrasadDetail.scss";
import "../../../Css/gallery.scss";
import Arasuri from "../../../Components/arasuri";
import OnlinePrasadService from "../../../Api_config/E_store/onlinePrasad";
import { useParams } from "react-router-dom";
import { routes } from "../../../_Constants/routes";

const OnlinePrasadDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [number, setNumber] = useState(1);
  const [data, setData] = useState();
  const [currentVariant, setCurrentVariant] = useState();
  const [isLoading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [id, setId] = useState();
  // setId(location?.state?.id);
  // const parseUrlParams = () => {
  //   const pathname = window.location.pathname;
  //   const pathSegments = pathname.split("/");

  //   // Assuming the URL format is: /online-ambaji-temple-prasad-detail/:name/:id
  //   if (pathSegments.length >= 5) {
  //     setName(decodeURIComponent(pathSegments[3]));
  //     setId(pathSegments[4]);
  //   }
  // };
  // if (location?.state?.id) {
  //   setId(location?.state?.id);
  // } else {
  //   parseUrlParams();
  // }
  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     parseUrlParams();
  //     setLoading(false); // Set loading to false after 5000 milliseconds (5 seconds)
  //   }, 3000);

  //   return () => clearTimeout(timeoutId);
  // }, [setId]);
  // useEffect(() => {
  //   if (id) {
  //     OnlinePrasadService.getById(id).then((res) => {
  //       // OnlinePrasadService.getById(location?.state?.id).then((res) => {
  //       if (res.data.success) {
  //         setData(res.data.data[0]);
  //         setCurrentVariant(res.data.data[0]?.ProductsVariants[0]);
  //         setTimeout(() => {
  //           setLoading(false);
  //         }, [5000]);
  //       }
  //     });
  //   } else {
  //     parseUrlParams();
  //   }

  //   //   .then((res) => {
  //   //     if (res.data.success && res.data.data.length > 0) {
  //   //       setData(res.data.data[0]);
  //   //       setCurrentVariant(res.data.data[0]?.ProductsVariants[0]);
  //   //       setLoading(false);
  //   //     } else {
  //   //       // Handle empty data or display an error message
  //   //       setLoading(false);
  //   //     }
  //   //   });
  //   // .catch((e) => console.log(e));
  // }, [setCurrentVariant, setId]);

  // LOGIC FOR URL PASS TO THE ANOTHER TAB
  useEffect(() => {
    const parseUrlParams = () => {
      const pathname = window.location.pathname;
      const pathSegments = pathname.split("/");

      // Assuming the URL format is: /online-ambaji-temple-prasad-detail/:name/:id
      if (pathSegments.length >= 1) {
        setName(decodeURIComponent(pathSegments[2]));
        setId(pathSegments[3]);
      }
    };

    parseUrlParams();
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true); // Set loading to true when starting to fetch data

      OnlinePrasadService.getById(id)
        .then((res) => {
          if (res.data.success && res.data.data.length > 0) {
            setData(res.data.data[0]);
            setCurrentVariant(res.data.data[0]?.ProductsVariants[0]);
          } else {
            // Handle empty data or display an error message
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // Handle API call error
          // console.error("Error fetching data:", error);
        })
        .finally(() => {
          setLoading(false); // Always set loading to false after API call completes
        });
    }
  }, [id]);
  // console.log(currentVariant);
  // const buyNow = () => {

  //   console.log("Navigating to checkout with state: ", { isDirectBuy: true });

  //   const requestData = {
  //     product_id: data?.id,
  //     product_variant_id: currentVariant?.id,
  //     qty: number,
  //     product_category_id: data?.category_id,
  //     variant_data: currentVariant,
  //     product_name: data?.name,
  //   };
  //   console.log("Request Data: ", requestData);

  //   const response = CartService.buyNow(requestData);
  //   console.log("buy now ");
  //   navigate("/checkout", { state: { isDirectBuy: true } });

  //   // console.log(response);
  // };

  const buyNow = async () => {
    // console.log("buy now works");
    const requestData = {
      product_id: data?.id,
      product_variants_id: currentVariant?.id,
      quantity: number,
      product_category_id: data?.category_id,
      // ProductsVariant: currentVariant,
      // product_name: data?.name,
      note: currentVariant?.note,
      user_id: parseInt(localStorage.getItem("user_id")),
    };
    localStorage.setItem("cartData", JSON.stringify(requestData));

    // console.log("Request Data: ", requestData);

    try {
      const res = await CartService.getBuyNowData(requestData);
      // CartService.buyNow(requestData);

      const state = { isDirectBuy: true };
      // console.log("Navigating to checkout with state: ", state);

      if (res?.data?.code === 200) {
        navigate("/checkout", { state: { isDirectBuy: true } });
      }
    } catch (error) {
      // console.log(error);
    }
  };

  return (
    <>
      {!isLoading ? (
        <div className="online-prashad-detail">
          <section className="header-banner shopDetailed-banner">
            <div className="container">
              <h1>{data?.name}</h1>
            </div>
          </section>
          <section className="shop-page">
            <main className="container">
              <div className="row">
                <div className="col-lg-5 col-md-6 col-sm-12">
                  <Gallery currentVariant={currentVariant} />
                </div>
                <div className="col-lg-7 col-md-6 col-sm-12">
                  <h1 className="main__h1">{data?.name}</h1>
                  <p className="main__p">{data?.description}</p>
                  <div className="main__price-box">
                    <div className="main__current-price">
                      <h2 className="main__h2 mb-0">
                        ₹{currentVariant?.sell_rate}
                      </h2>
                      {/* {data?.ProductsVariants[0]?.mrp !==
                        data?.ProductsVariants[0]?.sell_rate && (
                        <div className="main__previous-price">
                          {data?.ProductsVariants[0]?.mrp}
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="d-flex mb-3" style={{ gap: "0.5rem" }}>
                    {data?.ProductsVariants?.map((item, i) => {
                      return (
                        <p
                          className={
                            "main__p--discount" +
                            (currentVariant?.id === item?.id
                              ? " main__p--discount-active"
                              : "")
                          }
                          onClick={() => setCurrentVariant(item)}
                        >
                          {item?.value_in_uom +
                            " " +
                            item?.UnitOfMeasurement?.name}
                        </p>
                      );
                    })}
                  </div>
                  <div className="main_text">
                    <p>
                      {/* <span>
                        <b>નોંધ :</b> ૪૦૦ ગ્રામ માં ૧૦૦ ગ્રામ ના ૪ બોક્સ પૅકિંગ
                        પ્રાપ્ત થશે.
                      </span> */}
                      <span>
                        <b>{currentVariant?.note}</b>
                      </span>
                    </p>
                  </div>
                  {/* {currentVariant ? (
                    currentVariant?.stock_value[0]?.quantity === 0 ||
                    currentVariant?.stock_value?.length === 0 ? (
                      <div className="stock-out">
                        <p>Out of stock</p>
                      </div>
                    ) : null
                  ) : null} */}
                  <div className="main__buttons">
                    {/* {currentVariant && currentVariant.stock_value && ( */}
                    <button
                      className="p-3 orangebtn main__buy-button"
                      onClick={() => buyNow()}
                      //   disabled={
                      //     currentVariant.stock_value.length > 0
                      //       ? currentVariant.stock_value[0].quantity < number
                      //         ? true
                      //         : false
                      //       : true
                      //   }
                    >
                      <Cart className="main__cart" />
                      ખરીદી કરો
                    </button>
                    {/* )} */}
                  </div>

                  {/* <div className="main__buttons">
                    
                    <button
                      className="p-3 orangebtn main__buy-button"
                      onClick={() => buyNow()}
                      disabled={
                        currentVariant?.stock_value?.length > 0
                          ? currentVariant?.stock_value[0]?.quantity < number
                            ? true
                            : false
                          : true
                      }
                    >
                      <Cart className="main__cart" />
                      ખરીદી કરો
                    </button>
                  </div> */}
                </div>
              </div>
            </main>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};
export default OnlinePrasadDetail;

// import React, { useEffect } from "react";
// import { useState } from "react";
// import { useLocation, useNavigate } from "react-router";
// import CartService from "../../../Api_config/E_store/cart";
// import Footer from "../../../Components/lazyLoader";
// import Gallery from "../../../Components/gallery/gallery";
// import LazyLoader from "../../../Components/lazyLoader";
// // import temple1 from "../../../Assets/Images/Temple/temple1.png";
// // import IconMinus from "../../assets/images/icon-minus.svg";
// // import IconPlus from "../../assets/images/icon-plus.svg";
// // import PrayIcon from "../../../Assets/Images/Home_page/PrayIcon.svg";
// import { ReactComponent as Cart } from "../../../Assets/Images/E_store/icon-cart.svg";

// // import "../Shop/ShopOnline/ShopOnline.scss";
// import "../../../Css/gallery.scss";
// import OnlinePrasadService from "../../../Api_config/E_store/onlinePrasad";
// import Arasuri from "../../../Components/arasuri";

// const OnlinePrasadDetail = () => {
//   const location = useLocation();
//   const navigate = useNavigate();
//   const [number, setNumber] = useState(1);
//   const [data, setData] = useState();
//   const [currentVariant, setCurrentVariant] = useState();
//   const [isLoading, setLoading] = useState(true);

//   useEffect(() => {
//     OnlinePrasadService.getById(location?.state?.id).then((res) => {
//       if (res.data.success) {
//         setData(res.data.data[0]);
//         console.log("setData");
//         setCurrentVariant(res.data.data[0]?.ProductsVariants[0]);
//         setTimeout(() => {
//           setLoading(false);
//         }, [5000]);
//       }
//     });
//     // .catch((e) => console.log(e));
//   }, [setCurrentVariant]);
//   // console.log(currentVariant);
//   // const buyNow = () => {

//   //   console.log("Navigating to checkout with state: ", { isDirectBuy: true });

//   //   const requestData = {
//   //     product_id: data?.id,
//   //     product_variant_id: currentVariant?.id,
//   //     qty: number,
//   //     product_category_id: data?.category_id,
//   //     variant_data: currentVariant,
//   //     product_name: data?.name,
//   //   };
//   //   console.log("Request Data: ", requestData);

//   //   const response = CartService.buyNow(requestData);
//   //   console.log("buy now ");
//   //   navigate("/checkout", { state: { isDirectBuy: true } });

//   //   // console.log(response);
//   // };

//   const buyNow = () => {
//     console.log("buy now works");
//     const requestData = {
//       product_id: data?.id,
//       product_variant_id: currentVariant?.id,
//       quantity: number,
//       product_category_id: data?.category_id,
//       variant_data: currentVariant,
//       product_name: data?.name,
//       note: currentVariant?.note,
//     };

//     // console.log("Request Data: ", requestData);

//     CartService.buyNow(requestData);

//     const state = { isDirectBuy: true };
//     // console.log("Navigating to checkout with state: ", state);

//     navigate("/checkout", { state: { isDirectBuy: true } });
//   };

//   return (
//     <>
//       {/* {!isLoading ? ( */}
//       <div className="online-prashad-detail">
//         <section className="header-banner shopDetailed-banner">
//           <div className="container">
//             <h1>{data?.name}</h1>
//           </div>
//         </section>
//         <section className="shop-page">
//           <main className="container">
//             <div className="row">
//               <div className="col-lg-5 col-md-6 col-sm-12">
//                 {/* <Gallery currentVariant={currentVariant} /> */}
//                 <Gallery currentVariant={currentVariant} />
//               </div>
//               <div className="col-lg-7 col-md-6 col-sm-12">
//                 <h1 className="main__h1">{data?.name}</h1>
//                 <p className="main__p">{data?.description}</p>
//                 <div className="main__price-box">
//                   <div className="main__current-price">
//                     <h2 className="main__h2 mb-0">₹{currentVariant?.mrp}</h2>
//                     {/* {data?.ProductsVariants[0]?.mrp !==
//                         data?.ProductsVariants[0]?.sell_rate && (
//                         <div className="main__previous-price">
//                           {data?.ProductsVariants[0]?.mrp}
//                         </div>
//                       )} */}
//                   </div>
//                 </div>
//                 <div className="d-flex mb-3" style={{ gap: "0.5rem" }}>
//                   {data?.ProductsVariants?.map((item, i) => {
//                     return (
//                       <p
//                         className={
//                           "main__p--discount" +
//                           (currentVariant?.id === item?.id
//                             ? " main__p--discount-active"
//                             : "")
//                         }
//                         onClick={() => setCurrentVariant(item)}
//                       >
//                         {item?.value_in_uom +
//                           " " +
//                           item?.UnitOfMeasurement?.name}
//                       </p>
//                     );
//                   })}
//                 </div>
//                 <div className="main_text">
//                   <p>
//                     {/* <span>
//                         <b>નોંધ :</b> ૪૦૦ ગ્રામ માં ૧૦૦ ગ્રામ ના ૪ બોક્સ પૅકિંગ
//                         પ્રાપ્ત થશે.
//                       </span> */}
//                     <span>
//                       <b>{currentVariant?.note}</b>
//                     </span>
//                   </p>
//                 </div>
//                 {/* {currentVariant ? (
//                       currentVariant?.stock_value[0]?.quantity === 0 ||
//                       currentVariant?.stock_value?.length === 0 ? (
//                         <div className="stock-out">
//                           <p>Out of stock</p>
//                         </div>
//                       ) : null
//                     ) : null} */}
//                 <div className="main__buttons">
//                   {/* <div className="main__number-box p-3">
//                       <button
//                         className="main__number-button"
//                         onClick={() => {
//                           if (number === 1) return;
//                           setNumber((prevValue) => prevValue - 1);
//                         }}
//                       >
//                         <img src={IconMinus} alt="Ambaji Temple Banaskatha Gujarat" />
//                       </button>
//                       <p className="main__p main__p--number">{number}</p>
//                       <button
//                         className="main__number-button"
//                         onClick={() => {
//                           setNumber((prevValue) => prevValue + 1);
//                         }}
//                       >
//                         <img src={IconPlus}alt="Ambaji Temple Banaskatha Gujarat" />
//                       </button>
//                     </div> */}

//                   <button
//                     className="p-3 orangebtn main__buy-button"
//                     // onClick={() => buyNow()}
//                     // disabled={
//                     //   currentVariant?.stock_value?.length > 0
//                     //     ? currentVariant?.stock_value[0]?.quantity < number
//                     //       ? true
//                     //       : false
//                     //     : true
//                     // }
//                   >
//                     <Cart className="main__cart" />
//                     ખરીદી કરો
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </main>
//         </section>
//         <Arasuri />
//         <Footer />
//       </div>
//       {/* ) : (
//          <LazyLoader />
//        )} */}
//     </>
//   );
// };
// export default OnlinePrasadDetail;
