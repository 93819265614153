import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const getAllNews = () => {
  return http.get(api_urls?.getAllNews);
};

const getByNewsId = (id) => {
    return http.get(`${api_urls?.getByNewsId}?id=${id}`);
  };


const NewsService = {
    getAllNews,
    getByNewsId
  };
  
  export default NewsService;
  