import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import UtilsService from '../../../Api_config/Home_page/utils';
import Footer from '../../../Components/footer';
import { routes } from '../../../_Constants/routes';
import { payu_api_url, payu_key } from '../../../_Constants/api_urls';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './mandirYagnaBooking.scss';
import Arasuri from '../../../Components/arasuri';
import YagnaBookingService from '../../../Api_config/Yagna_booking/yagnaBooking';
import LazyLoader from '../../../Components/lazyLoader';
import Error404 from '../../../Pages/error404';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const MandirYagnaBooking = () => {
  const navigate = useNavigate();
  const myButtonRef = useRef(null);
  const [startDate, setStartDate] = useState();
  const [templeList, setTempleList] = useState([]);
  const [yagnaCategory, setYagnaCategory] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [isAvailable, setAvailability] = useState(false);
  const [isOTPSend, setOTPSend] = useState(false);
  const [isOTPVerified, setOTPVerified] = useState(false);
  const [slotAvailableMsg, setSlotAvailableMsg] = useState('');
  const [payUData, setPayUData] = useState();
  const [yagnaDate, setYagnaDate] = useState();
  const [allAvailableTemple, setAvailableTemple] = useState([]);
  const [templeData, setTempleData] = useState();
  // const [allAvailableDates, setAvailableDates] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [otp, setOTP] = useState();
  const [isIdentityHidden, setIdentityHidden] = useState();

  const [initialValues, setInitialValues] = useState({
    yagna_date: '',
    yagna_temple_id: '',
    yagna_category_id: '',
    first_name: '',
    last_name: '',
    email_id: '',
    primary_contact_code_id: 1,
    primary_contact_number: '',
    address: ''
  });
  const [availabilityIntervalId, setAvailabilityIntervalId] = useState();
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    YagnaBookingService.getYagnaTemple()
      .then((res) => {
        if (res.data.success) {
          setTempleList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });

    /** Get Yagna Category */
    YagnaBookingService.getYagnaCategory()
      .then((res) => {
        if (res.data.success) {
          setYagnaCategory(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });

    /** Get Country */
    UtilsService.getCountry()
      .then((res) => {
        if (res.data.success) {
          setCountryList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  }, [setTempleList, setYagnaCategory, setCountryList, setAvailableTemple]);

  const checkForAvailability = (yagna_date) => {
    // console.log(yagna_date);
    if (yagna_date) {
      setYagnaDate(yagna_date);
      YagnaBookingService.getTempleByDate(yagna_date)
        .then(
          (res) => {
            if (res.data.success) {
              setAvailableTemple(res.data.data);
              setSlotAvailableMsg(
                res.data.data.length > 0 ? '' : 'No Data Found'
              );
              if (res.data.data?.length <= 0) {
                setSlotAvailableMsg(res.data?.message);
              }
            } else {
              setSlotAvailableMsg('No Data Found');
            }
          }
          // }
        )
        .catch((error) => {
          if (
            error?.response?.data?.code === 401 ||
            error?.response?.data?.code === 500
          ) {
            navigate(routes?.error500);
          } else {
            setSlotAvailableMsg(error?.response?.data?.message);
          }
        });
      if (yagna_date && !isOTPVerified && !isAvailable) {
        // console.log(isOTPVerified, yagna_date, isAvailable);
        const intervalId = setInterval(() => {
          checkForAvailability(yagna_date);
        }, 120000);
        setAvailabilityIntervalId(intervalId);
      }
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(availabilityIntervalId);
    };
  }, [availabilityIntervalId]);

  /**
   * Send OTP
   * @param {*} primary_contact_number
   */
  const sendOTP = (primary_contact_number) => {
    if (isOTPSend) setOTP('');
    YagnaBookingService.sendOTP(primary_contact_number)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  /**
   * Verify OTP
   * @param {*} OTP
   * @param {*} primary_contact_number
   */
  const verifyOTP = (primary_contact_number) => {
    YagnaBookingService.verifyOTP(primary_contact_number, otp)
      .then((res) => {
        if (res.data.success) {
          setOTPVerified(true);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };

  const onSubmit = (values) => {
    Object.assign(values, {
      yagna_category_id: Number(templeData?.yagna_category_id),
      yagna_date: yagnaDate,
      yagna_temple_id: Number(templeData?.id),
      primary_contact_code_id: Number(values?.primary_contact_code_id)
    });
    if (isIdentityHidden === 'show') {
      // Handle Cash payment logic here
      // console.log("demo");
      YagnaBookingService.getOfflineBooking(values)
        .then((res) => {
          // console.log(res);
          if (res.data.success) {
            navigate(`/yagna/booking-confirmation/${res.data.data.id}`);
          } else {
            // Handle other scenarios if needed
            navigate(`/yagna/booking-failed/`);

            toast.error('Cash payment failed.', { autoClose: 1500 });
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log("errrorrrr");
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    } else {
      // Default to Online payment logic if paymentMode is not "cash"
      YagnaBookingService.yagnaBooking(values)
        .then((res) => {
          if (res.data.success) {
            setPayUData(res.data.data);
            setTimeout(() => {
              if (myButtonRef.current) {
                myButtonRef.current.click();
              }
            }, 200);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    }
  };

  const handleCheckSlots = () => {
    const slots = checkForAvailability(startDate);
    setAvailableTemple(slots);
  };

  const handleDateChange = (date) => {
    const formattedDate = date.toISOString().split('T')[0];
    setStartDate(formattedDate);
    setAvailability(false); // Reset availability when the date changes
    setAvailableTemple([]); // Reset available slots when the date changes
  };
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  //-----page-close-404-error-logic-p-----
  if (true) {
    return <Error404 />;
  }
  //-----page-close-404-error-logic-p-----
  return (
    <>
      <Helmet>
        <title>અંબાજી મંદિર યજ્ઞ બુકિંગ</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Shri 51 Shaktipeeth Parikrama Mahotsav Yagna Booking. Book Gabbar Yagna and Shaktipeeth Yagna at Ambaji Temple. "
        />
        <meta
          name="keywords"
          content="Ambaji Yajna Booking , Gabbar Yagna Booking , Shaktipeeth Yagna Booking , Parikrama Mahotsav Yagna Booking, Shri 51 Shaktipeeth Parikrama Mahotsav Yagna Booking "
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="booking">
          <section className="header-banner yagna-booking">
            <div className="container">
              <h1>અંબાજી મંદિર યજ્ઞ બુકિંગ</h1>
            </div>
          </section>
          <section className="about-page">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="booking-form card">
                    <Formik
                      initialValues={initialValues}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={false}
                      onSubmit={onSubmit}
                    >
                      {(formik) => (
                        <Form className="py-5 px-3">
                          <div className="row align-items-center">
                            <div className=" col-12 col-sm-12 col-md-8 col-lg-8 mb-2  ">
                              <label
                                htmlFor="yagna_date"
                                style={{
                                  fontWeight: '600',
                                  marginRight: '10px',
                                  lineHeight: '30px',
                                  marginBottom: '0px'
                                }}
                              >
                                Select Date
                              </label>
                              <DatePicker
                                showIcon
                                selected={
                                  startDate ? new Date(startDate) : null
                                }
                                onChange={(date) => handleDateChange(date)}
                                dateFormat="yyyy-MM-dd"
                                // minDate={new Date("2024-02-12")}
                                // maxDate={new Date("2024-02-16")}
                                customInput={
                                  <div style={{ position: 'relative' }}>
                                    <input
                                      placeholder="Select Date"
                                      type="text"
                                      value={startDate || ''}
                                      readOnly
                                    />
                                    <div
                                      style={{
                                        position: 'absolute',
                                        left: '10px',
                                        top: '8px'
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="1em"
                                        height="1em"
                                        viewBox="0 0 48 48"
                                      >
                                        <mask id="ipSApplication0">
                                          <g
                                            fill="none"
                                            stroke="#fff"
                                            strokeLinejoin="round"
                                            strokeWidth="4"
                                          >
                                            <path
                                              strokeLinecap="round"
                                              d="M40.04 22v20h-32V22"
                                            ></path>
                                            <path
                                              fill="#fff"
                                              d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                                            ></path>
                                          </g>
                                        </mask>
                                        <path
                                          fill="currentColor"
                                          d="M0 0h48v48H0z"
                                          mask="url(#ipSApplication0)"
                                        ></path>
                                      </svg>
                                    </div>
                                  </div>
                                }
                              />
                              <ErrorMessage
                                component="div"
                                name="yagna_date"
                                className="red-text"
                              />
                            </div>
                            <div className="d-flex justify-content-lg-end col-12 col-sm-12 col-md-4 col-lg-4 mb-2 justify-content-md-start">
                              <button
                                type="button"
                                className="orangebtn"
                                onClick={handleCheckSlots}
                              >
                                Check Slots
                              </button>
                            </div>
                          </div>

                          <div className="row">
                            {allAvailableTemple?.length > 0 &&
                              allAvailableTemple.slice(0, 1).map((item, i) => {
                                return (
                                  <>
                                    <div
                                      className="col-12 col-lg-6 col-md-12 col-sm-12"
                                      key={i}
                                    >
                                      <div
                                        className="card p-2 my-3"
                                        hidden={
                                          isAvailable &&
                                          formik?.values?.yagna_date !==
                                            item?.date
                                        }
                                      >
                                        <div className="d-flex justify-content-between align-items-center">
                                          <div>
                                            <h6 style={{ color: '#ae0015' }}>
                                              {' '}
                                              મહા યજ્ઞ | Rs. 1100
                                            </h6>
                                            <strong>Available Kunds : 9</strong>
                                          </div>
                                          <button
                                            type="button"
                                            className="orangebtn"
                                            style={{ width: '150px' }}
                                            onClick={() => [
                                              formik.setFieldValue(
                                                'yagna_date',
                                                item?.yagna_date
                                              ),
                                              setAvailability(true),
                                              setTempleData(item),
                                              setAvailableTemple([item])
                                            ]}
                                          >
                                            Book
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="col-12 col-lg-6 col-md-12 col-sm-12"
                                      key={i}
                                    >
                                      <div
                                        className="card p-2 my-3"
                                        hidden={
                                          isAvailable &&
                                          formik?.values?.yagna_date !==
                                            item?.date
                                        }
                                      >
                                        <div className=" d-flex justify-content-between align-items-center">
                                          <div>
                                            <h6 style={{ color: '#ae0015' }}>
                                              યજ્ઞ | Rs. 600
                                            </h6>
                                            <strong>Available Kunds : 6</strong>
                                          </div>
                                          <button
                                            type="button"
                                            className="orangebtn"
                                            style={{ width: '150px' }}
                                            onClick={() => [
                                              formik.setFieldValue(
                                                'yagna_date',
                                                item?.yagna_date
                                              ),
                                              setAvailability(true),
                                              setTempleData(item),
                                              setAvailableTemple([item])
                                            ]}
                                          >
                                            Book
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          </div>

                          {isAvailable ? (
                            <>
                              <fieldset className="mt-3">
                                <div className="row">
                                  <div className="col-12 col-lg-6">
                                    <div className="row">
                                      <div className="form-group col-5">
                                        <label htmlFor="primary_contact_code_id">
                                          Phone Code
                                        </label>
                                        <Field
                                          as="select"
                                          className="form-control"
                                          name="primary_contact_code_id"
                                          disabled={isOTPVerified}
                                        >
                                          {countryList?.map((item, i) => {
                                            return (
                                              <option value={item?.id} key={i}>
                                                +{item?.phone_code}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                        <ErrorMessage
                                          component="div"
                                          name="primary_contact_code_id"
                                          className="red-text"
                                        />
                                      </div>
                                      <div className="form-group col-7">
                                        <label htmlFor="primary_contact_number">
                                          Mobile Number
                                        </label>
                                        <Field
                                          type="text"
                                          inputMode="numeric"
                                          className="form-control"
                                          name="primary_contact_number"
                                          disabled={isOTPVerified}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="primary_contact_number"
                                          className="red-text"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  {isOTPSend && !isOTPVerified && (
                                    <div className="form-group col-12 col-lg-2">
                                      <label htmlFor="otp">OTP</label>
                                      <input
                                        type="text"
                                        inputMode="numeric"
                                        className="form-control"
                                        min="6"
                                        max="6"
                                        name="otp"
                                        placeholder="_ _ _ _ _ _"
                                        onKeyUp={(event) =>
                                          setOTP(event.target?.value)
                                        }
                                      />
                                      {(otp?.length > 6 || otp?.length < 6) && (
                                        <span className="red-text">
                                          OTP must be a 6 digits number and only
                                          contains positive number!
                                        </span>
                                      )}
                                      {otp?.toString()?.includes('-') && (
                                        <p className="red-text">
                                          OTP must be a 6 digits number and only
                                          contains positive number!
                                        </p>
                                      )}
                                    </div>
                                  )}
                                  {!isOTPVerified && (
                                    <div className="form-group col-12 col-lg-4">
                                      <div>
                                        {isOTPSend && (
                                          <button
                                            className="orangebtn"
                                            type="button"
                                            style={{ marginTop: '25px' }}
                                            onClick={() =>
                                              verifyOTP(
                                                formik?.values
                                                  ?.primary_contact_number
                                              )
                                            }
                                            disabled={
                                              otp?.length !== 6 ||
                                              otp?.toString()?.includes('-')
                                            }
                                          >
                                            Verify OTP
                                          </button>
                                        )}
                                        {isOTPSend && seconds == '00' && (
                                          <button
                                            className="whitebtn ms-2"
                                            type="button"
                                            onClick={() =>
                                              sendOTP(
                                                formik?.values
                                                  ?.primary_contact_number
                                              )
                                            }
                                            disabled={
                                              formik?.errors
                                                ?.primary_contact_number
                                            }
                                            style={{
                                              marginTop: '25px'
                                            }}
                                          >
                                            Resend OTP
                                          </button>
                                        )}

                                        {!isOTPSend && (
                                          <button
                                            className="btn orangebtn mb-1"
                                            type="button"
                                            onClick={() =>
                                              sendOTP(
                                                formik?.values
                                                  ?.primary_contact_number
                                              )
                                            }
                                            disabled={
                                              formik?.errors
                                                ?.primary_contact_number ||
                                              formik?.values
                                                ?.primary_contact_number
                                                ?.length < 9
                                            }
                                            style={{ marginTop: '25px' }}
                                          >
                                            Send OTP
                                          </button>
                                        )}
                                      </div>
                                      {isOTPSend && seconds > 0 ? (
                                        <p>
                                          Resend OTP in
                                          {seconds < 10
                                            ? ` 0${seconds}`
                                            : ` ${seconds}`}{' '}
                                          sec
                                        </p>
                                      ) : (
                                        <p></p>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </fieldset>
                              {isOTPVerified && (
                                <fieldset>
                                  <div className="row">
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                      <label htmlFor="first_name">
                                        First Name
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="first_name"
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="first_name"
                                        className="red-text"
                                      />
                                    </div>
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                      <label htmlFor="last_name">
                                        Last Name
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="last_name"
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="last_name"
                                        className="red-text"
                                      />
                                    </div>
                                    <div className="form-group col-12 col-md-12 col-lg-4">
                                      <label htmlFor="email_id">Email Id</label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="email_id"
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="email_id"
                                        className="red-text"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="form-group col-12">
                                      <label htmlFor="address">Address</label>
                                      <Field
                                        as="textarea"
                                        className="form-control"
                                        placeholder="Address"
                                        name="address"
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="address"
                                        className="red-text"
                                      />
                                    </div>
                                  </div>

                                  {/* mode of payment  */}

                                  <div className="row">
                                    <div className="form-group col-12">
                                      <label>Mode of Payment</label> <br />
                                      {/* <Field name="mode_of_payment" type="radio" value="cash" onClick={() => setOTPSend(true)}  />  &nbsp;
<label htmlFor="cash"> Cash</label> &nbsp; */}
                                      <div
                                        className="show"
                                        onClick={() => [
                                          setIdentityHidden('show')
                                        ]}
                                      ></div>
                                      <input
                                        // name="mode_of_payment"
                                        type="radio"
                                        value="cash"
                                        checked={isIdentityHidden === 'show'}
                                        onChange={() =>
                                          setIdentityHidden('show')
                                        }
                                      />
                                      <span htmlFor="cash"> Cash</span> &nbsp;
                                      &nbsp;
                                      {/* otp field for cash payment  */}
                                      {isIdentityHidden === 'show' ? (
                                        <>
                                          <button
                                            className="orangebtn mb-1 ms-2"
                                            type="button"
                                            onClick={() =>
                                              sendOTP('9726835073')
                                            }
                                            disabled={
                                              formik?.errors
                                                ?.primary_contact_number ||
                                              formik?.values
                                                ?.primary_contact_number
                                                ?.length < 9
                                            }
                                          >
                                            Get OTP
                                          </button>
                                          <div className="form-group col-12 col-lg-2">
                                            <label htmlFor="otp">OTP</label>
                                            <input
                                              type="text"
                                              inputMode="numeric"
                                              className="form-control"
                                              // min="6"
                                              // max="6"
                                              name="otp"
                                              placeholder="- - - - - -"
                                              onKeyUp={(event) =>
                                                setOTP(event.target?.value)
                                              }
                                            />
                                            {/* {(otp?.length !== 6 || !/^\d+$/.test(otp)) && (
                                            <p className="red-text">
                                              OTP must be a 6 digits number and only contains positive numbers!
                                            </p>
                                          )} */}
                                          </div>
                                          {isOTPVerified && (
                                            <div className="row">
                                              <div className="form-group col-12 col-md-6 col-lg-4">
                                                <label htmlFor="receipt_number">
                                                  Receipt Number
                                                </label>
                                                <Field
                                                  type="text"
                                                  className="form-control"
                                                  name="receipt_number"
                                                />
                                                <ErrorMessage
                                                  component="div"
                                                  name="receipt_number"
                                                  className="red-text"
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </>
                                      ) : isIdentityHidden ===
                                        'hide' ? null : null}
                                      <input
                                        // name="mode_of_payment"
                                        type="radio"
                                        value="online"
                                        checked={isIdentityHidden === 'hide'}
                                        onChange={() =>
                                          setIdentityHidden('hide')
                                        }
                                      />
                                      <span htmlFor="online"> Online</span>
                                      <ErrorMessage
                                        component="div"
                                        name="address"
                                        className="red-text"
                                      />
                                    </div>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <button
                                      type="button"
                                      className="whitebtn mb-1"
                                      onClick={() => setModalOpen(true)}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="submit"
                                      className="orangebtn"
                                      onClick={onSubmit}
                                      // disabled={!formik?.isValid}
                                    >
                                      Proceed to Book
                                    </button>
                                  </div>
                                </fieldset>
                              )}
                            </>
                          ) : (
                            <p className="text-center red-text">
                              {slotAvailableMsg}
                            </p>
                          )}
                        </Form>
                      )}
                    </Formik>

                    <form method="POST" action={payu_api_url}>
                      <input type="hidden" name="key" value={payu_key} />
                      <input
                        type="hidden"
                        name="txnid"
                        value={payUData?.txnid}
                      />
                      <input
                        type="hidden"
                        name=" amount"
                        value={payUData?.amount}
                      />
                      <input
                        type="hidden"
                        name="productinfo"
                        value={payUData?.productinfo}
                      />
                      <input
                        type="hidden"
                        name=" firstname"
                        value={payUData?.firstname}
                      />
                      <input
                        type="hidden"
                        name="lastname"
                        value={payUData?.lastname}
                      />
                      <input
                        type="hidden"
                        name="email"
                        value={payUData?.email}
                      />
                      <input type="hidden" name="phone" value="9198672387" />
                      <input type="hidden" name="surl" value={payUData?.surl} />
                      <input type="hidden" name="furl" value={payUData?.furl} />
                      <input type="hidden" name="curl" value={payUData?.curl} />
                      <input type="hidden" name="hash" value={payUData?.hash} />
                      <input type="hidden" name="udf1" value={payUData?.udf1} />
                      <input type="hidden" name="udf2" value={payUData?.udf2} />
                      <input type="hidden" name="udf3" value={payUData?.udf3} />
                      <input type="hidden" name="udf4" value={payUData?.udf4} />
                      <input type="hidden" name="udf5" value={payUData?.udf5} />
                      <button hidden={true} ref={myButtonRef}>
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>{' '}
          </section>
          <section>
            <h3 className="text-center">
              યજ્ઞ બાબત ની સહાય માટે ના સંપર્ક નમ્બર:{' '}
              <a href="tel:919925072473">99250 72473</a> ,{' '}
              <a href="tel:919974546100">99745 46100</a>
            </h3>
          </section>
          <Arasuri />
          <Footer />

          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Example Modal"
          >
            <div className="">
              <h4>Are you sure, you want to cancel the booking proccess?</h4>
              <div className="card-footer d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-default"
                  onClick={() => setModalOpen(false)}
                >
                  No
                </button>
                <button
                  type="button"
                  className="orangebtn"
                  onClick={() => [
                    setModalOpen(false),
                    setAvailability(false)
                    //   setAvailableDates([]),
                    // window.location.reload(),
                  ]}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <LazyLoader />
      )}
      {/* <ComingSoon/> */}
    </>
  );
};
export default MandirYagnaBooking;
