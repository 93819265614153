import axios from "axios";

export default axios.create({
  // baseURL: "https://beta-api-ambajitemple-in.onrender.com/public/api/",
  // baseURL: "https://api.ambajitemple.bintech.services/public/api/",
  baseURL: "https://api.ambajitemple.in/public/api/",

  headers: {
    "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data",
  },
});
