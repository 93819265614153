import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const createShaktipithSang = (data) => {
  // console.log(data);
  return http.post(api_urls?.createShaktipithSang, data);
};

const getByShaktipithKeyword = (organizer_contact) => {
  return http.get(
    `${api_urls?.getByShaktipithKeyword}?keyword=${organizer_contact}`
  );
};

const sendOTP = (organizer_contact) => {
  return http.get(
    `${api_urls?.shaktipithSang_sendOTP}?contact_number=${organizer_contact}`
  );
};

const verifyOTP = (organizer_contact, otp) => {
  return http.get(
    `${api_urls?.shaktipithSang_verifyOTP}?contact_number=${organizer_contact}&otp=${otp}`
  );
};

const checkRegisteredNumber = (organizer_contact) => {
  return http.get(`${api_urls?.getUser}?contact_number=${organizer_contact}`);
};

const GabbarShaktipithSangService = {
  createShaktipithSang,
  getByShaktipithKeyword,
  sendOTP,
  verifyOTP,
  checkRegisteredNumber,
};

export default GabbarShaktipithSangService;
