import React, { useState } from 'react';
import './search.scss';
import Select from 'react-select';
import Footer from '../../../Components/footer';
import Arasuri from '../../../Components/arasuri';
import http from '../../../_environment'; // Ensure you have this import for your HTTP requests
import { api_urls } from '../../../_Constants/api_urls';
import { useHeading } from '../../Bhadarvi_poonam/header'; // Import the custom hook

const Search = () => {
  const [selectedType, setSelectedType] = useState(null);
  const [searchId, setSearchId] = useState('');
  const [data, setData] = useState(null);
  const [showBhadarviBox, setShowBhadarviBox] = useState(false);
  const [dataType, setDataType] = useState(''); // Track type of data fetched
  const heading = useHeading(); // Call the custom hook

  const handleSelectChange = (selectedOption) => {
    setSelectedType(selectedOption.value);
  };

  const handleSearchInputChange = (event) => {
    setSearchId(event.target.value);
  };

  const handleSearch = () => {
    if (selectedType && searchId) {
      if (selectedType === 'Seva Camp') {
        getBySevaCampById(searchId);
      } else if (selectedType === 'Sangh') {
        getBySanghById(searchId);
      }
    }
  };

  const getBySevaCampById = async (id) => {
    const response = await http.get(`${api_urls?.getAllSevaCamp}?id=${id}`);
    // console.log("Seva Camp Data:", response.data);
    setData(response.data);
    setDataType('Seva Camp');
    setShowBhadarviBox(true);
  };

  const getBySanghById = async (id) => {
    const response = await http.get(`${api_urls?.getAllSangh}?id=${id}`);
    // console.log("Sangh Data:", response.data);
    setData(response.data);
    setDataType('Sangh');
    setShowBhadarviBox(true);
  };

  return (
    <>
      <div className="main-wrapper">
        <section
          className="header-banner bhadarvi-bg"
          style={{ backgroundPosition: 'top' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-xl-12">{heading}</div>
            </div>
          </div>
        </section>
        <section className="ThankYou-page-1">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
                <div className="form-group">
                  <label>Registration Type</label>
                  <Select
                    name="form-type"
                    options={[
                      { value: 'Seva Camp', label: 'Seva Camp' },
                      { value: 'Sangh', label: 'Sangh' }
                    ]}
                    isSearchable={false}
                    onChange={handleSelectChange}
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="bhadarvi-box">
                  <div className="bhadarvi-text">
                    <p>BhadarviPoonam/SanghOrSevaCamp/2024/</p>
                  </div>
                  <div className="bhadarvi-search-box">
                    <input
                      type="search"
                      placeholder="Search"
                      className="search-boxes"
                      value={searchId}
                      onChange={handleSearchInputChange}
                    />
                    <div>
                      <button className="whitebtn" onClick={handleSearch}>
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {showBhadarviBox && (
              <div className="box-bhadarvi">
                <div className="row justify-content-center">
                  <div className="col-xl-7 col-lg-9 col-md-10 col-sm-12 col-12">
                    <div className="boxer-bhadarvi">
                      {dataType === 'Seva Camp' && (
                        <>
                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Name of Seva Camp</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>{data?.data[0]?.name || 'N/A'}</p>
                              </div>
                            </div>
                          </div>

                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Name of Trust</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>{data?.data[0]?.trust_name || 'N/A'}</p>
                              </div>
                            </div>
                          </div>

                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Name of Head Person</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>
                                  {data?.data[0]?.representative_name || 'N/A'}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Start Date</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>{data?.data[0]?.start_date || 'N/A'}</p>
                              </div>
                            </div>
                          </div>

                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>End Date</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>{data?.data[0]?.end_date || 'N/A'}</p>
                              </div>
                            </div>
                          </div>

                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Vehicle Number</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>
                                  {data?.data[0]?.vehicle1_number} <br />
                                  {data?.data[0]?.vehicle2_number}
                                  <br />
                                  {data?.data[0]?.vehicle3_number}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Seva Camp Category</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>
                                  {data?.data[0]?.SevaCampCategory?.name ||
                                    'N/A'}
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Status</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>{data?.data[0]?.Status?.name || 'N/A'}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                      {dataType === 'Sangh' && (
                        <>
                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Organizer Name</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>{data?.data[0]?.organizer_name || 'N/A'}</p>
                              </div>
                            </div>
                          </div>

                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Arrival Date</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>{data?.data[0]?.arrival_date || 'N/A'}</p>
                              </div>
                            </div>
                          </div>

                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Vehicle Number</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>
                                  {data?.data[0]?.vehicle1_number}
                                  <br />
                                  {data?.data[0]?.vehicle2_number}
                                  <br />
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="bhadarvi-decoration">
                            <div className="row">
                              <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12">
                                <p>
                                  <strong>Status</strong>
                                </p>
                              </div>
                              <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
                                <p>{data?.data[0]?.Status?.name || 'N/A'}</p>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </section>

        <Arasuri />
        <Footer />
      </div>
    </>
  );
};

export default Search;
