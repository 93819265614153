import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// import { Helmet } from "react-helmet-async";
import { Swiper, SwiperSlide } from 'swiper/react';

// import Swiper, { Navigation,Scrollbar } from "swiper";
// SwiperCore.use([Mousewheel]);

// import SwiperCore, { Mousewheel, Pagination, Scrollbar, A11y, Autoplay, EffectFade, Navigation } from 'swiper/core';
// import 'swiper/swiper.min.css';
// import 'swiper/components/pagination/pagination.min.css';
// import 'swiper/components/navigation/navigation.min.css';
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectFade
  // Mousewheel,
} from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';
import 'swiper/css/effect-fade';
import { FaArrowUp } from 'react-icons/fa';
// import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

// services
import CmsService from '../../Api_config/Home_page/cms';
// import BlogService from "../../Api_config/Home_page/blog";
import ProductService from '../../Api_config/Products/product';
import ScheduleService from '../../Api_config/Home_page/schedule';
import EventService from '../../Api_config/Home_page/event';
import UtilsService from '../../Api_config/Home_page/utils';

// components
import Footer from '../../Components/footer';
// import LazyLoader from "../../Components/lazyLoader";
import TemplesSection2 from './templesSection2';
import { routes } from '../../_Constants/routes';

// images

// ---------------other images------------

import prasad from '../../Assets/Images/Home_page/Ambaji_HomePage_Prasad.png';
// import temple1 from "../../Assets/Images/Home_page/temple1.png";
// import ambajitemple1 from "../../Assets/Images/Home_page/linear.png";
import carBlack from '../../Assets/Images/Home_page/car-black.svg';
import carWhite from '../../Assets/Images/Home_page/car-white.svg';
import planeBlack from '../../Assets/Images/Home_page/plane-black.svg';
import planeWhite from '../../Assets/Images/Home_page/plane-white.svg';
import trainBlack from '../../Assets/Images/Home_page/train-black.svg';
import trainWhite from '../../Assets/Images/Home_page/train-white.svg';
import TempleIcon from '../../Assets/Images/Home_page/TempleIcon.svg';
import SunriseRed from '../../Assets/Images/Home_page/SunriseRed.svg';
import AftrnoonRed from '../../Assets/Images/Home_page/AftrnoonRed.svg';
import EveningRed from '../../Assets/Images/Home_page/EveningRed.svg';
import DonationRed from '../../Assets/Images/Home_page/DonationRed.svg';
import LadduRed from '../../Assets/Images/Home_page/LadduRed.svg';
import MapsRed from '../../Assets/Images/Home_page/MapsRed.svg';
import ParkingRed from '../../Assets/Images/Home_page/ParkingRed.svg';
import AvashRed from '../../Assets/Images/Home_page/AvashRed.svg';
import MasalaRed from '../../Assets/Images/Home_page/MasalaRed.svg';
import UddanKhatola from '../../Assets/Images/Home_page/UddanKhatola.svg';
import SpotlightLight from '../../Assets/Images/Home_page/SpotlightLight.svg';
import RedTemple from '../../Assets/Images/Home_page/RedTemple.svg';
// import PrayIcon from "../../Assets/Images/Home_page/PrayIcon.svg";
import MoneyDonation from '../../Assets/Images/Home_page/AmbajiTemple_MoneyDonation.png';

// css
import './home.scss';
import '../../Css/global.scss';
import '../../Css/innerPages.scss';
import Arasuri from '../../Components/arasuri';

const MobileHome = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [cmsData, setCmsData] = useState();
  const [productData, setProductData] = useState();
  const [scheduleData, setScheduleData] = useState([]);
  const [pastData, setPastData] = useState([]);
  const [futureData, setFutureData] = useState([]);
  const [showPreviousEvents, setShowPreviousEvents] = useState(false);
  const [showUpcomingEvents, setShowUpcomingEvents] = useState(true);
  const [currentDate, setCurrentDate] = useState('');
  const [tithi, setTithi] = useState();
  const [expanded, setExpanded] = useState(false);
  const [isLoading, setLoading] = useState(true);

  const handlePreviousEventsClick = () => {
    setShowPreviousEvents(true);
    setShowUpcomingEvents(false);
  };

  const handleUpcomingEventsClick = () => {
    setShowPreviousEvents(false);
    setShowUpcomingEvents(true);
  };

  useEffect(() => {
    // cms data api point
    const id = 1;
    CmsService.getAll().then((res) => {
      // console.log(res.data);
      setCmsData(res.data.data);
      setLoading(false);
    });

    // schedule data api point
    ScheduleService.getById(5)
      .then((res) => {
        setScheduleData(res.data.data);
      })
      .catch((error) => {
        // console.error("Error fetching schedule data:", error);
        navigate(routes?.error500);
      });

    // event data api point
    EventService.getByTimeFrameFuture(id).then((res) => {
      setFutureData(res.data.data);
    });
    EventService.getByTimeFramePast(id).then((res) => {
      setPastData(res.data.data);
    });

    // product data api point
    ProductService.getAll().then((res) => {
      // console.log(res.data.data);
      setProductData(res.data.data);
    });

    // Tithi data api point
    UtilsService.getTithi().then((res) => {
      if (res.data.success) {
        setTithi(res.data.data[0]);
      }
    });
  }, []);

  // for showing time in section 4
  useEffect(() => {
    const interval = setInterval(() => {
      const date = new Date();
      const formattedDate = `${date.getDate()} ${date.toLocaleString(
        'default',
        { month: 'short' }
      )} / ${date.getFullYear()}`;
      setCurrentDate(formattedDate);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  // ------------------- //

  //   scroll to top button
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  // const toggleExpand = () => {
  //   setExpanded(!expanded);
  // };
  // ---------- //

  return (
    <div className="d-block d-xl-none">
      <div
        className="scroll-top-btn"
        style={{ display: visible ? 'inline' : 'none' }}
        onClick={scrollToTop}
      >
        <button>
          <FaArrowUp style={{ display: visible ? 'inline' : 'none' }} />
        </button>
      </div>

      {/* --------------------- Slider: Section-1 */}
      <SwiperSlide>
        <Swiper
          spaceBetween={10}
          pagination={{
            clickable: true,
            type: 'bullets',
            direction: 'horizontal'
          }}
          modules={[
            Navigation,
            Pagination,
            Scrollbar,
            A11y,
            Autoplay,
            EffectFade
          ]}
          slidesPerView={1}
          loop
          effect="fade"
          speed={500}
          fadeEffect={{
            crossFade: true
          }}
          className="mySwiper"
          autoplay={{
            delay: 2000
          }}
        >
          {cmsData?.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <section className="mainbanner">
                  <div className="bannerslider">
                    <div className="banner-item">
                      <div className="bannerimg">
                        <img
                          src={item.mob_image}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                    </div>
                  </div>
                </section>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SwiperSlide>
      {/* Slider: Section-1--------------------- */}

      {/* --------------------- 3 temples ( temple , gabbar , shaktipith)  */}
      <TemplesSection2 />
      {/* 3 temples ( temple , gabbar , shaktipith) ---------------------  */}

      {/* --------------------- parikrama sang:Section-3  */}

      <section className="center aboutbg overlay overlay-bhadarvi primary window_height Parikrama-gabber">
        <div className="container">
          <div className="about-wrapper">
            <div className="row">
              <div className="col-xl-10">
                <h2 style={{ marginBottom: '50px' }}>
                  ગબ્બર તળેટી સંપૂર્ણ શક્તિપીઠ પરિક્રમા સંઘ સ્થાપના અભિયાન
                </h2>
                <div className="btn-group" style={{ marginBottom: '20px' }}>
                  <a href="/shaktipith-sang" className="whitebtn">
                    પરિક્રમા સંઘ નોંધણી
                  </a>
                </div>{' '}
                <br />
                <div className="btn-group">
                  <a href="/search-shaktipithSang" className="whitebtn">
                    અગાઉની નોંધણી શોધો
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* parikrama sang:Section-3 ---------------------  */}

      {/* --------------------- mandir schedule:Section-4  */}

      <section className="schedule-section center">
        <div className="container">
          <div className="row">
            {scheduleData?.map((item) => {
              return (
                <div className="col-md-12 col-lg-4" key={item.key}>
                  <h2>
                    <span>સમય અનુસૂચિ</span>
                  </h2>

                  <div className="todaydate align-items-center">
                    <div className="templeimg">
                      <img
                        src={TempleIcon}
                        alt="Ambaji Temple Banaskatha Gujarat"
                      ></img>
                    </div>
                    <div>
                      <div className="date">{currentDate}</div>
                      <p className="ms-3">{tithi?.tithi}</p>
                    </div>
                  </div>
                  <div className="eventbox">
                    <div className="event-timeicon">
                      <img
                        src={SunriseRed}
                        alt="Ambaji Temple Banaskatha Gujarat"
                      ></img>
                      <h5>સવાર </h5>
                    </div>
                    {item?.morning?.map((data) => {
                      return (
                        <>
                          <p key={item.key}>
                            <b>{data.key} </b> {data.start_time} {data.end_time}
                          </p>
                        </>
                      );
                    })}
                  </div>
                  <div className="eventbox">
                    <div className="event-timeicon">
                      <img
                        src={AftrnoonRed}
                        alt="Ambaji Temple Banaskatha Gujarat"
                      ></img>
                      <h5>બપોર</h5>
                    </div>
                    {item?.afternoon?.map((data) => {
                      return (
                        <>
                          <p key={item.key}>
                            <b>{data.key} </b> {data.start_time} {data.end_time}
                          </p>
                        </>
                      );
                    })}
                  </div>
                  <div className="eventbox">
                    <div className="event-timeicon">
                      <img
                        src={EveningRed}
                        alt="Ambaji Temple Banaskatha Gujarat"
                      ></img>
                      <h5>સાંજ</h5>
                    </div>
                    {item?.evening?.map((data) => {
                      return (
                        <>
                          <p key={item.key}>
                            <b>{data.key} </b> {data.start_time} {data.end_time}
                          </p>
                        </>
                      );
                    })}
                  </div>
                </div>
              );
            })}
            <div className="col-md-12 col-lg-8">
              <h2>
                <span>ઉત્સવો</span>
              </h2>
              <div>
                <button
                  className="button whitebtn"
                  htmlFor="danger-outlined"
                  style={{
                    marginRight: '10px'
                  }}
                  onClick={handleUpcomingEventsClick}
                >
                  આગામી ઉત્સવો
                </button>
                <button
                  className="button whitebtn"
                  htmlFor="success-outlined"
                  onClick={handlePreviousEventsClick}
                >
                  પૂર્ણ થયેલ ઉત્સવો
                </button>

                <Swiper
                  direction={'horizontal'}
                  slidesPerView={1}
                  spaceBetween={30}
                  pagination={{
                    clickable: true
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {showPreviousEvents && (
                    <div>
                      {pastData?.map((item) => {
                        return (
                          <SwiperSlide>
                            <div
                              style={{
                                marginTop: '30px',
                                marginBottom: '50px'
                              }}
                            >
                              <div className="col-md-12">
                                <div className="eventpost">
                                  <div className="eventimg">
                                    <img
                                      src={item.thumbnail_image}
                                      alt="Ambaji Temple Banaskatha Gujarat"
                                    ></img>
                                  </div>
                                  <div className="eventcontent">
                                    <h3>{item.name}</h3>
                                    <p>
                                      {new Date(
                                        item?.start_time
                                      ).toLocaleDateString('en-GB')}{' '}
                                      to{' '}
                                      {new Date(
                                        item?.end_time
                                      ).toLocaleDateString('en-GB')}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </div>
                  )}
                </Swiper>
                <Swiper
                  direction={'horizontal'}
                  slidesPerView={1}
                  spaceBetween={30}
                  pagination={{
                    clickable: true
                  }}
                  modules={[Pagination]}
                  className="mySwiper"
                >
                  {showUpcomingEvents && (
                    <div>
                      {futureData?.length > 0 ? (
                        <>
                          {futureData?.map((item) => {
                            return (
                              <SwiperSlide>
                                <div
                                  style={{
                                    marginTop: '30px',
                                    marginBottom: '50px'
                                  }}
                                >
                                  <div className="col-md-12">
                                    <div className="eventpost">
                                      <div className="eventimg">
                                        <img
                                          src={item.thumbnail_image}
                                          alt="Ambaji Temple Banaskatha Gujarat"
                                        ></img>
                                      </div>
                                      <div className="eventcontent">
                                        <h3>{item.name}</h3>
                                        <p>
                                          {new Date(
                                            item?.start_time
                                          ).toLocaleDateString('en-GB')}{' '}
                                          to{' '}
                                          {new Date(
                                            item?.end_time
                                          ).toLocaleDateString('en-GB')}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })}
                        </>
                      ) : (
                        <p className="red-text mt-5 text-center">
                          No Upcoming Events
                        </p>
                      )}
                    </div>
                  )}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*mandir schedule:Section-4  ---------------------  */}

      {/*  ---------------------  e-parikrama AR/VR : Section-5  */}

      <section className="center aboutbg overlay overlay-bhadarvi primary parikrama-bg window_height">
        <div className="container">
          <div className="about-wrapper">
            <div className="row">
              <div className="col-xl-10">
                <h2>AR/VR હેડસેટ દ્વારા અંબાજી મંદિરની ઇ-પરિક્રમા</h2>
                <p>
                  અમે મંદિરની એક વીડિયો ટૂર બનાવી છે જે ભક્તોને ઓગમેન્ટેડ
                  રિયાલિટી (એઆર)/ વર્ચ્યુઅલ રિયાલિટી (વીઆર) હેડસેટ પહેરીને
                  મંદિરની ડિજિટલ પરિક્રમા કરવા માટે સુવિધા આપે છે.
                </p>
                <div className="btn-group">
                  <a href="#" className="whitebtn">
                    ટૂંક સમયમાં આવી રહ્યું છે
                    {/* Experience Now */}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* e-parikrama AR/VR : Section-5   ---------------------   */}

      {/*   ---------------------   online services : Section-6  */}

      <section className="service-section center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <h2>
                ઓનલાઇન <span>સેવાઓ</span>
              </h2>
              <div className="service-list">
                <div className="row">
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <a href="/donation-money">
                      <div className="servicebox">
                        <div className="service-icon">
                          {/* <i className="iicon-icon-11"></i> */}
                          <img
                            src={DonationRed}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5> દાન</h5>
                        <p>
                          વિવિધ ડિજિટલ ચુકવણીઓ દ્વારા મંદિરમાં ઑનલાઇન દાન કરો.
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <a href="/online-prashad">
                      <div className="servicebox">
                        <div className="service-icon">
                          {/* <i className="iicon-icon-10"></i> */}
                          <img
                            src={LadduRed}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5> પ્રસાદ</h5>
                        <p>
                          મંદિરનો પ્રસાદ ઓનલાઈન ઓર્ડર કરો અને તેને તમારા ઇચ્છિત
                          સ્થાન પર પહોંચાડો.{' '}
                        </p>
                      </div>
                    </a>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="servicebox">
                      <div className="service-icon">
                        {/* <i className="iicon-icon-9"></i> */}
                        <img
                          src={MapsRed}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <h5>ધજા</h5>
                      <p>
                        તમે ઉપલબ્ધ સ્લોટમાંથી તમારા આગમન પહેલા ધજા બુક કરી શકો
                        છો.{' '}
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6 col-sm-12">
                    <div className="servicebox">
                      <div className="service-icon">
                        {/* <i className="iicon-icon-8"></i> */}
                        <img
                          src={ParkingRed}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <h5>પાર્કિંગ</h5>
                      <p>
                        મંદિરો દ્વારા ઓનલાઈન પાર્કિંગ સુવિધા તમને તમારી કાર માટે
                        જગ્યા પ્રીબુક કરવામાં મદદ કરે છે.{' '}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="service-top">
                <h2>
                  ભક્તો <span> માટેની સુવિધા</span>
                </h2>
                <div className="row">
                  <div className="col-md-6">
                    <div className="footerbox">
                      <div className="footericon">
                        {/* <i className="iicon-icon-13"></i> */}
                        <img
                          src={AvashRed}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <h5>આવાસ</h5>
                      <p>
                        સામુદાયિક માધના રૂમમાં વિભાજિત એસી અને નોન-એસી રૂમની
                        સુવિધા ઉપલબ્ધ છે
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="footerbox">
                      <div className="footericon">
                        {/* <i className="iicon-icon-12"></i> */}
                        <img
                          src={MasalaRed}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <h5>ભોજનાલય</h5>
                      <p>
                        મા આરાસુરી અંબાના મંદિરે આવતા તમામ ભક્તોને દરરોજ ભોજન
                        પ્રસાદ પીરસવામાં આવે છે
                      </p>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="footerbox">
                      <div className="footericon">
                        {/* <i className="iicon-icon-13"></i> */}
                        <img
                          src={UddanKhatola}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <h5>ઉડનખટોલા</h5>
                      <p>
                        સૌ કોઈ માટે નવો અનુભવ અપાવતી ઉડનખટોલા સેવા હવે અંબાજી
                        ખાતે ઉપલબ્ધ છે
                      </p>
                      {/* {ComingSoonComponent} */}
                      {/* <a className="readlink">
                Read more<i className="iicon-icon-28"></i>
              </a> */}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="footerbox">
                      <div className="footericon">
                        {/* <i className="iicon-icon-12"></i> */}
                        <img
                          src={SpotlightLight}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <h5>લાઇટ શો</h5>
                      <p>
                        અંબાજી મંદિર ખાતે મનમોહક લાઇટ એન્ડ સાઉન્ડ શોનો અનુભવ
                        કરો, જે ઇતિહાસ અને આધ્યાત્મિકતા દ્વારા મંત્રમુગ્ધ કરી
                        દેનારી યાત્રા છે
                      </p>
                      {/* {ComingSoonComponent} */}
                      {/* <a className="readlink">
                Read more<i className="iicon-icon-28"></i>
              </a> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* online services : Section-6  ---------------------    */}

      {/* ---------------------  donation : Section-7  */}

      <section className="center society-section overlay primary society-bg window_height">
        <div className="container">
          <div className="society-wrapper">
            <div className="text-center">
              {/* <h2>
        Let’s join hands for the <span>society</span>
      </h2> */}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="societybox">
                  <div className="societybox-img">
                    <img
                      src={MoneyDonation}
                      alt="Ambaji Temple Banaskatha Gujarat"
                    ></img>
                  </div>
                  <div className="societybox-body">
                    <h5>પૈસાનું દાન </h5>
                    <p>અંબાજી મંદિરને ઓનલાઈન પૈસા દાન કરો.</p>
                    <a href="/donation-money" className="orangebtn">
                      દાન કરો
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="societybox">
                  <div className="societybox-img">
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1692249623208_SikharDan.png"
                      alt="Ambaji Temple Banaskatha Gujarat"
                    ></img>
                    {/* <img src={gold} alt="Ambaji Temple Banaskatha Gujarat"></img> */}
                  </div>
                  <div className="societybox-body">
                    <h5>સોનાનું દાન </h5>
                    <p>મંદિર મા સુવર્ણશિખર માટે દાન આપનાર બનો.</p>
                    <a href="/donation-gold" className="orangebtn">
                      દાન કરો
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* donation : Section-7 ---------------------   */}

      {/* ---------------------  shopping : Section-8  */}

      {/* <section className="shop-section center">
        <div className="container">
          <div className="text-center">
            <h2 style={{ margin: "0px" }}>
              <span>ઓનલાઇન ખરીદી</span>
            </h2>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            navigation={true}
            // pagination={{
            //   clickable: true,
            //   type: "bullets",
            //   direction: "horizontal",
            // }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            breakpoints={{
              576: {
                slidesPerView: 2,
              },
              992: {
                slidesPerView: 3,
              },
            }}
          >
            {productData?.map((item, i) => {
              return (
                <SwiperSlide key={i}>
                  <div className="societybox">
                    <div className="societybox-img">
                      <img src={item.thumbnail_image} alt="Ambaji Temple Banaskatha Gujarat"></img>
                    </div>
                    <div className="societybox-body">
                      <h5>{item.name}</h5>
                      <p> {item?.description.substring(0, 35)}...</p>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div className="text-center">
            <a href="/shop-online" className="whitebtn">
              બધી પ્રોડક્ટ્સ જુઓ
            </a>
          </div>
        </div>
      </section> */}

      {/* shopping : Section-8 ---------------------   */}

      {/* ---------------------  ambaji prasad : Section-9 */}

      <section className="center prashad-section window-height">
        <div className="container">
          <div className="prashad-wrapper">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="prashadcontent">
                  <div className="serviceicon">
                    {/* <i className="iicon-icon-19"></i> */}
                    <img
                      src={RedTemple}
                      alt="Ambaji Temple Banaskatha Gujarat"
                      style={{ filter: 'none' }}
                    ></img>
                  </div>
                  <h2>
                    <span>અંબાજી પ્રસાદ</span> તમારા ઘર આંગણે
                  </h2>
                  <p>
                    અંબાજી મંદિરનો પ્રસાદ ઑન્લીને ઓર્ડર કરી તમારા ઘરે ડિલિવરી
                    મેળવો
                  </p>
                  {/*=========e-commerce-button=======*/}
                  <button
                    className="orangebtn"
                    onClick={() => navigate(routes?.onlinePrasad)}
                  >
                    ઓનલાઇન ખરીદી કરો
                  </button>
                  {/*=========e-commerce-button=======*/}
                  {/* {ComingSoonComponent} */}
                </div>
              </div>
              <div className="col-md-6 text-center">
                <div className="prashadimg">
                  <img
                    src={prasad}
                    alt="Ambaji Temple Banaskatha Gujarat"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ambaji prasad : Section-9  ---------------------   */}

      {/* blogs  */}

      {/* <section className="blog-section center">
          <div className="container">
            <div className="text-center blog-heading">
              <h2>બ્લોગ્સ</h2>
            </div>

            <div className="blog-wrapper">
              <div className="row justify-content-center">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={20}
                  navigation={true}
                  modules={[Pagination, Navigation]}
                  className="mySwiper"
                  breakpoints={{
                    576: {
                      slidesPerView: 2,
                    },
                    992: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {blogData?.map((item, i) => {
                    return (
                      <SwiperSlide key={i}>
                        <div
                          className="blogbox"
                          onClick={() =>
                            navigate(routes?.blogDetail, {
                              state: { id: item?.id },
                            })
                          }
                        >
                          <div className="blogbox-img">
                            <img
                              src={item.thumbnail_image}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                          <div className="blogbox-body">
                            <h5
                              className="mb-3"
                              style={{ fontSize: "13px" }}
                            >
                              {item?.name?.substring(0, 20)}...
                            </h5>
                            <p>{item?.description?.substring(0, 50)}...</p>
                            <button
                              type="button"
                              className="orangebtn"
                              onClick={() =>
                                navigate(routes?.blogDetail, {
                                  state: { id: item?.id },
                                })
                              }
                            >
                              View
                            </button>
                            
                          </div>
                        </div>
                      </SwiperSlide>
                    );
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </section> */}

      {/*---------------------  how to reach : Section-10 */}

      <section className="center reach-section overlay primary reach-bg window_height">
        <div className="container">
          <div className="reach-wrapper">
            <h2>
              અંબાજી <span>કેવી રીતે પહોંચવું</span>
            </h2>
            <div className="reachrow">
              <div className="reachbox">
                <div className="reach-icon">
                  {/* <i className="iicon-icon-7"></i> */}
                  <i className="iicon-icon-all-1">
                    <img
                      src={carBlack}
                      alt="car-black"
                      className="img-bottom"
                    ></img>
                    <img
                      src={carWhite}
                      alt="car-white"
                      className="img-top"
                    ></img>
                  </i>
                </div>
                <h5>રોડ માર્ગે અંબાજી પહોંચવું</h5>
                <p>
                  માઉન્ટ આબુ (50 કિમી), ઉદયપુર (172 કિમી), અમદાવાદ (175 કિમી),
                  દ્વારકા (620 કિમી), ગીર (520 કિમી) અને પાલનપુર (60 કિમી) જેવા
                  ઘણા મોટા શહેરો સાથે અંબાજી રોડ મારફતે સારી કનેક્ટિવિટી ધરાવે
                  છે. આ સ્થળોએ બસ સેવાના ઘણા વિકલ્પો ઉપલબ્ધ છે. અંબાજી સાથે સારી
                  કનેક્ટિવિટી ધરાવતા અન્ય સ્થળો જોધપુર, સુરત, અંકલેશ્વર, વડોદરા
                  વગેરે છે.
                </p>
              </div>
              <div className="reachbox">
                <div className="reach-icon">
                  {/* <i className="iicon-icon-6"></i> */}
                  <i className="iicon-icon-all-1">
                    <img
                      src={planeBlack}
                      alt="plane-black"
                      className="img-bottom"
                    ></img>
                    <img
                      src={planeWhite}
                      alt="plane-white"
                      className="img-top"
                      style={{ top: '-1px' }}
                    ></img>
                  </i>
                </div>
                <h5>હવાઈ માર્ગે અંબાજી પહોંચવું</h5>
                <p>
                  અંબાજીનું સૌથી નજીકનું એરપોર્ટ સરદાર વલ્લભાઈ પટેલ ઈન્ટરનેશનલ
                  એરપોર્ટ છે જે અંબાજીથી 180 કિમી દૂર આવેલું છે. આ એરપોર્ટ
                  અંબાજી નગરને વિશ્વના ઘણા મોટા શહેરો અને નગરો સાથે જોડે છે.
                  અંબાજી જવા માટે એરપોર્ટ પરથી ટેક્સી ભાડે લેવી શક્ય છે.
                </p>
              </div>
              <div className="reachbox">
                <div className="reach-icon">
                  {/* <i className="iicon-icon-5"></i> */}
                  <i className="iicon-icon-all-1">
                    <img
                      src={trainBlack}
                      alt="train-black"
                      className="img-bottom"
                      style={{ top: '-1px' }}
                    ></img>
                    <img
                      src={trainWhite}
                      alt="train-white"
                      className="img-top"
                    ></img>
                  </i>
                </div>
                <h5>ટ્રેન દ્વારા અંબાજી પહોંચવું</h5>
                <p>
                  અંબાજી ગુજરાત અને રાજસ્થાન બંનેની લોકલ ટ્રેનો દ્વારા ખૂબ જ
                  સારી રીતે જોડાયેલું છે કારણ કે તે બંને રાજ્યો સાથે સરહદ ધરાવે
                  છે. આબુ રોડ સ્ટેશન અંબાજીનું સૌથી નજીકનું રેલ્વે સ્ટેશન છે અને
                  તે 20 કિમીના અંતરે છે. આ રેલ્વે સ્ટેશન સૌથી મહત્વપૂર્ણ નગરો
                  અને શહેરો સાથે સારી કનેક્ટિવિટી ધરાવે છે. આ સ્ટેશનથી અંબાજી
                  સુધી ખૂબ જ ઓછા ખર્ચે ટેક્સીઓ અને વાહનવ્યવહારની અન્ય પદ્ધતિઓ
                  ઉપલબ્ધ છે.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/*how to reach : Section-10 ---------------------   */}

      {/*---------------------   arasuri : Section-11  */}
      <Arasuri />
      {/*arasuri : Section-11 ---------------------    */}

      <Footer />
    </div>
  );
};

export default MobileHome;
