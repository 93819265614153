import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginSchema } from "../../_Constants/validations";
import Footer from "../../Components/footer";
import "./auth.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Arasuri from "../../Components/arasuri";
import LoginService from "../../Api_config/Auth/login";
import { routes } from "../../_Constants/routes";
import LazyLoader from "../../Components/lazyLoader";
import CartService from "../../Api_config/E_store/cart";
const Login = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  const [showPassword, setShowPassword] = useState(false);
  const [isShowToaster, setShowToaster] = useState(false);

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      primary_contact_number: "",
      password: "",
    },
    validationSchema: LoginSchema,
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values) => {
      // console.log(values);
      LoginService.login(values)
        .then(async (res) => {
          if (res.data.success) {
            setShowToaster(true);
            // console.log(res.data);
            // console.log(res.data.data);
            // console.log(res.data?.token);
            // console.log(res.data.data[0]?.id);
            localStorage.setItem("token", JSON.stringify(res.data?.token));
            localStorage.setItem(
              "user_id",
              res.data.data[0]?.id
              // JSON.stringify(res.data.data?.user_id)
            );
            if (
              localStorage.getItem("previous_url") == "/online-shopping-detail"
            ) {
              return navigate("/cart");
            }

            if (localStorage.getItem("previous_url") == "/checkout") {
              const redirection_url =
                localStorage.getItem("previous_url") || "/home";
              navigate(redirection_url, { state: { isDirectBuy: true } });
              const cartData = localStorage.getItem("cartData");
              if (cartData) {
                const cartDataObj = JSON.parse(cartData);
                const finalData = {
                  ...cartDataObj,
                  user_id: res.data.data[0]?.id,
                };
                try {
                  const res = await CartService.getBuyNowData(finalData);
                } catch (error) {
                  // console.log(error);
                }
              }
            } else {
              const redirection_url =
                localStorage.getItem("previous_url") || "/home";
              navigate(redirection_url, { state: { isDirectBuy: true } });
              const cartData = localStorage.getItem("cartData");
              if (cartData) {
                const cartDataObj = JSON.parse(cartData);
                const finalData = {
                  ...cartDataObj,
                  user_id: res.data.data[0]?.id,
                };
                try {
                  const res = await CartService.getBuyNowData(finalData);
                } catch (error) {
                  // console.log(error);
                }
              }
            }
            const redirection_url =
              localStorage.getItem("previous_url") || "/home";
            navigate(redirection_url, { state: { isDirectBuy: true } });
            const cartData = localStorage.getItem("cartData");
            if (cartData) {
              const cartDataObj = JSON.parse(cartData);
              const finalData = {
                ...cartDataObj,
                user_id: res.data.data[0]?.id,
              };
              try {
                const res = await CartService.getBuyNowData(finalData);
              } catch (error) {
                // console.log(error);
              }
            }
            toast.success(res.data.message, { autoClose: 1000 });
          }
        })
        .catch((error) => {
          // navigate(routes?.error500);
          toast.error(error.response?.data?.message, { autoClose: 1000 });
        });
    },
    enableReinitialize: true,
  });
  return (
    <>
      <section className="header-banner contactus-banner">
        <div className="container">
          <h1>Login</h1>
        </div>
      </section>
      <div className="auth">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10 col-sm-12 col-12">
              <div className="card">
                <form action="POST" onSubmit={formik.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="primary_contact_number">
                      Mobile No. <span className="red-text">*</span>
                    </label>
                    <input
                      type="text"
                      inputMode="numeric"
                      name="primary_contact_number"
                      id="primary_contact_number"
                      className="form-control"
                      onChange={(e) => {
                        formik.handleChange(e);
                        formik.setFieldTouched(
                          "primary_contact_number",
                          true,
                          true
                        );
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                        formik.setFieldTouched(
                          "primary_contact_number",
                          true,
                          true
                        );
                      }}
                      maxLength="10"
                      pattern="\d{10}"
                    />
                    {formik?.touched?.primary_contact_number &&
                      formik?.errors?.primary_contact_number && (
                        <p className="red-text">
                          {formik?.errors?.primary_contact_number}
                        </p>
                      )}
                  </div>

                  <div className="form-group">
                    <label htmlFor="password">
                      Password <span className="red-text">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type={showPassword ? "text" : "password"}
                        name="password"
                        id="password"
                        className="form-control"
                        onChange={(e) => {
                          formik.handleChange(e);
                          formik.setFieldTouched("password", true, true);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          formik.setFieldTouched("password", true, true);
                        }}
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          {showPassword ? (
                            <FaEyeSlash
                              onClick={() => setShowPassword(false)}
                            />
                          ) : (
                            <FaEye onClick={() => setShowPassword(true)} />
                          )}
                        </span>
                      </div>
                    </div>
                    {formik?.touched?.password && formik?.errors?.password && (
                      <p className="red-text">{formik?.errors?.password}</p>
                    )}
                  </div>

                  <p
                    className="forgot-link text-end"
                    onClick={() => navigate("/forgot-password")}
                  >
                    Forgot Password?
                  </p>

                  <div className="form-footer mt-2">
                    <button
                      type="submit"
                      className="orangebtn w-100"
                      disabled={!formik?.isValid}
                    >
                      Login
                    </button>
                    <p className="text-center mt-4">
                      Don't have an account?{" "}
                      <span onClick={() => navigate(routes.signUp)}>
                        Sign up here
                      </span>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isShowToaster ? (
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
      ) : null}
      <Arasuri />
      <Footer />
    </>
  );
};
export default Login;
