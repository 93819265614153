import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const getSlots = (requestData) => {
  return http.post(api_urls?.getSlots, requestData);
};

const getDhaja = () => {
  return http.get(api_urls?.getDhaja);
};

const checkForAvailability = (requestData) => {
  return http.post(api_urls?.yagna_checkForAvailability, requestData);
};
const checkRegisteredNumber = (primary_contact_number) => {
  return http.get(
    `${api_urls?.getUser}?primary_contact_number=${primary_contact_number}`
  );
};

const getDhajaBooking = (requestData) => {
  return http.post(api_urls?.getDhajaBooking, requestData);
};

const getBookingById = (id) => {
  return http.get(`${api_urls?.getDhajaBooking}?id=${id}`);
};

const getAllAvailableDates = (temple_id) => {
  return http.get(
    `${api_urls?.yagna_getAllAvailableDates}?yagna_temple_id=${temple_id}`
  );
};
const payU = (requestData) => {
  return http.post(api_urls?.createDhajaBooking, requestData);
};

// const getTempleByDate = (yagna_date) => {
//   return http.get(`${api_urls?.getTempleByDate}?yagna_date=${yagna_date}`);
// };

const getTempleByDate = (yagna_date) => {
  // Convert yagna_date to the desired format "yyyy-MM-dd"
  const formattedDate = new Date(yagna_date).toISOString().split("T")[0];

  // Construct the API URL with the formatted date
  return http.get(`${api_urls?.getTempleByDate}?yagna_date=${formattedDate}`);
};

const getOfflineBooking = (requestData) => {
  // console.log(requestData);
  return http.post(api_urls?.getOfflineBoking, requestData);
};

/** OTP Vefification API's */

const sendOTP = (primary_contact_number) => {
  return http.get(
    `${api_urls?.yagna_sendOTP}?contact_number=${primary_contact_number}`
  );
};

const verifyOTP = (primary_contact_number, otp) => {
  return http.get(
    `${api_urls?.yagna_verifyOTP}?contact_number=${primary_contact_number}&otp=${otp}`
  );
};

const DhajaBookingService = {
  getSlots,
  getDhaja,
  checkForAvailability,
  getDhajaBooking,
  getBookingById,
  getAllAvailableDates,
  sendOTP,
  verifyOTP,
  getTempleByDate,
  getOfflineBooking,
  payU,
  checkRegisteredNumber,
};
export default DhajaBookingService;
