import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const getAll = () => {
  return http.get(api_urls?.getEvent);
};

const getById = (id) => {
  return http.get(`${api_urls?.getEvent}?id=${id}`);
};

const getByTimeFramePast = () => {
  const currentDate = new Date();
  // console.log(currentDate)
  const formattedDate = currentDate.toISOString(); // Format the current date as needed
  // console.log(formattedDate)

  return http.get(`${api_urls?.getEvent}?past=${formattedDate}`);
};

const getByTimeFrameFuture = () => {
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString(); // Format the current date as needed

  return http.get(`${api_urls?.getEvent}?future=${formattedDate}`);
};

const EventService = {
  getAll,
  getById,
  getByTimeFramePast,
  getByTimeFrameFuture,
};

export default EventService;
