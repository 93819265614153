import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { routes } from '../../_Constants/routes';
import SignUpService from '../../Api_config/Auth/signUp';
import Footer from '../../Components/footer';
import Arasuri from '../../Components/arasuri';

const OTPVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOTP] = useState();
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const verifyOTP = () => {
    SignUpService.verifyOTP(location?.state?.primary_contact_number, otp)
      .then((res) => {
        // console.log(res)
        if (res.data.success) {
          navigate(routes?.login);
        }
        toast.success(res.data.message);
      })
      .catch((error) => {
        navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };

  const resendOTP = () => {
    SignUpService.sendOTP(location?.state?.primary_contact_number)
      .then((res) => {
        if (res.data.success) {
          setSeconds(30);
        }
        toast.success(res.data.message);
      })
      .catch((error) => {
        navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };

  return (
    <>
      <section className="header-banner contactus-banner">
        <div className="container">
          <h1>OTP Verification</h1>
        </div>
      </section>

      <div className="auth">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10 col-sm-12 col-12">
              <div className="card">
                <div>
                  <div className="form-group">
                    <label htmlFor="otp">OTP</label>
                    <input
                      type="text"
                      inputMode="numeric"
                      name="otp"
                      id="otp"
                      min="6"
                      max="6"
                      placeholder="_ _ _ _ _ _"
                      className="form-control"
                      onKeyUp={(event) => setOTP(event.target.value)}
                    />
                    {(otp?.length > 6 || otp?.length < 6) && (
                      <p className="red-text">OTP must be a 6 digits number</p>
                    )}
                  </div>
                  <div className="d-flex">
                    <div className="">
                      <button
                        type="submit"
                        className="orangebtn"
                        onClick={() => verifyOTP()}
                        disabled={otp?.length !== 6}
                      >
                        Verify OTP
                      </button>
                    </div>
                    <div className="" style={{ marginLeft: '5px' }}>
                      {seconds == '00' && (
                        <button
                          className="whitebtn"
                          onClick={() => resendOTP()}
                        >
                          Resend OTP
                        </button>
                      )}
                    </div>
                  </div>
                  <div>
                    {seconds > 0 ? (
                      <p>
                        Resend OTP in
                        {seconds < 10 ? `0${seconds}` : ` ${seconds}`} sec
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};
export default OTPVerification;
