import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TouristService from "../../../Api_config/Home_page/touristAttraction";
import { routes } from "../../../_Constants/routes";
import Footer from "../../../Components/footer";
import { Helmet } from "react-helmet-async";

import "./touristAttraction.scss";
import Arasuri from "../../../Components/arasuri";
import LazyLoader from "../../../Components/lazyLoader";

const TouristAttraction = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  const [touristData, setTouristData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    TouristService.getAll().then((res) => {
      // console.log(res.data);
      setTouristData(res.data.data);
    });
  }, [setTouristData]);

  const gotoTouristInfo = (tourist) => {
    // console.log(tourist);
    navigate(routes?.touristAttractionDetails, {
      state: { id: tourist?.id },
    });
    // navigate("/product-info", { state: { id: productId } });
  };

  return (
    <>
      <Helmet>
        <title>Ambaji Temple Tourist Attraction | Banaskantha Gujarat</title>
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti
Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti
timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and
Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details,
Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji,
Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta
          name="author"
          content="Ambaji Temple and Gabbar Banaskantha Gujarat | BinTech Services Ahmedabad"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner tourist-banner">
            <div className="container">
              <h1>પર્યટકો માટે નું આકર્ષણ</h1>
            </div>
          </section>

          <section className="attractions-page">
            <div className="container">
              <div className="row justify-content-center">
                {touristData?.map((item) => {
                  return (
                    <div
                      className="col-md-6 col-lg-4"
                      onClick={() => gotoTouristInfo(item)}
                    >
                      <div className="blogbox">
                        <div className="blogbox-img">
                          <img
                            src={item.thumbnail_image}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <div className="blogbox-body">
                          <h4>{item.name}</h4>
                          <a href="#">
                            <h6>{item.location}</h6>
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default TouristAttraction;
