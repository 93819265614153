import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Modal from "react-modal";
import { Helmet } from "react-helmet-async";

import Footer from "../../Components/footer";
import "./gabbar.scss";

// images
import gabbarBanner from "../../Assets/Images/Gabbar/gabbar-banner.jpeg";
import templeImg from "../../Assets/Images/Temple/Ambaji Temple Shree Yantra.jpeg";
import Arasuri from "../../Components/arasuri";
import gImage1 from "../../Assets/Images/Gabbar/gImage1.png";
import gImage2 from "../../Assets/Images/Gabbar/gImage2.png";
import gImage3 from "../../Assets/Images/Gabbar/gImage3.png";
import gImage4 from "../../Assets/Images/Gabbar/gImage4.png";
import ZoomIcon from "../../Assets/Images/Temple/ZoomIcon.svg";
import LazyLoader from "../../Components/lazyLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px ",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
};
const Gabbar = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const images = [gImage1, gImage2, gImage3, gImage4];

  const handleImageClick = (index) => {
    setActiveSlide(index);
    setModalOpen(true);
  };

  return (
    <>
      {/* helmet  */}
      <Helmet>
        <title>
          About Ambaji Mandir Gabbar Shaktipeeth | Banaskantha Gujarat
        </title>
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti
Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti
timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and
Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details,
Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji,
Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar "
        />
        <meta
          name="author"
          content="Ambaji Temple and Gabbar Banaskantha Gujarat | BinTech Services Ahmedabad"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* ------------  */}
      {!isLoading ? (
        <div className="main-wrapper">
          {/* gabbar banner  */}
          <section className="header-banner gabbar-banner">
            <div className="container">
              <h1>અંબાજી ગબ્બર શક્તિપીઠ</h1>
            </div>
          </section>
          {/* -------------  */}

          {/* details  */}
          <section className="about-page">
            <div className="container">
              <div className="about-main">
                <div className="templetop">
                  <div className="row ">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <div className="templeimg">
                        <img
                          src={gabbarBanner}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <h2>
                        ગબ્બર <span>પર્વત પર આપનું સ્વાગત છે</span>
                      </h2>
                      <p className="white-space">
                        ગુજરાતીમાં ગબ્બરનો શાબ્દિક અર્થ થાય છે પહાડી. ગબ્બર
                        "અખંડ જ્યોત જે જમીનના સ્તરથી 1.5 કિલોમીટર ઉપર આવેલું છે,
                        ચોક્કસ 999 પગથિયાં માટે પ્રસિદ્ધ છે. દર વર્ષે લગભગ 30
                        લાખ ભક્તો ગબ્બરના દર્શન માટે આવે છે." "માતાજી કા ઝુલા",
                        "કાલ ભૈરવ મંદિર" છે. ગબ્બર પરિક્રમા સાથેના આકર્ષણના
                        મુખ્ય બિંદુઓ છે. અંબાજી નગરની ટેકરી પરથી ભવ્ય નજારો,
                        અરવલ્લી ના જંગલો જોવા લાયક છે. રોપ-વે સેવાઓ પણ 1998ના
                        વર્ષમાં શરૂ કરવામાં આવી હતી. રોપ-વે માટેનું બુકિંગ
                        ઓફિસમાંથી સરળતાથી કરી શકાય છે. ભારતીય ધર્મગ્રંથો અનુસાર
                        ગબ્બર તીર્થ, વૈદિક નદી સરસ્વતીના ઉત્પત્તિના કિનારે,
                        અંબિકા જંગલમાં આરાસુરની ટેકરીઓમાં, દક્ષિણ-પશ્ચિમ તરફ
                        અરવલ્લીની જૂની ટેકરીઓ (51) શક્તિપીઠો માં ની એક છે .
                      </p>
                      <br />
                      <p className="white-space">
                        દેવી ભાગવતની દંતકથા અનુસાર, મહિશાસુર સમગ્ર બ્રહ્માંડ
                        માટે ખતરનાક રાક્ષસ હતો, તેથી ત્રિદેવ બ્રહ્મા (સૃષ્ટિના
                        ભગવાન), વિષ્ણુ (જાળવણીના ભગવાન) અને મહેશ (વિનાશના ભગવાન)
                        ના નેતૃત્વ હેઠળના તમામ દેવો આખરે ગયા. બ્રહ્માંડની
                        સર્વોચ્ચ મૂળ કોસ્મિક શક્તિ મહાદેવી આધ્યા શક્તિના અંતિમ
                        આશ્રય , બચાવ અને મદદ માટે તેમની પૂજા કરી. અને પછી આધ્યા
                        દેવી શક્તિ સૂર્યની કિરણોના તેજસ્વી વર્તુળથી ઘેરાયેલા
                        શસ્ત્રો સાથે પૃથ્વી પર અવતર્યા, જેથી અણુ ઊર્જા તેના
                        મૂળમાંથી ઉભરી આવે છે અને દેવીએ તેની પવિત્ર તલવાર દ્વારા
                        મહિષાસુર રાક્ષસને મારી નાખ્યો અને મુક્ત કર્યો અને
                        ત્યારથી તે વિશ્વમાં "મહિસાસુર મર્દિની" તરીકે પ્રખ્યાત
                        થયાં.
                      </p>
                      <br />
                    </div>
                  </div>
                </div>
                <div className="about-tabs">
                  <p style={{ whiteSpace: "pre-wrap" }}>રામાયણમાં ગબ્બર</p>
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    રામાયણમાં કહેલી દંતકથા અનુસાર, ભગવાન રામ અને લક્ષ્મણ
                    સીતાજીની શોધમાં શ્રૃંગી રુષિના આશ્રમમાં આવ્યા, જ્યાં તેમને
                    ગબ્બરમાં દેવી અંબાજીની પૂજા કરવાનું કહેવામાં આવ્યું. અને
                    રામે તેમ કર્યું અને જગત માતા શક્તિ (સમગ્ર બ્રહ્માંડની ઉર્જા
                    માતા) દેવી અંબાજીએ તેમને "અજય" નામનું ચમત્કારિક તીર આપ્યું,
                    જેની મદદથી રામે યુદ્ધમાં તેમના દુશ્મન રાવણ પર વિજય મેળવ્યો
                    અને તેનો વધ કર્યો.
                  </p>
                  <br />
                  <p style={{ whiteSpace: "pre-wrap" }}>ભગવાન કૃષ્ણનું મુંડન</p>
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    એવી પણ એક દંતકથા છે કે દ્વાપર યુગના સમયગાળા દરમિયાન ભગવાન
                    બાલકૃષ્ણના વાળ ઉતારવાની બાબરી નો પ્રસંગ પણ અહીં આ ગબ્બર
                    ટેકરી પર ઉજવાયો હતો, તેમના પાલક માતા-પિતા નંદ અને યશોદા,
                    જેમણે દેવી અંબાજી અને ભગવાન શિવની પૂજા કરી હતી.
                  </p>
                  <br />
                </div>

                {/* multiple images  */}
                <div className="about-tabs">
                  <div className="row">
                    {images.map((image, index) => (
                      <div
                        key={index}
                        className="col-lg-2 col-md-3 col-sm-4 col-6"
                      >
                        <div className="about-gallery">
                          <div
                            className="about-img"
                            onClick={() => handleImageClick(index)}
                          >
                            <img src={image} alt={`image-${index}`} />
                            <span className="zoomicon">
                              <img
                                src={ZoomIcon}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ---------------  */}

          <Arasuri />
          <Footer />

          <div className="temple-image-modal">
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setModalOpen(false)}
              style={customStyles}
              shouldCloseOnOverlayClick={false}
              contentLabel="Example Modal"
            >
              <div className="d-flex justify-content-end close-btn">
                <button
                  type="button"
                  className="btn"
                  onClick={() => setModalOpen(false)}
                >
                  X
                </button>
              </div>
              <Swiper
                navigation={true}
                modules={[Navigation]}
                initialSlide={activeSlide}
                loop
                className="mySwiper"
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="img-container">
                      <img
                        src={image}
                        alt={`images of temple-${index}`}
                        style={{ display: "block", margin: "auto" }}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Modal>
          </div>
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default Gabbar;
