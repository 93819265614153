import http from '../../_environment';
import { api_urls } from '../../_Constants/api_urls';

const getAllDonation = () => {
  return http.get(api_urls?.getAllBhadarviDonationCategory);
};

const createDonation = (data) => {
  console.log(data);
  return http.post(api_urls?.createDonation, data);
};

const BhadarviDonationService = {
  getAllDonation,
  createDonation
};

export default BhadarviDonationService;
