import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const create = (data) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return http.post(api_urls?.createSwayamSevak, data, { headers });
};

const getAllSeva = (id) => {
  return http.get(`${api_urls?.getAllSeva}`);
};
const getAllCampforcontact = (representative_primary_contact_number) => {
  return http.get(
    `${api_urls?.getAllSevaCamp}?representative_primary_contact_number=${representative_primary_contact_number}`
  );
};
const getAllCampCategory = () => {
  return http.get(api_urls?.getSevaCampCategory);
};

const getAllVehicleCategory = () => {
  return http.get(api_urls?.getAllVehicleCategory);
};

// OTP

const sendOTP = (representative_primary_contact_number) => {
  return http.get(
    `${api_urls?.moneyDonation_sendOTP}?contact_number=${representative_primary_contact_number}`
  );
};

const verifyOTP = (representative_primary_contact_number, otp) => {
  return http.get(
    `${api_urls?.moneyDonation_verifyOTP}?contact_number=${representative_primary_contact_number}&otp=${otp}`
  );
};

const getBySevaCampKeyword = (representative_primary_contact_number) => {
  return http.get(
    `${api_urls?.getBySevaCampKeyword}?representative_primary_contact_number=${representative_primary_contact_number}`
  );
};

// USER
const checkRegisteredNumber = (representative_primary_contact_number) => {
  return http.get(
    `${api_urls?.getUser}?primary_contact_number=${representative_primary_contact_number}`
  );
};
const saveInFirebase = (data) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return http.post(api_urls?.saveInFirebase, data, { headers });
};
const getTC = () => {
  return http.get(api_urls?.getTermsConditions);
};
const getBySwayamsevakById = (id) => {
  return http.get(`${api_urls?.getSwayamSevak}?id=${id}`);
};

const SwayamSevakService = {
  create,
  getAllSeva,
  sendOTP,
  verifyOTP,
  checkRegisteredNumber,
  saveInFirebase,
  getAllCampCategory,
  getAllVehicleCategory,
  getBySevaCampKeyword,
  getTC,
  getBySwayamsevakById,
  getAllCampforcontact,
};

export default SwayamSevakService;
