import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Modal from "react-modal";
import { Helmet } from "react-helmet-async";

import Footer from "../../Components/footer";
import "./temple.scss";
import "../Home/home.scss";

import Arasuri from "../../Components/arasuri";

// images
import templeImg from "../../Assets/Images/Temple/Ambaji Temple Shree Yantra.jpeg";
import ambajiTemple from "../../Assets/Images/Temple/templepage.jpeg";
import tImage1 from "../../Assets/Images/Temple/tImage1.png";
import tImage2 from "../../Assets/Images/Temple/tImage2.png";
import tImage3 from "../../Assets/Images/Temple/tImage3.png";
import tImage4 from "../../Assets/Images/Temple/tImage4.png";
import ZoomIcon from "../../Assets/Images/Temple/ZoomIcon.svg";
import LazyLoader from "../../Components/lazyLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    padding: "0px ",
    transform: "translate(-50%, -50%)",
    border: "none",
  },
};
const Temple = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  const images = [tImage1, tImage2, tImage3, tImage4];

  const handleImageClick = (index) => {
    setActiveSlide(index);
    setModalOpen(true);
  };

  return (
    <>
      {/* helmet  */}
      <Helmet>
        <title>About Ambaji Mandir | Banaskantha Gujarat</title>
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti
Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti
timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and
Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details,
Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji,
Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar "
        />
        <meta
          name="author"
          content="Ambaji Temple and Gabbar Banaskantha Gujarat | BinTech Services Ahmedabad"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* ---------------  */}

      {!isLoading ? (
        <div className="main-wrapper temple">
          {/* tample banner  */}
          <section className="header-banner temple-banner">
            <div className="container">
              <h1>અંબાજી મંદિર</h1>
            </div>
          </section>
          {/* ---------------  */}

          {/* details  */}
          <section className="about-page">
            <div className="container">
              <div className="about-main">
                <div className="templetop">
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <div className="templeimg">
                        <img
                          src={ambajiTemple}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                    </div>

                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                      <h2>
                        અંબાજી <span>મંદિરમાં આપનું સ્વાગત છે</span>
                      </h2>
                      <p className="white-space">
                        અંબાજી મંદિર ઉત્તર ગુજરાત ભારતના બનાસકાંઠા જિલ્લાના
                        દાંતા તાલુકામાં આવેલા અંબાજી શહેરમાં આવેલું છે.
                        અરવલ્લીની સુંદર ટેકરીઓ અંબાજી નગરને ઘેરી લે છે અને
                        અંબાજી "શિખરોની લાઇન" માં વસેલું હોવાનું કહેવાય છે.
                        અરવલ્લીની ટેકરીઓ સ્થાનિક રીતે મેવાતની ટેકરીઓ તરીકે પણ
                        ઓળખાય છે. અંબાજી નગર ઉત્તર ગુજરાત અને રાજસ્થાનના આબુ રોડ
                        જેવા અનેક સ્થળોની સરહદો વચ્ચે આવેલું છે.
                      </p>
                      <br />
                      <p className="white-space">
                        અંબાજીમાં ઉનાળો તીવ્ર પવન સાથે 30 ડિગ્રીથી 46 ડિગ્રી
                        વચ્ચે તાપમાન સાથે જ્વલંત હોય છે. શિયાળામાં તાપમાન 10
                        ડિગ્રીથી 36 ડિગ્રી સુધી રહે છે અને આ મુલાકાત લેવાનો
                        આનંદદાયક સમય બનાવે છે. સરેરાશ 20 ઇંચ વરસાદ સાથે ચોમાસું
                        મુલાકાત લેવાનો શ્રેષ્ઠ સમય છે. મંદિર 480 મીટરની ઉંચાઈ પર
                        છે.
                      </p>
                      <br />

                      <p className="white-space">
                        અંબાજી એક મહત્વપૂર્ણ મંદિર છે જ્યાં દર અઠવાડિયે હજારો
                        ભક્તો તેની તરફ આવે છે. આ મંદિરનું સ્થાન પાલનપુરથી 65
                        કિમી, માઉન્ટ આબુથી 45 કિમી, અમદાવાદથી 50 કિમી,
                        કડિયાદ્રાથી 50 કિમી અને આબુ રોડથી 20 કિમી દૂર જેવા ઘણા
                        મોટા સ્થળોએથી સરળતાથી પહોંચી શકાય તેવું બનાવે છે.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="about-tabs">
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    રસપ્રદ વાત એ છે કે પવિત્ર આરાસુરી અંબાજી મંદિરની અંદર દેવીની
                    કોઈ પ્રતિમા કે છબી નથી. પૂજારીઓએ ગોખના ઉપરના ભાગને એવી રીતે
                    સજાવ્યો હતો કે તે દૂરથી દેવીની મૂર્તિ જેવો દેખાય. પવિત્ર
                    શ્રી યંત્ર મૂળ દેવતા તરીકે પૂજવામાં આવે છે. તેને યંત્રનો
                    ફોટો પાડવાની પરવાનગી નથી. પૂર્ણિમાના દિવસોમાં, મંદિર દિવ્ય
                    પ્રકાશ મેળવે છે અને લાખો ભક્તો તેની સુંદરતા અને
                    આધ્યાત્મિકતામાં ભીંજાવા માટે તેની મુલાકાત લે છે. ભાદ્ર
                    પૂર્ણિમાના દિવસે એક વિશાળ મેળો ભરાય છે અને ઘણા લોકો તેમના
                    વતનથી ચાલીને મંદિરે પહોંચે છે.
                  </p>
                  <br />
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    મંદિરની અંદર એક ચમકતું સોનેરી શક્તિ વિસા શ્રી યંત્ર છે જેમાં
                    કુર્મા પાછળ બહિર્મુખ આકાર અને તેની સાથે 51 બિજ અક્ષરો છે. આ
                    યંત્રો અને ઉજૈન અને નેપાળમાં જોવા મળતા યંત્રો વચ્ચે સુમેળ
                    સમીકરણ છે. આ પૂજાનું મુખ્ય કેન્દ્ર છે અને તેમાં વિશેષ
                    ભૌમિતિક રૂપરેખાઓ પર પવિત્ર વૈદિક લખાણ છે. આંખો પર પટ્ટી
                    બાંધ્યા પછી જ વ્યક્તિ શ્રી વિસા યંત્રની પૂજા કરી શકે છે.
                  </p>
                  <br />
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    મંદિરના શિખર પર ભવ્ય લાલ ધ્વજ છે અને મંદિર સોનાના શંકુ સાથે
                    સફેદ આરસનું બનેલું છે. આ વિસ્તારના નાગર બ્રાહ્મણોએ મંદિરનું
                    નિર્માણ કરાવ્યું હતું અને પવિત્ર આદેશો અનુસાર મંદિરના માત્ર
                    બે દરવાજા (મોટા આગળનો દરવાજો અને એક નાનો બાજુનો દરવાજો)
                    બાંધવામાં આવ્યો હતો. મંદિર ચાચર ચોકથી ચારે બાજુથી ઘેરાયેલું
                    છે અને અહીં હવન કરવામાં આવે છે.
                  </p>
                  <br />
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    માનસરોવર નામનો લંબચોરસ પવિત્ર કુંડ છે જે અંબાજી મંદિરથી થોડે
                    દૂર છે.
                  </p>
                  <br />
                  <p style={{ whiteSpace: "pre-wrap" }}>
                    સમગ્ર અંબાજી નગર દિવાળીના તહેવારોની ઉજવણી કરે છે ત્યારે
                    સમગ્ર અંબાજી નગર ઝગમગી ઉઠે છે.
                  </p>
                </div>

                {/* multiple images  */}
                <div className="about-tabs">
                  <div className="row">
                    {images.map((image, index) => (
                      <div
                        key={index}
                        className="col-lg-2 col-md-3 col-sm-4 col-6"
                      >
                        <div className="about-gallery">
                          <div
                            className="about-img"
                            onClick={() => handleImageClick(index)}
                          >
                            <img src={image} alt={`image-${index}`} />
                            <span className="zoomicon">
                              <img
                                src={ZoomIcon}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* ------------  */}

                {/* yantra  */}

                <div className="container mt-4">
                  <h2 className="text-center">
                    વિશ્વનું સૌથી મોટું <span>શ્રીયંત્ર</span>
                  </h2>
                  {/* <h3 >વિશ્વનું સૌથી મોટું શ્રીયંત્ર</h3> */}
                  <div className="row align-items-center">
                    <div>
                      <div className="templeimg">
                        <img
                          style={{
                            margin: "auto",
                            display: "block",
                            maxWidth: "400px",
                            height: "auto",
                          }}
                          src={templeImg}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ---------------  */}

          <Arasuri />
          <Footer />

          <div className="temple-image-modal">
            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setModalOpen(false)}
              style={customStyles}
              shouldCloseOnOverlayClick={false}
              contentLabel="Example Modal"
            >
              <div className="d-flex justify-content-end close-btn">
                <button
                  type="button"
                  className="btn"
                  onClick={() => setModalOpen(false)}
                >
                  X
                </button>
              </div>
              <Swiper
                navigation={true}
                modules={[Navigation]}
                initialSlide={activeSlide}
                loop
                className="mySwiper"
              >
                {images.map((image, index) => (
                  <SwiperSlide key={index}>
                    <div className="img-container">
                      <img
                        src={image}
                        alt={`images of temple-${index}`}
                        style={{ display: "block", margin: "auto" }}
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Modal>
          </div>
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default Temple;
