import React, { useRef, useState, useEffect } from "react";
import "./onlinePrasadReceipt.scss";
import OnlinePrasadService from "../../../Api_config/E_store/onlinePrasad";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "../../Donation/Converter/date";
import SignatureService from "../../../Api_config/Home_page/signature";
import Arasuri from "../../../Components/arasuri";
import Footer from "../../../Components/footer";
import { useReactToPrint } from "react-to-print";
import { Helmet } from "react-helmet-async";
import { routes } from "../../../_Constants/routes";

const OnlinePrasadReceipt = () => {
  const componentRef = useRef();
  const [transactionData, setTransactionData] = useState();
  const [signatureData, setSignatureData] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const formattedDate = formatDate(transactionData?.order_placed_date);

  useEffect(() => {
    const id = location?.pathname?.split("/").pop();
    // console.log(location, Number(id));
    OnlinePrasadService.getByProductId(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setTransactionData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        // console.log(e);
      });

    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Ambaji Temple Prasad Order Receipt",
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    },
  });

  const qrUrl = transactionData?.qr_url;

  return (
    <>
      {/* HELMET TAG  */}
      <Helmet>
        <title>Ambaji Temple - Prasad Order Receipt</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details, Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji, Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* -------- */}
      <div className="booking" style={{ backgroundColor: "#f5f5f5" }}>
        <section className="header-banner temple-banner">
          <div className="container">
            <h1>Prasad Receipt</h1>
          </div>
        </section>
        <div className="print-container" ref={componentRef}>
          <div className="pdf-btn">
            <div className="container">
              <div className="row">
                <div
                  style={{ width: "800px", margin: "0px auto 5px" }}
                  className="email-container"
                >
                  <table width="100%">
                    <tr className="header">
                      <td
                        width="17%"
                        rowSpan="3"
                        align="center"
                        valign="middle"
                      >
                        <div className="logo">
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                            style={{ width: "100px", height: "100px" }}
                            alt="Logo"
                          />
                        </div>
                      </td>
                      <td width="83%">
                        <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                      </td>
                    </tr>
                    <tr className="header">
                      <td>
                        <p>
                          <strong>Ta.</strong> Danta, &nbsp;
                          <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                          385110.
                        </p>
                        <p>
                          <strong>Phone No</strong> : 02749-262236 &nbsp;
                          <strong>Email </strong>: ambajitempletrust@gmail.com,
                          &nbsp; www.ambajitemple.in
                        </p>
                        <p>
                          <strong>PAN No </strong>: AACTS5457M &nbsp;
                          <strong>GST No</strong> : 24AACTS5457M2Z0
                        </p>
                      </td>
                    </tr>
                    <tr className="header"></tr>

                    <tr className="headig-text">
                      <td colSpan="2" align="center" valign="middle">
                        <h2>PRASAD ORDER RECEIPT</h2>
                      </td>
                    </tr>
                    <tr className="rs-note">
                      <td colSpan="2" align="center">
                        <p>
                          "May the holy mother Maa Ambe Bless you and your
                          family, friends and give you a happy,
                          <br /> prosperous and spiritually enlightened life
                          ahead"
                        </p>
                      </td>
                    </tr>

                    <tr className="header">
                      <td colSpan="2" align="right">
                        <p>
                          <strong> Date</strong> : {formattedDate}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="center">
                        <table width="100%" className="date-section">
                          <tr>
                            <td width="50%">
                              <strong>Payment Receipt Number</strong>
                            </td>
                            <td width="50%">{transactionData?.order_number}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Name</strong>
                            </td>
                            <td>
                              {transactionData?.User?.first_name ||
                              transactionData?.User?.last_name
                                ? `${transactionData?.User?.first_name || ""} ${
                                    transactionData?.User?.last_name || ""
                                  }`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Mobile Number</strong>
                            </td>
                            <td>
                              {transactionData?.User?.primary_contact_number ||
                                "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Product Detail</strong>
                            </td>
                            <td>
                              {transactionData?.OrderItems?.length > 0
                                ? transactionData.OrderItems.map(
                                    (item, index) => (
                                      <div key={index}>
                                        {item.product_name
                                          ? `${item.product_name}, `
                                          : ""}
                                        Quantity: {item.quantity || "-"},
                                        Amount: {item.amount || "-"}
                                      </div>
                                    ),
                                  )
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Total Amount</strong>
                            </td>
                            <td>
                              {transactionData?.total_amount != null
                                ? `Rs. ${transactionData.total_amount}`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Mode Of Payment</strong>
                            </td>
                            <td>
                              {transactionData?.ModeOfPayment?.name != null
                                ? transactionData?.ModeOfPayment?.name
                                : "Online"}
                            </td>
                          </tr>
                          {transactionData?.txn_ref ? (
                            <tr>
                              <td>
                                <strong>Transaction Reference Number</strong>
                              </td>
                              <td>{transactionData.txn_ref}</td>
                            </tr>
                          ) : null}
                        </table>
                      </td>
                    </tr>

                    <tr align="center" valign="middle" className="teg-line">
                      <td colSpan="2">
                        <table
                          cellSpacing="0"
                          cellPadding="0"
                          border="0"
                          width="100%"
                        >
                          <tr align="center">
                            <td width="40%" valign="middle" className="barcode">
                              <img
                                src={qrUrl}
                                alt="qr Code"
                                // src="https://qrcg-free-editor.qr-code-generator.com/main/assets/images/websiteQRCode_noFrame.png"
                                width="110"
                              />
                            </td>
                            <td width="60%" valign="middle" className="sign">
                              {signatureData?.map((item) => {
                                return (
                                  <>
                                    <img
                                      src={item.signature_image}
                                      // src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716962038848_MicrosoftTeams-image(7).png"
                                      width="120"
                                      height="100"
                                    />
                                    <br />
                                    <p>
                                      {item.name}
                                      <br />
                                      {item.designation}
                                    </p>
                                  </>
                                );
                              })}
                              <p>
                                Shree Arasuri Ambaji Mata Devasthan Trust Ambaji
                                {/* વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                                  શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                                  અંબાજી */}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container text-center">
          <button className="btn orangebtn my-2" onClick={handlePrint}>
            Receipt Download
          </button>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};
export default OnlinePrasadReceipt;
