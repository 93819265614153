import React from "react";
import ambajitemple1 from "../Assets/Images/Home_page/linear.png";
import temple1 from "../Assets/Images/Home_page/temple1.png";
import PrayIcon from "../Assets/Images/Home_page/PrayIcon.svg";
import "../Css/arasuri.scss";

const Arasuri = () => {
  return (
    <section className="window_height temple-section">
      <div className="container">
        <div className="temple-wrapper">
          <div className="temple-image">
            <img
              src={ambajitemple1}
              alt="Ambaji Temple Banaskatha Gujarat"
            ></img>
            <div className="headingdiv">
              1200 <span>વર્ષનું અસ્તિત્વ</span>
            </div>
            <div className="templeicon">
              <img src={temple1} alt="Ambaji Temple Banaskatha Gujarat"></img>
            </div>
          </div>
          <div className="temple-content">
            <h2>
              આરાસુરી <span>અંબાજી મંદિર</span>
            </h2>
            <div className="praydiv">
              {/* <i className="iicon-icon-2"></i> */}
              <img src={PrayIcon} alt="Ambaji Temple Banaskatha Gujarat"></img>
              દર વર્ષે 12.5M+ ભક્તો
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Arasuri;
