import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import UtilsService from "../../../Api_config/Home_page/utils";
// import { useHistory } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import TransactionService from "../../../Api_config/Profile/transaction";
import Select from "react-select";
import { routes } from "../../../_Constants/routes";

const Transaction = () => {
  const navigate = useNavigate();

  const [donationType, setDonationType] = useState("money");
  const [moneyDonationData, setMoneyDonationData] = useState([]);
  const [goldDonationData, setGoldDonationData] = useState([]);
  const [counterMoneyDonation, setCounterMoneyDonation] = useState([]);
  const [counterGoldDonation, setCounterGoldDonation] = useState([]);
  const [articleGet, setArticleGet] = useState([]);

  const [prasadType, setPrasadType] = useState("website");

  const [prasadData, setPrasadData] = useState([]);
  const [prasadCounterData, setPrasadCounterData] = useState([]);
  const [orderData, setOrderData] = useState([]);

  useEffect(() => {
    /** Get Gold Donation */
    if (localStorage.getItem("user_id")) {
      // console.log(localStorage.getItem("user_id"));
      TransactionService.getGoldDonation()
        .then((res) => {
          if (res.data.success) {
            setGoldDonationData(res.data.data);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log(error);
        });
    }

    /** Get Money Donation */
    if (localStorage.getItem("user_id")) {
      TransactionService.getMoneyDonation()
        .then((res) => {
          if (res.data.success) {
            setMoneyDonationData(res.data.data);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log(error)
        });
    }

    if (localStorage.getItem("user_id")) {
      TransactionService.getMoneyCounter()
        .then((res) => {
          if (res.data.success) {
            setCounterMoneyDonation(res.data.data);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log(error)
        });
    }

    if (localStorage.getItem("user_id")) {
      TransactionService.getGoldCounter()
        .then((res) => {
          if (res.data.success) {
            setCounterGoldDonation(res.data.data);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log(error)
        });
    }

    if (localStorage.getItem("user_id")) {
      TransactionService.getArticalDonation()
        .then((res) => {
          if (res.data.success) {
            setArticleGet(res.data.data);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log(error)
        });
    }

    /** Get Prasad Data */
    if (localStorage.getItem("user_id")) {
      TransactionService.getOrder(1)
        .then((res) => {
          if (res.data.success) {
            setPrasadData(res.data.data);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log(error)
        });
    }
    if (localStorage.getItem("user_id")) {
      TransactionService.getCounterPrasad(true)
        .then((res) => {
          if (res.data.success) {
            setPrasadCounterData(res.data.data);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log(error)
        });
    }

    /** Get Order Data */
    if (localStorage.getItem("user_id")) {
      TransactionService.getOrder(2)
        .then((res) => {
          if (res.data.success) {
            setOrderData(res.data.data);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log(error)
        });
    }
  }, [setPrasadData, setOrderData]);

  return (
    <div className="transaction">
      <Tabs>
        <TabList>
          <Tab
            onClick={() => {
              setDonationType("money");
            }}
          >
            Donation
          </Tab>
          <Tab
            onClick={() => {
              setPrasadType("website");
            }}
          >
            Prasad
          </Tab>
          <Tab>Product</Tab>
        </TabList>

        {/* Donation tab */}
        <TabPanel>
          <div className="mb-3">
            <div className="col-sm-6">
              <Select
                onChange={(data) => {
                  setDonationType(data.value);
                }}
                defaultValue={{
                  value: "money",
                  label: "Website Money Donation",
                }}
                options={[
                  { value: "money", label: "Website Money Donation" },
                  { value: "gold", label: "Website Gold Donation" },
                  { value: "moneyCounter", label: "Counter Money Donation" },
                  { value: "goldCounter", label: "Counter Gold Donation" },
                  { value: "article", label: "Article Donation" },
                ]}
              ></Select>
            </div>
          </div>
          {donationType === "money" && (
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="28%">Transaction Id</th>
                    <th width="20%">Date</th>
                    <th width="20%">Donation Purpose</th>
                    <th width="12%">Amount</th>
                    <th width="20%">View</th>
                  </tr>
                </thead>
                {moneyDonationData?.length >= 1 ? (
                  moneyDonationData.map((item) => {
                    return (
                      <tbody>
                        <tr>
                          <td style={{ wordBreak: "break-word" }}>
                            {item?.donation_number}
                          </td>
                          <td>{UtilsService.formatDate(item?.createdAt)}</td>
                          <td>{item?.MoneyDonationType?.name}</td>
                          <td>
                            Rs. {""}
                            {item?.amount}
                          </td>

                          <td>
                            <button
                              onClick={() =>
                                navigate(`/donation-money-receipt/${item.id}`, {
                                  state: { id: item?.id },
                                })
                              }
                              className="whitebtn"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="red-text text-center">
                        No Data Found
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          )}
          {donationType === "gold" && (
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="30%">Transaction Id</th>
                    <th width="30%">Date</th>
                    <th width="20%">Amount</th>
                    <th width="20%">View</th>
                  </tr>
                </thead>
                {goldDonationData?.length >= 1 ? (
                  goldDonationData.map((item) => {
                    return (
                      <tbody>
                        <tr>
                          <td style={{ wordBreak: "break-word" }}>
                            {item?.donation_number}
                          </td>
                          <td>{UtilsService.formatDate(item?.createdAt)}</td>
                          <td>
                            Rs. {""}
                            {item?.amount}
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                navigate(`/donation-gold-receipt/${item.id}`, {
                                  state: { id: item?.id },
                                })
                              }
                              className="whitebtn"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={4} className="red-text text-center">
                        No Data Found
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          )}
          {donationType === "moneyCounter" && (
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="28%">Transaction Id</th>
                    <th width="20%">Date</th>
                    <th width="20%">Donation Purpose</th>
                    <th width="12%">Amount</th>
                    <th width="20%">View</th>
                  </tr>
                </thead>
                {counterMoneyDonation?.length >= 1 ? (
                  counterMoneyDonation.map((item) => {
                    return (
                      <tbody>
                        <tr>
                          <td style={{ wordBreak: "break-word" }}>
                            {item?.donation_number}
                          </td>
                          <td>{UtilsService.formatDate(item?.createdAt)}</td>
                          <td>{item?.MoneyDonationType?.name}</td>
                          <td>
                            Rs. {""}
                            {item?.amount}
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                navigate(
                                  `/donation-counter-money-receipt/${item.id}`,
                                  {
                                    state: { id: item?.id },
                                  },
                                )
                              }
                              className="whitebtn"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="red-text text-center">
                        No Data Found
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          )}
          {donationType === "goldCounter" && (
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="28%">Transaction Id</th>
                    <th width="20%">Date</th>
                    <th width="20%">Donated</th>
                    <th width="12%">Amount</th>
                    <th width="20%">View</th>
                  </tr>
                </thead>
                {counterGoldDonation?.length >= 1 ? (
                  counterGoldDonation.map((item) => {
                    return (
                      <tbody>
                        <tr>
                          <td style={{ wordBreak: "break-word" }}>
                            {item?.donation_number}
                          </td>
                          <td>{UtilsService.formatDate(item?.createdAt)}</td>
                          <td>{item?.GoldDonationType?.name}</td>
                          <td>
                            Rs. {""}
                            {item?.amount}
                          </td>
                          <td>
                            <button
                              onClick={() =>
                                navigate(
                                  `/donation-counter-gold-receipt/${item.id}`,
                                  {
                                    state: { id: item?.id },
                                  },
                                )
                              }
                              className="whitebtn"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="red-text text-center">
                        No Data Found
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          )}
          {donationType === "article" && (
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="28%">Transaction Id</th>
                    <th width="20%">Date</th>
                    <th width="20%">Name</th>
                    <th width="12%">Quantity</th>
                    <th width="20%">View</th>
                  </tr>
                </thead>

                {articleGet?.length >= 1 ? (
                  articleGet.map((item) => {
                    return (
                      <tbody>
                        <tr>
                          <td style={{ wordBreak: "break-word" }}>
                            {item?.donation_number}
                          </td>
                          <td>{UtilsService.formatDate(item?.createdAt)}</td>
                          <td>{item?.article_name}</td>
                          <td>{item?.quantity}</td>

                          <td>
                            <button
                              onClick={() =>
                                navigate(
                                  `/donation-article-receipt/${item.id}`,
                                  {
                                    state: { id: item?.id },
                                  },
                                )
                              }
                              className="whitebtn"
                            >
                              View Details
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={5} className="red-text text-center">
                        No Data Found
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          )}
        </TabPanel>
        {/* Donation tab */}

        {/* prasad tab  */}
        <TabPanel>
          <div className="mb-3">
            <div className="col-sm-6">
              <Select
                onChange={(data) => {
                  setPrasadType(data.value);
                }}
                defaultValue={{
                  value: "website",
                  label: "website",
                }}
                options={[
                  { value: "website", label: "Website" },
                  { value: "temple", label: "Temple" },
                ]}
              ></Select>
            </div>
          </div>

          {prasadType === "website" && (
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="20%">Transaction Id</th>
                    <th width="20%">Date</th>
                    <th width="20%">Product</th>
                    <th width="8%">Order Status</th>
                    <th width="12%">Amount</th>
                    <th width="20%">View</th>
                  </tr>
                </thead>

                {prasadData?.length > 0 ? (
                  <>
                    {prasadData?.map((item, i) => {
                      return (
                        <>
                          {
                            <tbody>
                              <tr>
                                <td style={{ wordBreak: "break-word" }}>
                                  {item?.order_number}
                                </td>
                                <td>
                                  {UtilsService.formatDate(
                                    item?.order_placed_date,
                                  )}
                                </td>
                                <td>
                                  {item?.OrderItems?.map((item, i) => {
                                    return <span>{item?.product_name}, </span>;
                                  })}
                                </td>
                                <td>{item?.OrderStatus?.name}</td>
                                <td>Rs. {item?.total_amount}</td>
                                <td>
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/online-ambaji-temple-prasad-receipt/${item?.id}`,
                                        {
                                          state: { id: item?.id },
                                        },
                                      )
                                    }
                                    className="whitebtn"
                                  >
                                    View Details
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          }
                        </>
                      );
                    })}
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={6} className="red-text text-center">
                        No Data Found
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          )}
          {prasadType === "temple" && (
            <div className="table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="20%">Transaction Id</th>
                    <th width="20%">Date</th>
                    <th width="20%">Product</th>
                    <th width="8%">Mode Of Payment</th>
                    <th width="12%">Amount</th>
                    <th width="20%">View</th>
                  </tr>
                </thead>

                {prasadCounterData?.length > 0 ? (
                  <>
                    {prasadCounterData?.map((item, i) => {
                      return (
                        <>
                          {
                            <tbody>
                              <tr>
                                <td style={{ wordBreak: "break-word" }}>
                                  {item?.order_number}
                                </td>
                                <td>
                                  {UtilsService.formatDate(
                                    item?.order_placed_date,
                                  )}
                                </td>
                                <td>
                                  {item?.CounterOrderItems?.map((item, i) => {
                                    return <span>{item?.product_name}, </span>;
                                  })}
                                </td>
                                <td>{item?.ModeOfPayment?.name}</td>
                                <td>Rs. {item?.total_amount}</td>
                                <td>
                                  <button
                                    onClick={() =>
                                      navigate(
                                        `/counter-ambaji-temple-prasad-receipt/${item?.id}`,
                                        {
                                          state: { id: item?.id },
                                        },
                                      )
                                    }
                                    className="whitebtn"
                                  >
                                    View Details
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          }
                        </>
                      );
                    })}
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={6} className="red-text text-center">
                        No Data Found
                      </td>
                    </tr>
                  </tbody>
                )}
              </table>
            </div>
          )}
        </TabPanel>
        {/* Product Tab  */}

        {/* Product Tab */}
        <TabPanel>
          <div className="table-responsive">
            <table>
              <thead>
                <tr>
                  {/* <th>Order Id</th> */}
                  <th width="20%">Transaction Id</th>
                  <th width="20%">Date</th>
                  <th width="20%">Product</th>
                  <th width="8%">Order Status</th>
                  <th width="12%">Amount</th>
                  <th width="20%">View</th>
                </tr>
              </thead>
              {orderData?.length > 0 ? (
                <>
                  {orderData?.map((item, i) => {
                    return (
                      <>
                        {
                          <tbody>
                            <tr>
                              {/* <td>{item?.id}</td> */}
                              <td style={{ wordBreak: "break-word" }}>
                                {item?.order_number}
                              </td>
                              <td>
                                {UtilsService.formatDate(
                                  item?.order_placed_date,
                                )}
                              </td>
                              <td>
                                {item?.OrderItems?.map((item, i) => {
                                  return <span>{item?.product_name}, </span>;
                                })}
                              </td>
                              <td>{item?.OrderStatus?.name}</td>
                              <td>Rs. {item?.amount}</td>
                              <td>
                                <button
                                  onClick={() =>
                                    navigate(
                                      `/profile/transaction/order-view/${item.id}`,
                                      {
                                        state: { id: item?.id },
                                      },
                                    )
                                  }
                                  className="whitebtn"
                                >
                                  View Details
                                </button>
                              </td>
                            </tr>
                          </tbody>
                        }
                      </>
                    );
                  })}
                </>
              ) : (
                <tbody>
                  <tr>
                    <td colSpan={6} className="red-text text-center">
                      No Data Found
                    </td>
                  </tr>
                </tbody>
              )}
            </table>
          </div>
        </TabPanel>
        {/* Product Tab */}
      </Tabs>
    </div>
  );
};
export default Transaction;
