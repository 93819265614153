import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import './swayamSevak.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
// import SevaCampService from '../../../Api_config/Bhadarvi_poonam/sevaCamp';
// import UtilsService from '../../../Api_config/Home_page/utils';
// import { routes } from '../../../_Constants/routes';
import { SwayamSevakSchema } from '../../../_Constants/validations';
import { RxCross1 } from 'react-icons/rx';
import { Helmet } from 'react-helmet-async';
import { format, subDays } from 'date-fns';
import Error404 from '../../../Pages/error404';
import SwayamSevakService from '../../../Api_config/Swayam_sevak/swayamSevak';
import LazyLoader from '../../../Components/lazyLoader';

const SwayamSevak = (props) => {
  // const [backendErrors, setBackendErrors] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const { data } = props;
  // const history = useHistory();
  const navigate = useNavigate();

  // const [validationSchema, setValidationSchema] = useState(sevaCampSchema);
  const [accepted, setAccepted] = useState(false);
  const [file, setFile] = useState();
  const [filePreview1, setFilePreview1] = useState();
  // const [filePreview2, setFilePreview2] = useState();
  // const [filePreview3, setFilePreview3] = useState();
  // const [fileError, setFileError] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false); // Step 1: State variable
  const imgref1 = useRef();

  const [campCategoryList, setCampCategoryList] = useState([]);
  // const [error, setError] = useState('');

  // const fileFields = [
  //   { field: 'identity_proof', previewSetter: setFilePreview1 }
  // ];

  // const [isGroupRegistration, setIsGroupRegistration] = useState(false);
  // const handlebooking_typeChange = (e) => {
  //   const selectedType = e.target.value;
  //   setIsGroupRegistration(selectedType === 'group');
  //   formik.setFieldValue('booking_type', selectedType);
  // };
  const idProofOptions = [
    { value: 'Aadhar Card', label: 'Aadhar Card' },
    // { value: "Pan card", label: "Pan Card" },
    { value: 'Passport', label: 'Passport' },
    { value: 'Driving License', label: 'Driving License' },
    { value: 'Election Card', label: 'Election Card' },
    { value: 'Other', label: 'Other Government Valid ID Proof' }
  ];

  useState(false);

  const handleAddMember = () => {
    const newMember = {
      name: '',
      contact_number: '',
      identity_proof_type: '',
      identity_proof: ''
    };
    formik.setFieldValue('SevaMemberDetails', [
      ...formik.values.SevaMemberDetails,
      newMember
    ]);
  };

  useEffect(() => {
    SwayamSevakService.getAllSeva()
      .then((res) => {
        let sevaList = res.data.data.map((seva) => ({
          value: seva.id,
          label: seva.name
        }));
        setCampCategoryList(sevaList);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    if (data?.identity_proof) {
      setFilePreview1(data?.identity_proof);
    }
  }, [data?.identity_proof]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  useState(false);
  const formik = useFormik({
    initialValues: {
      group_name: '',
      swayma_sevak_name: '',
      primary_contact_number: '',
      email_id: data?.email_id ?? '',

      address_line_1: data?.address_line_1 ?? '',
      address_line_2: data?.address_line_2 ?? '',
      village: data?.village ?? '',
      taluka: data?.taluka ?? '',
      zip_code: data?.zip_code ?? '',
      district: data?.district ?? '',
      date: data?.date ?? '',
      no_of_people: data?.no_of_people ?? '',
      no_of_days: data?.no_of_days ?? '',
      seva_type_id: [],
      identity_proof_type: data?.identity_proof_type ?? '',
      identity_proof: data?.identity_proof ?? '',
      booking_type: data?.booking_type ?? '',
      gender: data?.gender ?? '',
      status_id: data?.status_id ?? '',
      age: data?.age ?? '',
      SevaMemberDetails: []
    },
    validationSchema: SwayamSevakSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values, { setSubmitting }) => {
      const formData = new FormData();

      // Check if file and accepted are valid
      if (filePreview1 && accepted) {
        if (file) {
          formData.append('identity_proof', file);
        } else if (data) {
          formData.append('identity_proof', data.identity_proof);
        }

        // Conditionally append fields based on booking_type
        if (values.booking_type === 'group') {
          formData.append('group_name', values.group_name);
          formData.append(
            'SevaMemberDetails',
            JSON.stringify(values.SevaMemberDetails)
          );
          formData.append('no_of_people', values.no_of_people);
        }

        formData.append('swayma_sevak_name', values.swayma_sevak_name);
        formData.append(
          'primary_contact_number',
          values.primary_contact_number
        );
        formData.append('email_id', values.email_id);
        formData.append('zip_code', values.zip_code);
        formData.append('identity_proof_type', values.identity_proof_type);
        formData.append('date', values.date);
        formData.append('address_line_1', values.address_line_1);
        formData.append('address_line_2', values.address_line_2);
        formData.append('village', values.village);
        formData.append('taluka', values.taluka);
        formData.append('district', values.district);
        formData.append('no_of_days', values.no_of_days);
        formData.append('seva_type_id', '[' + values.seva_type_id + ']'); // e.g., "[1,2]"
        formData.append('booking_type', values.booking_type);
        formData.append('gender', values.gender);
        formData.append('status_id', '1');
        formData.append('age', values.age);

        // Debugging: Log the formData content
        // for (let pair of formData.entries()) {
        //   // console.log(pair[0] + ": " + pair[1]);
        // }

        // Main code
        SwayamSevakService.create(formData)
          .then((res) => {
            // console.log(formData);

            if (res.data.success) {
              toast.success(res.data.message, { autoClose: 1000 });
              setSuccessMessage(res.data.message);
              // setFileError(false);

              navigate(`/swayam-sevak-receipt/${res?.data?.data?.id}`);
            } else {
              setSuccessMessage(res.data.message);
              // setFileError(true);
            }
          })
          .catch((error) => {
            let errorMessage = 'An error occurred. Please try again later.';
            if (error.response && error.response.data) {
              const responseData = error.response.data;
              if (responseData.error) {
                const validationErrors = responseData.error;
                errorMessage = Object.values(validationErrors)
                  .map((err) => err.message)
                  .join(', ');
              } else if (responseData.message) {
                errorMessage = responseData.message;
              }
            }
            // setBackendErrors(errorMessage);
            toast.error(errorMessage, { autoClose: 1500 });
          });

        setTimeout(() => {
          setSubmitting(false);
        }, 1000);
      } else {
        alert('You must accept the declaration.');
        if (!file) {
          // setFileError(true);
        }
        if (!accepted) {
          // handleNextButtonClick();
        }
      }
      // navigate(routes?.error500);
    }
  });
  if (!accepted) {
    formik.handleBlur('accepted');
  }
  // / Handle file selection
  const selectImg = (event, index) => {
    const files = event.target.files;
    if (files.length > 0) {
      const imgObjects = [];
      for (let i = 0; i < files.length; i++) {
        imgObjects.push({ index, file: files[i] });
      }
      uploadImagesToFirebase(index, imgObjects);
    } else {
      console.error('No files selected.');
    }
  };

  const uploadImagesToFirebase = (index, imgObjects) => {
    const formData = new FormData();

    imgObjects.forEach((fileObject, i) => {
      formData.append(
        'image_file',
        fileObject.file,
        `image_${fileObject.index}_${i}`
      );
    });

    // Call saveInFirebase method from SwayamSevakService
    SwayamSevakService.saveInFirebase(formData)
      .then((response) => {
        const identityProof = response.data.data; // Assuming response.data is an array of image URLs
        // console.log(identityProof);
        // Update memberDetails with identityProof
        const updatedMemberDetails = [...formik?.values?.SevaMemberDetails];
        // console.log(updatedMemberDetails[0], identityProof);
        updatedMemberDetails[index].identity_proof = identityProof;
        // eslint-disable-next-line no-undef
        onUploadSuccess(updatedMemberDetails); // Pass updated details to parent or save state
      })
      .catch((error) => {
        // console.error('Error uploading images to Firebase:', error);
      });
  };

  const fileChange = (e, event) => {
    if (event === 1) {
      const imageUrl1 = URL.createObjectURL(e.target.files[0]);
      setFilePreview1(imageUrl1);
    }
    // else if (event == 2) {
    //   const imageUrl2 = URL.createObjectURL(e.target.files[0]);
    //   setFilePreview2(imageUrl2);
    // } else if (event == 3) {
    //   const imageUrl3 = URL.createObjectURL(e.target.files[0]);
    //   setFilePreview3(imageUrl3);
    // }

    setFile(e.target.files[0]);
  };
  // Function to calculate the minimum allowed date based on the selected start date
  const getMinMaxDates = (startDate) => {
    if (!startDate) {
      // Default values if startDate is not available
      return {
        minDate: '',
        maxDate: ''
      };
    }

    const startDateObj = new Date(startDate);
    const minDateObj = subDays(startDateObj, 10); // 10 days before start date
    const maxDateObj = subDays(startDateObj, 1); // 1 day before start date

    return {
      minDate: format(minDateObj, 'yyyy-MM-dd'),
      maxDate: format(maxDateObj, 'yyyy-MM-dd')
    };
  };
  // const { minDate, maxDate } = getMinMaxDates(formik.values.start_date);

  const handleCheckboxChangeforSeva = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      formik.setFieldValue('seva_type_id', [
        ...formik.values.seva_type_id,
        value
      ]);
    } else {
      formik.setFieldValue(
        'seva_type_id',
        formik.values.seva_type_id.filter((id) => id !== value)
      );
    }
  };

  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked);
  };

  const handleInputChange = (index, field, value) => {
    const updatedMembers = [...formik.values.SevaMemberDetails];
    updatedMembers[index] = { ...updatedMembers[index], [field]: value };
    formik.setFieldValue('SevaMemberDetails', updatedMembers);
  };

  const { handleChange, handleSubmit } = formik;
  // -----page-close-404-error-logic-p-----
  if (true) {
    return <Error404 />;
  }
  //-----page-close-404-error-logic-p-----
  return (
    <>
      {/* helmet  */}
      <Helmet>
        <title>Swayam Sevak Registration | Ambaji Bhadarvi Poonam 2024</title>
        <meta
          name="description"
          content="Register Your Swayam Sevak Registration 2024"
        />
        <meta name="keywords" content="Ambaji Swayam Sevak Registration" />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* helmet  */}
      {!isLoading ? (
        <div className="sang-wrapper">
          <section className="header-banner temple-banner">
            <div className="container">
              <h1>સ્વયંસેવક રજીસ્ટ્રેશન</h1>
            </div>
          </section>

          <section className="swaymsevak-box">
            <div className="container">
              <div className="SwayamSevak">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <h3>
                      શક્તિપીઠ અંબાજી માં વિવિધ સેવાઓ માટે માતાજીનાં સ્વયં સેવક
                      (પૂજારી)
                      <br /> તરીકે ગ્રુપની અથવા વ્યક્તિગત નોધણી ફોર્મ
                    </h3>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="swaymsevak-radio-main">
                              <label className="d-flex align-items-center">
                                <input
                                  type="radio"
                                  name="booking_type"
                                  value="individual"
                                  required="required"
                                  checked={
                                    formik.values.booking_type === 'individual'
                                  }
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      'booking_type',
                                      e.target.value
                                    )
                                  }
                                />{' '}
                                Individual Registration / વ્યક્તિગત નોંધણી
                              </label>

                              <label className="d-flex align-items-center">
                                <input
                                  type="radio"
                                  name="booking_type"
                                  value="group"
                                  required="required"
                                  checked={
                                    formik.values.booking_type === 'group'
                                  }
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      'booking_type',
                                      e.target.value
                                    )
                                  }
                                />{' '}
                                Group Registration / ગ્રુપ નોંધણી{' '}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {formik.values.booking_type === 'group' && (
                          <>
                            <div className="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                              <div className="form-group">
                                <div>
                                  <label>Name of Group / ગ્રુપ નું નામ</label>
                                </div>
                                <div>
                                  <input
                                    type="text"
                                    className={`form-control ${
                                      formik.touched.group_name &&
                                      formik.errors.group_name
                                        ? 'is-invalid'
                                        : ''
                                    }`}
                                    name="group_name"
                                    value={formik?.values.group_name}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      formik.setFieldTouched(
                                        'group_name',
                                        true,
                                        true
                                      );
                                    }}
                                    onBlur={(e) => {
                                      formik.handleBlur(e);
                                      formik.setFieldTouched(
                                        'group_name',
                                        true,
                                        true
                                      );
                                    }}
                                  />
                                </div>
                                {formik.touched.group_name &&
                                  formik.errors.group_name && (
                                    <div style={{ color: '#ae0015' }}>
                                      {formik.errors.group_name}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </>
                        )}
                        <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-12">
                          <div className="form-group">
                            <div>
                              <label>
                                {formik.values.booking_type === 'group' ? (
                                  <>
                                    Name of Group Leader / સ્વયં સેવક ગ્રુપ લીડર
                                    (પૂજારી) નું નામ
                                  </>
                                ) : (
                                  <>Name / સ્વયં સેવકનું નામ</>
                                )}
                                <span style={{ color: '#ae0015' }}>*</span>
                              </label>
                            </div>
                            <div>
                              <input
                                type="text"
                                className={`form-control ${
                                  formik.touched.swayma_sevak_name &&
                                  formik.errors.swayma_sevak_name
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="swayma_sevak_name"
                                value={formik?.values.swayma_sevak_name}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  formik.setFieldTouched(
                                    'swayma_sevak_name',
                                    true,
                                    true
                                  );
                                }}
                                onBlur={(e) => {
                                  formik.handleBlur(e);
                                  formik.setFieldTouched(
                                    'swayma_sevak_name',
                                    true,
                                    true
                                  );
                                }}
                              />
                            </div>
                            {formik.touched.swayma_sevak_name &&
                              formik.errors.swayma_sevak_name && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.swayma_sevak_name}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <div>
                              <label>
                                Age / ઉમર
                                <span style={{ color: '#ae0015' }}>*</span>
                              </label>
                            </div>
                            <div>
                              <input
                                type="text"
                                inputMode="numeric"
                                className={`form-control ${
                                  formik.touched.age && formik.errors.age
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="age"
                                value={formik?.values.age}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                  formik.setFieldTouched('age', true, true);
                                }}
                                onBlur={(e) => {
                                  formik.handleBlur(e);
                                  formik.setFieldTouched('age', true, true);
                                }}
                              />
                            </div>
                            {formik.touched.age && formik.errors.age && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.age}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-12 col-12">
                          <div className="form-group ">
                            <div>
                              <label>
                                Gender / જાતિ
                                <span style={{ color: '#ae0015' }}>*</span>
                              </label>
                            </div>
                            <div>
                              <div className="swaymsevak-radio">
                                <label className="d-flex align-items-center">
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="male"
                                    checked={formik.values.gender === 'male'}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      formik.setFieldTouched(
                                        'gender',
                                        true,
                                        true
                                      ); // Mark as touched
                                    }}
                                    onBlur={(e) => {
                                      formik.handleBlur(e);
                                      formik.setFieldTouched(
                                        'gender',
                                        true,
                                        true
                                      ); // Mark as touched
                                    }}
                                  />
                                  Male
                                </label>

                                <label className="d-flex align-items-center">
                                  <input
                                    type="radio"
                                    name="gender"
                                    value="female"
                                    checked={formik.values.gender === 'female'}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      formik.setFieldTouched(
                                        'gender',
                                        true,
                                        true
                                      ); // Mark as touched
                                    }}
                                    onBlur={(e) => {
                                      formik.handleBlur(e);
                                      formik.setFieldTouched(
                                        'gender',
                                        true,
                                        true
                                      ); // Mark as touched
                                    }}
                                  />
                                  Female
                                </label>
                              </div>
                            </div>
                            {formik.touched.gender && formik.errors.gender && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.gender}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        {formik.values.booking_type === 'group' && (
                          <>
                            <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                              <div className="form-group">
                                <label>
                                  No. of member’s in group / ગ્રુપ મેમ્બર્સ ની
                                  સંખ્યા
                                </label>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  className={`form-control ${
                                    formik.touched.no_of_people &&
                                    formik.errors.no_of_people
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  name="no_of_people"
                                  value={formik?.values.no_of_people}
                                  onChange={(e) => {
                                    formik.handleChange(e);
                                    formik.setFieldTouched(
                                      'no_of_people',
                                      true,
                                      true
                                    );
                                  }}
                                  onBlur={(e) => {
                                    formik.handleBlur(e);
                                    formik.setFieldTouched(
                                      'no_of_people',
                                      true,
                                      true
                                    );
                                  }}
                                />
                                {formik.touched.no_of_people &&
                                  formik.errors.no_of_people && (
                                    <div style={{ color: '#ae0015' }}>
                                      {formik.errors.no_of_people}
                                    </div>
                                  )}
                              </div>
                            </div>
                          </>
                        )}

                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label>
                              Phone Number / સંપર્ક નંબર
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                inputMode="numeric"
                                className={`form-control ${
                                  formik.touched.primary_contact_number &&
                                  formik.errors.primary_contact_number
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="primary_contact_number"
                                value={formik.values.primary_contact_number}
                                // value={
                                //   formik?.values.primary_contact_number
                                // }
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Allow only digits and limit length to 10
                                  if (/^\d{0,10}$/.test(value)) {
                                    handleChange(e);
                                  }
                                }}
                                onBlur={formik.handleBlur}
                                maxLength="10"
                                pattern="\d{10}"
                              />
                            </div>
                            {formik.touched.primary_contact_number &&
                              formik.errors.primary_contact_number && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.primary_contact_number}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12">
                          <div className=" form-group">
                            <label htmlFor="email_id">
                              E-Mail Address / ઈ-મેલ એડ્રેસ
                              <span style={{ color: '#ae0015' }}>*</span>{' '}
                            </label>
                            <input
                              type="email_id"
                              name="email_id"
                              id="email_id"
                              className="form-control"
                              onChange={formik.handleChange}
                              // onBlur={formik.handleBlur}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldValue(
                                  'email_id',
                                  e.target.value.toLowerCase()
                                );
                              }}
                              value={formik.values.email_id}
                            />
                            {formik.touched.email_id &&
                              formik.errors.email_id && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.email_id}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="form-group">
                            <label>
                              How many days in a year do you want to do service
                              work? /<br /> વર્ષમાં કેટલા દિવસ સેવા કાર્ય કરવા
                              માંગો છો ?
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              inputMode="numeric"
                              className={`form-control ${
                                formik.touched.no_of_days &&
                                formik.errors.no_of_days
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="no_of_days"
                              value={formik?.values.no_of_days}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched(
                                  'no_of_days',
                                  true,
                                  true
                                );
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched(
                                  'no_of_days',
                                  true,
                                  true
                                );
                              }}
                              max="2024-09-18"
                              min="2024-09-1"
                            />
                            {formik.touched.no_of_days &&
                              formik.errors.no_of_days && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.no_of_days}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="form-group">
                            <label>
                              Date of service / <br />
                              સેવા કરવાની તારીખ
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="date"
                              className={`form-control ${
                                formik.touched.date && formik.errors.date
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="date"
                              value={formik?.values.date}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched('date', true, true);
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched('date', true, true);
                              }}
                              max="2024-09-18"
                              min={formik.values.start_date || '2024-09-1'}
                            />
                            {formik.touched.date && formik.errors.date && (
                              <div
                                className="invalid-feedback"
                                style={{ color: '#ae0015' }}
                              >
                                {formik.errors.date}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label>
                              Address Line 1 / સરનામું 1{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.address_line_1 &&
                                formik.errors.address_line_1
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="address_line_1"
                              value={formik?.values.address_line_1}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched(
                                  'address_line_1',
                                  true,
                                  true
                                );
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched(
                                  'address_line_1',
                                  true,
                                  true
                                );
                              }}
                            />
                            {formik.touched.address_line_1 &&
                              formik.errors.address_line_1 && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.address_line_1}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label>Address Line 2 / સરનામું 2</label>
                            <input
                              type="text"
                              className="form-control"
                              name="address_line_2"
                              value={formik?.values.address_line_2}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched(
                                  'address_line_2',
                                  true,
                                  true
                                );
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched(
                                  'address_line_2',
                                  true,
                                  true
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label>
                              Village / ગામ{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.village && formik.errors.village
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="village"
                              value={formik?.values.village}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched('village', true, true);
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched('village', true, true);
                              }}
                            />
                            {formik.touched.village &&
                              formik.errors.village && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.village}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label>
                              Taluka / તાલુકો
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.taluka && formik.errors.taluka
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="taluka"
                              value={formik?.values.taluka}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched('taluka', true, true);
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched('taluka', true, true);
                              }}
                            />
                            {formik.touched.taluka && formik.errors.taluka && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.taluka}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label>
                              District / જીલ્લો
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              className={`form-control ${
                                formik.touched.district &&
                                formik.errors.district
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="district"
                              value={formik?.values.district}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched('district', true, true);
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched('district', true, true);
                              }}
                            />
                            {formik.touched.district &&
                              formik.errors.district && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.district}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
                          <div className="form-group">
                            <label>
                              Pincode / પિનકોડ
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              inputMode="numeric"
                              className={`form-control ${
                                formik.touched.zip_code &&
                                formik.errors.zip_code
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="zip_code"
                              value={formik?.values.zip_code}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched('zip_code', true, true);
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched('zip_code', true, true);
                              }}
                            />
                            {formik.touched.zip_code &&
                              formik.errors.zip_code && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.zip_code}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="form-group">
                            <label>
                              Type of ID Proof / આઈડી પ્રૂફનો પ્રકાર{' '}
                            </label>
                            <Select
                              name="identity_proof_type"
                              className={
                                formik?.errors.identity_proof_type &&
                                formik?.touched.identity_proof_type
                                  ? 'is-invalid'
                                  : 'valid'
                              }
                              options={idProofOptions}
                              onBlur={() =>
                                formik?.setFieldTouched(
                                  'identity_proof_type',
                                  true
                                )
                              }
                              onChange={(opt) => {
                                if (opt && opt.label === 'other') {
                                  setShowOtherInput(true);
                                  formik.setFieldValue(
                                    'identity_proof_type',
                                    opt.value
                                  );
                                } else {
                                  setShowOtherInput(false);
                                  formik.setFieldValue(
                                    'identity_proof_type',
                                    opt ? opt.value : ''
                                  );
                                }
                              }}
                              value={
                                idProofOptions.find(
                                  (option) =>
                                    option.value ===
                                    formik.values.identity_proof_type
                                ) || null
                              }
                              isSearchable={true}
                            />
                            {showOtherInput ? (
                              <input
                                type="text"
                                name="other_camp_type_details"
                                value={formik.values.other_camp_type_details}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control"
                              />
                            ) : null}
                            {formik.errors.identity_proof_type &&
                              formik.touched.identity_proof_type && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.identity_proof_type}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                          <div className="form-group">
                            <label>
                              Please Upload the ID Proof / અપલોડ આઈડી પ્રૂફ
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .png, .pdf"
                              multiple
                              className="form-control"
                              name="identity_proof"
                              onBlur={formik.handleBlur}
                              ref={imgref1}
                              onChange={(event) => fileChange(event, 1)}
                            />
                            {/* {formik.touched.identity_proof &&
                            formik.errors.identity_proof && (
                              <div style={{ color: "#ae0015" }}>
                                {formik.errors.identity_proof}
                              </div>
                            )} */}
                            {/* {formik.touched.identity_proof &&
                            formik.errors.identity_proof && (
                              <div style={{ color: "#ae0015" }}>
                                {formik.errors.identity_proof}
                              </div>
                            )} */}
                            {filePreview1 && (
                              <>
                                <div className="mt-2"></div>
                                <img
                                  src={filePreview1}
                                  className="img-preview img"
                                  style={{
                                    height: '125px',
                                    width: '125px',
                                    objectFit: 'cover'
                                  }}
                                />
                                <RxCross1
                                  style={{
                                    position: 'absolute',
                                    color: '#ae0015',
                                    fontSize: '1.5em',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    imgref1.current.value = '';
                                    setFile();
                                    setFilePreview1();
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Type of Seva / સેવાનો પ્રકાર</label>
                            <div className="swaymsevak-radio-check">
                              {campCategoryList.map((seva) => (
                                <div
                                  className="d-flex align-items-center"
                                  key={seva.value}
                                >
                                  <label
                                    htmlFor={`seva-${seva.value}`}
                                    className="d-flex align-items-center"
                                  >
                                    <input
                                      type="checkbox"
                                      id={`seva-${seva.value}`}
                                      value={seva.value}
                                      onChange={handleCheckboxChangeforSeva}
                                    />

                                    {seva.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                            {formik.touched.seva_type_id &&
                              formik.errors.seva_type_id && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.seva_type_id}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      {formik.values.booking_type === 'group' && (
                        <div className="SwayamSevak-table">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label>
                                  Member details / ગ્રુપ મેમ્બર ની વિગતો
                                </label>
                                <div className="table-responsive">
                                  <table>
                                    <thead>
                                      <tr>
                                        <th width="7%">Sr. No.</th>
                                        <th width="25%">Name</th>
                                        <th width="25%">Contact Number</th>
                                        <th width="23%">Type of ID Proof</th>
                                        <th width="20%">Upload ID Proof</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {formik?.values?.SevaMemberDetails?.map(
                                        (SevaMemberDetails, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>
                                              <input
                                                type="text"
                                                value={SevaMemberDetails.name}
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    index,
                                                    'name',
                                                    e.target.value
                                                  )
                                                }
                                                className="form-control"
                                              />
                                            </td>
                                            <td>
                                              <input
                                                type="text"
                                                inputMode="numeric"
                                                value={
                                                  SevaMemberDetails.contact_number
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    index,
                                                    'contact_number',
                                                    e.target.value
                                                  )
                                                }
                                                className="form-control"
                                                maxLength="10"
                                                pattern="\d{10}"
                                              />
                                            </td>
                                            <td>
                                              <select
                                                value={
                                                  SevaMemberDetails.identity_proof_type
                                                }
                                                onChange={(e) =>
                                                  handleInputChange(
                                                    index,
                                                    'identity_proof_type',
                                                    e.target.value
                                                  )
                                                }
                                                className="form-control"
                                                name="identity_proof_type"
                                              >
                                                <option value="" disabled>
                                                  Select Pass Issued For
                                                </option>
                                                <option value="Aadhar Card">
                                                  Aadhar Card
                                                </option>
                                                <option value="Passport">
                                                  Passport
                                                </option>
                                                <option value="Driving License">
                                                  Driving License
                                                </option>
                                                <option value="Election Card">
                                                  Election Card
                                                </option>{' '}
                                                <option value="Other">
                                                  Other Government Valid ID
                                                  Proof
                                                </option>
                                              </select>
                                            </td>
                                            <td>
                                              {/* {formik.values.SevaMemberDetails?.map(
                                          (member, index) => (
                                            <div key={index}> */}
                                              <input
                                                type="file"
                                                multiple
                                                onChange={(event) =>
                                                  selectImg(event, index)
                                                }
                                              />
                                              {/* </div> */}
                                              {/* ) */}
                                              {/* )} */}
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div className="d-flex justify-content-end">
                                <button
                                  type="button"
                                  className="orangebtn"
                                  onClick={handleAddMember}
                                >
                                  Add Member
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <div className="swaymsevak-text">
                              <label>શરતો</label>
                              <p>
                                (૧) હિન્દુ સંસ્કૃતિ ની ગરિમા જળવાય તેનું ધ્યાન
                                રાખવું.
                              </p>
                              <p>
                                (૨) ભોજનની વ્યવસ્થા અત્રેની ભોજનાલય ખાતે કરવામાં
                                આવશે.
                              </p>
                              <p>
                                (૩) પ્રવેશ માટે યોગ્ય વસ્ત્રો ધારણ કરવાના રહેશે.
                              </p>
                              <p>
                                (૪) આપના ધ્વારા નિયત કરેલ તારીખ/તિથી એ જ સેવા
                                કાર્ય કરવા આવવાનું રહેશે.
                              </p>
                              <p>
                                (૫) સ્વયં સેવક ગ્રૂપ ના તમામ સભ્યોના આધારકાર્ડ
                                રજૂ કરવાના રહેશે.
                              </p>
                              <p>
                                (૬) અનિવાર્ય કારણસર મંદિર ટ્રસ્ટ ધ્વારા આપે
                                દર્શાવેલ તારીખ/તિથી માં ફેરબદલી કરી શકશે.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label
                          style={{
                            marginBottom: '25px'
                          }}
                        >
                          <div className="d-flex">
                            <span>
                              <input
                                className="checkbox-boxes-seva"
                                type="checkbox"
                                id="accept-checkbox"
                                onChange={handleCheckboxChange}
                              />
                            </span>
                            <span>
                              <b>હું ઉપર દર્શાવેલ તમામ શરતો સ્વીકારું છું.</b>
                            </span>
                          </div>
                        </label>
                      </div>

                      <button
                        className="orangebtn"
                        // type="next"
                        type="submit"
                        style={{ width: '100%' }}
                      >
                        Register
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};
export default SwayamSevak;
