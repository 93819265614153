import React, { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import ProfileService from "../../../Api_config/Profile/userProfile";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../_Constants/routes";
// import { UserProfileSchema } from "../../_constant/validations";

const UserProfile = () => {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      id: "",
      first_name: "",
      last_name: "",
      email_id: "",
      primary_contact_number: "",
      password: "",
      pan_number: "",
      user_role_id: "34",
    },
    enableReinitialize: true,
    // validationSchema: UserProfileSchema,
    onSubmit: (values) => {
      // console.log(values);
      ProfileService.updateUser(values)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message, { autoClose: 1000 });
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          //toast.error(error.response.data.message);
        });
    },
  });

  useEffect(() => {
    const userId = localStorage.getItem("user_id");
    if (userId) {
      ProfileService.getUserData()
        // if (localStorage.getItem("user_id")) {
        //   ProfileService.getUserData()

        .then((res) => {
          if (res.data.success) {
            formik.setValues(res.data.data[0]);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log(error)
        });
    }
  }, [formik?.setValues]);

  return (
    <div className="user-profile">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="form-group col-12 col-lg-6">
            <label htmlFor="first_name">
              First Name / પ્રથમ નામ <span className="red-text">*</span>
            </label>
            <input
              type="text"
              name="first_name"
              id="first_name"
              className="form-control"
              value={formik?.values?.first_name}
              onChange={formik.handleChange}
            />
            {formik?.touched?.first_name && formik?.errors?.first_name && (
              <p className="red-text">{formik?.errors?.first_name}</p>
            )}
          </div>
          <div className="form-group col-12 col-lg-6">
            <label htmlFor="last_name">
              Last Name / અટક <span className="red-text">*</span>
            </label>
            <input
              type="text"
              name="last_name"
              id="last_name"
              className="form-control"
              value={formik?.values?.last_name}
              onChange={formik.handleChange}
            />
            {formik?.touched?.last_name && formik?.errors?.last_name && (
              <p className="red-text">{formik?.errors?.last_name}</p>
            )}
          </div>
        </div>
        <div className="row">
          <div className="form-group col-12 col-lg-6">
            <label htmlFor="email_id">
              Email Id / ઈમેલ આઈડી <span className="red-text">*</span>
            </label>
            <input
              type="email"
              name="email_id"
              id="email_id"
              className="form-control"
              value={formik?.values?.email_id}
              onChange={formik.handleChange}
            />
            {formik?.touched?.email_id && formik?.errors?.email_id && (
              <p className="red-text">{formik?.errors?.email_id}</p>
            )}
          </div>
          <div className="form-group col-12 col-lg-6">
            <label htmlFor="pan_number">PAN Number / પાન કાર્ડ નંબર</label>
            <input
              type="text"
              name="pan_number"
              id="pan_number"
              className="form-control"
              value={formik?.values?.pan_number}
              onChange={formik.handleChange}
            />
            {formik?.touched?.pan_number && formik?.errors?.pan_number && (
              <p className="red-text">{formik?.errors?.pan_number}</p>
            )}
          </div>
        </div>
        <div className="form-group">
          <label htmlFor="primary_contact_number">
            Mobile Number / મોબાઈલ નંબર <span className="red-text">*</span>
          </label>
          <input
            type="text"
            name="primary_contact_number"
            id="primary_contact_number"
            value={formik?.values?.primary_contact_number}
            className="form-control"
            onChange={formik.handleChange}
          />
          {formik?.touched?.primary_contact_number &&
            formik?.errors?.primary_contact_number && (
              <p className="red-text">
                {formik?.errors?.primary_contact_number}
              </p>
            )}
        </div>
        {/* <div className="form-group">
                    <label htmlFor="password">Password<span>*</span></label>
                    <input type="password" name="password" id="password" className="form-control" value={formik?.values?.password} onChange={formik.handleChange} />
                </div> */}

        <div className="form-footer d-flex justify-content-end mt-5">
          <button type="submit" className="orangebtn">
            Update
          </button>
        </div>
      </form>
    </div>
  );
};
export default UserProfile;
