import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import Modal from "react-modal";
import "./photoGallery.scss";
import Footer from "../../Components/footer";
import Arasuri from "../../Components/arasuri";
import { Helmet } from "react-helmet-async";

import ZoomIcon from "../../Assets/Images/Photo_gallery/ZoomIcon.svg";
import photo1 from "../../Assets/Images/Photo_gallery/pg1.png";
import photo2 from "../../Assets/Images/Photo_gallery/pg2.png";
import photo3 from "../../Assets/Images/Photo_gallery/pg3.png";
import photo4 from "../../Assets/Images/Photo_gallery/pg4.png";
import LazyLoader from "../../Components/lazyLoader";

const imageData = [
  { src: photo1, caption: "અંબાજી મંદિર" },
  { src: photo2, caption: "અંબાજી મંદિર" },
  { src: photo3, caption: "અંબાજી મંદિર" },
  { src: photo4, caption: "અંબાજી મંદિર" },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
};

const PhotoGallery = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);
  const openModal = (index) => {
    setActiveSlide(index);
    setModalOpen(true);
  };

  return (
    <>
      <Helmet>
        <title>Ambaji Temple Photo Gallery | Banaskantha Gujarat</title>
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti
Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti
timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and
Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details,
Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji,
Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta
          name="author"
          content="Ambaji Temple and Gabbar Banaskantha Gujarat | BinTech Services Ahmedabad"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section
            className="header-banner temple-banner"
            style={{
              backgroundImage:
                'url("https://storage.googleapis.com/ambaji-temple.appspot.com/1692249532794_Gabbar.jpeg")',
            }}
          >
            <div className="container">
              <h1>ફોટો ગેલેરી</h1>
            </div>
          </section>

          <section className="gallery-page">
            <div className="container">
              <div className="row">
                {imageData.map((image, index) => (
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12"
                    key={index}
                  >
                    <div className="photo-gallery">
                      <div
                        className="photo-img"
                        onClick={() => openModal(index)}
                      >
                        <img
                          src={image.src}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        />
                        <span className="photo-zoomicon">
                          <img
                            src={ZoomIcon}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          />
                        </span>
                      </div>
                      <p>{image.caption}</p>
                    </div>
                  </div>
                ))}
                <PhotoModal
                  isModalOpen={isModalOpen}
                  setModalOpen={setModalOpen}
                  activeSlide={activeSlide}
                  imageData={imageData}
                />
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

const PhotoModal = ({ isModalOpen, setModalOpen, activeSlide, imageData }) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={() => setModalOpen(false)}
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      contentLabel="Image Modal"
    >
      <div className="d-flex justify-content-end close-btn">
        <button
          type="button"
          className="btn"
          onClick={() => setModalOpen(false)}
        >
          X
        </button>
      </div>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        initialSlide={activeSlide}
        loop
        className="mySwiper"
      >
        {imageData.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="img-container">
              <img
                src={image.src}
                alt="images of temple"
                style={{ display: "block", margin: "auto" }}
              />
              <p>{image.caption}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Modal>
  );
};

export default PhotoGallery;
