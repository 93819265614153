import React from "react";
import { useNavigate } from "react-router";
import { routes } from "../../_Constants/routes";

const LogOutConfirmationModal = (props) => {
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    props?.closeModal();
    navigate(routes?.login);
  };

  return (
    <div className="log-out-confirmation-modal">
      <div className="card">
        <div className="card-body">
          <h4 className="text-center">Log Out</h4>
          <p className="text-center">You will be redirected to login page.</p>
        </div>
        <div className="card-footer d-flex justify-content-between">
          <button
            type="button"
            className="whitebtn"
            onClick={props?.closeModal}
          >
            No
          </button>
          <button type="button" className="orangebtn" onClick={() => logout()}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};
export default LogOutConfirmationModal;
