import React from "react";
import SwiperCore, { Navigation, Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
SwiperCore.use([Navigation, Pagination, Thumbs]);

export default function Gallery(props) {
  const currentVariant = props;
  const [swiper, setSwiper] = React.useState(null);
  const [thumbsSwiper, setThumbsSwiper] = React.useState(null);
  const renderMainSwiper = (props) => (
    <>
      <Swiper
        loop={true}
        // navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        {props?.currentVariant.images?.map((item) => {
          return (
            <SwiperSlide>
              <img src={item} alt="pic1" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );

  const renderThumbnailsSwiper = (props) => (
    <Swiper
      onSwiper={(swiper) => setThumbsSwiper(swiper)}
      spaceBetween={15}
      slidesPerView={4}
      loop={true}
      freeMode
      watchSlidesVisibility
      watchSlidesProgress
      style={{ marginTop: "10px" }}
    >
      {props?.currentVariant.images?.map((item) => {
        // console.log(item);
        return (
          <SwiperSlide>
            <img src={item} alt="Thumbanail" />
          </SwiperSlide>
        );
      })}
    </Swiper>
  );

  return (
    <div>
      {renderMainSwiper(currentVariant)}
      {renderThumbnailsSwiper(currentVariant)}
    </div>
  );
}
// export default Gallery;
