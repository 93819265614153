import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../../../Components/footer";
import Arasuri from "../../../Components/arasuri";
import "./dhajaBookingReceipt.scss";
import { useReactToPrint } from "react-to-print";
import Error404 from "../../../Pages/error404";
import { useLocation, useNavigate } from "react-router-dom";
import { formatNumberWithCommas } from "../../Donation/Converter/numberWithCommas";
import { formatDate } from "../../Donation/Converter/date";
import SignatureService from "../../../Api_config/Home_page/signature";
import { routes } from "../../../_Constants/routes";
import DhajaBookingService from "../../../Api_config/Dhaja_booking/dhajaBooking";
const DhajaBookingReceipt = () => {
  const componentRef = useRef();
  const [dhajaBookingData, setDhajaBookingData] = useState();
  const location = useLocation();
  const navigate = useNavigate();
  const [signatureData, setSignatureData] = useState();
  const formattedDate = formatDate(dhajaBookingData?.booking_date);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Dhaja Booking Receipt",
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    },
  });
  useEffect(() => {
    const id = location?.pathname?.split("/").pop();
    // console.log(location, Number(id));
    DhajaBookingService.getBookingById(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setDhajaBookingData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        // console.log(e);
      });
    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);
  const qrUrl = dhajaBookingData?.qr_url;
  //-----page-close-404-error-logic-p-----
  // if (true) {
  //   return <Error404 />;
  // }
  //-----page-close-404-error-logic-p-----

  return (
    <>
      <Helmet>
        <title>Ambaji Mandir Dhaja Booking | ધજા બુકિંગ ની માહિતી </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Shri 51 Shaktipeeth Parikrama Mahotsav Yagna Booking. Book Gabbar Yagna and Shaktipeeth Yagna at Ambaji Temple."
        />
        <meta
          name="keywords"
          content="Ambaji Yajna Booking, Gabbar Yagna Booking, Shaktipeeth Yagna Booking, Parikrama Mahotsav Yagna Booking, Shri 51 Shaktipeeth Parikrama Mahotsav Yagna Booking "
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="booking" style={{ backgroundColor: "#f5f5f5" }}>
        <section className="dhaja-booking">
          <div className="container">
            <h1>ધજા બુકિંગ ની માહિતી</h1>
          </div>
        </section>
        <div className="print-container" ref={componentRef}>
          <div className="pdf-btn">
            <div className="container">
              <div className="row">
                <div
                  style={{ width: "800px", margin: "20px auto 5px" }}
                  className="email-container"
                >
                  <table width="100%">
                    <tr className="header">
                      <td
                        width="17%"
                        rowSpan="3"
                        align="center"
                        valign="middle"
                      >
                        <div className="logo">
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                            style={{ width: "100px", height: "100px" }}
                            alt="Logo"
                          />
                        </div>
                      </td>
                      <td width="83%">
                        <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                      </td>
                    </tr>
                    <tr className="header">
                      <td>
                        <p>
                          <strong>Ta.</strong> Danta, &nbsp;
                          <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                          385110.
                        </p>
                        <p>
                          <strong>Phone No</strong> : 02749-262236 &nbsp;
                          <strong>Email </strong>: ambajitempletrust@gmail.com,
                          &nbsp; www.ambajitemple.in
                        </p>
                        <p>
                          <strong>PAN No </strong>: AACTS5457M &nbsp;
                          <strong>GST No</strong> : 24AACTS5457M2Z0
                        </p>
                      </td>
                    </tr>
                    <tr className="header"></tr>

                    <tr className="headig-text">
                      <td colSpan="2" align="center" valign="middle">
                        <h2>DHAJA BOOKING RECEIPT</h2>
                      </td>
                    </tr>
                    <tr className="rs-note">
                      <td colSpan="2" align="center">
                        <p>
                          "May the holy mother Maa Ambe Bless you and your
                          family, friends and give you a happy,
                          <br /> prosperous and spiritually enlightened life
                          ahead"
                        </p>
                      </td>
                    </tr>

                    <tr className="header">
                      <td colSpan="2" align="right">
                        <p>
                          <strong>Booking Date</strong> : {formattedDate}{" "}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="center">
                        <table width="100%" className="date-section">
                          <tr>
                            <td width="50%">
                              <strong>Dhaja Booking Number</strong>
                            </td>
                            <td width="50%">
                              {dhajaBookingData?.registration_number}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Booking Source</strong>
                            </td>
                            <td>{dhajaBookingData?.booking_category}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Name</strong>
                            </td>
                            <td>
                              {dhajaBookingData?.first_name ||
                              dhajaBookingData?.last_name
                                ? `${dhajaBookingData?.first_name || ""} ${
                                    dhajaBookingData?.last_name || ""
                                  }`
                                : "-"}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Phone Number</strong>
                            </td>
                            <td>{dhajaBookingData?.primary_contact_number}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Selected Dhaja Date and Time Slot</strong>
                            </td>
                            <td>
                              {dhajaBookingData?.date}
                              <br />
                              {dhajaBookingData?.time_slot}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Dhaja Length</strong>
                            </td>
                            <td>{dhajaBookingData?.Dhaja?.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Amount</strong>
                            </td>
                            <td>{dhajaBookingData?.Dhaja?.price}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Mode Of Payment</strong>
                            </td>
                            <td>
                              {
                                dhajaBookingData?.DhajaWebsitePaymentDetails
                                  ?.ModeOfPayment?.name
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Transaction Reference No.</strong>
                            </td>
                            <td>
                              {
                                dhajaBookingData?.DhajaWebsitePaymentDetails
                                  ?.txn_ref
                              }
                            </td>{" "}
                          </tr>
                          <tr>
                            <td>
                              <strong>Payment Receipt Number</strong>
                            </td>
                            <td>BANKREF123</td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    <tr className="header">
                      <td colSpan="2">
                        <p style={{ marginLeft: "10px" }}>
                          <strong>Note</strong> : Please reach 30 minutes prior
                          to the selected time and contact the temple’s
                          administrative office.
                        </p>
                      </td>
                    </tr>
                    <tr align="center" valign="middle" className="teg-line">
                      <td colSpan="2">
                        <table
                          cellSpacing="0"
                          cellPadding="0"
                          border="0"
                          width="100%"
                        >
                          <tr align="center">
                            <td width="40%" valign="middle" className="barcode">
                              <img src={qrUrl} width="110" />
                            </td>
                            <td width="60%" valign="middle" className="sign">
                              {signatureData?.map((item) => {
                                return (
                                  <>
                                    <img
                                      src={item.signature_image}
                                      width="110"
                                      height="auto"
                                    />
                                    <p>
                                      {item.name}
                                      <br />
                                      {item.designation}
                                    </p>
                                  </>
                                );
                              })}
                              <p>
                                Shree Arasuri Ambaji Mata Devasthan Trust Ambaji
                                {/* વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                                  શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                                  અંબાજી */}
                              </p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <button className="btn orangebtn my-2" onClick={handlePrint}>
            Receipt Download
          </button>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};

export default DhajaBookingReceipt;
