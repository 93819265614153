import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import CartService from '../../../Api_config/E_store/cart';
import CheckoutService from '../../../Api_config/E_store/checkout';
import UtilsService from '../../../Api_config/Home_page/utils';
import Footer from '../../../Components/footer';
import { routes } from '../../../_Constants/routes';
import { payu_api_url, payu_key } from '../../../_Constants/api_urls';
// import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
// import { FaCalendarAlt } from "react-icons/fa";
import './ShaktiDarshanamAddress.scss';
import Arasuri from '../../../Components/arasuri';
import AddressDetailsService from '../../../Api_config/Profile/addressDetails';
import ProfileService from '../../../Api_config/Profile/userProfile';
import Error404 from '../../../Pages/error404';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const ShaktiDarshanamAddress = () => {
  const myButtonRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [cartData, setCartData] = useState();
  const [subAmount, setSubAmount] = useState(600);
  const [addressData, setAddressData] = useState([]);
  const [activeAddress, setActiveAddress] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [userData, setUserData] = useState();
  const [payuRes, setpayuRes] = useState();
  const [showErrorMsg, setErrorMsg] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [expectedDate, setExpectedDate] = useState();
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('token'))) {
      var cart_data;
      if (location?.state?.isDirectBuy) {
        cart_data = CartService.getBuyNowData();
      } else {
        cart_data = CartService.getCartData();
      }

      if (cart_data) {
        setCartData(cart_data);
      }
      // cart_data?.forEach(
      //   // (el) => setSubAmount((prev) => prev + el?.qty * el?.variant_data?.mrp),
      //   (el) => setSubAmount(600)
      // );
    } else {
      localStorage.setItem('previous_url', location?.pathname);
      navigate(routes?.login);
    }

    AddressDetailsService.getAddress()
      .then((res) => {
        if (res.data.success) {
          setAddressData(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
      });

    ProfileService.getUserData()
      .then((res) => {
        if (res.data.success) {
          setUserData(res.data.data[0]);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
      });

    UtilsService.getCountry().then((res) => {
      if (res.data.success) {
        setCountryList(res.data.data);
      }
    });
  }, [
    setCartData,
    setZipcodeList,
    setProvinceList,
    setCityList,
    setAddressData,
    setCountryList,
    location?.state?.isDirectBuy,
    setDeliveryCharge,
    setActiveAddress
  ]);

  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email_id: '',
      primary_contact_number: '',
      primary_contact_code_id: 1,
      address_line_1: '',
      address_line_2: '',
      address_name: '',
      city_id: '',
      country_id: '',
      province_id: '',
      zipcode_id: '',
      expected_delivery_date: ''
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (!activeAddress) {
        setErrorMsg(true);
      } else {
        checkout(values);
      }
      // console.log(values)
    }
  });

  const addressForm = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      primary_contact_number: '',
      primary_contact_code_id: 0,
      address_name: '',
      address_line_1: '',
      address_line_2: '',
      country_id: '',
      province_id: '',
      city_id: '',
      zipcode_id: '',
      user_id: '',
      expected_delivery_date: ''
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      Object.assign(values, {
        user_id: JSON.parse(localStorage.getItem('user_id'))
      });
      AddressDetailsService.saveAddress(values)
        .then((res) => {
          if (res.data.success) {
            AddressDetailsService.getAddress();
            console
              .log(res.data.success)
              .then((res) => {
                if (res.data.success) {
                  setAddressData(res.data.data);
                  resetForm();
                }
              })
              .catch((error) => {
                navigate(routes?.error500);
              });
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          toast.error(error.response.data.message);
        });
      setModalOpen(false);
    }
  });

  const getZipcodeByCityDeliverable = (event) => {
    UtilsService.getZipcodeByCityDeliverable(
      event?.target?.value ?? event
    ).then((res) => {
      if (res.data.success) {
        setZipcodeList(res.data.data);
      }
    });
  };

  const getProvince = (event) => {
    UtilsService.getProvince(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
      });
  };

  const getCity = (event) => {
    UtilsService.getCity(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setCityList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
      });
  };

  const checkout = (values) => {
    const requestData = {
      order: {
        user_id: JSON.parse(localStorage.getItem('user_id')),
        status_id: 1,
        expected_delivery_date: expectedDate ? expectedDate : ''
      },
      order_items: cartData,
      order_details: {
        first_name: values?.first_name,
        last_name: values?.last_name,
        email_id: userData?.email_id,
        primary_contact_number: values?.primary_contact_number,
        primary_contact_code_id: 1,
        address_line_1: values?.address_line_1,
        address_line_2: values?.address_line_2,
        address_name: values?.address_name,
        city_id: values?.city_id,
        country_id: values?.country_id,
        province_id: values?.province_id,
        zipcode_id: values?.zipcode_id
      }
    };
    CheckoutService.checkout(requestData)
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data);
          setpayuRes(res.data.data);
          setTimeout(() => {
            if (myButtonRef.current) {
              myButtonRef.current.click();
            }
          });
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
      });
  };
  const handleCheckboxChange = () => {
    setDatePickerVisible(!isDatePickerVisible);
  };

  const minSelectableDate = new Date();
  minSelectableDate.setDate(minSelectableDate.getDate() + 10);

  useEffect(() => {
    const initialDate = new Date();
    initialDate.setDate(initialDate.getDate() + 10);

    setStartDate(initialDate);
  }, []);

  const { handleChange, handleSubmit } = formik;

  const [value, setValue] = React.useState(null);

  const handleChangs = (event) => {
    setValue(event.target.value);
  };

  //-----page-close-404-error-logic-p-----
  if (true) {
    return <Error404 />;
  }
  //-----page-close-404-error-logic-p-----

  return (
    <div className="main-wrapper">
      <section className="header-banner shopOnline-banner">
        <div className="container">
          <h1>Subscribe to Paper Copy</h1>
        </div>
      </section>
      <section className="contact-page">
        <div className="container">
          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="contact-wrapper">
                <div className="contact-form">
                  <div className="address-container w-100s">
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="orangebtn"
                        onClick={() => setModalOpen(true)}
                      >
                        Add New Address
                      </button>
                    </div>
                    <div className="row justify-content-between mb-4">
                      {addressData?.map((item, i) => {
                        return (
                          <div className="col-12 col-lg-6 p-2">
                            <div
                              className={
                                'card p-3' +
                                (item?.id === activeAddress ? ' active' : '')
                              }
                              key={i}
                              onClick={() => [
                                setActiveAddress(item?.id),
                                formik?.setValues(item),
                                getProvince(item?.country_id),
                                getCity(item?.province_id),
                                getZipcodeByCityDeliverable(item?.city_id)
                              ]}
                            >
                              <span className="d-flex justify-content-between align-items-center">
                                <h6 className="mb-2">{item?.address_name}</h6>
                                <input
                                  type="radio"
                                  checked={item?.id === activeAddress}
                                />
                              </span>
                              <p>{item?.first_name + ' ' + item?.last_name}</p>
                              <p>{item?.primary_contact_number}</p>
                              <p>{item?.address_line_1},</p>
                              <p>{item?.address_line_2}</p>
                              <p>{item?.address_name}</p>
                              <p>
                                {item?.City?.name}, {item?.Province?.name}-
                                {item?.ZipCode?.name}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <div>
                      {addressData?.length <= 0 && (
                        <p className="red-text text-center">
                          Please add your address
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="row" hidden={true}>
                    <div className="col-12 col-md-6 form-group">
                      <label> First Name / પ્રથમ નામ</label>
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleChange}
                        value={formik?.values?.first_name}
                        name="first_name"
                      ></input>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Last Name / અટક</label>
                      <input
                        type="text"
                        className="form-control"
                        name="last_name"
                        onChange={handleChange}
                        value={formik?.values?.last_name}
                      ></input>
                    </div>
                    <div className="col-12 form-group">
                      <label>Street Address / શેરી સરનામું</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address_line_1"
                        onChange={handleChange}
                        value={formik?.values?.address_line_1}
                      ></input>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label> Mobile Number / મોબાઈલ નંબર</label>
                      <input
                        type="tel"
                        className="form-control"
                        name="primary_contact_number"
                        onChange={handleChange}
                        value={formik?.values?.primary_contact_number}
                      ></input>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Email / ઈમેલ આઈડી</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email_id"
                        onChange={handleChange}
                        value={formik?.values?.email_id}
                      ></input>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Country / દેશ</label>
                      <select
                        name="country_id"
                        className="form-control"
                        value={formik?.values?.country_id}
                        onChange={(event) => [
                          formik.setFieldValue(
                            'country_id',
                            event.target.value
                          ),
                          getProvince(event)
                        ]}
                      >
                        <option value="" selected disabled>
                          Select Country
                        </option>
                        {countryList?.map((item, i) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Province / રાજ્ય</label>
                      <select
                        name="province_id"
                        className="form-control"
                        value={formik?.values?.province_id}
                        onChange={(event) => [
                          formik.setFieldValue(
                            'province_id',
                            event.target.value
                          ),
                          getCity(event)
                        ]}
                      >
                        <option value="" selected disabled>
                          Select Province
                        </option>
                        {provinceList?.map((item, i) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Town-City / નગર-શહેર</label>
                      <select
                        name="city_id"
                        className="form-control"
                        value={formik?.values?.city_id}
                        onChange={(event) => [
                          formik.setFieldValue('city_id', event.target.value),
                          getZipcodeByCityDeliverable(event)
                        ]}
                      >
                        <option value="" selected disabled>
                          Select City
                        </option>
                        {cityList?.map((item, i) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>

                    <div className="form-group col-12 col-lg-6">
                      <label htmlFor="zipcode_id">
                        Zipcode / પિનકોડ
                        <br />
                        <small>(Below listed zipcodes are deliverable)</small>
                      </label>
                      <select
                        name="zipcode_id"
                        className="form-control"
                        value={formik?.values?.zipcode_id}
                        onChange={(event) => [
                          formik.setFieldValue('zipcode_id', event.target.value)
                        ]}
                      >
                        <option value="" selected disabled>
                          Select Zipcode
                        </option>
                        {zipcodeList?.map((item, i) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                      {formik?.touched?.zipcode_id &&
                        formik?.errors?.zipcode_id && (
                          <p className="red-text">
                            {formik?.errors?.zipcode_id}
                          </p>
                        )}
                    </div>
                  </div>
                </div>

                <div className="checkout-info">
                  <div className="review-order">
                    <div id="order_review">
                      <div className="cart-totals">
                        {/* delivery charges  */}

                        <div className="order-total d-flex justify-content-between">
                          <p className="m-0 fw-semibold">લવાજમ દર</p>
                          <span>
                            <select value={value} onChange={handleChangs}>
                              <option value="1">વાર્ષિક - ₹ 500</option>
                              <option value="2">ત્રિવાર્ષિક - ₹ 1,700</option>
                              <option value="3">પાંચ વર્ષના - ₹ 2,800</option>
                              <option value="4">
                                આજીવન (૨૦ વર્ષ) - ₹ 11,000
                              </option>
                              <option value="5">છૂટક અંકના - ₹ 50</option>
                            </select>
                          </span>
                        </div>
                        <hr />
                        <div className="order-total d-flex justify-content-between">
                          <p className="m-0">Subscription Start Date</p>
                          <ins className="p-0 text-decoration-none">
                            <span className="amount">09-05-2025</span>
                          </ins>
                        </div>

                        <div className="order-total d-flex justify-content-between">
                          <p className="m-0">Subscription End Date</p>
                          <ins className="p-0 text-decoration-none">
                            <span className="amount">09-05-2025</span>
                          </ins>
                        </div>

                        <hr />
                        {/* total charges  */}
                        <div className="order-total d-flex justify-content-between">
                          <p className="text-uppercase m-0 fw-semibold">
                            TOTAL
                          </p>
                          <span className="price">
                            <ins className="p-0 text-decoration-none">
                              <span className="amount fw-semibold">
                                <bdi>
                                  <span>₹</span>
                                  {subAmount}
                                </bdi>
                              </span>
                            </ins>
                          </span>
                        </div>

                        <div className="col-md-12">
                          <button
                            type="submit"
                            className="yellowbtn w-100 mt-2"
                          >
                            Proceed For Payment
                          </button>
                        </div>
                        {showErrorMsg && !activeAddress && (
                          <p className="text-white mb-0 text-center">
                            Please select address detail!
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
      >
        <button
          className="btn btn-default float-end"
          onClick={() => setModalOpen(false)}
        >
          <FaTimes />
        </button>
        <br />
        <br />
        <form onSubmit={addressForm.handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-6 form-group">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                name="first_name"
                required
                placeholder="First Name"
                className="form-control"
                value={addressForm?.values?.first_name}
                onChange={addressForm.handleChange}
              />
            </div>
            <div className="col-12 col-md-6 form-group">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                name="last_name"
                required
                placeholder="Last Name"
                className="form-control"
                value={addressForm?.values?.last_name}
                onChange={addressForm.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 form-group">
              <label htmlFor="primary_contact_code_id">Country Code</label>
              <select
                type="text"
                name="primary_contact_code_id"
                className="form-control"
                required
                value={addressForm?.values?.primary_contact_code_id}
                onChange={addressForm.handleChange}
              >
                <option value={0} selected disabled>
                  Select Country Code
                </option>
                {countryList?.map((item, i) => {
                  return (
                    <option value={item?.id} key={i}>
                      +{item?.phone_code}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-12 col-md-6 form-group">
              <label htmlFor="primary_contact_number">Contact Number</label>
              <input
                type="text"
                required
                placeholder="Contact Number"
                minLength={10}
                maxLength={10}
                name="primary_contact_number"
                className="form-control"
                value={addressForm?.values?.primary_contact_number}
                onChange={addressForm.handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="address_name">Address Type</label>
            <input
              type="text"
              required
              name="address_name"
              className="form-control"
              placeholder="Home / Office / Others"
              value={addressForm?.values?.address_name}
              onChange={addressForm.handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="address_line_1">
              Address Line 1{' '}
              <small>(House no / Floor / Building / Flat Name)</small>
            </label>
            <input
              type="text"
              placeholder="House no / Floor / Building / Flat Name"
              name="address_line_1"
              className="form-control"
              value={addressForm?.values?.address_line_1}
              onChange={addressForm.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address_line_2">
              Address Line 2<small>(Area / Sector / Locality / Landmark)</small>
            </label>
            <input
              placeholder="Area / Sector / Locality / Landmark"
              type="text"
              name="address_line_2"
              className="form-control"
              value={addressForm?.values?.address_line_2}
              onChange={addressForm.handleChange}
              required
            />
          </div>
          <div className="row">
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="country_id">Country</label>
              <select
                name="country_id"
                className="form-control"
                value={addressForm?.values?.country_id}
                onChange={(event) => [
                  addressForm.setFieldValue('country_id', event.target.value),
                  getProvince(event)
                ]}
                required
              >
                <option value="" selected disabled>
                  Select Country
                </option>
                {countryList?.map((item, i) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
            </div>
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="province_id">Province</label>
              <select
                name="province_id"
                className="form-control"
                value={addressForm?.values?.province_id}
                required
                onChange={(event) => [
                  addressForm.setFieldValue('province_id', event.target.value),
                  getCity(event)
                ]}
              >
                <option value="" selected disabled>
                  Select Province
                </option>
                {provinceList?.map((item, i) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="city_id">City</label>
              <select
                name="city_id"
                className="form-control"
                value={addressForm?.values?.city_id}
                required
                // onChange={addressForm.handleChange}
                onChange={(event) => [
                  addressForm.setFieldValue('city_id', event.target.value),
                  getZipcodeByCityDeliverable(event)
                ]}
              >
                <option value="" selected disabled>
                  Select City
                </option>
                {cityList?.map((item, i) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
            </div>
            {/* <div className="form-group col-12 col-lg-6">
              <label htmlFor="zipcode">Zipcode</label>
              <input
                type="text"
                minLength={6}
                maxLength={6}
                name="zipcode"
               
                className="form-control"
                value={addressForm?.values?.zipcode}
                onChange={addressForm.handleChange}
              />
            </div> */}

            <div className="form-group col-12 col-lg-6">
              <label htmlFor="zipcode_id">
                Zipcode <small>(Below listed zipcodes are deliverable)</small>
              </label>
              <select
                name="zipcode_id"
                className="form-control"
                value={addressForm?.values?.zipcode_id}
                required
                // onChange={formik.handleChange}
                onChange={(event) => [
                  addressForm.setFieldValue('zipcode_id', event.target.value)
                ]}
              >
                <option value="" selected disabled>
                  Select Zipcode
                </option>
                {zipcodeList?.map((item, i) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
              {formik?.touched?.zipcode_id && formik?.errors?.zipcode_id && (
                <p className="red-text">{formik?.errors?.zipcode_id}</p>
              )}
            </div>
          </div>
          <div className="form-footer d-flex justify-content-end">
            <button type="submit" className="orangebtn">
              {addressForm?.values?.id ? 'Update' : 'Add'}
            </button>
          </div>
        </form>
      </Modal>

      <Arasuri />
      <Footer />
      <form method="POST" action={payu_api_url}>
        <input type="hidden" name="key" value={payu_key} />
        <input type="hidden" name="txnid" value={payuRes?.txnid} />
        <input type="hidden" name=" amount" value={payuRes?.amount} />
        <input type="hidden" name="productinfo" value={payuRes?.productinfo} />
        <input type="hidden" name="firstname" value={payuRes?.firstname} />
        <input type="hidden" name="lastname" value={payuRes?.lastname} />
        <input type="hidden" name="email" value={payuRes?.email} />
        <input type="hidden" name="phone" value="9198672387" />
        <input type="hidden" name="surl" value={payuRes?.surl} />
        <input type="hidden" name="furl" value={payuRes?.furl} />
        <input type="hidden" name="curl" value={payuRes?.curl} />
        <input type="hidden" name="hash" value={payuRes?.hash} />
        <input type="hidden" name="udf1" value={payuRes?.udf1} />
        <input type="hidden" name="udf2" value={payuRes?.udf2} />
        <input type="hidden" name="udf3" value={payuRes?.udf3} />
        <input type="hidden" name="udf4" value={payuRes?.udf4} />
        <input type="hidden" name="udf5" value={payuRes?.udf5} />
        <button hidden={true} ref={myButtonRef}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default ShaktiDarshanamAddress;
