import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

/** User Profile */
const getUserData = () => {
  return http.get(
    // `${api_urls?.getUser}?id=${user_id}}`
    `${api_urls?.getUser}?id=${parseInt(localStorage.getItem("user_id"))}`
  );
};

const updateUser = (requestData) => {
  return http.post(api_urls?.updateUser, requestData);
};

const ProfileService = {
  getUserData,
  updateUser,
};
export default ProfileService;
