// fundamentals
import React, { useState, useEffect } from "react";

// components
import "./shippingAndDelivery.scss";
import Footer from "../../Components/footer";
import "../Home/home.scss";

// images
import ambajitemple from "../../Assets/Images/Home_page/linear.png";
import temple1 from "../../Assets/Images/Home_page/temple1.png";
import PrayIcon from "../../Assets/Images/Home_page/PrayIcon.svg";
import Arasuri from "../../Components/arasuri";
import LazyLoader from "../../Components/lazyLoader";

function ShippingAndDelivery() {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  return (
    <>
      {!isLoading ? (
        <div className="main-wrapper">
          {/* banner  */}
          <section className="header-banner temple-banner">
            <div className="container">
              <h1>ડિલિવરી નીતિ</h1>
            </div>
          </section>
          {/* ----------  */}

          {/* details  */}
          <div className="shipping-delivery">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="shipping-text">
                    <h2>ખરીદીની અને અરજીની પ્રક્રિયા :</h2>
                    <ul>
                      <li>
                        અંબાજી મંદિર ટ્રસ્ટ વેબસાઇટનો યોગ્ય સેક્શનમાં પ્રાસાદ
                        અને અન્ય મંદિરના સંબંધિત ઉત્પાદો ખરીદવા માટે અવલંબન
                        પ્રદાન કરે છે
                      </li>
                      <li>
                        ખરીદી પૂર્ણ થવાની પહેલા, ગ્રાહકોએ અરજી કરવી છતાં પોતાની
                        સાચવેલી વિગતો પૂરી કરવાનો ઇજાજત આપવો છે.
                      </li>
                    </ul>
                  </div>
                  <div className="shipping-text">
                    <h2>પૈસાની રહીથી પગાર :</h2>
                    <ul>
                      <li>
                        ખરીદી પૂર્ણ થવાની પહેલા, ગ્રાહકોએ ઓનલાઇન ચુકવણી માટે
                        ઉપયોગકર્તા વિગતો પૂરી કરવાનો ઇજાજત આપવો છે.
                      </li>
                    </ul>
                  </div>
                  <div className="shipping-text">
                    <h2>ડિલિવરી નીતિ :</h2>
                    <ul>
                      <li>
                        પ્રસાદ અને અન્ય ઉત્પાદોની ડિલિવરી માટે, ખરીદી ગ્રાહકની
                        સરનામું અને વિગતોનો ઉપયોગ થશે.
                      </li>
                      <li>
                        ડિલિવરી સમયમાં દિનો, સપ્તાહ, અથવા અન્ય પ્રદેશને સંબંધિત
                        કરવાનો નિર્ધારણ કરવામાં આવશે.
                      </li>
                    </ul>
                  </div>
                  <div className="shipping-text">
                    <h2>રદ અને પુનઃસ્થાપન :</h2>
                    <ul>
                      <li>
                        ગ્રાહકોએ અરજી કરતાં પહેલા, પ્રથમિક પરિસ્થિતિઓ પર આધાર
                        રાખવાનો અધિકાર રાખવામાં આવશે.
                      </li>
                    </ul>
                  </div>
                  <div className="shipping-text">
                    <h2>પૂર્વ-ચુક્તા ઓર્ડર :</h2>
                    <ul>
                      <li>
                        અંબાજી મંદિર ટ્રસ્ટ વેબસાઇટ પર ખરીદી કરવાનો માત્ર
                        પૂર્વ-ચુક્તાથી માન્ય કરશે.
                      </li>
                      <li>
                        પૈસાની ચુકવણી માટે ઓનલાઇન પૈસાના મોડનો સમર્થન કરશે.
                      </li>
                    </ul>
                  </div>
                  <div className="shipping-text">
                    <h2>ડિલિવરી પ્રક્રિયા :</h2>
                    <ul>
                      <li>ડિલિવરી માટે મહત્તમ 2 પ્રયાસથી સહાય કરવામાં આવશે.</li>
                      <li>
                        ઓર્ડર પ્રાપ્ત ન થયેલાં અને કોઇ પણ વારંવાર ડિલિવરી થતાં ન
                        હોય, તો ઓર્ડર રદ થશે અને કોઈ પણ રિફંડ મળશે નહિ.
                      </li>
                    </ul>
                  </div>
                  <div className="shipping-text">
                    <h2>ડિલિવરી સમય :</h2>
                    <ul>
                      <li>
                        ગુજરાતમાં સ્થિત ગુજરાત રીજનના પિન કોડ પર 5-9 દિવસમાં
                        ઓર્ડર ડિલિવરી થશે.
                      </li>
                    </ul>
                  </div>
                  {/* <div className="shipping-text">
                <h2>ઓર્ડર માત્ર બોક્સ :</h2>
                <ul>
                  <li>ઓર્ડર કરવાનો મહત્તમ નગ રકમ બોક્સ હોવું જોઈએ.</li>
                </ul>
              </div> */}
                  <div className="shipping-text">
                    <h2>રિટર્ન અને રિફંડ :</h2>
                    <ul>
                      <li>
                        કોઈનાં પણ કારણસર ઓર્ડર રિટર્ન અથવા રિફંડ માટેની સવારણ
                        અમને મળશે નહિ.
                      </li>
                    </ul>
                  </div>
                  <div className="shipping-text">
                    <h2>ડિલિવરી ચાર્જેસ :</h2>
                    <ul>
                      <li>
                        ડિલિવરી ચાર્જેસ એક્સ્ટ્રા રહેશે અને તેમનો ભાર ગ્રાહકે
                        ચુકતો કરવામાં આવશે.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Arasuri />
          <Footer />
          {/* ----------  */}
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
}

export default ShippingAndDelivery;
