import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './bhadarviPoonamLocation.scss';
import '../../../Css/innerPages.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import LazyLoader from '../../../Components/lazyLoader';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useHeading } from '../header'; // Import the custom hook
import ControlSecurity from '../../../Assets/Images/Bhadarvi_poonam/location-parking/security-room.svg';

import { NavLink } from 'react-router-dom';

const BhadarviPoonamLocationControlRoom = () => {
  const [value, setValue] = useState('1'); // Default active tab is '1'
  const heading = useHeading(); // Call the custom hook

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Ambaji Bhadarvi Poonam 2024</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Discover the divine celebration of Ambaji Bhadarvi Poonam 2024 at Ambaji Temple"
        />
        <meta
          name="keywords"
          content="Ambaji Bhadarvi Poonam 2024, Ambaji Temple, Bhadarvi Poonam festival"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner Location-banner">
            <div className="container">
              <div className="container">
                {heading}
                <h1>Control Room Number : 02749-262040</h1>
              </div>
            </div>
          </section>

          <section className="Location">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <NavLink
                    to="/bhadarvi-poonam"
                    className="orangebtn"
                    style={{ alignItems: 'center' }}
                  >
                    પાછા જવા માટે
                  </NavLink>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="tabboxes-location">
                    <TabContext value={value}>
                      <TabPanel value="1">
                        <div className="row">
                          <div className="col-lg-12">
                            <h3 style={{ margin: '0px 0px 15px' }}>
                              Control Room Number : <span>02749-262040</span>
                            </h3>
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://maps.app.goo.gl/wdJ4j116XFVJRGzC9"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ControlSecurity}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      CONTROL ROOM (POLICE) / કંટ્રોલ રૂમ
                                      (પોલીસ)
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.332024,72.848347"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ControlSecurity}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      CONTROL ROOM (PANCHAYAT) / કંટ્રોલ રૂમ
                                      (પંચાયત)
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default BhadarviPoonamLocationControlRoom;
