import React, { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Arasuri from '../../../../Components/arasuri';
import Footer from '../../../../Components/footer';
import './bhadarviPoonamDonationReceipt.scss';
import { useReactToPrint } from 'react-to-print';
// import Error404 from '../../../../Pages/error404';
import { useLocation, useNavigate } from 'react-router-dom';
import DonationService from '../../../../Api_config/Bhadarvi_poonam/donation';
import SignatureService from '../../../../Api_config/Home_page/signature';
import { routes } from '../../../../_Constants/routes';
import { numberToWords } from '../../../Donation/Converter/numberToWords';
import { formatNumberWithCommas } from '../../../Donation/Converter/numberWithCommas';
import { formatDate } from '../../../Donation/Converter/date';

const BhadarviPoonamDonationReceipt = () => {
  const componentRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [donationData, setDonationData] = useState();
  const [signatureData, setSignatureData] = useState();
  const amountInWords = numberToWords(donationData?.amount);
  const formattedAmount = formatNumberWithCommas(donationData?.amount);
  const formattedDate = formatDate(donationData?.createdAt);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Bhadarvi Poonam Donation Receipt',
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    }
  });
  useEffect(() => {
    const id = location?.pathname?.split('/').pop();
    // console.log(location, Number(id));
    DonationService.getBhadarviDonationById(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setDonationData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        // console.log(e);
      });
    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);
  const qrUrl = donationData?.qr_url;
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  //-----page-close-404-error-logic-p-----
  // if (true) {
  //   return <Error404 />;
  // }
  //-----page-close-404-error-logic-p-----

  return (
    <>
      <div className="booking" style={{ backgroundColor: '#f5f5f5' }}>
        <section className="header-banner bhadarvi-poonam-banner-receipt">
          <div className="container">
            <h1>Bhadarvi Poonam 2024 Donation Receipt</h1>
          </div>
        </section>
        <div className="print-container" ref={componentRef}>
          <div className="pdf-btn">
            <div className="container">
              <div className="row">
                <div
                  style={{ width: '800px', margin: '20px auto 5px' }}
                  className="email-container"
                >
                  <table width="100%">
                    <tr className="header">
                      <td width="17%" align="center" valign="middle">
                        <div className="logo">
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                            alt="Logo"
                            width="234"
                            height="234"
                          />
                        </div>
                      </td>
                      <td width="83%">
                        <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                        <p>
                          <strong>Ta.</strong> Danta, &nbsp;
                          <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                          385110.
                        </p>
                        <p>
                          <strong>Phone No</strong> : 02749-262236 &nbsp;
                          <strong>Email </strong>: ambajitempletrust@gmail.com,
                          &nbsp; www.ambajitemple.in
                        </p>
                        <p>
                          <strong>PAN No </strong>: AACTS5457M &nbsp;
                          <strong>GST No</strong> : 24AACTS5457M2Z0
                        </p>
                      </td>
                    </tr>
                    <tr className="header">
                      <td
                        width="17%"
                        rowSpan="3"
                        align="center"
                        valign="middle"
                      >
                        <div className="logo">
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1725616457588_bhadarvipoonam-logo-img.png"
                            alt="Logo"
                          />
                        </div>
                      </td>
                    </tr>

                    <tr className="headig-text">
                      <td
                        height="107"
                        colSpan="2"
                        align="center"
                        valign="middle"
                      >
                        <h2>Bhadarvi Poonam 2024 Donation Receipt</h2>
                      </td>
                    </tr>

                    <tr className="header">
                      <td colSpan="2" align="right">
                        <p>
                          <strong>Donation Date : </strong>
                          {formattedDate}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="center">
                        <table width="100%" className="date-section">
                          <tr>
                            <td width="50%">
                              <strong>Donation Number</strong>
                            </td>
                            <td width="50%">{donationData?.donation_number}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Name</strong>
                            </td>
                            <td>
                              {donationData?.first_name ||
                              donationData?.last_name
                                ? `${donationData?.first_name || ''} ${
                                    donationData?.last_name || ''
                                  }`
                                : '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Email Id</strong>
                            </td>
                            <td>{donationData?.email_id}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Phone Number</strong>
                            </td>
                            <td>{donationData?.primary_contact_number}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Donation Date</strong>
                            </td>
                            <td>{formattedDate}</td>
                          </tr>

                          <tr>
                            <td>
                              <strong>PAN Number</strong>
                            </td>
                            <td>{donationData?.pan_number}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Donation Purpose</strong>
                            </td>
                            <td>{donationData?.BDonationCategory?.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Amount</strong>
                            </td>
                            <td>₹{donationData?.amount}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Mode Of Payment</strong>
                            </td>
                            <td>Online</td>
                          </tr>
                          {/* <tr>
                            <td>
                              <strong>Transaction Reference No.</strong>
                            </td>
                            <td>Reference</td>{' '}
                          </tr> */}
                          {/* <tr>
                            <td>
                              <strong>Payment Receipt Number</strong>
                            </td>
                            <td>5455675157445</td>
                          </tr> */}
                        </table>
                      </td>
                    </tr>

                    <tr align="center" valign="middle" className="teg-line">
                      <td colSpan="2">
                        <table
                          cellspacing="0"
                          cellpadding="0"
                          border="0"
                          width="100%"
                        >
                          <tbody>
                            <tr align="center">
                              <td width="40%" valign="middle" class="barcode">
                                <img src={qrUrl} width="110" />
                              </td>
                              <td width="60%" valign="middle" class="sign">
                                {signatureData?.map((item) => {
                                  return (
                                    <>
                                      <img
                                        src={item.signature_image}
                                        width="110"
                                        height="auto"
                                      />
                                      <p>
                                        {item.name}
                                        <br />
                                        {item.designation}
                                      </p>
                                    </>
                                  );
                                })}
                                <p>
                                  Shree Arasuri Ambaji Mata Devasthan Trust
                                  Ambaji
                                  {/* વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                                  શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                                  અંબાજી */}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <button className="btn orangebtn my-2" onClick={handlePrint}>
            Receipt Download
          </button>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};

export default BhadarviPoonamDonationReceipt;
