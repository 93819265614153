import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const signUp = (requestData) => {
  return http.post(api_urls?.signUp, requestData);
};

/** OTP Vefification API's */

const sendOTP = (primary_contact_number) => {
  return http.get(
    `${api_urls?.sendOtp}?contact_number=${primary_contact_number}`
  );
};

const verifyOTP = (primary_contact_number, otp) => {
  return http.get(
    `${api_urls?.verifyOtp}?contact_number=${primary_contact_number}&otp=${otp}`
  );
};

const getBySignUpKeyword = (primary_contact_number) => {
  return http.get(
    `${api_urls?.getBySignUpKeyword}?keyword=${primary_contact_number}`
  );
};

/** Reset Password */

// const resetPassword = (requestData) => {
//   return http.post(api_urls?.resetPasword, requestData);
// };

const SignUpService = {
  signUp,
  sendOTP,
  verifyOTP,
  getBySignUpKeyword,
  //   resetPassword,
};
export default SignUpService;
