import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

/** Reset Password */

const forgotPassword = (requestData) => {
  return http.post(api_urls?.forgotPassword, requestData);
};

const sendOTP = (primary_contact_number) => {
  return http.get(
    `${api_urls?.sendOtp}?contact_number=${primary_contact_number}`
  );
};

const verifyOTP = (primary_contact_number, otp) => {
  return http.get(
    `${api_urls?.verifyOtp}?contact_number=${primary_contact_number}&otp=${otp}`
  );
};

const checkRegisteredNumber = (primary_contact_number) => {
  return http.get(
    `${api_urls?.getUser}?primary_contact_number=${primary_contact_number}`
  );
};
const ForgotPasswordService = {
  forgotPassword,
  sendOTP,
  verifyOTP,
  checkRegisteredNumber,
};
export default ForgotPasswordService;
