import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const getSignatureAll = () => {
  return http.get(`${api_urls?.getSignatureAll}?is_display=true`);
};

const SignatureService = {
  getSignatureAll,
};

export default SignatureService;
