import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

/** Transaction */
const getGoldDonation = () => {
  return http.get(
    `${api_urls?.getGoldDonation}?user_id=${JSON.parse(
      localStorage.getItem("user_id"),
    )}`,
  );
};

const getArticalDonation = () => {
  return http.get(
    `${api_urls?.getArticalDonation}?user_id=${JSON.parse(
      localStorage.getItem("user_id"),
    )}`,
  );
};

const getMoneyDonation = () => {
  return http.get(
    `${api_urls?.getMoneyDonation}?user_id=${JSON.parse(
      localStorage.getItem("user_id"),
    )}`,
  );
};

const getMoneyCounter = () => {
  return http.get(
    `${api_urls?.getMoneyCounter}?user_id=${JSON.parse(
      localStorage.getItem("user_id"),
    )}`,
  );
};

const getGoldCounter = () => {
  return http.get(
    `${api_urls?.getGoldCounter}?user_id=${JSON.parse(
      localStorage.getItem("user_id"),
    )}`,
  );
};

const getCounterPrasad = () => {
  return http.get(
    `${api_urls?.getCounterPrasad}?user_id=${JSON.parse(
      localStorage.getItem("user_id"),
    )}`,
  );
};

const getOrder = (order_type) => {
  return http.get(
    `${api_urls?.getOrder}?user_id=${JSON.parse(
      localStorage.getItem("user_id"),
    )}&product_category_id=${order_type}`,
  );
};

const getOrderById = (order_id) => {
  return http.get(`${api_urls?.getOrder}?id=${order_id}`);
};

const TransactionService = {
  getOrder,
  getGoldDonation,
  getMoneyDonation,
  getOrderById,
  getMoneyCounter,
  getGoldCounter,
  getArticalDonation,
  getCounterPrasad,
};
export default TransactionService;
