import React, { useRef, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../Components/footer';
import Arasuri from '../../../Components/arasuri';
import './sevaBookingReceipt.scss';
import { useReactToPrint } from 'react-to-print';
import { useLocation, useNavigate } from 'react-router-dom';
import SevaCampService from '../../../Api_config/Bhadarvi_poonam/sevaCamp';
import { formatNumberWithCommas } from '../../Donation/Converter/numberWithCommas';
import { formatDate } from '../../Donation/Converter/date';
import SignatureService from '../../../Api_config/Home_page/signature';
import { routes } from '../../../_Constants/routes';
import { useHeading } from '../../Bhadarvi_poonam/header'; // Import the custom hook

const SevaBookingReceipt = () => {
  const componentRef = useRef();
  const heading = useHeading(); // Call the custom hook
  const location = useLocation();
  const navigate = useNavigate();
  const [termsConditions, setTermsConditions] = useState([]);
  const [sevacampData, setSevaCampData] = useState();
  const [signatureData, setSignatureData] = useState();
  const formattedDate = formatDate(sevacampData?.applied_date);
  const formattedAmount = formatNumberWithCommas(sevacampData?.amount);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Seva Camp Booking Receipt',
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    }
  });

  useEffect(() => {
    const fetchTermsConditions = async () => {
      try {
        const res = await SevaCampService.getTC();
        // Adjust mapping based on actual response structure
        setTermsConditions(res.data.data); // Assuming response.data.data is an array of terms
        setLoading(false);
      } catch (e) {
        setError('Failed to fetch terms and conditions.');
        setLoading(false);
      }
    };

    fetchTermsConditions();

    const id = location?.pathname?.split('/').pop();
    // console.log(location, Number(id));
    SevaCampService.getAllCamp(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setSevaCampData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        // console.log(e);
      });
    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);
  const qrUrl = sevacampData?.qr_url;
  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  // / Filter terms and conditions to only include those where sangh is true
  const filteredTermsConditions = termsConditions.filter(
    (term) => term.seva_camp === true
  );

  let statusMessage = '';
  if (sevacampData?.Status?.name === 'Approved') {
    statusMessage = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration.
            <br />
            Your Seva Camp details have been approved.
          </p>
        </td>
      </tr>
    );
  } else if (sevacampData?.Status?.name === 'Rejected') {
    statusMessage = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration. <br />
            Unfortunately, your Seva Camp details have been rejected.
          </p>
        </td>
      </tr>
    );
  } else {
    statusMessage = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration.
            <br /> Your Seva Camp details are under review.
          </p>
        </td>
      </tr>
    );
  }
  return (
    <>
      {/* helmet  */}
      <Helmet>
        <title>Seva Camp Booking | Ambaji Bhadarvi Poonam 2024</title>
        <meta
          name="description"
          content="Register Your Seva Camp for Ambaji Bhadarvi Poonam Fair 2024"
        />
        <meta
          name="keywords"
          content="Ambaji Bharavi Poonam 2024, Ambaji Temple, Bharavi Poonam festival, Ambaji pilgrimage, spiritual events, religious festivals India, Ambaji celebrations, Hindu festivals, Ambaji Poonam schedule, visit Ambaji Temple, divine rituals, cultural festivities, sacred events Gujarat, Ambaji event details, Seva Camp Registration, Sangh Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* helmet  */}

      <div className="booking" style={{ backgroundColor: '#f5f5f5' }}>
        <section className="header-banner seva-camp-bg">
          <div className="container">{heading}</div>
        </section>
        <div className="print-container" ref={componentRef}>
          <div className="pdf-btn">
            <div className="container">
              <div className="row">
                <div className="detail-container">
                  <table width="100%">
                    <tr className="header-main">
                      <td
                        width="17%"
                        rowSpan="3"
                        align="center"
                        valign="middle"
                      >
                        <div className="main-logo">
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                            width="100"
                            height="100"
                          />
                        </div>
                      </td>
                      <td width="83%">
                        <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                      </td>
                    </tr>

                    <tr className="header-main">
                      <td>
                        <p>
                          <strong>Ta.</strong> Danta, &nbsp;
                          <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                          385110.
                        </p>
                        <p>
                          <strong>Phone No</strong> : 02749-262236 / +91 87996
                          00890
                        </p>
                        {/* <p>
                          <strong>PAN No </strong>: AACTS5457M &nbsp;
                          <strong>GST No</strong> : 24AACTS5457M2Z0
                        </p> */}
                        <p>
                          <strong>Email </strong>:
                          ambajitempletrust@gmail.com,&nbsp;
                          <strong>Website </strong>: www.ambajitemple.in
                        </p>
                      </td>
                    </tr>
                    <tr className="header-main"></tr>
                    <tr className="headig-text-1">
                      <td colSpan="2" align="center" valign="middle">
                        <h2>SEVA CAMP BOOKING RECEIPT</h2>
                      </td>
                    </tr>
                    <tr className="rs-note-service">
                      <td colSpan="2" align="center">
                        <p>
                          ભાદરવી પૂનમ મહામેળો 2024
                          <span>Date: 12/09/2024 to 18/09/2024</span>
                        </p>
                      </td>
                    </tr>

                    <tr className="rs-note">
                      <td colSpan="2" align="center">
                        {statusMessage}
                      </td>
                    </tr>
                    {/* Status Message */}

                    <tr className="header-main">
                      <td colSpan="2" align="right">
                        <p>
                          <strong>Booking Date</strong> : {formattedDate}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2" align="center">
                        <table width="100%" className="images-section-1">
                          <tr>
                            <td>
                              <strong>Seva Camp Booking Number</strong>
                            </td>
                            <td style={{ color: '#a30018' }}>
                              <b>{sevacampData?.registration_number}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Booking Status</strong>
                            </td>
                            <td>{sevacampData?.Status?.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Seva Camp Name</strong>
                            </td>
                            <td>{sevacampData?.name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Start Date</strong>
                            </td>
                            <td>{sevacampData?.start_date}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>End Date</strong>
                            </td>
                            <td>{sevacampData?.end_date}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Trust Name</strong>
                            </td>
                            <td>{sevacampData?.trust_name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Head Person of Seva Camp</strong>
                            </td>
                            <td>{sevacampData?.representative_name}</td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Organizer’s Phone Number</strong>
                            </td>
                            <td>
                              {
                                sevacampData?.representative_primary_contact_number
                              }
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Seva Camp Type</strong>
                            </td>
                            <td>
                              {sevacampData?.SevaCampCategory?.name || '-'}
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>

                    {/* Vehicle Details Section */}
                    {sevacampData?.Status?.name === 'Approved' && (
                      <>
                        <tr className="headig-text-1">
                          <td colSpan="2" align="center" valign="middle">
                            <h2>Vehicle Details</h2>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="2" align="center" valign="middle">
                            <table width="100%" className="images-section-1">
                              <tr>
                                <td>
                                  <strong>Sr. No.</strong>
                                </td>
                                <td>
                                  <strong>Vehicle Type</strong>
                                </td>
                                <td>
                                  <strong>Vehicle Number</strong>
                                </td>
                              </tr>
                              {sevacampData?.Vehicle1CategorySevaCamp && (
                                <tr>
                                  <td>1.</td>
                                  <td>
                                    {sevacampData.Vehicle1CategorySevaCamp.name}
                                  </td>
                                  <td>{sevacampData.vehicle1_number}</td>
                                </tr>
                              )}
                              {sevacampData?.Vehicle2CategorySevaCamp && (
                                <tr>
                                  <td>2.</td>
                                  <td>
                                    {sevacampData.Vehicle2CategorySevaCamp.name}
                                  </td>
                                  <td>{sevacampData.vehicle2_number}</td>
                                </tr>
                              )}
                              {/* <tr>
                                <td>3.</td>
                                <td>
                                  {sevacampData?.Vehicle3CategorySevaCamp?.name}
                                </td>
                                <td>{sevacampData?.vehicle3_number}</td>
                              </tr>
                              <tr>
                                <td>4.</td>
                                <td>
                                  {sevacampData?.Vehicle4CategorySevaCamp?.name}
                                </td>
                                <td>{sevacampData?.vehicle4_number}</td>
                              </tr> */}
                            </table>
                          </td>
                        </tr>
                      </>
                    )}

                    <tr align="center" valign="middle" className="teg-line">
                      <td colSpan="2">
                        <table
                          cellSpacing="0"
                          cellPadding="0"
                          border="0"
                          width="100%"
                        >
                          <tbody>
                            <tr align="center">
                              <td
                                width="40%"
                                valign="middle"
                                className="barcode"
                              >
                                <img src={qrUrl} width="110" />
                              </td>
                              <td width="60%" valign="middle" className="sign">
                                {signatureData?.map((item) => {
                                  return (
                                    <>
                                      <img
                                        src={item.signature_image}
                                        width="110"
                                        height="auto"
                                      />
                                      <p>
                                        {item.name}
                                        <br />
                                        {item.designation}
                                      </p>
                                    </>
                                  );
                                })}
                                <p>
                                  Shree Arasuri Ambaji Mata Devasthan Trust
                                  Ambaji
                                  {/* વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                                  શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                                  અંબાજી */}
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </table>
                </div>

                <div className="page-break"></div>

                <div
                  className="detail-container"
                  style={{ paddingTop: '50px' }}
                >
                  <table width="100%">
                    <tr>
                      <td colSpan="2">
                        <div className="notice">
                          <h2>Instructions / સેવા કેમ્પ માટેની સૂચનાઓ</h2>
                          <div className="terms-conditions-content">
                            {filteredTermsConditions.length ? (
                              <ol>
                                {filteredTermsConditions.map((term, index) => (
                                  <li key={index}>{term.name}</li>
                                ))}
                              </ol>
                            ) : (
                              <p>No terms and conditions available.</p>
                            )}
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <button className="btn orangebtn my-2" onClick={handlePrint}>
            Receipt Download
          </button>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};

export default SevaBookingReceipt;
