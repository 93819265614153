import React from "react";
import "../Css/lazyLoader.scss";
import loader from "../Assets/Videos/loader-video.mp4";

function LazyLoader() {
  return (
    <>
      <div className="loader">
        <video
          autoPlay
          loop
          muted
          playsInline
          controlsList="nodownload"
          style={{ width: "250px", height: "auto" }}
        >
          <source src={loader} type="video/mp4" />
        </video>
      </div>
    </>
  );
}

export default LazyLoader;
