import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const create = (data) => {
  // console.log(data);
  return http.post(api_urls?.createSangh, data);
};

const getAllSangh = (id) => {
  return http.get(`${api_urls?.getAllSangh}?id=${id}`);
};
const getAllSanghforallcontact = (primary_contact_number) => {
  return http.get(
    `${api_urls?.getAllSangh}?primary_contact_number=${primary_contact_number}`
  );
};
const getArivalroute = () => {
  return http.get(api_urls?.getArrivalroute);
};

const getAllVehicleCategory = () => {
  return http.get(api_urls?.getAllVehicleCategory);
};

// OTP

const sendOTP = (primary_contact_number) => {
  return http.get(
    `${api_urls?.moneyDonation_sendOTP}?contact_number=${primary_contact_number}`
  );
};

const verifyOTP = (primary_contact_number, otp) => {
  return http.get(
    `${api_urls?.moneyDonation_verifyOTP}?contact_number=${primary_contact_number}&otp=${otp}`
  );
};

const getBySanghKeyword = (primary_contact_number) => {
  return http.get(
    `${api_urls?.getBySanghKeyword}?primary_contact_number=${primary_contact_number}`
  );
};
const getBySanghById = (id) => {
  return http.get(`${api_urls?.getAllSangh}?id=${id}`);
};
// USER
const checkRegisteredNumber = (primary_contact_number) => {
  return http.get(
    `${api_urls?.getUser}?primary_contact_number=${primary_contact_number}`
  );
};

// const cronRequest = (data) => {
//   return http.post(api_urls?.sevaCamp_cronRequest, data);
// };

const SanghService = {
  create,
  getAllSangh,
  sendOTP,
  verifyOTP,
  checkRegisteredNumber,
  getArivalroute,
  getAllVehicleCategory,
  getBySanghKeyword,
  getBySanghById,
  getAllSanghforallcontact,
};

export default SanghService;
