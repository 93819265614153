import React, { useEffect, useRef, useState } from 'react';
import LazyLoader from '../../Components/lazyLoader';
import { Helmet } from 'react-helmet-async';
import { useFormik } from 'formik';
import GabbarShaktipithSangService from '../../Api_config/51_shakipith/gabbarShaktipithSang';
import UtilsService from '../../Api_config/Home_page/utils';
import { toast } from 'react-toastify';
import ambajitemple from '../../Assets/Images/Home_page/linear.png';
import temple1 from '../../Assets/Images/Temple/temple1.png';
import PrayIcon from '../../Assets/Images/Home_page/PrayIcon.svg';
import Footer from '../../Components/footer';
import { useNavigate } from 'react-router';
import Modal from 'react-modal';
import { routes } from '../../_Constants/routes';
import Arasuri from '../../Components/arasuri';
import Error404 from '../../Pages/error404';
// import { payu_api_url, payu_key } from "../../../_constant/constant";

// import "./shaktipithSang.scss";

const GabbarTaletiSang = (props) => {
  const { data } = props;
  const navigate = useNavigate();

  const [phoneCodeList, setPhoneCodeList] = useState([]);
  const [showMemberDetails, setShowMemberDetails] = useState(false);
  const [isNumberEditable, setNumberEditable] = useState(true);
  const [isNumberRegistered, setNumberRegistered] = useState(false);
  const [isShowForm, setShowForm] = useState(false);
  const [otp, setOTP] = useState();
  const [isOTPSend, setOTPSend] = useState(false);
  const [isResend, setResend] = useState(false);
  const [userData, setUserData] = useState();
  const [seconds, setSeconds] = useState(30);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  // const [file, setFile] = useState("");
  const [files, setFiles] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  //   function handleChange(e) {
  //     console.log(e.target.files);
  //     setFile(URL.createObjectURL(e.target.files[0]));
  // }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  const formik = useFormik({
    initialValues: {
      sang_name: data?.sang_name ?? '',
      number_of_members: data?.number_of_members,
      organizer_name: data?.organizer_name ?? '',
      organizer_contact: data?.organizer_contact,
      organizer_email: data?.organizer_email ?? '',
      organizer_address: data?.organizer_address ?? '',
      date_of_visit: data?.date_of_visit ?? '',
      village: data?.village ?? '',
      taluka: data?.taluka ?? '',
      district: data?.district ?? '',

      members:
        (data?.GabbarShaktiPeethSangMembers ?? []).map((member) => ({
          member_name: member?.member_name ?? '',
          member_contact_number: member?.member_contact_number ?? '',
          member_id_proof: member?.member_id_proof ?? ''
        })) ?? []
    },
    validateOnChange: true,
    validateOnBlur: true,
    onSubmit: (values, { resetForm }) => {
      const userData = {
        sang_name: values?.sang_name,
        number_of_members: values?.number_of_members,
        organizer_contact: values?.organizer_contact,
        organizer_address: values?.organizer_address,
        organizer_email: values?.organizer_email,
        organizer_name: values?.organizer_name,
        village: values?.village,
        taluka: values?.taluka,
        district: values?.district,
        date_of_visit: values?.date_of_visit,
        members: values?.members
      };
      GabbarShaktipithSangService.createShaktipithSang(userData)
        .then((res) => {
          if (res.data.success) {
            // setShowToster(true);
            // setSuccessMessage(res.data.message);
            resetForm();
            toast.success(res.data.message, { autoClose: 1000 });
            navigate(routes?.thankYou, {
              state: {
                organizer_contact: values?.organizer_contact, // Assuming mobileNumber is defined
                sang_name: values?.sang_name // Assuming sangName is defined
              }
            });
          } else {
            // setSuccessMessage(null);
          }
        })
        .catch((e) => {
          // toast.error(res.data.error.message, { autoClose: 1000 });
          navigate(routes?.error500);
          // console.log(e);
        });
    }
  });

  // const bindData = () => {
  //   formik.setFieldValue("organizer_contact", userData?.organizer_contact);
  //   formik.setFieldValue("sang_name", userData?.sang_name);
  //   formik.setFieldValue("organizer_name", userData?.organizer_name);
  //   formik.setFieldValue("organizer_email", userData?.organizer_email);
  //   formik.setFieldValue("organizer_address", userData?.organizer_address);
  //   formik.setFieldValue("date_of_visit", userData?.date_of_visit);
  //   formik.setFieldValue("village", userData?.village);
  //   formik.setFieldValue("taluka", userData?.taluka);
  //   formik.setFieldValue("district", userData?.district);
  // };

  // check number for otp process

  const checkForContactNumber = (event) => {
    if (formik?.values?.organizer_contact) {
      GabbarShaktipithSangService.getByShaktipithKeyword(
        formik?.values?.organizer_contact
      ).then((res) => {
        if (res.data.success) {
          if (res.data.data.length !== 0) {
            setError('This contact number is already registered.');
            toast.error('This contact number is already registered.', {
              autoClose: 1500
            });
          } else {
            setNumberRegistered(true);
            setNumberEditable(false);
            setUserData(res.data.data[0]);
            GabbarShaktipithSangService.sendOTP(
              formik?.values?.organizer_contact
            )
              .then((res) => {
                if (res.data.success) {
                  setOTPSend(true);
                  setSeconds(30);
                }
              })
              .catch((error) => {
                navigate(routes?.error500);
                toast.error(error.response?.data?.message, { autoClose: 1500 });
              });
          }
        }
      });
    }
  };

  // const checkForContactNumber = (event) => {
  //   if (formik?.values?.organizer_contact) {
  //     ShaktipithSangService.getByKeyword(formik?.values?.organizer_contact)
  //       .then((res) => {
  //         if (res.data.success) {
  //           if (res.data.data.length !== 0) {
  //             toast.error("This contact number is already taken.", { autoClose: 1500 });
  //           } else {
  //             setNumberRegistered(true);
  //             setNumberEditable(false);
  //             setUserData(res.data.data[0]);
  //             ShaktipithSangService.sendOTP(formik?.values?.organizer_contact)
  //               .then((res) => {
  //                 if (res.data.success) {
  //                   setOTPSend(true);
  //                   setSeconds(30);
  //                 }
  //               })
  //               .catch((error) => {
  //        navigate(routes?.error500);
  //                 toast.error(error.response?.data?.message, { autoClose: 1500 });
  //               });
  //           }
  //         }
  //       });
  //   }
  // };

  // Define a state to store the error message
  const [error, setError] = useState('');

  // verify otp

  const verifyOTP = () => {
    if (otp?.length === 6) {
      formik.setFieldValue('sang_name', '');
      // formik.setFieldValue("organizer_name","");
      // formik.setFieldValue("organizer_contact",""),
      // formik.setFieldValue("organizer_email","")

      // formik?.resetForm()

      GabbarShaktipithSangService.verifyOTP(
        formik?.values?.organizer_contact,
        otp
      )
        .then((res) => {
          if (res.data.success) {
            if (userData) {
              setOpenModal(true);
            }
            setShowForm(true);
            setIsOTPVerified(true);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    }
  };

  /**
   * Resend OTP
   * @param organizer_contact
   */
  const resendOTP = () => {
    GabbarShaktipithSangService.sendOTP(formik?.values?.organizer_contact)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };
  // Function to handle OTP verification success
  const handleOTPVerificationSuccess = () => {
    // Open modal if userData is not null
    // if (userData) {
    //   setOpenModal(true);
    // }
    // Show the form
    setShowForm(true);
    // Mark OTP as verified
    setIsOTPVerified(true);
  };

  // Function to handle OTP verification failure
  const handleOTPVerificationFailure = () => {
    // Display error message
    toast.error('OTP verification failed');
    // Reset OTP field
    formik.setFieldValue('otp', '');
    // Set isOTPVerified to false
    setIsOTPVerified(false);
  };

  // const handleFileChange = (e) => {
  //   const selectedFile = e.target.files[0];
  //   setFile(URL.createObjectURL(selectedFile));
  // };
  const handleFileChange = (e, index) => {
    const newFiles = [...files];
    newFiles[index] = URL.createObjectURL(e.target.files[0]);
    setFiles(newFiles);
  };

  // for Number exists or not

  const [organizerContact, setOrganizerContact] = useState('');
  const handleOrganizerContactChange = (e) => {
    const newValue = e.target.value;
    setOrganizerContact(newValue);
    setError(''); // Clear the error message when the user changes the input

    formik.handleChange(e);

    // Perform the check for contact number availability
    if (newValue.length === 10) {
      // Assuming the contact number should be 10 digits
      checkContactNumberAvailability(newValue);
    }
  };

  const checkContactNumberAvailability = async (number) => {
    try {
      const response = await GabbarShaktipithSangService.getByShaktipithKeyword(
        number
      ); // Replace with your actual API service method
      if (response.data.isTaken) {
        setError('This contact number is already registered.');
      }
    } catch (error) {
      console.error('Error checking contact number availability:', error);
    }
  };
  const { handleChange, handleSubmit } = formik;

  //-----page-close-404-error-logic-p-----
  if (true) {
    return <Error404 />;
  }
  //-----page-close-404-error-logic-p-----
  return (
    <>
      <Helmet>
        <title>Ambaji Temple - Gabbar Taleti Sang</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Get detailed information of 51 Shaktipith Mahotsav."
        />
        <meta name="keywords" content="51 Shaktipith Mahotsav Ambaji" />
        <meta name="author" content="51 Shaktipith Mahotsav" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <>
          <section className="header-banner shaktipith-banner">
            <div className="container">
              <h1>ગબ્બર તળેટી સંપૂર્ણ શક્તિપીઠ પરિક્રમા સંઘ સ્થાપના અભિયાન</h1>
            </div>
          </section>
          <section>
            <div className="form-content">
              <div className="container">
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <div className="donatemain seva-donatemain">
                      <div className="row">
                        <div className="col-lg-12">
                          <h3>
                            પદયાત્રી સંઘ માટે{' '}
                            <span>ઓનલાઈન રજીસ્ટ્રેશન ફોર્મ</span>
                          </h3>
                        </div>
                        <div className="col-lg-12">
                          <form onSubmit={handleSubmit}>
                            <div className="row align-items-center">
                              <div className="col-xl-5 col-lg-6 col-md-8 col-sm-12">
                                <div className="form-group">
                                  <label>
                                    Organizer Contact Number / આયોજકનો સંપર્ક
                                    નંબર
                                  </label>
                                  <input
                                    type="text"
                                    inputMode="numeric"
                                    placeholder="Organizer Contact Number"
                                    className="form-control"
                                    name="organizer_contact"
                                    value={organizerContact}
                                    onChange={handleOrganizerContactChange}
                                    disabled={!isNumberEditable}
                                    onKeyDown={(e) => {
                                      if (
                                        e.key === 'ArrowUp' ||
                                        e.key === 'ArrowDown'
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    pattern="[0-9]{10}"
                                    required
                                  />
                                </div>
                              </div>
                              <div className="col-xl-7 col-lg-6 col-md-4 col-sm-12">
                                {isNumberEditable ? (
                                  <button
                                    type="button"
                                    className="whitebtn"
                                    onClick={() => checkForContactNumber()}
                                  >
                                    Verify Number
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="whitebtn"
                                    onClick={() => [
                                      setNumberEditable(true),
                                      setNumberRegistered(false),
                                      setShowForm(false)
                                    ]}
                                  >
                                    Edit Number
                                  </button>
                                )}
                              </div>
                              <span className="red-text">{error}</span>
                            </div>
                            <div className="row align-items-center">
                              {isNumberRegistered &&
                                !isNumberEditable &&
                                !isShowForm && (
                                  <div className="form-group">
                                    <div className="row align-items-center">
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label htmlFor="otp">OTP</label>
                                          <input
                                            type="text"
                                            inputMode="numeric"
                                            minLength="6"
                                            maxLength="6"
                                            pattern="[0-9]*"
                                            name="otp"
                                            id="otp"
                                            className="form-control"
                                            placeholder="_ _ _ _ _ _"
                                            value={otp}
                                            onChange={(event) => {
                                              const inputValue =
                                                event.target.value.replace(
                                                  /\D/g,
                                                  ''
                                                ); // Remove non-digit characters
                                              setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                                              setResend(false); // Reset the resend state if needed
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-8">
                                        <button
                                          type="button"
                                          className="orangebtn"
                                          disabled={otp?.length !== 6}
                                          onClick={() => verifyOTP()}
                                        >
                                          Verify OTP
                                        </button>
                                        {isOTPSend && seconds == '00' && (
                                          <button
                                            type="button"
                                            className="whitebtn ms-3"
                                            onClick={() => [
                                              resendOTP(),
                                              setOTP(''),
                                              setResend(true)
                                            ]}
                                          >
                                            Resend OTP
                                          </button>
                                        )}
                                      </div>
                                    </div>
                                    {isOTPSend && seconds > 0 ? (
                                      <span>
                                        Resend OTP in
                                        {seconds < 10
                                          ? ` 0${seconds}`
                                          : ` ${seconds}`}{' '}
                                        sec
                                      </span>
                                    ) : (
                                      <span> </span>
                                    )}
                                    {!isResend &&
                                      (otp?.length > 6 || otp?.length < 6) && (
                                        <p
                                          className="red-text"
                                          style={{ margin: '0px' }}
                                        >
                                          OTP must be a 6 digits number
                                        </p>
                                      )}
                                  </div>
                                )}

                              {isOTPVerified ? (
                                <>
                                  <>
                                    <div className="row">
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>Sang Name / સંઘનું નામ</label>
                                          <input
                                            type="text"
                                            placeholder="Sang Name"
                                            className={`form-control ${
                                              formik.touched.sang_name &&
                                              formik.errors.sang_name
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            name="sang_name"
                                            value={formik?.values.sang_name}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                          />
                                          {formik.touched.sang_name &&
                                            formik.errors.sang_name && (
                                              <div style={{ color: '#ae0015' }}>
                                                {formik.errors.sang_name}
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Organizer's Name / આયોજકનું નામ
                                          </label>
                                          <input
                                            type="text"
                                            placeholder="Organizer's Name"
                                            className={`form-control ${
                                              formik.touched.organizer_name &&
                                              formik.errors.organizer_name
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            name="organizer_name"
                                            value={
                                              formik?.values.organizer_name
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                          />
                                          {formik.touched.organizer_name &&
                                            formik.errors.organizer_name && (
                                              <div style={{ color: '#ae0015' }}>
                                                {formik.errors.organizer_name}
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Organizer's E-mail / આયોજકનું ઈ-મેલ
                                            એડ્રેસ
                                          </label>
                                          <input
                                            type="email"
                                            placeholder="Ex: info@ambajitemple.in"
                                            className={`form-control ${
                                              formik.touched.organizer_email &&
                                              formik.errors.organizer_email
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            name="organizer_email"
                                            value={
                                              formik?.values.organizer_email
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          {formik.touched.organizer_email &&
                                            formik.errors.organizer_email && (
                                              <div style={{ color: '#ae0015' }}>
                                                {formik.errors.organizer_email}
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Number of members in the Sang /
                                            સંઘમાં સભ્યોની સંખ્યા
                                          </label>
                                          <input
                                            type="text"
                                            placeholder="Number Of Members"
                                            className={`form-control ${
                                              formik.touched
                                                .number_of_members &&
                                              formik.errors.number_of_members
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            name="number_of_members"
                                            value={
                                              formik?.values.number_of_members
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            required
                                          />
                                          {formik.touched.number_of_members &&
                                            formik.errors.number_of_members && (
                                              <div style={{ color: '#ae0015' }}>
                                                {
                                                  formik.errors
                                                    .number_of_members
                                                }
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Organizer's Address / આયોજકનું
                                            સરનામું{' '}
                                          </label>
                                          <input
                                            type="text"
                                            placeholder="Organizer's Address"
                                            className={`form-control ${
                                              formik.touched.orag &&
                                              formik.errors.organizer_address
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            name="organizer_address"
                                            value={
                                              formik?.values.organizer_address
                                            }
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          {formik.touched.organizer_address &&
                                            formik.errors.organizer_address && (
                                              <div style={{ color: '#ae0015' }}>
                                                {
                                                  formik.errors
                                                    .organizer_address
                                                }
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-md-6">
                                        <div className="form-group">
                                          <label>
                                            Probable date of circumambulation /
                                            પરિક્રમા કરવાની સંભવિત તારીખ
                                          </label>
                                          <input
                                            type="date"
                                            placeholder="Date of Visit"
                                            className={`form-control ${
                                              formik.touched.date_of_visit &&
                                              formik.errors.date_of_visit
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            name="date_of_visit"
                                            value={formik?.values.date_of_visit}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            min={
                                              new Date()
                                                .toISOString()
                                                .split('T')[0]
                                            }
                                          />
                                          {formik.touched.date_of_visit &&
                                            formik.errors.date_of_visit && (
                                              <div style={{ color: '#ae0015' }}>
                                                {formik.errors.date_of_visit}
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label>Village / ગામ</label>
                                          <input
                                            type="text"
                                            placeholder="Village / ગામ"
                                            className={`form-control ${
                                              formik.touched.village &&
                                              formik.errors.village
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            name="village"
                                            value={formik?.values.village}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          {formik.touched.village &&
                                            formik.errors.village && (
                                              <div style={{ color: '#ae0015' }}>
                                                {formik.errors.village}
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label>Taluka / તાલુકા</label>
                                          <input
                                            type="text"
                                            placeholder="Taluka / તાલુકા"
                                            className={`form-control ${
                                              formik.touched.taluka &&
                                              formik.errors.taluka
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            name="taluka"
                                            value={formik?.values.taluka}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          {formik.touched.taluka &&
                                            formik.errors.taluka && (
                                              <div style={{ color: '#ae0015' }}>
                                                {formik.errors.taluka}
                                              </div>
                                            )}
                                        </div>
                                      </div>

                                      {/* District  */}
                                      <div className="col-md-4">
                                        <div className="form-group">
                                          <label>District / જીલ્લા</label>
                                          <input
                                            type="text"
                                            placeholder="District / જીલ્લા"
                                            className={`form-control ${
                                              formik.touched.district &&
                                              formik.errors.district
                                                ? 'is-invalid'
                                                : ''
                                            }`}
                                            name="district"
                                            value={formik?.values.district}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                          />
                                          {formik.touched.district &&
                                            formik.errors.district && (
                                              <div style={{ color: '#ae0015' }}>
                                                {formik.errors.district}
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                  {/* )}  */}
                                </>
                              ) : null}
                            </div>
                          </form>
                        </div>
                      </div>
                      {isShowForm ? (
                        <div className="row">
                          <div className="mb-5"></div>
                          <div className="col-md-12">
                            <h3>Members / સભ્યો</h3>
                            <form className="form-horizontal">
                              <div className="table-responsive">
                                <table
                                  className="table table-bordered table-striped table-highlight mb-3"
                                  style={{ borderColor: 'none' }}
                                >
                                  <thead>
                                    <tr>
                                      <th>Sr.No.</th>
                                      <th>Name</th>
                                      <th>Contact Number</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: 'center',
                                          paddingTop: '15px'
                                        }}
                                      >
                                        1.
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Name"
                                          value={
                                            formik?.values?.members[0]
                                              ?.member_name ?? ''
                                          }
                                          onChange={(e) => {
                                            const updatedMembers = [
                                              ...formik.values.members
                                            ];
                                            // Initialize updatedMembers[0  ] if it's undefined
                                            if (!updatedMembers[0]) {
                                              updatedMembers[0] = {};
                                            }
                                            updatedMembers[0].member_name =
                                              e.target.value;
                                            formik.setFieldValue(
                                              'members',
                                              updatedMembers
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Contact Number"
                                          value={
                                            formik?.values?.members[0]
                                              ?.member_contact_number ?? ''
                                          }
                                          onChange={(e) => {
                                            const updatedMembers = [
                                              ...formik.values.members
                                            ];
                                            updatedMembers[0].member_contact_number =
                                              e.target.value;
                                            formik.setFieldValue(
                                              'members',
                                              updatedMembers
                                            );
                                          }}
                                        />
                                      </td>
                                      {/* <td>
                                <input type="file" onChange={(e) => handleFileChange(e, 0)} />
                                {files[0] && <img src={files[0]} style={{ width: "7%", height: "7%" }}
                                  // value={formik?.values?.members?.member_id_proof}
                                  value={formik?.values?.members[0]?.member_id_proof ?? ""} />}
                              </td> */}
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: 'center',
                                          paddingTop: '15px'
                                        }}
                                      >
                                        2.
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Name"
                                          value={
                                            formik?.values?.members[1]
                                              ?.member_name ?? ''
                                          }
                                          onChange={(e) => {
                                            const updatedMembers = [
                                              ...formik.values.members
                                            ];
                                            // Initialize updatedMembers[1  ] if it's undefined
                                            if (!updatedMembers[1]) {
                                              updatedMembers[1] = {};
                                            }
                                            updatedMembers[1].member_name =
                                              e.target.value;
                                            formik.setFieldValue(
                                              'members',
                                              updatedMembers
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Contact Number"
                                          value={
                                            formik?.values?.members[1]
                                              ?.member_contact_number ?? ''
                                          }
                                          onChange={(e) => {
                                            const updatedMembers = [
                                              ...formik.values.members
                                            ];
                                            updatedMembers[1].member_contact_number =
                                              e.target.value;
                                            formik.setFieldValue(
                                              'members',
                                              updatedMembers
                                            );
                                          }}
                                        />
                                      </td>
                                      {/* <td>
                                <input type="file" onChange={(e) => handleFileChange(e, 1)} />
                                {files[1] && <img src={files[1]} style={{ width: "7%", height: "7%" }}
                                  // value={formik?.values?.members?.member_id_proof}
                                  value={formik?.values?.members[1]?.member_id_proof ?? ""} />}
                              </td> */}
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: 'center',
                                          paddingTop: '15px'
                                        }}
                                      >
                                        3.
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Name"
                                          value={
                                            formik?.values?.members[2]
                                              ?.member_name ?? ''
                                          }
                                          onChange={(e) => {
                                            const updatedMembers = [
                                              ...formik.values.members
                                            ];
                                            // Initialize updatedMembers[2  ] if it's undefined
                                            if (!updatedMembers[2]) {
                                              updatedMembers[2] = {};
                                            }
                                            updatedMembers[2].member_name =
                                              e.target.value;
                                            formik.setFieldValue(
                                              'members',
                                              updatedMembers
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Contact Number"
                                          value={
                                            formik?.values?.members[2]
                                              ?.member_contact_number ?? ''
                                          }
                                          onChange={(e) => {
                                            const updatedMembers = [
                                              ...formik.values.members
                                            ];
                                            updatedMembers[2].member_contact_number =
                                              e.target.value;
                                            formik.setFieldValue(
                                              'members',
                                              updatedMembers
                                            );
                                          }}
                                        />
                                      </td>
                                      {/* <td>
                                <input type="file" onChange={(e) => handleFileChange(e, 2)} />
                                {files[2] && <img src={files[2]} style={{ width: "7%", height: "7%" }}
                                  // value={formik?.values?.members?.member_id_proof}
                                  value={formik?.values?.members[2]?.member_id_proof ?? ""} />}
                              </td> */}
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: 'center',
                                          paddingTop: '15px'
                                        }}
                                      >
                                        4.
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Name"
                                          value={
                                            formik?.values?.members[3]
                                              ?.member_name ?? ''
                                          }
                                          onChange={(e) => {
                                            const updatedMembers = [
                                              ...formik.values.members
                                            ];
                                            // Initialize updatedMembers[3  ] if it's undefined
                                            if (!updatedMembers[3]) {
                                              updatedMembers[3] = {};
                                            }
                                            updatedMembers[3].member_name =
                                              e.target.value;
                                            formik.setFieldValue(
                                              'members',
                                              updatedMembers
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Contact Number"
                                          value={
                                            formik?.values?.members[3]
                                              ?.member_contact_number ?? ''
                                          }
                                          onChange={(e) => {
                                            const updatedMembers = [
                                              ...formik.values.members
                                            ];
                                            updatedMembers[3].member_contact_number =
                                              e.target.value;
                                            formik.setFieldValue(
                                              'members',
                                              updatedMembers
                                            );
                                          }}
                                        />
                                      </td>
                                      {/* <td>
                                <input type="file" onChange={(e) => handleFileChange(e, 3)} />
                                {files[3] && <img src={files[3]} style={{ width: "7%", height: "7%" }}
                                  // value={formik?.values?.members?.member_id_proof}
                                  value={formik?.values?.members[3]?.member_id_proof ?? ""} />}
                              </td> */}
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          textAlign: 'center',
                                          paddingTop: '15px'
                                        }}
                                      >
                                        5.
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Name"
                                          value={
                                            formik?.values?.members[4]
                                              ?.member_name ?? ''
                                          }
                                          onChange={(e) => {
                                            const updatedMembers = [
                                              ...formik.values.members
                                            ];
                                            // Initialize updatedMembers[4  ] if it's undefined
                                            if (!updatedMembers[4]) {
                                              updatedMembers[4] = {};
                                            }
                                            updatedMembers[4].member_name =
                                              e.target.value;
                                            formik.setFieldValue(
                                              'members',
                                              updatedMembers
                                            );
                                          }}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Contact Number"
                                          value={
                                            formik?.values?.members[4]
                                              ?.member_contact_number ?? ''
                                          }
                                          onChange={(e) => {
                                            const updatedMembers = [
                                              ...formik.values.members
                                            ];
                                            updatedMembers[4].member_contact_number =
                                              e.target.value;
                                            formik.setFieldValue(
                                              'members',
                                              updatedMembers
                                            );
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>

                                <button
                                  className="orangebtn"
                                  type="submit"
                                  style={{ width: '100%' }}
                                  disabled={
                                    !formik.isValid ||
                                    formik.isSubmitting ||
                                    formik.isValidating
                                  }
                                  onClick={handleSubmit}
                                >
                                  {formik.isSubmitting
                                    ? 'Submitting...'
                                    : 'Save'}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <Arasuri />
              <Footer />
            </div>
          </section>
        </>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default GabbarTaletiSang;
