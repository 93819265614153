import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const getTithi = () => {
  const date = new Date().toLocaleDateString("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return http.get(`${api_urls?.getTithi}?date=${date}`);
};

const TithiService = {
  getTithi,
};

export default TithiService;
