import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Footer from "../../../Components/footer";
import Arasuri from "../../../Components/arasuri";
import "./donationGoldReceipt.scss";
import { useReactToPrint } from "react-to-print";
import { isMobile } from "react-device-detect";
import GoldDonationService from "../../../Api_config/Donations/goldDonation";
import { numberToWords } from "../Converter/numberToWords";
import { formatNumberWithCommas } from "../Converter/numberWithCommas";
import { formatDate } from "../Converter/date";
import SignatureService from "../../../Api_config/Home_page/signature";
import { routes } from "../../../_Constants/routes";
const DonationGoldReceipt = () => {
  const navigate = useNavigate();
  const componentRef = useRef();
  const location = useLocation();
  const [transactionData, setTransactionData] = useState();
  const amountInWords = numberToWords(transactionData?.amount);
  const formattedAmount = formatNumberWithCommas(transactionData?.amount);
  const formattedDate = formatDate(transactionData?.createdAt);
  const [signatureData, setSignatureData] = useState();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Ambaji Temple Gold Donation Receipt",
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    },
  });

  useEffect(() => {
    const id = location?.pathname?.split("/").pop();
    // console.log(location, Number(id))
    GoldDonationService.getTransactionDetails(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setTransactionData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        //  console.log(e)
      });

    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Ambaji Temple - Gold Donation Receipt</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details, Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji, Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="booking" style={{ backgroundColor: "#f5f5f5" }}>
        <section className="header-banner gold-banner">
          <div className="container">
            <h1>Donation - Gold</h1>
          </div>
        </section>
        <div
          style={
            isMobile
              ? {
                  position: "relative",
                  overflow: "hidden",
                }
              : { position: "static" }
          }
        >
          <div
            className="print-container"
            ref={componentRef}
            style={isMobile ? { position: "absolute" } : { position: "static" }}
          >
            <div className="pdf-btn">
              <div className="container">
                <div className="row">
                  <div
                    className="email-container-box"
                    style={{ width: "800px", margin: "0px auto" }}
                  >
                    <table width="100%">
                      <tr className="header-1">
                        <td
                          width="17%"
                          rowSpan="3"
                          align="center"
                          valign="middle"
                        >
                          <div className="logo">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                              alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                            />
                          </div>
                        </td>
                        <td width="83%">
                          <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                        </td>
                      </tr>
                      <tr className="header-1">
                        <td>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>
                          <p>
                            <strong>PAN No </strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong> : 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="header-1"></tr>
                      <tr className="headig-text-1-es">
                        <td colSpan="2" align="center" valign="middle">
                          <h2>GOLD DONATION RECEIPT</h2>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2" align="center" valign="middle">
                          <table width="100%" className="date-section-box">
                            <tr>
                              <td width="52%" align="left">
                                <strong>Donation Number : </strong>
                                {transactionData?.donation_number}
                              </td>
                              <td width="48%" align="right">
                                <strong>Date : </strong>
                                {formattedDate}
                                &nbsp; &nbsp;
                                <span></span>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <table width="100%">
                            <tr>
                              <td width="55%">
                                <table width="100%">
                                  <tr>
                                    <td
                                      width="30%"
                                      height="0"
                                      valign="middle"
                                      className="rs-box-1"
                                    >
                                      <p>
                                        <span>Rs.</span>
                                        {formattedAmount} /-
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                                <div className="rs-note-1">
                                  <p>
                                    અંકે રૂપિયા <span>{amountInWords}</span>
                                  </p>
                                  <p>શ્રી માતાજી આપને સદા સુખી રાખે ...</p>
                                </div>
                              </td>
                              {transactionData?.user_identity !== "hidden" && (
                                <td
                                  width="50%"
                                  align="right"
                                  className="rs-note-1"
                                >
                                  <p>
                                    <span>
                                      {" "}
                                      <span>
                                        {" "}
                                        {transactionData?.GoldDonationDetail
                                          ?.first_name ||
                                        transactionData?.GoldDonationDetail
                                          ?.last_name
                                          ? `${
                                              transactionData
                                                ?.GoldDonationDetail
                                                ?.first_name || ""
                                            } ${
                                              transactionData
                                                ?.GoldDonationDetail
                                                ?.last_name || ""
                                            }`
                                          : "-"}
                                      </span>
                                    </span>
                                    <br />
                                    રહેવાસી{" "}
                                    <span>
                                      {
                                        transactionData?.GoldDonationDetail
                                          ?.City?.name
                                      }
                                      ,{" "}
                                      {
                                        transactionData?.GoldDonationDetail
                                          ?.Province?.name
                                      }
                                    </span>{" "}
                                    તરફથી મળ્યા છે.
                                    <br />
                                    જે આભાર સહ સ્વીકારવામાં આવે છે.
                                  </p>
                                </td>
                              )}
                            </tr>
                            <tr>
                              <td width="55%" className="sign-trust">
                                {/* <p>
                      Exempted U/s. 80(G) of I.T. Act Vide <br />
                      Commissioner of Income Tax, Gandhinagar <br />
                      No. DIT (E) 80G (5)/98/07-08, Date: 10/05/2007 <br />
                      Valid from: 01-04-2007
                    </p> */}
                              </td>
                              <td
                                width="50%"
                                align="center"
                                className="sign-vahivat-1"
                              >
                                {signatureData?.map((item) => {
                                  return (
                                    <>
                                      <img
                                        src={item.signature_image}
                                        alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                                        // src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716962038848_MicrosoftTeams-image(7).png"
                                        width="120"
                                        height="100"
                                      />
                                      <br />
                                      <p>
                                        {item.name}
                                        <br />
                                        {item.designation}
                                      </p>
                                    </>
                                  );
                                })}
                                <p>
                                  Shree Arasuri Ambaji Mata Devasthan Trust
                                  Ambaji
                                  {/* વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                                  શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                                  અંબાજી */}
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr className="teg-line">
                        <td colSpan="2">
                          <p style={{ color: "#a30018" }}>
                            Donation Received in Corpus fund account is received
                            with a specific direction that the donation paid in
                            corpus fund account will be kept intact and only
                            interest income will be spent for object of the
                            trust.
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div
                    className="border-dest"
                    style={{ width: "800px", margin: "0px auto" }}
                  >
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716205370014_scissorleft.png"
                      alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                    />{" "}
                    - - - - - - - - - - - - - -{" "}
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716205370014_scissorleft.png"
                      alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                    />{" "}
                    - - - - - - - - - - - - - -{" "}
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716204812542_scissor.png"
                      alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                    />{" "}
                    - - - - - - - - - - - - - -{" "}
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716204812542_scissor.png"
                      alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                    />
                  </div>
                  <div
                    className="email-container-box"
                    style={{ width: "800px", margin: "0px auto" }}
                  >
                    <table width="100%">
                      <tr className="header-1">
                        <td
                          width="17%"
                          rowSpan="3"
                          align="center"
                          valign="middle"
                        >
                          <div className="logo">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                              alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                            />
                          </div>
                        </td>
                        <td width="83%">
                          <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                        </td>
                      </tr>
                      <tr className="header-1">
                        <td>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>
                          <p>
                            <strong>PAN No </strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong> : 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="header-1"></tr>
                      <tr className="headig-text-1-es">
                        <td colSpan="2" align="center" valign="middle">
                          <h2>LETTER FROM THE DONEE</h2>
                        </td>
                      </tr>
                      <tr className="rs-note-1">
                        <td colSpan="2">
                          <p>
                            <strong>Donation Number : </strong>
                            {transactionData?.donation_number}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" align="center" valign="middle">
                          <table width="100%" className="date-section-box">
                            <tr>
                              <td className="rs-note-1">
                                <p>
                                  To,
                                  <br />
                                  The Administrator,
                                  <br />
                                  Shree Arasuri Ambaji Mandir Mata Devasthan
                                  Trust, Ambaji.
                                </p>
                              </td>
                              <td className="date-section-box">
                                <p>
                                  {transactionData?.user_identity !==
                                    "hidden" && (
                                    <span>
                                      <strong>From : </strong>
                                      {transactionData?.GoldDonationDetail
                                        ?.first_name ||
                                      transactionData?.GoldDonationDetail
                                        ?.last_name
                                        ? `${
                                            transactionData?.GoldDonationDetail
                                              ?.first_name || ""
                                          } ${
                                            transactionData?.GoldDonationDetail
                                              ?.last_name || ""
                                          }`
                                        : "-"}
                                      ,{" "}
                                      {
                                        transactionData?.GoldDonationDetail
                                          ?.City?.name
                                      }
                                      <br />
                                      <strong>Phone : </strong>
                                      {
                                        transactionData?.GoldDonationDetail
                                          ?.primary_contact_number
                                      }
                                      <br />
                                    </span>
                                  )}
                                  <strong>Date : </strong>
                                  {formattedDate} &nbsp; &nbsp; <span></span>
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr className="teg-line">
                        <td colSpan="2">
                          <p>Dear Sir / Mam,</p>
                        </td>
                      </tr>
                      <tr className="rs-note-1">
                        <td colSpan="2" align="center">
                          <p>
                            <span>
                              {" "}
                              Ref: Donation to be given in Corpus Fund account.{" "}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr className="rs-note-1">
                        <td colSpan="2">
                          <p>
                            Please find the donation of Rs.
                            <span style={{ margin: "0px" }}>
                              {formattedAmount}
                            </span>{" "}
                            <span style={{ margin: "0px" }}>
                              ({amountInWords}){" "}
                            </span>
                            equivalent with the purpose of
                            <strong> Gold Ornaments </strong>to the trust in
                            corpus fund account.
                          </p>
                        </td>
                      </tr>
                      <tr className="teg-line">
                        <td colSpan="2">
                          <p>
                            The donation is given with a specific direction that
                            the said amount of donation will be kept intact as
                            corpus fund of the trust and only interest income
                            received from the said donation will be utilized for
                            the objects of the trust.
                          </p>
                        </td>
                      </tr>
                      <tr className="teg-line">
                        <td>
                          <p>Thank you</p>
                        </td>
                        <td align="right">
                          <p>
                            <strong>Yours Faithfully</strong>
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isMobile && (
          <>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div
                    style={{ width: "100%" }}
                    className="email-container-boxers"
                  >
                    <table width="100%">
                      <tr align="center" className="header-1-hed">
                        <td>
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                            alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                            width="100"
                            height="98"
                          />
                          <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>

                          <p>
                            <strong>PAN No </strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong> : 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="headig-text-desige">
                        <td align="center" valign="middle">
                          <h2>GOLD DONATION RECEIPT</h2>
                        </td>
                      </tr>
                      <tr className="date-section-sec-1">
                        <td>
                          <strong>Donation Number : </strong>
                          {transactionData?.donation_number}
                        </td>
                      </tr>
                      <tr className="date-section-sec-1">
                        <td>
                          <strong>Date : </strong>
                          {formattedDate} &nbsp; &nbsp;
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="18%" className="table-1-box">
                            <tr>
                              <td valign="middle" className="rs-box-section">
                                <p>
                                  <span>Rs.</span> {formattedAmount} /-
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      {transactionData?.user_identity !== "hidden" && (
                        <tr className="rs-notees-1">
                          <td>
                            <p>
                              અંકે રૂપિયા <span>{amountInWords} </span>
                            </p>
                            <p>
                              <span>
                                {transactionData?.User?.first_name ||
                                transactionData?.User?.last_name
                                  ? `${
                                      transactionData?.User?.first_name || ""
                                    } ${transactionData?.User?.last_name || ""}`
                                  : "-"}
                              </span>
                              <br />
                              <span>
                                {
                                  transactionData?.GoldDonationDetail?.City
                                    ?.name
                                }
                              </span>
                              {", "}
                              <span>
                                {
                                  transactionData?.GoldDonationDetail?.Province
                                    ?.name
                                }
                              </span>
                              રહેવાસી તરફથી મળ્યા છે.
                              <br />
                              જે આભાર સહ સ્વીકારવામાં આવે છે.
                            </p>
                            <p>શ્રી માતાજી આપને સદા સુખી રાખે ...</p>
                          </td>
                        </tr>
                      )}

                      <tr align="right">
                        <td valign="middle" className="sign-vahivat-box-1">
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201169285_modi-sign.png"
                            width="80"
                            alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                          />
                        </td>
                      </tr>

                      <tr align="right">
                        <td valign="middle" className="sign-vahivat-box-1">
                          <p>
                            વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                            શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ અંબાજી
                          </p>
                        </td>
                      </tr>

                      <tr align="center">
                        <td valign="middle" className="teg-line-hed">
                          <p style={{ color: "#a30018" }}>
                            Donation Received in Corpus fund account is received
                            with a specific direction that the donation paid in
                            corpus fund account will be kept intact and only
                            interest income will be spent for object of the
                            trust.
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="border-dest-tex" style={{ width: "100%" }}>
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716205370014_scissorleft.png"
                      alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                    />
                    <span className="border-dest-tex1">
                      - - - - - - - - - -
                    </span>
                    <span className="border-dest-tex2">
                      - - - - - - - - - - -
                    </span>
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716204812542_scissor.png"
                      alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                    />
                  </div>
                  <div
                    style={{ width: "100%" }}
                    className="email-container-boxers"
                  >
                    <table width="100%">
                      <tr align="center" className="header-1-hed">
                        <td>
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                            alt="Gold Donation - Ambaji Temple Banaskantha Gujarat"
                            width="100"
                            height="98"
                          />
                          <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>
                          <p>
                            <strong>PAN No </strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong> : 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="headig-text-desige">
                        <td colSpan="2" align="center" valign="middle">
                          <h2>LETTER FROM THE DONEE</h2>
                        </td>
                      </tr>
                      <tr className="rs-notees-1">
                        <td colSpan="2">
                          <p>
                            <strong>Donation Number : </strong>
                            {transactionData?.donation_number}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="date-section-sec-1">
                          <p>
                            {transactionData?.user_identity !== "hidden" && (
                              <span>
                                <strong>From : </strong>{" "}
                                {`${transactionData?.GoldDonationDetail?.first_name} ${transactionData?.GoldDonationDetail?.last_name}`}
                                ,{" "}
                                {
                                  transactionData?.GoldDonationDetail?.City
                                    ?.name
                                }
                                <br />
                                <strong>Phone : </strong>
                                {
                                  transactionData?.GoldDonationDetail
                                    ?.primary_contact_number
                                }
                                <br />
                              </span>
                            )}
                            <strong>Date : </strong>
                            {formattedDate} &nbsp; &nbsp;
                          </p>
                        </td>
                      </tr>
                      <tr align="left">
                        <td className="rs-notees-1">
                          <p>
                            <strong>To</strong>,<br />
                            The Administrator,
                            <br />
                            Shree Arasuri Ambaji Mandir Mata Devasthan Trust,
                            Ambaji.
                          </p>
                        </td>
                      </tr>
                      <tr className="teg-line-hed">
                        <td colSpan="2">
                          <p>Dear Sir / Mam,</p>
                        </td>
                      </tr>
                      <tr className="rs-notees-1">
                        <td colSpan="2" align="center">
                          <p>
                            <span>
                              Ref : Donation to be given in Corpus Fund account.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr className="rs-notees-1">
                        <td colSpan="2">
                          <p>
                            Please find the donation of Rs.
                            <span style={{ margin: "0px" }}>
                              {formattedAmount}
                            </span>{" "}
                            <span style={{ margin: "0px" }}>
                              ({amountInWords})
                            </span>{" "}
                            equivalent with the purpose of
                            <strong> Gold Ornaments </strong>to the trust in
                            corpus fund account.
                          </p>
                        </td>
                      </tr>
                      <tr className="teg-line-hed">
                        <td colSpan="2">
                          <p>
                            The donation is given with a specific direction that
                            the said amount of donation will be kept intact as
                            corpus fund of the trust and only interest income
                            received from the said donation will be utilized for
                            the objects of the trust.
                          </p>
                        </td>
                      </tr>
                      <tr className="teg-line-hed">
                        <td colSpan="2" align="right">
                          <p>Thank you</p>
                          <p>
                            <strong>Yours Faithfully</strong>
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="container text-center">
          <button className="btn orangebtn my-2" onClick={handlePrint}>
            Download Receipt
          </button>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};

export default DonationGoldReceipt;
