import { api_urls } from "../../_Constants/api_urls";
import http from "../../_environment";

// const getAll = () => {
//   return http.get(
//     `${
//       api_urls?.getProduct
//     }?is_shown_on_website=${true}&exclude_category_id=${1}`
//   );
// };

const getPrasadAll = () => {
  return http.get(
    `${
      api_urls?.getPrasadAll
    }?is_shown_on_website=${true}&product_category_id=${1}`
  );
};

const getById = (id) => {
  return http.get(`${api_urls?.getProduct}?id=${id}`);
};

// for get receipt
const getByProductId = (id) => {
  return http.get(`${api_urls?.getOrder}?id=${id}`);
};
// for get receipt
const getByOrderId = (id) => {
  return http.get(`${api_urls?.getCounterOrder}?id=${id}`);
};
const OnlinePrasadService = {
  //   getAll,
  getById,
  getPrasadAll,
  getByOrderId,
  getByProductId,
};

export default OnlinePrasadService;
