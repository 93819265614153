import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const getYagnaCategory = () => {
  return http.get(api_urls?.getYagnaCategory);
};

const getYagnaTemple = () => {
  return http.get(api_urls?.getYagnaTemple);
};

const checkForAvailability = (requestData) => {
  return http.post(api_urls?.yagna_checkForAvailability, requestData);
};

const yagnaBooking = (requestData) => {
  return http.post(api_urls?.yagnaBook, requestData);
};

const getBookingById = (id) => {
  return http.get(`${api_urls?.getYagna}?id=${id}`);
};

const getAllAvailableDates = (temple_id) => {
  return http.get(
    `${api_urls?.yagna_getAllAvailableDates}?yagna_temple_id=${temple_id}`,
  );
};

// const getTempleByDate = (yagna_date) => {
//   return http.get(`${api_urls?.getTempleByDate}?yagna_date=${yagna_date}`);
// };

const getTempleByDate = (yagna_date) => {
  // Convert yagna_date to the desired format "yyyy-MM-dd"
  const formattedDate = new Date(yagna_date).toISOString().split("T")[0];

  // Construct the API URL with the formatted date
  return http.get(`${api_urls?.getTempleByDate}?yagna_date=${formattedDate}`);
};

const getOfflineBooking = (requestData) => {
  // console.log(requestData);
  return http.post(api_urls?.getOfflineBoking, requestData);
};

/** OTP Vefification API's */

const sendOTP = (primary_contact_number) => {
  return http.get(
    `${api_urls?.yagna_sendOTP}?contact_number=${primary_contact_number}`,
  );
};

const verifyOTP = (primary_contact_number, otp) => {
  return http.get(
    `${api_urls?.yagna_verifyOTP}?contact_number=${primary_contact_number}&otp=${otp}`,
  );
};

const YagnaBookingService = {
  getYagnaCategory,
  getYagnaTemple,
  checkForAvailability,
  yagnaBooking,
  getBookingById,
  getAllAvailableDates,
  sendOTP,
  verifyOTP,
  getTempleByDate,
  getOfflineBooking,
};
export default YagnaBookingService;
