import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './bhadarviPoonamLocation.scss';
import '../../../Css/innerPages.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import LazyLoader from '../../../Components/lazyLoader';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useHeading } from '../header'; // Import the custom hook
import { MdOutlineNightShelter } from 'react-icons/md';
import { LuParkingCircle } from 'react-icons/lu';
import SheterRoom from '../../../Assets/Images/Bhadarvi_poonam/location-parking/bed.svg';

import { NavLink } from 'react-router-dom';

const BhadarviPoonamLocationRestShelter = () => {
  const [value, setValue] = useState('1'); // Default active tab is '1'
  const heading = useHeading(); // Call the custom hook
  const [selectedTab, setSelectedTab] = useState('REST SHELTERS');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Ambaji Bhadarvi Poonam 2024</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Discover the divine celebration of Ambaji Bhadarvi Poonam 2024 at Ambaji Temple"
        />
        <meta
          name="keywords"
          content="Ambaji Bhadarvi Poonam 2024, Ambaji Temple, Bhadarvi Poonam festival"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner Location-banner">
            <div className="container">
              <div className="container">{heading}</div>
            </div>
          </section>

          <section className="Location">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <NavLink
                    to="/bhadarvi-poonam"
                    className="orangebtn"
                    style={{ alignItems: 'center' }}
                  >
                    પાછા જવા માટે
                  </NavLink>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="tabboxes-location">
                    <TabContext value={value}>
                      <Tabs
                        className="tab-btn-location"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        indicatorColor=" "
                        allowScrollButtonsMobile
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 }
                          }
                        }}
                      >
                        <Tab
                          className={
                            selectedTab === 'REST SHELTERS'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="1"
                          onClick={() => {
                            setSelectedTab('REST SHELTERS');
                          }}
                          label="REST SHELTERS"
                        />

                        <Tab
                          className={
                            selectedTab === 'REST SHELTERS HADAD ROAD'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="2"
                          onClick={() => {
                            setSelectedTab('REST SHELTERS HADAD ROAD');
                          }}
                          label="REST SHELTERS HADAD ROAD"
                        />
                        <Tab
                          className={
                            selectedTab === 'REST SHELTERS DANTA ROAD'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="3"
                          onClick={() => {
                            setSelectedTab('REST SHELTERS DANTA ROAD');
                          }}
                          label="REST SHELTERS DANTA ROAD"
                        />
                      </Tabs>

                      <TabPanel value="1">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33632,72.87978"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      IN FRONT OF KAMAKSHI TEMPLE (PLACE OF
                                      NATHUBHAI JOSHI) / કામાક્ષી મંદિરની સામે
                                      (નાથુભાઈ જોષીનું સ્થાન)
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33077,72.85079"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      {' '}
                                      OLD COLLEGE NEAR POLICE STATION AMBAJI /
                                      અંબાજી પોલીસ સ્ટેશન પાસે જૂની કોલેજ
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.29,72.83828"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      {' '}
                                      IN FRONT OF HETAKSHI MARBLE / હેતાક્ષી
                                      માર્બલની સામે
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.313190,72.843711"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      INAUGURATION AND SEVA CAMP DOME / ઉદઘાટન
                                      અને સેવા કેમ્પ ડોમ
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.333712,72.848016"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      DARSHAN PATH FOR PADYATRI / પદયાત્રી માટે
                                      દર્શન પથ
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.334514,72.848346"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      REST ROOM FOR CLEANING STAFF / સફાઈ
                                      કર્મચારીઓ માટે આરામ ખંડ
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.331086,72.848230"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>DIWALI BAA / દિવાળી બા</p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.331539,72.848715"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      PUSHPAVATI DHARAMSHALA / પુષ્પાવતી
                                      ધર્મશાળા
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel value="2">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.335005,72.878167"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      KAMAKSHI TEMPLE (HADAD ROAD LOCATION-1) /
                                      કામાક્ષી મંદિર
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.334867,72.878079"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      KAMAKSHI TEMPLE (HADAD ROAD LOCATION-2) /
                                      કામાક્ષી મંદિર
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.334814,72.878014"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      KAMAKSHI TEMPLE (HADAD ROAD LOCATION-3) /
                                      કામાક્ષી મંદિર
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.334665, 72.878363"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      KAMAKSHI TEMPLE (HADAD ROAD LOCATION-4) /
                                      કામાક્ષી મંદિર
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel value="3">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.198280,72.769195"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      ADARSH NIVAS SCHOOL (DANTA ROAD
                                      LOCATION-1) / આદર્શ નિવાસ શાળા
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.198449,72.769519"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={SheterRoom}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      ADARSH NIVAS SCHOOL (DANTA ROAD
                                      LOCATION-2) / આદર્શ નિવાસ શાળા
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default BhadarviPoonamLocationRestShelter;
