import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './bhadarviPoonamLocation.scss';
import '../../../Css/innerPages.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import LazyLoader from '../../../Components/lazyLoader';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useHeading } from '../header'; // Import the custom hook
import { PiToilet } from 'react-icons/pi';
import PublicToilet from '../../../Assets/Images/Bhadarvi_poonam/location-parking/public-toilet.svg';

import { NavLink } from 'react-router-dom';

const BhadarviPoonamToiletLocation = () => {
  const [value, setValue] = useState('1'); // Default active tab is '1'
  const heading = useHeading(); // Call the custom hook
  const [selectedTab, setSelectedTab] = useState('TOILET BLOCK AT BIG PARKING');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Ambaji Bhadarvi Poonam 2024</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Discover the divine celebration of Ambaji Bhadarvi Poonam 2024 at Ambaji Temple"
        />
        <meta
          name="keywords"
          content="Ambaji Bhadarvi Poonam 2024, Ambaji Temple, Bhadarvi Poonam festival"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner Location-banner">
            <div className="container">
              <div className="container">{heading}</div>
            </div>
          </section>

          <section className="Location">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <NavLink
                    to="/bhadarvi-poonam"
                    className="orangebtn"
                    style={{ alignItems: 'center' }}
                  >
                    પાછા જવા માટે
                  </NavLink>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="tabboxes-location">
                    <TabContext value={value}>
                      <Tabs
                        className="tab-btn-location"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        allowScrollButtonsMobile
                        scrollButtons
                        indicatorColor=" "
                        aria-label="visible arrows tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 }
                          }
                        }}
                      >
                        <Tab
                          className={
                            selectedTab === 'TOILET BLOCK AT BIG PARKING'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="1"
                          label="TOILET BLOCK AT BIG PARKING"
                          onClick={() => {
                            setSelectedTab('TOILET BLOCK AT BIG PARKING');
                          }}
                        />
                        <Tab
                          className={
                            selectedTab ===
                            'TOILET BLOCK AT BIG PARKING (HADAD)'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="2"
                          label="TOILET BLOCK AT BIG PARKING (HADAD)"
                          onClick={() => {
                            setSelectedTab(
                              'TOILET BLOCK AT BIG PARKING (HADAD)'
                            );
                          }}
                        />
                        <Tab
                          className={
                            selectedTab ===
                            'TOILET BLOCK AT BIG PARKING (DANTA)'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="3"
                          label="TOILET BLOCK AT BIG PARKING (DANTA)"
                          onClick={() => {
                            setSelectedTab(
                              'TOILET BLOCK AT BIG PARKING (DANTA)'
                            );
                          }}
                        />
                        <Tab
                          className={
                            selectedTab === 'TOILET BLOCK AT SHELTERS NEAR'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="4"
                          label="TOILET BLOCK AT SHELTERS NEAR"
                          onClick={() => {
                            setSelectedTab('TOILET BLOCK AT SHELTERS NEAR');
                          }}
                        />
                        <Tab
                          className={
                            selectedTab === 'PERMANENT LOCATION OF TOILET'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="5"
                          label="PERMANENT LOCATION OF TOILET"
                          onClick={() => {
                            setSelectedTab('PERMANENT LOCATION OF TOILET');
                          }}
                        />
                      </Tabs>

                      <TabPanel value="1">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=23.325675,72.641437"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Vasaniya Mahadev near Gandhinagar /
                                      ગાંધીનગર પાસે વાસણીયા મહાદેવ
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=23.472512,72.566993"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>Gozaria / ગોઝારીયા</p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=23.669155,72.557005"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Visnagar - kada road / વિસનગર - કડા રોડ
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=23.783093,72.559133"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Umta - Visnagar highway / ઉમતા - વિસનગર
                                      હાઇવે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=23.941928,72.67349"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Arathi - Annapurna Mata Mandir / આરાથી
                                      અન્નપૂર્ણા માતા મંદિર
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.029742,72.789383"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Near Satlasna College / સતલાસના કોલેજ પાસે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.049912,72.7793"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>Satlasna / સતલાસના</p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24°10'47.9,72°45'00.6"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>Jalyan seva / જલ્યાણ સેવા</p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.141968,72.598955"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>Jalotra / જલોત્રા</p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.194937,72.766295"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>Near Danta / દાંતા પાસે</p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=23.498112,72.908737"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Salal gaam Himmat nagar / સલાલ ગામ હિંમત
                                      નગર
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=23.873453,73.027641"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Vijaynagar 3 Rasta Idar (Rani Talaw) IDAR
                                      / વિજયનગર 3 રસ્તો ઇડર (રાણી તલાવ) ઇડર
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.010902,73.041565"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Nr Raghu Chaya Hotel Khedbramah / રઘુ છાયા
                                      હોટલ ખેડબ્રમા પાસે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.135677,73.012008"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Agia gam - (Matoda) / આગિયા ગામ - (મટોડા)
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.228607,73.007457"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>Kheroj / ખેરોજ</p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=22.628023,72.916137"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>Bhumel / ભૂમેલ</p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.331732,72.884277"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Heliped Ground Chikhla / હેલીપેડ ગ્રાઉન્ડ
                                      ચીખલા
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=224.17972,72.74955"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Ratanpur-Danta road / રતનપુર-દાંતા રોડ
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel value="2">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B019'50.8%22N+72%C2%B052'58.1%22E/@24.3307721,72.8802235,1150m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d24.33077"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      HELIPAD PARKING (4 & 2 WHEELER) / હેલીપેડ
                                      પાર્કિંગ (4 અને 2 વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B020'00.9%22N+72%C2%B052'42.8%22E/@24.3336696,72.8781792,332m/data=!3m1!1e3!4m4!3m3!8m2!3d24.3335855!4d"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      NEAR KAMAKSHI TEMPLE (4 & 2 WHEELER) /
                                      કામાક્ષી મંદિર પાસે (4 અને 2 વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B019'58.9%22N+72%C2%B052'35.8%22E/@24.333005,72.8763218,235m/data=!3m1!1e3!4m4!3m3!8m2!3d24.3330382!4d7"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      3 SAV STONE PARKING (4 & 2 WHEELER) / 3
                                      સેવ સ્ટોન પાર્કિંગ (4 અને 2 વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B019'52.6%22N+72%C2%B052'11.4%22E/@24.3312779,72.8672491,1150m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d24.33127"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      OPP. SUPER MARBLE (2 WHEELER) / સુપર
                                      માર્બલની સામે (2 વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B019'51.0%22N+72%C2%B051'15.7%22E/@24.330835,72.8531309,288m/data=!3m2!1e3!4b1!4m10!1m5!3m4!2zMjTCsDE5J"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      TOWARDS MANGYA VAN (SHOP 50-52-52) (2
                                      WHEELER) / માંગ્યા વાન તરફ (દુકાન
                                      50-52-52) (2 વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel value="3">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B019'16.8%22N+72%C2%B050'44.9%22E/@24.3214105,72.84545,166m/data=!3m1!1e3!4m4!3m3!8m2!3d24.3213401!4d72"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      6 RITURAJ PARKING (OPP TAJ MARBLE) (2 + 4
                                      WHEELER) / 6 ઋતુરાજ પાર્કિંગ (તાજ માર્બલની
                                      સામે) (2 + 4 વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B019'13.0%22N+72%C2%B050'46.6%22E/@24.320264,72.8450589,288m/data=!3m2!1e3!4b1!4m10!1m5!3m4!2zMjTCsDE5J"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      BESIND AMBAJI TRUST COLLEGE (ENTRY & EXIT
                                      DIFFER) (2 + 4 WHEELER) / અંબાજી ટ્રસ્ટ
                                      કોલેજની બાજુમાં (એન્ટ્રી અને એક્ઝિટ
                                      ડિફરન્સ) (2 + 4 વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B018'52.6%22N+72%C2%B050'43.8%22E/@24.3146974,72.8454672,164m/data=!3m1!1e3!4m4!3m3!8m2!3d24.3146112!4d"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      BESIDE GANESH GAU SEVA ASHRAM (2 + 4
                                      WHEELER) / ગણેશ ગૌ સેવા આશ્રમની બાજુમાં (2
                                      + 4 વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B017'24.8%22N+72%C2%B050'20.5%22E/@24.290219,72.8377949,288m/data=!3m2!1e3!4b1!4m10!1m5!3m4!2zMjTCsDE3J"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      HEETANKSHI MARBLE -IN FRONT OF SHELTER (2
                                      + 4 WHEELER) / હીતાંક્ષી માર્બલ - આશ્રયની
                                      આગળ (2 + 4 વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B017'40.8%22N+72%C2%B050'23.8%22E/@24.294673,72.8392909,288m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d24.294673!"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      9 FRONT OF VANRAJI RESORT (4 WHEELER ONLY)
                                      / વનરાજી રિસોર્ટનો 9 આગળનો ભાગ (ફક્ત 4
                                      વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B017'29.6%22N+72%C2%B050'21.7%22E/@24.291547,72.8387029,288m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d24.291547!"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      FRONT OF GULA BHAI VANCHIYA HOUSE (4
                                      WHEELER ONLY) / ગુલા ભાઈ વાંછીયા ઘરની આગળ
                                      (ફક્ત 4 વ્હીલર)
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel value="4">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B017'25.3%22N+72%C2%B050'17.9%22E/@24.290365,72.838291,258m/data=!3m2!1e3!4b1!4m10!1m5!3m4!2zMjTCsDE3Jz"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      SHELTER NEAR HEETANKSHI MARBLE / હીતાંક્ષી
                                      માર્બલ પાસે આશ્રય
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B020'03.9%22N+72%C2%B052'39.7%22E/@24.33442,72.877699,258m/data=!3m2!1e3!4b1!4m10!1m5!3m4!2zMjTCsDIwJzA"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      SHELTER NEAR KAMAKSHI TEMPLE / કામાક્ષી
                                      મંદિર પાસે આશ્રય
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps/place/24%C2%B019'49.9%22N+72%C2%B051'03.3%22E/@24.3305154,72.8494599,514m/data=!3m2!1e3!4b1!4m7!1m2!2m1!1sGOVT+SCH"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      SHELTER AT GOVT. SCHOOL GROUND / સરકારી
                                      શાળાના મેદાનમાં આશ્રય
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel value="5">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33605592432517,72.84762381767747"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      OPP. SHAKTIDHWAR AMBAJI TEMPLE /
                                      શક્તિદ્વારા અંબાજી મંદિરની સામે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.330810526903434,72.84828923122997"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      DIVALI BA GURUBHAVAN NEAR / દિવાળી બા
                                      ગુરુભવન પાસે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33302684066776,72.84908930238122"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      OPP. BAS STATION SARVE NO.58 / બસ સ્ટેશનની
                                      સામે, સર્વે નં.58
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.338355544725506,72.83010002331845"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      GABBAR TALETI - OPP. CANTIN / ગબ્બર તલેટી
                                      - કેન્ટીનની સામે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33866711953449,72.82328390312702"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      GABBAR PARIKRAMA MARG / ગબ્બર પરિક્રમા
                                      માર્ગ
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33414487625204,72.84845271021787"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>AMBIKA BHOJANALAY / અંબિકા રેસ્ટોરન્ટ</p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33302684066776,72.84908930238122"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>BUS STATION / બસ સ્ટેશન</p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33302071928625,72.84986608722485"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      NEAR GRAM PANCHAYAT / ગ્રામ પંચાયત પાસે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.335853943461572,72.85192109717254"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>MANSAROVAR / માનસરોવર</p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.330898000766556,72.85058617764308"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      OLD COLLEAGE COMPOUND / ઓલ્ડ કોલેજ
                                      કમ્પાઉન્ડ
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33757970164958,72.8472062680236"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      OPP. SANSKRUT CHHATRALAY / સંસ્કૃત
                                      છાત્રાલયની સામે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33882930390354,72.82869784689265"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={PublicToilet}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      GABBAR OFFICE - NEAR / ગબ્બર ઓફિસ - નજીક
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default BhadarviPoonamToiletLocation;
