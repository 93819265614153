import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const getTransactionDetails = (id) => {
  return http.get(`${api_urls?.getArticalDonation}?id=${id}`);
};

const ArticleDonationService = {
  getTransactionDetails,
};

export default ArticleDonationService;
