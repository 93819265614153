import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const create = (data) => {
  const headers = {
    "Content-Type": "multipart/form-data",
  };
  return http.post(api_urls?.createSevaCamp, data, { headers });
};

const getAllCamp = (id) => {
  return http.get(`${api_urls?.getAllSevaCamp}?id=${id}`);
};
const getAllCampforcontact = (representative_primary_contact_number) => {
  return http.get(
    `${api_urls?.getAllSevaCamp}?representative_primary_contact_number=${representative_primary_contact_number}`
  );
};
const getAllCampCategory = () => {
  return http.get(api_urls?.getSevaCampCategory);
};

const getAllVehicleCategory = () => {
  return http.get(api_urls?.getAllVehicleCategory);
};

// OTP

const sendOTP = (representative_primary_contact_number) => {
  return http.get(
    `${api_urls?.moneyDonation_sendOTP}?contact_number=${representative_primary_contact_number}`
  );
};

const verifyOTP = (representative_primary_contact_number, otp) => {
  return http.get(
    `${api_urls?.moneyDonation_verifyOTP}?contact_number=${representative_primary_contact_number}&otp=${otp}`
  );
};

const getBySevaCampKeyword = (representative_primary_contact_number) => {
  return http.get(
    `${api_urls?.getBySevaCampKeyword}?representative_primary_contact_number=${representative_primary_contact_number}`
  );
};

// USER
const checkRegisteredNumber = (representative_primary_contact_number) => {
  return http.get(
    `${api_urls?.getUser}?primary_contact_number=${representative_primary_contact_number}`
  );
};
const getArivalroute = () => {
  return http.get(api_urls?.getArrivalroute);
};
const getTC = () => {
  return http.get(api_urls?.getTermsConditions);
};
const getBySevaCampById = (id) => {
  return http.get(`${api_urls?.getAllSevaCamp}?id=${id}`);
};

const SevaCampService = {
  create,
  getAllCamp,
  sendOTP,
  verifyOTP,
  checkRegisteredNumber,
  getArivalroute,
  getAllCampCategory,
  getAllVehicleCategory,
  getBySevaCampKeyword,
  getTC,
  getBySevaCampById,
  getAllCampforcontact,
};

export default SevaCampService;
