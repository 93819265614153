import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import './sthanicVehiclePass.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import EmployeeVehiclePassService from '../../../Api_config/Bhadarvi_poonam/employeevehiclepass';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RxCross1 } from 'react-icons/rx';
import { useHeading } from '../../Bhadarvi_poonam/header'; // Import the custom hook

// import { EmpVehiclePass } from '../../../_Constants/validations';
import SevaCampService from '../../../Api_config/Bhadarvi_poonam/sevaCamp';
const SthanikVehiclePass = (props) => {
  // const [backendErrors, setBackendErrors] = useState([]);
  // const [loading, setLoading] = useState(true);
  const { data } = props;
  const heading = useHeading(); // Call the custom hook

  // const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  // const [registrationNumber, setRegistrationNumber] = useState("");
  // const [successMessage, setSuccessMessage] = useState("");
  // const [isShowToster, setShowToster] = useState(false);
  // const [showDetails, setShowDetails] = useState(true);
  // const [vehicleCount, setVehicleCount] = useState(0);
  // const [vehicleCategoryList, setVehicleCategoryList] = useState([]);
  // const [file, setFile] = useState();
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const [otp, setOTP] = useState();
  const [seconds, setSeconds] = useState(30);
  const [isOTPSend, setOTPSend] = useState(false);
  const [isResend, setResend] = useState(false);
  const [fileName1, setFileName1] = useState('');
  const [pdf, setPdf] = useState('');
  const [pdf1, setPdf1] = useState('');
  // const [fileName2, setFileName2] = useState("");
  // const [fileName3, setFileName3] = useState("");
  // const [fileError, setFileError] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [userData, setUserData] = useState();
  // const [filePreview3, setFilePreview3] = useState();
  const [filePreview4, setFilePreview4] = useState();
  // const [filePreview5, setFilePreview5] = useState();
  // const [filePreview6, setFilePreview6] = useState();
  // const [filePreview8, setFilePreview8] = useState();
  // const [filePreview9, setFilePreview9] = useState();
  const [isNumberEditable, setNumberEditable] = useState(true);
  const imgref1 = useRef();
  const imgref2 = useRef();
  const [showregmsg, setShowRegMessage] = useState(false);

  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState('');

  const [filePreview1, setFilePreview1] = useState();
  // const [filePreview2, setFilePreview2] = useState();
  const [isShowForm, setShowForm] = useState(false);
  // const [error, setError] = useState('');
  const [vehicleError, setVehicleError] = useState('');
  // const [arrivalList, setArrivalList] = useState([]);
  const [passType, setPassType] = useState('');
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [isNumberRegistered, setNumberRegistered] = useState(false);

  useState(false);

  // Update the state when the dropdown value changes
  // const handlePassTypeChange = (e) => {
  //   setPassType(e.target.value);
  //   formik.handleChange(e);
  // };
  // const fileChange = (e, event) => {
  //   if (event == 1) {
  //     const imageUrl1 = URL.createObjectURL(e.target.files[0]);
  //     setFilePreview1(imageUrl1);
  //   } else if (event == 2) {
  //     const imageUrl2 = URL.createObjectURL(e.target.files[0]);
  //     setFilePreview2(imageUrl2);
  //   } else if (event == 3) {
  //     const imageUrl3 = URL.createObjectURL(e.target.files[0]);
  //     setFilePreview3(imageUrl3);
  //   }

  //   setFile(e.target.files[0]);
  // };

  // const fileChange = (event, fileNumber) => {
  //   const file = event.currentTarget.files[0];
  //   if (fileNumber === 1) {
  //     formik.setFieldValue("employee_doc", file);
  //     setFilePreview1(URL.createObjectURL(file));
  //     setFileName1(file.name);
  //   } else if (fileNumber === 4) {
  //     formik.setFieldValue("vehicle_doc", file);
  //     setFilePreview4(URL.createObjectURL(file));
  //     setFileName2(file.name);
  //   } else if (fileNumber === 7) {
  //     formik.setFieldValue("order_doc", file);
  //     setFilePreview7(URL.createObjectURL(file));
  //     setFileName3(file.name);
  //   }
  // };

  const fileChange1 = (e, event) => {
    if (event === 4) {
      const imageUrl4 = URL.createObjectURL(e.target.files[0]);
      setFilePreview4(imageUrl4);
      setPdf1(e.target.files[0].name);
    }
    // else if (event === 5) {
    //   const imageUrl5 = URL.createObjectURL(e.target.files[0]);
    //   setFilePreview5(imageUrl5);
    //   setPdf1(e.target.files[0].name);
    // } else if (event === 6) {
    //   const imageUrl6 = URL.createObjectURL(e.target.files[0]);
    //   setFilePreview6(imageUrl6);
    //   setPdf1(e.target.files[0].name);
    // }

    setFile1(e.target.files[0]);
  };
  const fileChange = (e, event) => {
    if (event === 1) {
      // console.log(e.target.files[0].name);
      setPdf(e.target.files[0].name);
      const imageUrl1 = URL.createObjectURL(e.target.files[0]);
      // console.log(imageUrl1);

      setFilePreview1(imageUrl1);
      setFileName1();
    }
    // else if (event === 2) {
    //   setPdf(e.target.files[0].name);
    //   const imageUrl2 = URL.createObjectURL(e.target.files[0]);
    //   setFilePreview2(imageUrl2);
    // } else if (event === 3) {
    //   setPdf(e.target.files[0].name);
    //   const imageUrl3 = URL.createObjectURL(e.target.files[0]);
    //   setFilePreview3(imageUrl3);
    // }

    setFile2(e.target.files[0]);
  };

  useEffect(() => {
    // SevaCampService.getAllVehicleCategory()
    //   .then((res) => {
    //     let vehicleCategoryList = res.data.data.map(function (vehicleCategory) {
    //       return { value: vehicleCategory.id, label: vehicleCategory.name };
    //     });
    //     // console.log(vehicleCategoryList);
    //     setVehicleCategoryList(vehicleCategoryList);
    //   })
    //   .catch((e) => {
    //     // navigate(routes?.error500);
    //     // console.log(e);
    //   });
    // SevaCampService.getArivalroute()
    //   .then((res) => {
    //     let arrivalList = res.data.data.map(function (arrivalroute) {
    //       return { value: arrivalroute.id, label: arrivalroute.name };
    //     });
    //     // console.log(campCategoryList);
    //     setArrivalList(arrivalList);
    //   })
    //   .catch((e) => {
    //     // navigate(routes?.error500);
    //     // console.log(e);
    //   });
  }, []);
  // const submitData = (data) => {
  //   EmployeeVehiclePassService.create(data).then((res) => {
  //     // console.log(res);

  //     if (res.data.success) {
  //       setSuccessMessage(res.data.message); // Set the success message
  //       setFileError(false);
  //       setShowToster(true);
  //       window.location.reload(true);
  //     } else {
  //       setFileError(true);
  //       setSuccessMessage(null); // Clear any previous success message
  //     }
  //   });
  //   if (!file) {
  //     setFileError(true); // Set file error if file is not selected
  //   }
  //   if (!accepted) {
  //     handleNextButtonClick(); // Show error message if declaration is not accepted
  //   }
  // };
  const validateVehicleNumber = (value) => {
    if (!vehicleNumberRegex.test(value)) {
      return 'Invalid vehicle number format';
    }
    if (value === formik.values.vehicle_number) {
      return 'Vehicle number must be unique';
    }
    return null;
  };

  const vehicleNumberRegex = /^[A-Z]{1,2}\d{2}[A-Z]{1,2}\d{4}$/; // Adjust the regex as needed
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      primary_contact_number: '',
      email_id: '',
      office_address: '',
      department: 'Resident',
      designation: 'Resident',
      vehicle_number: '',
      start_date: '',
      end_date: '',
      arrival_route_id: '',
      pass_type: 'Ambaji Mandir Sthanik Vahan Pass',
      vehicle_category: '',
      employee_doc: data?.employee_doc ?? '',
      // order_doc: data?.order_doc ?? '',
      vehicle_doc: data?.vehicle_doc ?? ''
    },
    // validationSchema: EmpVehiclePass,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      // console.log(filePreview1, accepted);
      if (filePreview1 && filePreview4 && !accepted) {
        if (file2) {
          formData.append('employee_doc', file2);
        } else if (data) {
          formData.append('employee_doc', data.employee_doc);
        }
        if (file1) {
          formData.append('vehicle_doc', file1);
        } else if (data) {
          formData.append('vehicle_doc', data.vehicle_doc);
        }
        // if (file3) {
        //   formData.append('order_doc', file3);
        // } else if (data) {
        //   formData.append('order_doc', data.order_doc);
        // }
        formData.append('first_name', values.first_name);
        formData.append('last_name', values.last_name);
        formData.append('office_address', values.office_address);
        formData.append('pass_type', 'Ambaji Mandir Sthanik Vahan Pass');
        // formData.append("start_date", values.start_date);
        // formData.append("end_date", values.end_date);
        formData.append('designation', 'Resident');

        formData.append('vehicle_category', values.vehicle_category);
        // formData.append("arrival_route_id", values.arrival_route_id);
        formData.append(
          'primary_contact_number',
          values.primary_contact_number
        );
        formData.append('vehicle_number', values.vehicle_number);
        formData.append('department', 'Resident');

        formData.append('email_id', values.email_id);

        EmployeeVehiclePassService.create(formData)
          .then((res) => {
            // console.log("in create api ");
            if (res.data.success) {
              toast.success(res.data.message, { autoClose: 1000 });
              // setSuccessMessage(res.data.message);
              // setFileError(false);
              // const fetchedRegistrationNumber =
              //   res.data.data.registration_number;
              // setRegistrationNumber(fetchedRegistrationNumber);
              navigate(
                `/ambaji-sthanik-vehicle-pass/registration/success/${res?.data?.data?.id}`,
                {
                  // state: {
                  //   mobileNumber: values.primary_contact_number,
                  //   vehicleNumber1: values.vehicle_number,
                  //   vehicleNumber2: values.vehicle2_number,
                  //   registration_number: fetchedRegistrationNumber,
                  // },
                }
              );
            } else {
              // setSuccessMessage(res.data.message);
              // setFileError(true);
            }
          })
          .catch((error) => {
            let errorMessage = 'An error occurred. Please try again later.';
            if (error.response && error.response.data) {
              const responseData = error.response.data;
              if (responseData.error) {
                const validationErrors = responseData.error;
                errorMessage = Object.values(validationErrors)
                  .map((err) => err.message)
                  .join(', ');
              } else if (responseData.message) {
                errorMessage = responseData.message;
              }
            }
            // setBackendErrors(errorMessage);
            toast.error(errorMessage, { autoClose: 1500 });
          });

        // .catch((error) => {
        //   let errorMessage = "An error occurred. Please try again later.";
        //   if (error.response && error.response.data) {
        //     const responseData = error.response.data;
        //     if (responseData.error && responseData.error.errors) {
        //       const validationErrors = responseData.error.errors;
        //       if (
        //         Array.isArray(validationErrors) &&
        //         validationErrors.length > 0
        //       ) {
        //         errorMessage = validationErrors[0].message;
        //       }
        //     } else if (responseData.message) {
        //       errorMessage = responseData.message;
        //     }
        //   }
        //   setBackendErrors(errorMessage);
        // });
      } else {
        // if (!file) {
        //   setFileError(true);
        // }
        if (!accepted) {
          // handleNextButtonClick();
        }
      }
    }
  });

  if (!accepted) {
    formik.handleBlur('accepted');
  }

  // const handleCheckboxChange = (event) => {
  //   setAccepted(event.target.checked);
  // };

  // const handleNextButtonClick = () => {
  //   if (accepted) {
  //     window.location.href = "sangh-booking-receipt/:id";
  //   } else {
  //     alert("You must accept declaration.");
  //   }
  // };
  // useEffect(() => {
  //   if (data?.employee_doc) {
  //     setFilePreview1(data?.employee_doc);
  //   }
  // }, [data?.employee_doc]);
  // number registered or not
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  /**
   * Verify OTP
   * @param primary_contact_number
   */
  const verifyOTP = () => {
    if (otp?.length === 6) {
      formik.setFieldValue('first_name', '');
      formik.setFieldValue('last_name', '');
      formik.setFieldValue('email_id', '');
      formik.setFieldValue('address_line_1', '');
      formik.setFieldValue('address_line_2', '');
      formik.setFieldValue('city_id', '');
      formik.setFieldValue('country_id', '');
      formik.setFieldValue('province_id', '');
      formik.setFieldValue('zipcode', '');
      formik.setFieldValue('pan_number', '');
      // formik?.resetForm()

      SevaCampService.verifyOTP(formik?.values?.primary_contact_number, otp)
        .then((res) => {
          if (res.data.success) {
            if (userData) {
              // setOpenModal(true);
            }
            setShowForm(true);
            setIsOTPVerified(true);
            // setOTPVerified(true);
          }
        })
        .catch((error) => {
          // navigate(routes?.error500);
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    }
  };
  /**
   * Resend OTP
   * @param primary_contact_number
   */
  const resendOTP = () => {
    SevaCampService.sendOTP(formik?.values?.primary_contact_number)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        // navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };
  const checkForContactNumber = (event) => {
    if (formik?.values?.primary_contact_number) {
      EmployeeVehiclePassService.getByEVPKeyword(
        formik?.values?.primary_contact_number
      ).then((res) => {
        if (res.data.success) {
          if (res.data.data.length !== 0) {
            // setError('This contact number is already registered.');
            setShowRegMessage(true);
            // toast.error('This contact number is already registered.', {
            //   autoClose: 1500
            // });
          } else {
            setNumberRegistered(true);
            setNumberEditable(false);
            setUserData(res.data.data[0]);
            setShowRegMessage(false);

            setVerifiedPhoneNumber(formik?.values.primary_contact_number);

            SevaCampService.sendOTP(formik?.values?.primary_contact_number)
              .then((res) => {
                if (res.data.success) {
                  setOTPSend(true);
                  setSeconds(30);
                }
              })
              .catch((error) => {
                // navigate(routes?.error500);
                toast.error(error.response?.data?.message, {
                  autoClose: 1500
                });
              });
          }
        }
      });
    }
  };

  const { handleChange } = formik;

  return (
    <div className="sang-wrapper">
      <section className="header-banner temple-banner">
        <div className="container">{heading}</div>
      </section>
      <section className="seva-camp">
        <div className="container">
          <div className="sang-camp-box">
            {!isOTPVerified && (
              <div className="row">
                <div className="form-group">
                  <div className="row" style={{ rowGap: '10px' }}>
                    <div className="col-xl-7 col-lg-7 col-md-10 col-sm-12">
                      <div className="row " style={{ rowGap: '10px' }}>
                        <div className="col-xl-6 col-lg-6 col-md-7">
                          <label>
                            Primary Contact Number / સંપર્ક નંબર
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            inputMode="numeric"
                            placeholder="Contact Number"
                            className="form-control"
                            name="primary_contact_number"
                            value={formik?.values.primary_contact_number}
                            // onChange={handleChange}
                            onChange={(e) => {
                              const value = e.target.value;
                              // Allow only digits and limit length to 10
                              if (/^\d{0,10}$/.test(value)) {
                                handleChange(e);
                              }
                            }}
                            disabled={!isNumberEditable}
                            maxLength="10"
                            pattern="\d{10}"
                            required
                          />

                          {/* <p className="mb-1 red-text">
                                {formik?.errors?.primary_contact_number}
                              </p> */}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-5 d-flex align-items-end">
                          {isNumberEditable ? (
                            <button
                              type="button"
                              className="whitebtn"
                              onClick={() => checkForContactNumber()}
                            >
                              Verify Number
                            </button>
                          ) : (
                            <button
                              type="button"
                              className="whitebtn"
                              onClick={() => [
                                setNumberEditable(true),
                                setNumberRegistered(false)
                              ]}
                            >
                              Edit Number
                            </button>
                          )}

                          {/* Conditionally render the message if the number is
                          already registered
                          {isNumberRegistered &&
                            !isNumberEditable &&
                            !isShowForm && (
                              <p className="text-danger mt-2">
                                You have already applied for Vehicle Pass with
                                this contact number.
                              </p>
                            )} */}
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-7">
                        {showregmsg && (
                          <div className="mt-1" style={{ color: '#ae0015' }}>
                            You have already applied for Vehicle Pass with this
                            contact number.
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {isNumberRegistered && !isNumberEditable && !isShowForm && (
                  <div className="form-group">
                    <div
                      className="row align-items-end"
                      style={{ rowGap: '5px' }}
                    >
                      <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-7">
                        <label htmlFor="otp">OTP</label>
                        <input
                          type="text"
                          inputMode="numeric"
                          minLength="6"
                          maxLength="6"
                          pattern="[0-9]*" // Only allow numeric input
                          name="otp"
                          id="otp"
                          className="form-control"
                          placeholder="_ _ _ _ _ _"
                          value={otp}
                          onChange={(event) => {
                            const inputValue = event.target.value.replace(
                              /\D/g,
                              ''
                            ); // Remove non-digit characters
                            setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                            setResend(false); // Reset the resend state if needed
                          }}
                        />
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
                        <button
                          type="button"
                          className="orangebtn"
                          onClick={() => verifyOTP()}
                          disabled={otp?.length !== 6}
                        >
                          Verify OTP
                        </button>

                        {isOTPSend && seconds == '00' && (
                          <button
                            type="button"
                            className="whitebtn ms-1"
                            onClick={() => [
                              resendOTP(),
                              setOTP(''),
                              setResend(true)
                            ]}
                          >
                            Resend OTP
                          </button>
                        )}
                        {/* <button
                        type="button"
                        className="whitebtn ms-3"
                        // onClick={() => [resendOTP(), setOTP(""), setResend(true)]}
                      >
                        Resend OTP
                      </button> */}
                      </div>
                      {isOTPSend && seconds > 0 ? (
                        <p className="mb-0 mt-1">
                          Resend OTP in
                          {seconds < 10 ? ` 0${seconds}` : ` ${seconds}`} sec
                        </p>
                      ) : (
                        <p className="mb-0"></p>
                      )}
                      {!isResend && (otp?.length > 6 || otp?.length < 6) && (
                        <p className="mb-0" style={{ color: '#ae0015' }}>
                          OTP must be a 6 digits number
                        </p>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {isShowForm && !isNumberEditable ? (
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            First Name / નામ
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.first_name &&
                              formik.errors.first_name
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="first_name"
                            value={formik?.values.first_name}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched('first_name', true, true);
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldTouched('first_name', true, true);
                            }}
                          />
                          {formik.touched.first_name &&
                            formik.errors.first_name && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.first_name}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Last Name / અટક
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.last_name &&
                              formik.errors.last_name
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="last_name"
                            value={formik?.values.last_name}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched('last_name', true, true);
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldTouched('last_name', true, true);
                            }}
                          />
                          {formik.touched.last_name &&
                            formik.errors.last_name && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.last_name}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Primary Contact Number / સંપર્ક નંબર
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            inputMode="numeric"
                            className={`form-control ${
                              formik.touched.primary_contact_number &&
                              formik.errors.primary_contact_number
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="primary_contact_number"
                            disabled
                            value={formik?.values.primary_contact_number}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (/^\d{0,10}$/.test(value)) {
                                formik.handleChange(e);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            maxLength={10}
                          />
                          {formik.touched.primary_contact_number &&
                            formik.errors.primary_contact_number && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.primary_contact_number}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            E-mail / ઈ-મેલ આઈડી
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="email"
                            className={`form-control ${
                              formik.touched.email_id && formik.errors.email_id
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="email_id"
                            value={formik?.values.email_id}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.email_id &&
                            formik.errors.email_id && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.email_id}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            Address / સરનામું
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.office_address &&
                              formik.errors.office_address
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="office_address"
                            value={formik?.values.office_address}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.office_address &&
                            formik.errors.office_address && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.office_address}
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    {/* <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Start Date / સેવા કેમ્પ શરુ કરવાની તારીખ{" "}
                            <span style={{ color: "#ae0015" }}>*</span>
                          </label>
                          <input
                            type="date"
                            className={`form-control ${
                              formik.touched.start_date &&
                              formik.errors.start_date
                                ? "is-invalid"
                                : ""
                            }`}
                            name="start_date"
                            value={formik?.values.start_date}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched("start_date", true, true);
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldTouched("start_date", true, true);
                            }}
                            max="2024-09-18"
                            min="2024-09-12"
                          />
                          {formik.touched.start_date &&
                            formik.errors.start_date && (
                              <div style={{ color: "#ae0015" }}>
                                {formik.errors.start_date}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            End Date / સેવા કેમ્પ પૂર્ણ કરવાની તારીખ{" "}
                            <span style={{ color: "#ae0015" }}>*</span>
                          </label>
                          <input
                            type="date"
                            // placeholder="End Date / સેવા કેમ્પ પૂર્ણ કરવાની તારીખ"
                            className={`form-control ${
                              formik.touched.end_date && formik.errors.end_date
                                ? "is-invalid"
                                : ""
                            }`}
                            name="end_date"
                            value={formik?.values.end_date}
                            onChange={(e) => {
                              formik.handleChange(e);
                              formik.setFieldTouched("end_date", true, true); // Ensure field is marked as touched
                            }}
                            onBlur={(e) => {
                              formik.handleBlur(e);
                              formik.setFieldTouched("end_date", true, true); // Ensure field is marked as touched
                            }}
                            max="2024-09-18"
                            min={formik.values.start_date || "2024-09-12"}
                          />
                          {formik.touched.end_date &&
                            formik.errors.end_date && (
                              <div style={{ color: "#ae0015" }}>
                                {formik.errors.end_date}
                              </div>
                            )}
                        </div>
                      </div>
                    </div> */}

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Vehicle Category / વાહન શ્રેણી
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <select
                            className={`form-control ${
                              formik.touched.vehicle_category &&
                              formik.errors.vehicle_category
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="vehicle_category"
                            value={formik.values.vehicle_category}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          >
                            <option value="" disabled>
                              Select Vehicle Category
                            </option>
                            <option value="Two Wheeler">Two Wheeler</option>
                            <option value="Four Wheeler">Four Wheeler</option>
                            <option value="Others">Others</option>
                          </select>
                          {formik.touched.vehicle_category &&
                            formik.errors.vehicle_category && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.vehicle_category}
                              </div>
                            )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Vehicle Number / વાહન નંબર
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${
                              formik.touched.vehicle_number &&
                              (formik.errors.vehicle_number || vehicleError)
                                ? 'is-invalid'
                                : ''
                            }`}
                            name="vehicle_number"
                            value={formik?.values.vehicle_number}
                            onChange={(e) => {
                              const { name, value } = e.target;
                              const uppercasedValue = value.toUpperCase(); // Convert to uppercase
                              formik.setFieldValue(name, uppercasedValue); // Update Formik's value
                              const error =
                                validateVehicleNumber(uppercasedValue); // Validate the uppercase value
                              setVehicleError(error || ''); // Update error state
                            }}
                            onBlur={formik.handleBlur}
                          />
                          {(formik.touched.vehicle_number &&
                            formik.errors.vehicle_number) ||
                          vehicleError ? (
                            <div style={{ color: '#ae0015' }}>
                              {formik.errors.vehicle_number || vehicleError}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Id Proof / આઈડી પ્રૂફ
                            <span style={{ color: '#ae0015' }}>*</span> <br />
                            (Supported Format .jpg, .png, .jpeg, .pdf)
                          </label>
                          <input
                            type="file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            ref={imgref1}
                            className="form-control"
                            name="employee_doc"
                            onChange={(event) => fileChange(event, 1)}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.employee_doc &&
                            formik.errors.employee_doc && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.employee_doc}
                              </div>
                            )}
                          {filePreview1 && (
                            <div
                              style={{
                                marginTop: '10px',
                                position: 'relative'
                              }}
                            >
                              {pdf && pdf.toLowerCase().endsWith('.pdf') ? (
                                <div>
                                  <iframe
                                    src={filePreview1}
                                    title="PDF Viewer"
                                    frameBorder="0"
                                    scrolling="auto"
                                    height="300px"
                                    width="100%"
                                    onError={(e) =>
                                      console.error('Error loading PDF:', e)
                                    }
                                  ></iframe>
                                  <RxCross1
                                    style={{
                                      position: 'absolute',
                                      color: '#ae0015',
                                      fontSize: '1.5em',
                                      cursor: 'pointer',
                                      top: '4px',
                                      right: '4px',
                                      background: '#fff'
                                    }}
                                    onClick={() => {
                                      imgref1.current.value = '';
                                      setFile2();
                                      setFilePreview1();
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    position: 'relative',
                                    height: '125px',
                                    width: '125px'
                                  }}
                                >
                                  <img
                                    src={filePreview1}
                                    alt="File Preview"
                                    style={{
                                      height: '125px',
                                      width: '125px',
                                      objectFit: 'cover'
                                    }}
                                    onError={(e) =>
                                      console.error('Error loading image:', e)
                                    }
                                  />
                                  <RxCross1
                                    style={{
                                      position: 'absolute',
                                      color: '#ae0015',
                                      fontSize: '1.5em',
                                      cursor: 'pointer',
                                      top: '4px',
                                      right: '-30px',
                                      background: '#fff'
                                    }}
                                    onClick={() => {
                                      imgref1.current.value = '';
                                      setFile2();
                                      setFilePreview1();
                                    }}
                                  />
                                </div>
                              )}
                              <div>{fileName1}</div>{' '}
                              {/* Displaying the file name */}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label>
                            Vehicle RC Book / વાહન RC બુક
                            <span style={{ color: '#ae0015' }}>*</span>
                            <br /> (Supported Format .jpg, .png, .jpeg, .pdf)
                          </label>
                          <input
                            type="file"
                            accept=".jpg, .jpeg, .png, .pdf"
                            ref={imgref2}
                            className="form-control"
                            name="vehicle_doc"
                            onChange={(event) => fileChange1(event, 4)}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.vehicle_doc &&
                            formik.errors.vehicle_doc && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.vehicle_doc}
                              </div>
                            )}
                          {filePreview4 && (
                            <div
                              style={{
                                marginTop: '10px',
                                position: 'relative'
                              }}
                            >
                              {pdf1 && pdf1.toLowerCase().endsWith('.pdf') ? (
                                <div>
                                  <iframe
                                    src={filePreview4}
                                    title="PDF Viewer"
                                    frameBorder="0"
                                    scrolling="auto"
                                    height="300px"
                                    width="100%"
                                    onError={(e) =>
                                      console.error('Error loading PDF:', e)
                                    }
                                  ></iframe>
                                  <RxCross1
                                    style={{
                                      position: 'absolute',
                                      color: '#ae0015',
                                      fontSize: '1.5em',
                                      cursor: 'pointer',
                                      top: '4px',
                                      right: '4px',
                                      background: '#fff'
                                    }}
                                    onClick={() => {
                                      imgref2.current.value = '';
                                      setFile1();
                                      setFilePreview4();
                                    }}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    position: 'relative',
                                    height: '125px',
                                    width: '125px'
                                  }}
                                >
                                  <img
                                    src={filePreview4}
                                    alt="File Preview"
                                    style={{
                                      height: '125px',
                                      width: '125px',
                                      objectFit: 'cover'
                                    }}
                                    onError={(e) =>
                                      console.error('Error loading image:', e)
                                    }
                                  />
                                  <RxCross1
                                    style={{
                                      position: 'absolute',
                                      color: '#ae0015',
                                      fontSize: '1.5em',
                                      cursor: 'pointer',
                                      top: '4px',
                                      right: '-30px',
                                      background: '#fff'
                                    }}
                                    onClick={() => {
                                      imgref2.current.value = '';
                                      setFile1();
                                      setFilePreview4();
                                    }}
                                  />
                                </div>
                              )}
                              <div>{fileName1}</div>{' '}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <button
                      className="orangebtn"
                      // type="next"
                      type="submit"
                      style={{ width: '100%' }}
                    >
                      Register
                    </button>
                  </form>
                </div>
              </div>
            ) : null}
          </div>
        </div>{' '}
      </section>
      <Arasuri />
      <Footer />
    </div>
  );
};

export default SthanikVehiclePass;
