import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet-async";
import Footer from "../../../Components/footer";
import LazyLoader from "../../../Components/lazyLoader";
import temple1 from "../../../Assets/Images/Temple/temple1.png";
import ambajitemple from "../../../Assets/Images/Home_page/linear.png";
import PrayIcon from "../../../Assets/Images/Home_page/PrayIcon.svg";
import "./onlinePrasad.scss";
import OnlinePrasadService from "../../../Api_config/E_store/onlinePrasad";
import Arasuri from "../../../Components/arasuri";
import { routes } from "../../../_Constants/routes";

const OnlinePrasad = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    OnlinePrasadService.getPrasadAll()
      .then((res) => {
        // console.log(res.data);
        setProductData(res.data.data);
        setTimeout(() => {
          setLoading(false);
        }, [1000]);
      })
      .catch((error) => {
        navigate(routes?.error500);
      });
  }, []);
  return (
    <>
      <Helmet>
        <title>
          Buy Ambaji Mandir Mohanthal and Chikki Prasad Online Order Booking
        </title>
        <meta
          name="description"
          content="Order Ambaji Temple Prasad Online. Experience the divine with authentic Ambaji Mandir
Mohanthal and Chikki Prasad delivered directly to your doorstep. Enjoy the blessings of
Mataji through our convenient online Prasad ordering service. Experience live darshan, view
temple and aarti timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi
Puja, Dhaja and Yagna through our website."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details,
Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji,
Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar "
        />
        <meta
          name="author"
          content="Ambaji Temple and Gabbar Banaskantha Gujarat | BinTech Services Ahmedabad"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="online-prashad">
          <section className="header-banner shopOnline-banner">
            <div className="container">
              <h1>ઓનલાઈન પ્રસાદ ખરીદી કરો</h1>
            </div>
          </section>
          <section className="shop-page">
            <div className="container">
              <div className="row">
                {productData?.map((item) => {
                  return (
                    <div className="col-md-6 col-lg-4">
                      <div
                        className="societybox"
                        // onClick={() =>
                        //   navigate(`/online-prasad-detail/`, {
                        //     state: { id: item?.id },
                        //   })
                        // }
                        // onClick={() =>
                        //   navigate(`/online-prasad-detail/${item?.name}`, {
                        //     state: { id: item?.id },
                        //   })
                        // }
                      >
                        <div className="societybox-img">
                          <img
                            src={item.thumbnail_image}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <div className="societybox-body">
                          <h5>{item.name}</h5>
                          <p>
                            {item?.description?.length > 80
                              ? item?.description.substr(0, 80) + "..."
                              : item?.description}
                          </p>
                          <button
                            className="orangebtn"
                            onClick={() =>
                              navigate(
                                `/online-ambaji-temple-prasad-detail/${item?.name}/${item.id}`,
                                {
                                  state: { id: item?.id },
                                },
                              )
                            }
                          >
                            ખરીદી કરો
                          </button>
                          {/* <button className="orangebtn">
                            ટૂંક સમયમાં આવી રહ્યું છે
                          </button> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};
export default OnlinePrasad;
