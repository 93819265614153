import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const checkRegisteredNumber = (mobile_number) => {
  return http.get(
    `${api_urls?.getUser}?primary_contact_number=${mobile_number}`
  );
};

const checkoutGoldDonation = (requestData) => {
  return http.post(api_urls?.createGoldDonation, requestData);
};

const saveUser = (requestData) => {
  return http.post(api_urls?.createUser, requestData);
};

const getTransactionDetails = (id) => {
  return http.get(`${api_urls?.getGoldDonation}?id=${id}`);
};
const getCounterTransactionDetails = (id) => {
  return http.get(`${api_urls?.getGoldCounter}?id=${id}`);
};

const sendOTP = (primary_contact_number) => {
  return http.get(
    `${api_urls?.goldDonation_sendOTP}?contact_number=${primary_contact_number}`
  );
};

const verifyOTP = (primary_contact_number, otp) => {
  return http.get(
    `${api_urls?.goldDonation_verifyOTP}?contact_number=${primary_contact_number}&otp=${otp}`
  );
};

const GoldDonationService = {
  checkRegisteredNumber,
  checkoutGoldDonation,
  saveUser,
  getTransactionDetails,
  getCounterTransactionDetails,
  sendOTP,
  verifyOTP,
};

export default GoldDonationService;
