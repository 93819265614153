import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useFormik } from 'formik';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
// import GoldDonationService from "../../api-config/gold-donation";
// import MoneyDonationService from "../../api-config/money-donation-service";
import { ForgotPasswordSchema } from '../../_Constants/validations';
import Footer from '../../Components/footer';
import { toast } from 'react-toastify';
import './auth.css';
import { routes } from '../../_Constants/routes';
import Arasuri from '../../Components/arasuri';
import ForgotPasswordService from '../../Api_config/Auth/forgotPassword';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [otp, setOTP] = useState();
  const [seconds, setSeconds] = useState(30);
  const [isOTPSend, setOTPSend] = useState(false);
  const [isResend, setResend] = useState(false);
  const [isOTPVerified, setOTPVerified] = useState(false);
  const [isNumberEditable, setNumberEditable] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [userData, setUserData] = useState();

  const formik = useFormik({
    initialValues: {
      primary_contact_number: '',
      confirm_password: '',
      new_password: ''
    },
    // validationSchema: ForgotPasswordSchema,
    validateOnChange: true,
    onSubmit: (values) => {
      const requestData = {
        id: userData?.id,
        password: values?.new_password
      };
      ForgotPasswordService.forgotPassword(requestData).then(
        (res) => {
          if (res.data.success) {
            navigate(routes?.login);
          }
        },
        (error) => {
          // console.log(error)
        }
      );
    }
  });

  const checkForContactNumber = (event) => {
    if (formik?.values?.primary_contact_number) {
      ForgotPasswordService.checkRegisteredNumber(
        formik?.values?.primary_contact_number
      ).then((res) => {
        if (res.data.success) {
          setNumberEditable(false);
          setUserData(res.data.data[0]);
          // MoneyDonationService.sendOTP(formik?.values?.primary_contact_number).then(res => {
          //     if (res.data.success) {
          //         setOTPSend(true);
          //         setSeconds(30);
          //     }
          // }).catch((error) => {
          //     toast.error(error.response?.data?.message, { autoClose: 1500 });
          //   navigate(routes?.error500);
          // });
          ForgotPasswordService.sendOTP(formik?.values?.primary_contact_number)
            .then((res) => {
              if (res.data.success) {
                setOTPSend(true);
                setSeconds(30);
              }
            })
            .catch((error) => {
              toast.error(error.response?.data?.message, { autoClose: 1500 });
              navigate(routes?.error500);
            });
        }
      });
    }
  };

  /**
   * Resend OTP
   * @param primary_contact_number
   */
  const resendOTP = () => {
    // MoneyDonationService.sendOTP(formik?.values?.primary_contact_number).then(res => {
    //     if (res.data.success) {
    //         setOTPSend(true);
    //         setSeconds(30);
    //     }
    // }).catch((error) => {
    //        navigate(routes?.error500);
    //     toast.error(error.response?.data?.message, { autoClose: 1500 });
    // });
    ForgotPasswordService.sendOTP(formik?.values?.primary_contact_number)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message, { autoClose: 1500 });
        navigate(routes?.error500);
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const verifyOTP = () => {
    if (otp?.length === 6) {
      // MoneyDonationService.verifyOTP(formik?.values?.primary_contact_number, otp)
      //     .then((res) => {
      //         if (res.data.success) {
      //             setOTPVerified(true)
      //         }
      //     })
      //     .catch((error) => {
      //        navigate(routes?.error500);
      //         toast.error(error.response?.data?.message, { autoClose: 1500 });
      //     });
      ForgotPasswordService.verifyOTP(
        formik?.values?.primary_contact_number,
        otp
      )
        .then((res) => {
          if (res.data.success) {
            setOTPVerified(true);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    }
  };

  return (
    <>
      <section className="header-banner contactus-banner">
        <div className="container">
          <h1>Reset Password</h1>
        </div>
      </section>
      <div className="auth">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-8 col-md-10 col-sm-12 col-12">
              <div className="card">
                <form action="POST" onSubmit={formik.handleSubmit}>
                  <div className="row align-items-end">
                    <div className="col-12 col-md-8 form-group">
                      <label htmlFor="primary_contact_number">
                        Mobile Number <span className="red-text">*</span>
                      </label>
                      <input
                        type="text"
                        inputMode="numeric"
                        name="primary_contact_number"
                        id="primary_contact_number"
                        className="form-control"
                        onChange={formik.handleChange}
                        disabled={!isNumberEditable}
                      />
                      {formik.touched.primary_contact_number &&
                        formik.errors.primary_contact_number && (
                          <p className="red-text">
                            {formik.errors.primary_contact_number}
                          </p>
                        )}
                    </div>
                    <div className="col-12 col-md-4 form-group">
                      {isNumberEditable ? (
                        <button
                          type="button"
                          className="whitebtn"
                          onClick={() => checkForContactNumber()}
                        >
                          Verify Number
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="whitebtn"
                          onClick={() => [
                            setNumberEditable(true),
                            setOTPVerified(false),
                            setOTP('')
                          ]}
                        >
                          Edit Number
                        </button>
                      )}
                    </div>
                  </div>
                  {!isOTPVerified && !isNumberEditable && (
                    <div className="row align-items-end">
                      <div className="col-xl-5 col-lg-3 col-md-3 form-group">
                        <label htmlFor="otp">OTP</label>

                        <input
                          type="text"
                          inputMode="numeric"
                          minLength="6"
                          maxLength="6"
                          pattern="[0-9]*" // Only allow numeric input
                          name="otp"
                          id="otp"
                          className="form-control"
                          placeholder="_ _ _ _ _ _"
                          value={otp}
                          onChange={(event) => {
                            const inputValue = event.target.value.replace(
                              /\D/g,
                              ''
                            ); // Remove non-digit characters
                            setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                            setResend(false); // Reset the resend state if needed
                          }}
                        />
                      </div>

                      <div className="col-xl-7 col-lg-9 col-md-9 d-flex">
                        <div className="form-group">
                          <button
                            type="button"
                            className="orangebtn"
                            disabled={otp?.length !== 6}
                            onClick={() => verifyOTP()}
                          >
                            Verify OTP
                          </button>
                          {!isOTPVerified && isOTPSend && seconds == '00' && (
                            <button
                              type="button"
                              className="whitebtn ms-1"
                              onClick={() => [
                                resendOTP(),
                                setOTP(''),
                                setResend(true)
                              ]}
                            >
                              Resend OTP
                            </button>
                          )}
                        </div>
                      </div>
                      {!isOTPVerified && isOTPSend && seconds > 0 ? (
                        <span>
                          Resend OTP in
                          {seconds < 10 ? ` 0${seconds}` : ` ${seconds}`} sec
                        </span>
                      ) : (
                        <span></span>
                      )}
                      {!isResend && (otp?.length > 6 || otp?.length < 6) && (
                        <p className="red-text">
                          OTP must be a 6 digits number
                        </p>
                      )}
                    </div>
                  )}
                  {isOTPVerified && (
                    <fieldset>
                      <div className="form-group">
                        <label htmlFor="new_password">
                          New Password <span className="red-text">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type={showPassword ? 'text' : 'password'}
                            name="new_password"
                            id="new_password"
                            className="form-control"
                            onChange={formik.handleChange}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              {showPassword ? (
                                <FaEyeSlash
                                  onClick={() => setShowPassword(false)}
                                />
                              ) : (
                                <FaEye onClick={() => setShowPassword(true)} />
                              )}
                            </span>
                          </div>
                        </div>
                        {formik.touched.new_password &&
                          formik.errors.new_password && (
                            <p className="red-text">
                              {formik.errors.new_password}
                            </p>
                          )}
                      </div>
                      <div className="form-group">
                        <label htmlFor="confirm_password">
                          Confirm Password <span className="red-text">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type={showConfirmPassword ? 'text' : 'password'}
                            name="confirm_password"
                            id="confirm_password"
                            className="form-control"
                            onChange={formik.handleChange}
                          />
                          <div className="input-group-append">
                            <span className="input-group-text">
                              {showConfirmPassword ? (
                                <FaEyeSlash
                                  onClick={() => setShowConfirmPassword(false)}
                                />
                              ) : (
                                <FaEye
                                  onClick={() => setShowConfirmPassword(true)}
                                />
                              )}
                            </span>
                          </div>
                        </div>
                        {formik.touched.confirm_password &&
                          formik.errors.confirm_password && (
                            <p className="red-text">
                              {formik.errors.confirm_password}
                            </p>
                          )}
                        {/* {console.log(
                      formik?.values?.new_password,
                      formik?.values?.confirm_password,
                    )} */}
                        {formik.values?.confirm_password?.length > 2 &&
                          formik?.values?.new_password !=
                            formik?.values?.confirm_password && (
                            <p className="red-text">
                              Passwords don't match. Please try again.
                            </p>
                          )}
                      </div>
                      <div className="form-footer mt-3">
                        <button
                          type="submit"
                          className="orangebtn"
                          disabled={
                            !formik?.isValid ||
                            formik?.values?.new_password !=
                              formik?.values?.confirm_password
                          }
                        >
                          Reset Password
                        </button>
                      </div>
                    </fieldset>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};
export default ForgotPassword;
