import React, { useRef, useState, useEffect } from 'react';
import '../../Home/home.scss';

import './bhadarvipoonamreceiptdownload.scss';
import LazyLoader from '../../../Components/lazyLoader';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import Select from 'react-select';
import { useFormik } from 'formik';
import SanghService from '../../../Api_config/Bhadarvi_poonam/sangh';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import SevaCampService from '../../../Api_config/Bhadarvi_poonam/sevaCamp';
import { SevaCampSchema } from '../../../_Constants/validations';
import { formatDate } from '../../Donation/Converter/date';
import { useReactToPrint } from 'react-to-print';
import SignatureService from '../../../Api_config/Home_page/signature';
import { useHeading } from '../../Bhadarvi_poonam/header'; // Import the custom hook

const BhadarviPoonamReceiptDownload = (props) => {
  const { data } = props;
  const location = useLocation();
  const heading = useHeading(); // Call the custom hook

  const [selectedType, setSelectedType] = useState('');
  // const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [otp, setOTP] = useState();
  const [seconds, setSeconds] = useState(30);
  const [isResend, setResend] = useState(false);
  const [isOTPSend, setOTPSend] = useState(false);
  const [isShowForm, setShowForm] = useState(false);
  const [isNumberRegistered, setNumberRegistered] = useState(false);
  const [isNumberEditable, setNumberEditable] = useState(true);
  const [userData, setUserData] = useState();
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState('');
  const [error, setError] = useState('');
  const [sevacampData, setSevaCampData] = useState();
  const componentRef = useRef();
  const formattedDate = formatDate(sevacampData?.applied_date);
  const [sanghData, setSanghData] = useState();
  const formattedDateforsangh = formatDate(sanghData?.createdAt);
  const [signatureData, setSignatureData] = useState();
  const [termsConditions, setTermsConditions] = useState([]);
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [isOTPVerifiedforsangh, setIsOTPVerifiedSangh] = useState(false);
  // const [selection, setSelection] = useState('');
  const [termsConditionsforsangh, setTermsConditionsforsangh] = useState([]);

  const qrUrl = sevacampData?.qr_url;
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Seva Booking Receipt',
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    }
  });
  const handleSelectChange = (selectedOption) => {
    setNumberEditable(true);
    // setNumberRegistered(false);
    //
    setIsOTPVerifiedSangh(false);
    setIsOTPVerified(false);
    //
    setSelectedType(selectedOption.value);
  };

  const filteredTermsConditions = termsConditions.filter(
    (term) => term.seva_camp === true
  );
  const filteredTermsConditionsforsangh = termsConditionsforsangh.filter(
    (term) => term.sangh === true
  );
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTermsConditions = async () => {
      try {
        const res = await SevaCampService.getTC();
        // Adjust mapping based on actual response structure
        setTermsConditions(res.data.data); // Assuming response.data.data is an array of terms
        setLoading(false);
      } catch (e) {
        setError('Failed to fetch terms and conditions.');
        setLoading(false);
      }
    };

    fetchTermsConditions();
  }, []);
  useEffect(() => {
    const filteredTermsConditionsforsangh = async () => {
      try {
        const res = await SevaCampService.getTC();
        // Adjust mapping based on actual response structure
        setTermsConditionsforsangh(res.data.data); // Assuming response.data.data is an array of terms
        setLoading(false);
      } catch (e) {
        setError('Failed to fetch terms and conditions.');
        setLoading(false);
      }
    };

    filteredTermsConditionsforsangh();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  /**
   * Verify OTP for seva
   * @param representative_primary_contact_number
   */
  const verifyOTPforseva = () => {
    const verificationSuccessful = true; // Replace with your actual verification result
    const representative_primary_contact_number = location?.pathname
      ?.split('/')
      .pop();
    if (otp?.length === 6) {
      formik.setFieldValue('first_name', '');
      formik.setFieldValue('last_name', '');
      formik.setFieldValue('email_id', '');
      formik.setFieldValue('address_line_1', '');
      formik.setFieldValue('address_line_2', '');
      formik.setFieldValue('city_id', '');
      formik.setFieldValue('country_id', '');
      formik.setFieldValue('province_id', '');
      formik.setFieldValue('zipcode', '');
      formik.setFieldValue('pan_number', '');
      // formik?.resetForm()

      SevaCampService.verifyOTP(
        formik?.values?.representative_primary_contact_number,
        otp
      )
        .then((res) => {
          if (res.data.success) {
            if (userData) {
              setIsOTPVerified(true);
              SevaCampService.getAllCampforcontact(
                Number(formik?.values?.representative_primary_contact_number)
              )
                .then((res) => {
                  if (res.data.success) {
                    setSevaCampData(res.data.data[0]);
                    // Handle the successful response here, e.g., update state or display data
                  } else {
                    // Handle the case where no data is found or the response is unsuccessful
                    // console.log("No data found or unsuccessful response");
                  }
                })
                .catch((e) => {
                  // Handle errors here
                  // console.error("Error fetching data:", e);
                });
              // setOpenModal(true);
            }
            setShowForm(true);
            setIsOTPVerified(true);
            // setOTPVerified(true);
          }
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    }
  };
  /**
   * Verify OTP for sangh
   * @param primary_contact_number
   */
  const verifyOTPforsangh = () => {
    const verificationSuccessful = true; // Replace with your actual verification result
    const primary_contact_number = location?.pathname?.split('/').pop();
    if (otp?.length === 6) {
      formik.setFieldValue('first_name', '');
      formik.setFieldValue('last_name', '');
      formik.setFieldValue('email_id', '');
      formik.setFieldValue('address_line_1', '');
      formik.setFieldValue('address_line_2', '');
      formik.setFieldValue('city_id', '');
      formik.setFieldValue('country_id', '');
      formik.setFieldValue('province_id', '');
      formik.setFieldValue('zipcode', '');
      formik.setFieldValue('pan_number', '');
      // formik?.resetForm()

      SanghService.verifyOTP(formik?.values?.primary_contact_number, otp)
        .then((res) => {
          if (res.data.success) {
            if (userData) {
              setIsOTPVerifiedSangh(true);
              SanghService.getAllSanghforallcontact(
                Number(formik?.values?.primary_contact_number)
              )
                .then((res) => {
                  if (res.data.success) {
                    setSanghData(res.data.data[0]);
                    // Handle the successful response here, e.g., update state or display data
                  } else {
                    // Handle the case where no data is found or the response is unsuccessful
                    // console.log("No data found or unsuccessful response");
                  }
                })
                .catch((e) => {
                  // Handle errors here
                  // console.error("Error fetching data:", e);
                });
              // setOpenModal(true);
            }
            setShowForm(true);
            setIsOTPVerifiedSangh(true);
            // setOTPVerified(true);
          }
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    }
  };
  /**
   * Resend OTP for seva
   * @param representative_primary_contact_number
   */
  const resendOTPseva = () => {
    SevaCampService.sendOTP(
      formik?.values?.representative_primary_contact_number
    )
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };
  const formik = useFormik({
    initialValues: {
      representative_primary_contact_number:
        data?.representative_primary_contact_number ?? '',
      primary_contact_number: data?.primary_contact_number ?? ''
    },
    validationSchema: SevaCampSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true
  });

  // number registered or not for seva
  const checkForContactNumberforseva = (event) => {
    if (formik?.values?.representative_primary_contact_number) {
      SevaCampService.getAllCampforcontact(
        formik?.values?.representative_primary_contact_number
      ).then((res) => {
        if (res.data.success) {
          if (res.data.data.length === 0) {
            // setError("This contact number is already registered.");
            toast.error('This contact number is not registered.', {
              autoClose: 1500
            });
          } else {
            setNumberRegistered(true);
            setNumberEditable(false);
            setUserData(res.data.data[0]);
            setVerifiedPhoneNumber(
              formik?.values.representative_primary_contact_number
            );

            SevaCampService.sendOTP(
              formik?.values?.representative_primary_contact_number
            )
              .then((res) => {
                if (res.data.success) {
                  setOTPSend(true);
                  setSeconds(30);
                }
              })
              .catch((error) => {
                toast.error(error.response?.data?.message, {
                  autoClose: 1500
                });
              });
          }
        }
      });
    }
  };
  // number registered or not for sangh
  const checkForContactNumberforsangh = (event) => {
    if (formik?.values?.primary_contact_number) {
      SanghService.getAllSanghforallcontact(
        formik?.values?.primary_contact_number
      ).then((res) => {
        if (res.data.success) {
          if (res.data.data.length === 0) {
            // setError("This contact number is already taken.");
            // toast.error("This contact number is already taken.", {
            //   autoClose: 1500,
            // });
            toast.error('This contact number is not registered.', {
              autoClose: 1500
            });
          } else {
            setNumberRegistered(true);
            setNumberEditable(false);
            setUserData(res.data.data[0]);
            setVerifiedPhoneNumber(formik?.values?.primary_contact_number);

            SanghService.sendOTP(formik?.values?.primary_contact_number)
              .then((res) => {
                if (res.data.success) {
                  setOTPSend(true);
                  setSeconds(30);
                }
              })
              .catch((error) => {
                toast.error(error.response?.data?.message, {
                  autoClose: 1500
                });
              });
          }
        }
      });
    }
  };
  const { handleChange, handleSubmit } = formik;
  /**
   * Resend OTP for sangh
   * @param primary_contact_number
   */
  const resendOTPsangh = () => {
    SanghService.sendOTP(formik?.values?.primary_contact_number)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };

  let statusMessage = '';
  if (sevacampData?.Status?.name === 'Approved') {
    statusMessage = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration.
            <br />
            Your Seva Camp details have been approved.
          </p>
        </td>
      </tr>
    );
  } else if (sevacampData?.Status?.name === 'Rejected') {
    statusMessage = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration. <br />
            Unfortunately, your Seva Camp details have been rejected.
          </p>
        </td>
      </tr>
    );
  } else {
    statusMessage = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration.
            <br /> Your Seva Camp details are under review.
          </p>
        </td>
      </tr>
    );
  }
  let statusMessageforsang = '';
  if (sanghData?.Status?.name === 'Approved') {
    statusMessageforsang = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration.
            <br />
            Your Sangh details have been approved.
          </p>
        </td>
      </tr>
    );
  } else if (sanghData?.Status?.name === 'Rejected') {
    statusMessageforsang = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration. <br />
            Unfortunately, your Sangh details have been rejected.
          </p>
        </td>
      </tr>
    );
  } else {
    statusMessageforsang = (
      <tr className="rs-note-service">
        <td align="center">
          <p style={{ color: '#ae0015' }}>
            Thank you for your registration.
            <br /> Your Sangh details are under review.
          </p>
        </td>
      </tr>
    );
  }
  // year dyanmic
  const currentYear = new Date().getFullYear();
  // document.getElementById("currentYear").textContent = currentYear;
  return (
    <>
      {!isLoading ? (
        <>
          <section className="header-banner temple-banner">
            <div className="container">{heading}</div>
          </section>
          <section className="receipt-download bhadarvipoonamreceipt">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Registration Type</label>
                    <Select
                      name="form-type"
                      options={[
                        { value: 'Seva Camp', label: 'Seva Camp' },
                        { value: 'Sangh', label: 'Sangh' }
                      ]}
                      isSearchable={false}
                      onChange={handleSelectChange}
                    />
                  </div>
                </div>
                {/* Contact for seva-camp */}
                {selectedType === 'Seva Camp' && !isOTPVerified && (
                  <div className="row">
                    <div className="form-group">
                      <div className="row" style={{ rowGap: '10px' }}>
                        <div className="col-xl-7 col-lg-7 col-md-10 col-sm-12">
                          <div
                            className="row align-items-end"
                            style={{ rowGap: '10px' }}
                          >
                            <div className="col-xl-6 col-lg-6 col-md-7">
                              <label>
                                Organiser's Phone Number / આયોજકનો સંપર્ક નંબર
                              </label>
                              <input
                                type="text"
                                inputMode="numeric"
                                placeholder="Contact Number"
                                className="form-control"
                                name="representative_primary_contact_number"
                                value={
                                  formik?.values
                                    .representative_primary_contact_number
                                }
                                // onChange={handleChange}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Allow only digits and limit length to 10
                                  if (/^\d{0,10}$/.test(value)) {
                                    handleChange(e);
                                  }
                                }}
                                disabled={!isNumberEditable}
                                maxLength="10"
                                pattern="\d{10}"
                                required
                              />
                              {/* <p className="mb-1 red-text">
                                {formik?.errors?.representative_primary_contact_number}
                              </p> */}
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-5">
                              {isNumberEditable ? (
                                <button
                                  type="button"
                                  className="whitebtn"
                                  onClick={() => checkForContactNumberforseva()}
                                >
                                  Verify Number
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="whitebtn"
                                  onClick={() => [
                                    setNumberEditable(true),
                                    setNumberRegistered(false)
                                  ]}
                                >
                                  Edit Number
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isNumberRegistered && !isNumberEditable && !isShowForm && (
                      <div className="form-group">
                        <div
                          className="row align-items-end"
                          style={{ rowGap: '10px' }}
                        >
                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-7">
                            <label htmlFor="otp">OTP</label>
                            <input
                              type="text"
                              inputMode="numeric"
                              minLength="6"
                              maxLength="6"
                              pattern="[0-9]*" // Only allow numeric input
                              name="otp"
                              id="otp"
                              className="form-control"
                              placeholder="_ _ _ _ _ _"
                              value={otp}
                              onChange={(event) => {
                                const inputValue = event.target.value.replace(
                                  /\D/g,
                                  ''
                                ); // Remove non-digit characters
                                setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                                setResend(false); // Reset the resend state if needed
                              }}
                            />
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
                            <button
                              type="button"
                              className="orangebtn"
                              onClick={() => verifyOTPforseva()}
                              disabled={otp?.length !== 6}
                            >
                              Verify OTP
                            </button>

                            {isOTPSend && seconds == '00' && (
                              <button
                                type="button"
                                className="whitebtn ms-1"
                                onClick={() => [
                                  resendOTPseva(),
                                  setOTP(''),
                                  setResend(true)
                                ]}
                              >
                                Resend OTP
                              </button>
                            )}
                            {/* <button
                        type="button"
                        className="whitebtn ms-3"
                        // onClick={() => [resendOTP(), setOTP(""), setResend(true)]}
                      >
                        Resend OTP
                      </button> */}
                          </div>
                          {isOTPSend && seconds > 0 ? (
                            <p>
                              Resend OTP in
                              {seconds < 10
                                ? ` 0${seconds}`
                                : ` ${seconds}`}{' '}
                              sec
                            </p>
                          ) : (
                            <p></p>
                          )}
                          {!isResend &&
                            (otp?.length > 6 || otp?.length < 6) && (
                              <p className="red-text">
                                OTP must be a 6 digits number
                              </p>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {/* Conatct for sangh */}
                {selectedType === 'Sangh' && !isOTPVerified && (
                  <div className="row">
                    <div className="form-group">
                      <div className="row" style={{ rowGap: '10px' }}>
                        <div className="col-xl-7 col-lg-7 col-md-10 col-sm-12">
                          <div
                            className="row align-items-end"
                            style={{ rowGap: '10px' }}
                          >
                            <div className="col-xl-6 col-lg-6 col-md-7">
                              <label>
                                Organiser's Phone Number / આયોજકનો સંપર્ક નંબર
                              </label>
                              <input
                                type="text"
                                inputMode="numeric"
                                placeholder="Contact Number"
                                className="form-control"
                                name="primary_contact_number"
                                value={formik?.values.primary_contact_number}
                                // onChange={handleChange}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Allow only digits and limit length to 10
                                  if (/^\d{0,10}$/.test(value)) {
                                    handleChange(e);
                                  }
                                }}
                                disabled={!isNumberEditable}
                                maxLength="10"
                                pattern="\d{10}"
                                required
                              />
                              {/* <p className="mb-1 red-text">
                                {formik?.errors?.primary_contact_number}
                              </p> */}
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-5">
                              {isNumberEditable ? (
                                <button
                                  type="button"
                                  className="whitebtn"
                                  onClick={() =>
                                    checkForContactNumberforsangh()
                                  }
                                >
                                  Verify Number
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className="whitebtn"
                                  onClick={() => [
                                    setNumberEditable(true),
                                    setNumberRegistered(false)
                                  ]}
                                >
                                  Edit Number
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isNumberRegistered && !isNumberEditable && !isShowForm && (
                      <div className="form-group">
                        <div
                          className="row align-items-end"
                          style={{ rowGap: '10px' }}
                        >
                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-7">
                            <label htmlFor="otp">OTP</label>
                            <input
                              inputMode="numeric"
                              minLength="6"
                              maxLength="6"
                              pattern="[0-9]*" // Only allow numeric input
                              name="otp"
                              id="otp"
                              className="form-control"
                              placeholder="_ _ _ _ _ _"
                              value={otp}
                              onChange={(event) => {
                                const inputValue = event.target.value.replace(
                                  /\D/g,
                                  ''
                                ); // Remove non-digit characters
                                setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                                setResend(false); // Reset the resend state if needed
                              }}
                            />
                          </div>
                          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
                            <button
                              type="button"
                              className="orangebtn"
                              onClick={() => verifyOTPforsangh()}
                              disabled={otp?.length !== 6}
                            >
                              Verify OTP
                            </button>

                            {isOTPSend && seconds == '00' && (
                              <button
                                type="button"
                                className="whitebtn ms-1"
                                onClick={() => [
                                  resendOTPsangh(),
                                  setOTP(''),
                                  setResend(true)
                                ]}
                              >
                                Resend OTP
                              </button>
                            )}
                            {/* <button
                        type="button"
                        className="whitebtn ms-3"
                        // onClick={() => [resendOTP(), setOTP(""), setResend(true)]}
                      >
                        Resend OTP
                      </button> */}
                          </div>
                          {isOTPSend && seconds > 0 ? (
                            <p>
                              Resend OTP in
                              {seconds < 10
                                ? ` 0${seconds}`
                                : ` ${seconds}`}{' '}
                              sec
                            </p>
                          ) : (
                            <p></p>
                          )}
                          {!isResend &&
                            (otp?.length > 6 || otp?.length < 6) && (
                              <p className="red-text">
                                OTP must be a 6 digits number
                              </p>
                            )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </section>
          {/* Print Seva Camp */}
          {isOTPVerified && (
            <section>
              <div className="print-container" ref={componentRef}>
                <div className="pdf-btn">
                  <div className="container">
                    <div className="row">
                      <div className="detail-container">
                        <table width="100%">
                          <tr className="header-main">
                            <td
                              width="17%"
                              rowSpan="3"
                              align="center"
                              valign="middle"
                            >
                              <div className="main-logo">
                                <img
                                  src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                                  width="100"
                                  height="100"
                                />
                              </div>
                            </td>
                            <td width="83%">
                              <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                            </td>
                          </tr>

                          <tr className="header-main">
                            <td>
                              <p>
                                <strong>Ta.</strong> Danta, &nbsp;
                                <strong>Dist.</strong> Banaskantha, &nbsp;
                                Gujarat - 385110.
                              </p>
                              <p>
                                <strong>Phone No</strong> : 02749-262236 / +91
                                87996 00890
                              </p>
                              {/* <p>
                          <strong>PAN No </strong>: AACTS5457M &nbsp;
                          <strong>GST No</strong> : 24AACTS5457M2Z0
                        </p> */}
                              <p>
                                <strong>Email </strong>:
                                ambajitempletrust@gmail.com,&nbsp;
                                <strong>Website </strong>: www.ambajitemple.in
                              </p>
                            </td>
                          </tr>
                          <tr className="header-main"></tr>
                          <tr className="headig-text-1">
                            <td colSpan="2" align="center" valign="middle">
                              <h2>SEVA CAMP BOOKING RECEIPT</h2>
                            </td>
                          </tr>
                          <tr className="rs-note-service">
                            <td colSpan="2" align="center">
                              <p>
                                ભાદરવી પૂનમ મહામેળો 2024
                                <span>Date: 12/09/2024 to 18/09/2024</span>
                              </p>
                            </td>
                          </tr>
                          <tr className="rs-note">
                            <td colspan="2" align="center">
                              {statusMessage}
                            </td>
                          </tr>
                          <tr className="header-main">
                            <td colSpan="2" align="right">
                              <p>
                                <strong>Booking Date</strong> : {formattedDate}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" align="center">
                              <table width="100%" className="images-section-1">
                                <tr>
                                  <td>
                                    <strong>Seva Camp Booking Number</strong>
                                  </td>
                                  <td style={{ color: '#a30018' }}>
                                    <b>{sevacampData?.registration_number}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Booking Status</strong>
                                  </td>
                                  <td>{sevacampData?.Status?.name}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Seva Camp Name</strong>
                                  </td>
                                  <td>{sevacampData?.name}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Start Date</strong>
                                  </td>
                                  <td>{sevacampData?.start_date}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>End Date</strong>
                                  </td>
                                  <td>{sevacampData?.end_date}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Trust Name</strong>
                                  </td>
                                  <td>{sevacampData?.trust_name}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Head Person of Seva Camp</strong>
                                  </td>
                                  <td>{sevacampData?.representative_name}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Organizer’s Phone Number</strong>
                                  </td>
                                  <td>
                                    {
                                      sevacampData?.representative_primary_contact_number
                                    }
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Seva Camp Type</strong>
                                  </td>
                                  <td>
                                    {sevacampData?.SevaCampCategory?.name}
                                  </td>
                                </tr>
                              </table>
                            </td>
                          </tr>

                          <tr className="headig-text-1">
                            <td colSpan="2" align="center" valign="middle">
                              <h2>Vehicle Details</h2>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" align="center" valign="middle">
                              <table width="100%" className="images-section-1">
                                <tr>
                                  <td>
                                    <strong>Sr. No.</strong>
                                  </td>
                                  <td>
                                    <strong>Vehicle Type</strong>
                                  </td>
                                  <td>
                                    <strong>Vehicle Number</strong>
                                  </td>
                                </tr>
                                {sevacampData?.Vehicle1CategorySevaCamp && (
                                  <tr>
                                    <td>1.</td>
                                    <td>
                                      {
                                        sevacampData.Vehicle1CategorySevaCamp
                                          .name
                                      }
                                    </td>
                                    <td>{sevacampData.vehicle1_number}</td>
                                  </tr>
                                )}
                                {sevacampData?.Vehicle2CategorySevaCamp && (
                                  <tr>
                                    <td>2.</td>
                                    <td>
                                      {
                                        sevacampData.Vehicle2CategorySevaCamp
                                          .name
                                      }
                                    </td>
                                    <td>{sevacampData.vehicle2_number}</td>
                                  </tr>
                                )}
                                {/* <tr>
                            <td>3.</td>
                            <td>
                              {sevacampData?.Vehicle3CategorySevaCamp?.name}
                            </td>
                            <td>{sevacampData?.vehicle3_number}</td>
                          </tr>
                          <tr>
                            <td>4.</td>
                            <td>
                              {sevacampData?.Vehicle4CategorySevaCamp?.name}
                            </td>
                            <td>{sevacampData?.vehicle4_number}</td>
                          </tr> */}
                              </table>
                            </td>
                          </tr>

                          <tr
                            align="center"
                            valign="middle"
                            className="teg-line"
                          >
                            <td colSpan="2">
                              <table
                                cellSpacing="0"
                                cellPadding="0"
                                border="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr align="center">
                                    <td
                                      width="40%"
                                      valign="middle"
                                      className="barcode"
                                    >
                                      <img src={qrUrl} width="110" />
                                    </td>
                                    <td
                                      width="60%"
                                      valign="middle"
                                      className="sign"
                                    >
                                      {signatureData?.map((item) => {
                                        return (
                                          <>
                                            <img
                                              src={item.signature_image}
                                              // src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716962038848_MicrosoftTeams-image(7).png"
                                              width="110"
                                              height="auto"
                                            />
                                            <p>
                                              {item.name}
                                              <br />
                                              {item.designation}
                                            </p>
                                          </>
                                        );
                                      })}
                                      <p>
                                        Shree Arasuri Ambaji Mata Devasthan
                                        Trust Ambaji
                                        {/* વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                                  શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                                  અંબાજી */}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="page-break"></div>

                      <div
                        className="detail-container"
                        style={{ paddingTop: '50px' }}
                      >
                        <table width="100%">
                          <tr>
                            <td colSpan="2">
                              <div className="notice">
                                <h2>Instructions / સેવા કેમ્પ માટેની સૂચનાઓ</h2>
                                <div className="terms-conditions-content">
                                  {filteredTermsConditions.length ? (
                                    <ol>
                                      {filteredTermsConditions.map(
                                        (term, index) => (
                                          <li>{term.name}</li>
                                        )
                                      )}
                                    </ol>
                                  ) : (
                                    <p>No terms and conditions available.</p>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container text-center">
                <button className="btn orangebtn my-2" onClick={handlePrint}>
                  Receipt Download
                </button>
              </div>
            </section>
          )}
          {/* Print Sangh */}
          {isOTPVerifiedforsangh && (
            <section>
              <div className="print-container" ref={componentRef}>
                <div className="pdf-btn">
                  <div className="container">
                    <div className="row">
                      <div className="detail-container">
                        <table width="100%">
                          <tr className="header-main">
                            <td
                              width="17%"
                              rowSpan="3"
                              align="center"
                              valign="middle"
                            >
                              <div className="main-logo">
                                <img
                                  src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                                  width="100"
                                  height="100"
                                />
                              </div>
                            </td>
                            <td width="83%">
                              <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                            </td>
                          </tr>

                          <tr className="header-main">
                            <td>
                              <p>
                                <strong>Ta.</strong> Danta, &nbsp;
                                <strong>Dist.</strong> Banaskantha, &nbsp;
                                Gujarat - 385110.
                              </p>
                              <p>
                                <strong>Phone No</strong> : 02749-262236 / +91
                                87996 00890
                              </p>
                              {/* <p>
                          <strong>PAN No </strong>: AACTS5457M &nbsp;
                          <strong>GST No</strong> : 24AACTS5457M2Z0
                        </p> */}
                              <p>
                                <strong>Email </strong>:
                                ambajitempletrust@gmail.com,&nbsp;
                                <strong>Website </strong>: www.ambajitemple.in
                              </p>
                            </td>
                          </tr>

                          <tr className="header-main"></tr>
                          <tr className="headig-text-1">
                            <td colSpan="2" align="center" valign="middle">
                              <h2>SANGH BOOKING RECEIPT</h2>
                            </td>
                          </tr>
                          <tr className="rs-note-service">
                            <td colSpan="2" align="center">
                              <p>
                                ભાદરવી પૂનમ મહામેળો 2024
                                <span>Date: 12/09/2024 to 18/09/2024</span>
                              </p>
                            </td>
                          </tr>
                          <tr className="rs-note">
                            <td colspan="2" align="center">
                              {statusMessageforsang}
                            </td>
                          </tr>
                          <tr className="header-main">
                            <td colSpan="2" align="right">
                              <p>
                                <strong>Booking Date</strong> :{' '}
                                {formattedDateforsangh}
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" align="center">
                              <table width="100%" className="images-section-1">
                                <tr>
                                  <td>
                                    <strong>Sangh Booking Number</strong>
                                  </td>
                                  <td style={{ color: '#a30018' }}>
                                    <b> {sanghData?.registration_number}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Booking Status</strong>
                                  </td>
                                  <td>{sanghData?.Status?.name}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Organization Name</strong>
                                  </td>
                                  <td>{sanghData?.organization_name}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Organizer Name</strong>
                                  </td>
                                  <td>{sanghData?.organizer_name}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Phone Number</strong>
                                  </td>
                                  <td>{sanghData?.primary_contact_number}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Arrival Date</strong>
                                  </td>
                                  <td>{sanghData?.arrival_date}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>No. of People</strong>
                                  </td>
                                  <td>{sanghData?.no_of_people}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Route of Arrival</strong>
                                  </td>
                                  <td>{sanghData?.ArrivalRoute?.name}</td>
                                </tr>
                              </table>
                            </td>
                          </tr>

                          <tr className="headig-text-1">
                            <td colSpan="2" align="center" valign="middle">
                              <h2>Vehicle Details</h2>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" align="center" valign="middle">
                              <table width="100%" className="images-section-1">
                                <tr>
                                  <td>
                                    <strong>Sr. No.</strong>
                                  </td>
                                  <td>
                                    <strong>Vehicle Type</strong>
                                  </td>
                                  <td>
                                    <strong>Vehicle Number</strong>
                                  </td>
                                </tr>
                                {sanghData?.Vehicle1Category && (
                                  <tr>
                                    <td>1.</td>
                                    <td>{sanghData.Vehicle1Category.name}</td>
                                    <td>{sanghData.vehicle1_number}</td>
                                  </tr>
                                )}
                                {sanghData?.Vehicle2Category && (
                                  <tr>
                                    <td>2.</td>
                                    <td>{sanghData.Vehicle2Category.name}</td>
                                    <td>{sanghData.vehicle2_number}</td>
                                  </tr>
                                )}
                                {sanghData?.Vehicle3Category && (
                                  <tr>
                                    <td>3.</td>
                                    <td>{sanghData.Vehicle3Category.name}</td>
                                    <td>{sanghData.vehicle3_number}</td>
                                  </tr>
                                )}
                              </table>
                            </td>
                          </tr>

                          <tr
                            align="center"
                            valign="middle"
                            className="teg-line"
                          >
                            <td colSpan="2">
                              <table
                                cellSpacing="0"
                                cellPadding="0"
                                border="0"
                                width="100%"
                              >
                                <tbody>
                                  <tr align="center">
                                    <td
                                      width="40%"
                                      valign="middle"
                                      className="barcode"
                                    >
                                      <img src={qrUrl} width="110" />
                                    </td>
                                    <td
                                      width="60%"
                                      valign="middle"
                                      className="sign"
                                    >
                                      {signatureData?.map((item) => {
                                        return (
                                          <>
                                            <img
                                              src={item.signature_image}
                                              // src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716962038848_MicrosoftTeams-image(7).png"
                                              width="120"
                                              height="100"
                                            />
                                            <br />
                                            <p>
                                              {item.name}
                                              <br />
                                              {item.designation}
                                            </p>
                                          </>
                                        );
                                      })}
                                      <p>
                                        Shree Arasuri Ambaji Mata Devasthan
                                        Trust Ambaji
                                        {/* વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                                  શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                                  અંબાજી */}
                                      </p>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </div>

                      <div className="page-break"></div>

                      <div
                        className="detail-container"
                        style={{ paddingTop: '50px' }}
                      >
                        <table width="100%">
                          <tr>
                            <td colSpan="2">
                              {/* <div className="note-introduction"></div> */}
                              <div className="notice">
                                <h2>Instructions / સંઘ માટેની સૂચનાઓ</h2>
                                <div className="terms-conditions-content">
                                  {filteredTermsConditionsforsangh.length ? (
                                    <ol>
                                      {filteredTermsConditionsforsangh.map(
                                        (term, index) => (
                                          <li key={index}>{term.name}</li> // Adjust based on actual field name
                                        )
                                      )}
                                    </ol>
                                  ) : (
                                    <p>No terms and conditions available.</p>
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container text-center">
                <button className="btn orangebtn my-2" onClick={handlePrint}>
                  Receipt Download
                </button>
              </div>
            </section>
          )}
          <Arasuri />
          <Footer />
        </>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default BhadarviPoonamReceiptDownload;
