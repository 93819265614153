export const regex = {
  onlyAlphabet: /^[A-Za-z ]+$/,
  passwordPattern:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[',",.`~+=_-|#$@$!%*?&])[A-Za-z\d',",.`~+=_-|#$@$!%*?&].{6,20}/,
  emailPattern:
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)$/,
  username:
    /^(?=.{1,254}$)(?=.{1,64}@)[-!#$%&'*+0-9=?A-Z^_`a-z{|}~]+(\.[-!#$%&'*+0-9=?A-Z^_`a-z{|}~]+)*@[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?(\.[A-Za-z0-9]([A-Za-z0-9-]{0,61}[A-Za-z0-9])?)$/,
  phonePattern: /^(?:(?:\+|0{0,2})91(\s*\s*)?|[0]?)?[6789]\d{9}$/,
  onlyNumbers: /^[0-9]*\.?[0-9]{0,2}$/,
  alphanumeric: /^[a-zA-Z0-9_]*$/,
  naturalNumbers: /^[1-9]*$/,
  urlPattern:
    /[(http(s)?):(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  imagePattern: /\.(jpg|jpeg|png)$/,
  secondaryPhonePattern:
    /^(?!-)(\+\d{1,2}\s?)?1?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{3,4}$/,
  dateRegex: /^\d{2}\d{2}\d{4}$/,
  panNumber: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  secondaryPanNumber:
    /[a-zA-Z]{3}[PCHFATBLJG]{1}[a-zA-Z]{1}[0-9]{4}[a-zA-Z]{1}$/,
};

export const extension = {
  imageExtension: ["png", "PNG", "jpg", "JPG", "jpeg", "JPEG"],
};
