// fundamentals
import React, { useState, useEffect } from 'react';

// components
import Footer from '../../Components/footer';
import '../Home/home.scss';
import './news.scss';
import LazyLoader from '../../Components/lazyLoader';

// api-config
import NewsService from '../../Api_config/Home_page/news';

// images
// import temple1 from "../../Assets/Images/Home_page/temple1.png";
// import PrayIcon from "../../Assets/Images/Home_page/PrayIcon.svg";
// import ambajitemple from '../../Assets/Images/Home_page/linear.png';
import Arasuri from '../../Components/arasuri';

function News() {
  const [newsData, setNewsData] = useState();
  const [loading, setLoading] = useState(true);

  //   api integration
  useEffect(() => {
    // const id = 1;
    NewsService.getAllNews().then((res) => {
      // console.log(res.data);
      setNewsData(res.data.data);
      //   setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, [3000]);
    });
  }, []);

  return (
    <>
      {!loading ? (
        <div className="main-wrapper">
          {/* banner  */}
          <section className="header-banner temple-banner">
            <div className="container">
              <h1>સમાચાર</h1>
            </div>
          </section>
          {/* ------- */}

          {/* details  */}
          <div className="news">
            <div className="service-list">
              <div className="container">
                <div className="row">
                  {newsData?.map((item) => {
                    return (
                      <div className="col-md-4">
                        {/* <a href={`/news-details`}> */}
                        <a href={`/news-details/${item.id}`}>
                          <div className="servicebox-hed">
                            <h5> {item.name}</h5>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {/* ------- */}

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
}

export default News;
