import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import './bhadarvipoonamdonation.scss';
import { routes } from '../../../_Constants/routes';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import { formatDate } from '../../Donation/Converter/date';

import DonationService from '../../../Api_config/Bhadarvi_poonam/donation';
const BhadarviPoonamDonationSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState();
  const formattedDate = formatDate(transactionData?.createdAt);

  useEffect(() => {
    const id = location?.pathname?.split('/').pop();
    // console.log(location, Number(id));
    DonationService.getBhadarviDonationById(Number(id))
      .then((res) => {
        if (res.data.success) {
          setTransactionData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
      });
  }, []);

  const printPaymentReciept = () => {
    const id = location?.pathname?.split('/').pop();
    navigate(
      `/bhadarvi-poonam-donation-receipt/${id}`
      // navigate(`/bhadarvi-poonam-donation-receipt`, {
      //   state: { data: transactionData }
      // }
    );
  };

  return (
    <>
      <section className="header-banner bhadarvi-poonam-banner">
        <div className="container">
          <h1>Donation Successful</h1>
        </div>
      </section>
      <section className="auth">
        <div className="container">
          <div className="row justify-content-center">
            <div class="col-xl-8 col-lg-8 col-md-10 col-sm-12 col-12">
              <div class="card">
                <div class="bhadarvi-poonam-success">
                  <div className="check-icon">
                    <FaCheck />
                  </div>
                  <br />
                  <h3 className="text-center">Donation Successful</h3>
                  <br />
                  <div className="row" style={{ rowGap: '15px' }}>
                    <div className="col-4">
                      <b>Transaction Id :</b>
                    </div>
                    <div className="col-8" style={{ wordBreak: 'break-all' }}>
                      {transactionData?.donation_number}
                    </div>
                    <div className="col-4">
                      <b>Person Name :</b>
                    </div>
                    <div className="col-8">
                      {' '}
                      {transactionData?.first_name || transactionData?.last_name
                        ? `${transactionData?.first_name || ''} ${
                            transactionData?.last_name || ''
                          }`
                        : '-'}
                    </div>
                    <div className="col-4">
                      <b>Email id :</b>
                    </div>
                    <div className="col-8"> {transactionData?.email_id}</div>

                    <div className="col-4">
                      <b>Phone Number :</b>
                    </div>
                    <div className="col-8">
                      {transactionData?.primary_contact_number}
                    </div>

                    <div className="col-4">
                      <b>PAN Number :</b>
                    </div>
                    <div className="col-8"> {transactionData?.pan_number}</div>
                    <div className="col-4">
                      <b>Date of Transaction :</b>
                    </div>
                    <div className="col-8"> {formattedDate}</div>
                    <div className="col-4">
                      <b>Donation Purpose : </b>
                    </div>
                    <div className="col-8">
                      {transactionData?.BDonationCategory?.name}
                    </div>
                    <div className="col-4">
                      <b>Amount :</b>
                    </div>
                    <div className="col-8">₹ {transactionData?.amount}</div>
                  </div>
                  <div className="d-flex justify-content-end mt-4">
                    <button
                      className="btn btn-success"
                      onClick={() => printPaymentReciept()}
                    >
                      Download
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Arasuri />
      <Footer />
    </>
  );
};
export default BhadarviPoonamDonationSuccess;
