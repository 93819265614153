import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router";
import Modal from "react-modal";
import { FaTimes } from "react-icons/fa";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import UtilsService from "../../../Api_config/Home_page/utils";
import Footer from "../../../Components/footer";
import { payu_api_url, payu_key } from "../../../_Constants/api_urls";
import { routes } from "../../../_Constants/routes";
import "./checkout.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa";
import Arasuri from "../../../Components/arasuri";
import CheckoutService from "../../../Api_config/E_store/checkout";
import ProfileService from "../../../Api_config/Profile/userProfile";
import CartService from "../../../Api_config/E_store/cart";
import AddressDetailsService from "../../../Api_config/Profile/addressDetails";
import moment from "moment"; // Ensure you have moment.js installed and imported
import LazyLoader from "../../../Components/lazyLoader";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

const Checkout = () => {
  const myButtonRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [cartData, setCartData] = useState([]);
  const [subAmount, setSubAmount] = useState(0);
  const [addressData, setAddressData] = useState([]);
  const [activeAddress, setActiveAddress] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [userData, setUserData] = useState();
  const [payuRes, setpayuRes] = useState();
  const [showErrorMsg, setErrorMsg] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [deliveryCharge, setDeliveryCharge] = useState(0);
  const [expectedDate, setExpectedDate] = useState();
  const [loading, setLoading] = useState(true);
  const [isCartAvailable, setIsCartAvailable] = useState(false);

  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const formattedDate = expectedDate
    ? moment(expectedDate).format("YYYY-MM-DD")
    : null;

  // old work
  // useEffect(() => {
  //   if (localStorage.getItem("token")) {
  //     var cart_data;

  //     // cartData = CartService.getBuyNowData();
  //     console.log(location?.state);
  //     console.log(cart_data);
  //     console.log(location?.state?.isDirectBuy);
  //     if (location?.state?.isDirectBuy) {
  //       console.log(cart_data);
  //       // cartData = CartService.getBuyNowData();
  //     } else {
  //       cart_data = CartService.getCartData();
  //       console.log(cart_data);
  //       // cart_data = CartService.getBuyNowData();
  //     }
  //     if (cart_data?.length < 1) {
  //       navigate("/cart");
  //     }
  //     if (cart_data) {
  //       setCartData(cart_data);
  //     }
  //     // cart_data?.forEach((el) =>
  //     //   // setSubAmount((prev) => prev + el?.qty * el?.variant_data?.sell_rate),
  //     //   setSubAmount((prev) => prev + el?.quantity * el?.ProductsVariant?.mrp)
  //     // );
  //   } else {
  //     localStorage.setItem("previous_url", location?.pathname);
  //     navigate(routes?.login);
  //   }

  //   /**
  //    * Get Address
  //    */
  //   AddressDetailsService.getAddress()
  //     .then((res) => {
  //       if (res.data.success) {
  //         setAddressData(res.data.data);
  //         setActiveAddress(res.data.data[0]?.id);
  //       }
  //     })
  //     .catch((error) => {
  //        navigate(routes?.error500);
  //       console.log(error);
  //     });

  //   /**
  //    * Get User Data
  //    */
  //   ProfileService.getUserData()
  //     .then((res) => {
  //       if (res.data.success) {
  //         setUserData(res.data.data[0]);
  //       }
  //     })
  //     .catch((error) => {
  //        navigate(routes?.error500);
  //       console.log(error);
  //     });

  //   /**
  //    * Get Country List
  //    */
  //   UtilsService.getCountry().then(
  //     (res) => {
  //       if (res.data.success) {
  //         setCountryList(res.data.data);
  //       }
  //     },
  //     (error) => {
  //       console.log(error);
  //     }
  //   );
  //   if (cart_data) {
  //     let order_items = {
  //       order_items: cart_data,
  //       user_id: localStorage.getItem("user_id"),
  //       product_category_id: 2,
  //     };

  //     CheckoutService.calculateDeliveryCharge(order_items).then(
  //       (res) => {
  //         if (res.data.success) {
  //           setDeliveryCharge(res.data.deliveryCharges);
  //           console.log(res.data.deliveryCharges);
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  //   }
  // }, [
  //   setCartData,
  //   setZipcodeList,
  //   setProvinceList,
  //   setCityList,
  //   setAddressData,
  //   setCountryList,
  //   location?.state?.isDirectBuy,
  //   setDeliveryCharge,
  //   setActiveAddress,
  // ]);

  // new code

  const fetchData = () => {
    if (localStorage.getItem("token")) {
      const user_id = parseInt(localStorage.getItem("user_id"));
      const fetchData = async () => {
        let cart_data;
        if (location?.state?.isDirectBuy) {
          try {
            const res = await CartService.getPrasadcart();
            if (res.data.success) {
              cart_data = res.data.data;
              setCartData(cart_data);
            }
            // console.log(res.data);
          } catch (error) {
            // console.log(error);
          }
        } else {
          try {
            const res = await CartService.getCartData();
            if (res.data.success) {
              cart_data = res.data.data;
              setCartData(cart_data);
            }
          } catch (error) {
            // console.log(error);
          }
        }

        if (cart_data) {
          let subAmount = 0;
          cart_data.forEach((el) => {
            subAmount += el.quantity * el.ProductsVariant.sell_rate;
          });
          setSubAmount(subAmount);

          let order_items = {
            order_items: cart_data,
            user_id: user_id,
            product_category_id: 2,
            address_id: activeAddress,
          };

          // console.log(order_items);

          try {
            const res = await CheckoutService.calculateDeliveryCharge(
              order_items
            );
            if (res.data.success) {
              setDeliveryCharge(res.data.deliveryCharges);
            }
          } catch (error) {
            // console.log(error);
          }
        }

        try {
          const res = await AddressDetailsService.getAddress();
          if (res.data.success) {
            setAddressData(res.data.data);
            setActiveAddress(res.data.data[0]?.id);
          }
        } catch (error) {
          // console.log(error);
        }

        try {
          const res = await ProfileService.getUserData();
          if (res.data.success) {
            setUserData(res.data.data[0]);
          }
        } catch (error) {
          // console.log(error);
        }

        try {
          const res = await UtilsService.getCountry();
          if (res.data.success) {
            setCountryList(res.data.data);
          }
        } catch (error) {
          // console.log(error);
        }
      };

      fetchData();
    } else {
      localStorage.setItem("previous_url", location?.pathname);
      navigate("/login", {
        state: { isDirectBuy: location?.state?.isDirectBuy },
      });
      // navigate("/login");
    }
  };

  useEffect(() => {
    // const cartData = JSON.parse(localStorage.getItem("cartData"));
    // console.log(cartData);

    // if (cartData && cartData.length > 0) {
    //   console.log(cartData);

    //   setCartData(cartData);
    //   setIsCartAvailable(true);
    // } else {
    //   setIsCartAvailable(false);
    // }
    setTimeout(() => {
      fetchData();
    }, 1000);
    fetchData();
  }, [location?.state?.isDirectBuy, navigate]);
  // ---------
  // useEffect(
  //   (user_id) => {
  //     if (localStorage.getItem("token")) {
  //       let cart_data;
  //       console.log(location?.state?.isDirectBuy);

  //       // Get cart data based on isDirectBuy flag
  //       if (location?.state?.isDirectBuy) {
  //         console.log(location?.state?.isDirectBuy);

  //         // cart_data = CartService.getPrasadcart(user_id);
  //         CartService.getPrasadcart()
  //           .then((res) => {
  //             if (res.data.success) {
  //               cart_data = res.data.data;
  //               setCartData(res.data.data);
  //               console.log(cart_data);
  //             }
  //           })
  //           .catch((error) => {
  //        navigate(routes?.error500);
  //             console.log(error);
  //           });
  //       } else {
  //         CartService.getCartData()
  //           .then((res) => {
  //             if (res.data.success) {
  //               cart_data = res.data.data;
  //               setCartData(res.data.data);
  //               console.log(cart_data);
  //             }
  //           })
  //           .catch((error) => {
  //        navigate(routes?.error500);
  //             console.log(error);
  //           });
  //         // cart_data = CartService.getCartData();
  //       }

  //       // Check if cart data exists and has items
  //       // if (!cart_data || cart_data.length < 1) {
  //       //   navigate("/cart");
  //       // }

  //       if (cart_data) {
  //         setCartData(cart_data);

  //         // Calculate sub amount
  //         let subAmount = 0;
  //         cart_data.forEach((el) => {
  //           subAmount += el.quantity * el.ProductsVariant.mrp;
  //         });
  //         setSubAmount(subAmount);

  //         // Calculate delivery charge
  //         let order_items = {
  //           order_items: cart_data,
  //           user_id: parseInt(localStorage.getItem("user_id")),
  //           product_category_id: 2,
  //         };

  //         CheckoutService.calculateDeliveryCharge(order_items).then(
  //           (res) => {
  //             if (res.data.success) {
  //               setDeliveryCharge(res.data.deliveryCharges);
  //               console.log(res.data.deliveryCharges);
  //             }
  //           },
  //           (error) => {
  //             console.log(error);
  //           }
  //         );
  //       }

  //       // Get Address
  //       AddressDetailsService.getAddress()
  //         .then((res) => {
  //           if (res.data.success) {
  //             setAddressData(res.data.data);
  //             setActiveAddress(res.data.data[0]?.id);
  //           }
  //         })
  //         .catch((error) => {
  //        navigate(routes?.error500);
  //           console.log(error);
  //         });

  //       // Get User Data
  //       ProfileService.getUserData()
  //         .then((res) => {
  //           if (res.data.success) {
  //             setUserData(res.data.data[0]);
  //           }
  //         })
  //         .catch((error) => {
  //        navigate(routes?.error500);
  //           console.log(error);
  //         });

  //       // Get Country List
  //       UtilsService.getCountry().then(
  //         (res) => {
  //           if (res.data.success) {
  //             setCountryList(res.data.data);
  //           }
  //         },
  //         (error) => {
  //           console.log(error);
  //         }
  //       );
  //     } else {
  //       localStorage.setItem("previous_url", location?.pathname);
  //       navigate(routes?.login);
  //     }
  //   },
  //   [
  //     setCartData,
  //     setZipcodeList,
  //     setProvinceList,
  //     setCityList,
  //     setAddressData,
  //     setCountryList,
  //     location?.state?.isDirectBuy,
  //     setDeliveryCharge,
  //     setActiveAddress,
  //   ]
  // );
  // --------

  // useEffect(() => {
  //   console.log(cartData);
  //   if (cartData) {
  //     let order_items = { "order_items": cartData }
  //     console.log();
  //     CheckoutService.calculateDeliveryCharge(order_items).then(
  //       (res) => {
  //         if (res.data.success) {
  //           // setCountryList(res.data.data);
  //           console.log("data");
  //           setDeliveryCharge(res.data.deliveryCharges)
  //         }
  //       },
  //       (error) => console.log(error),
  //     );
  //   }
  // }, [setDeliveryCharge, setCartData]);
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email_id: "",
      primary_contact_number: "",
      primary_contact_code_id: 1,
      address_line_1: "",
      address_line_2: "",
      address_name: "",
      city_id: "",
      country_id: "",
      province_id: "",
      zipcode_id: "",
      expected_delivery_date: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log(values);
      if (!activeAddress) {
        setErrorMsg(true);
      } else {
        checkout(values);
      }
      // console.log(values)
    },
  });
  //   onSubmit: (values, { resetForm }) => {
  //     values.user_id = parseInt(localStorage.getItem("user_id"));
  //     AddressDetailsService.saveAddress(values)
  //       .then((res) => {
  //         if (res.data.success) {
  //           AddressDetailsService.getAddress().then((res) => {
  //             if (res.data.success) {
  //               setAddressData(res.data.data);
  //               resetForm();
  //             }
  //           });
  //         }
  //       })
  //       .catch((error) => toast.error(error.response.data.message));
  //   },
  // });

  const addressForm = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      primary_contact_number: "",
      primary_contact_code_id: 0,
      address_name: "",
      address_line_1: "",
      address_line_2: "",
      country_id: "",
      province_id: "",
      city_id: "",
      zipcode_id: "",
      user_id: "",
      // address_id: 0,
      // expected_delivery_date: "",
    },
    enableReinitialize: true,
    // onSubmit: (values, { resetForm }) => {
    //   Object.assign(values, {
    //     user_id: JSON.parse(localStorage.getItem("user_id")),
    //   });
    //   // console.log(values)
    //   AddressDetailsService.saveAddress(values)
    //     .then((res) => {
    //       if (res.data.success) {
    //         AddressDetailsService.getAddress();
    //         console
    //           .log(res.data.success)
    //           .then((res) => {
    //             if (res.data.success) {
    //               setAddressData(res.data.data);
    //               resetForm();
    //             }
    //           })
    //           .catch((error) => {
    //             // console.log(error);
    //        navigate(routes?.error500);
    //           });
    //       }
    //     })
    //     .catch((error) => toast.error(error.response.data.message));
    //   setModalOpen(false);
    // },

    onSubmit: (values, { resetForm }) => {
      // Function to convert specific fields to integer
      const convertToInt = (value) => parseInt(value, 10);

      // Convert specified fields to integers
      const formattedValues = {
        ...values,
        primary_contact_number: convertToInt(values.primary_contact_number),
        country_id: convertToInt(values.country_id),
        province_id: convertToInt(values.province_id),
        city_id: convertToInt(values.city_id),
        zipcode_id: convertToInt(values.zipcode_id),
        user_id: convertToInt(JSON.parse(localStorage.getItem("user_id"))), // Fetch and convert user_id
      };

      AddressDetailsService.saveAddress(formattedValues)
        .then((res) => {
          if (res.data.success) {
            AddressDetailsService.getAddress()
              .then((res) => {
                if (res.data.success) {
                  setAddressData(res.data.data);
                  resetForm();
                }
              })
              .catch((error) => {
                console.error(error);
                navigate(routes?.error500);
              });
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          toast.error(error.response.data.message);
        });

      setModalOpen(false);
    },
  });

  const getProvince = (event) => {
    UtilsService.getProvince(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  };

  const getCity = (event) => {
    UtilsService.getCity(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setCityList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  };

  // const getZipcodeByCity = (event) => {
  //   UtilsService.getZipcodeByCity(event?.target?.value ?? event)
  //     .then((res) => {
  //       if (res.data.success) {
  //         setZipcodeList(res.data.data);
  //       }
  //     })
  //     .catch((error) => console.log(error));
  // };

  const getZipcodeByCityDeliverable = (event) => {
    UtilsService.getZipcodeByCityDeliverable(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setZipcodeList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  };

  const checkout = () => {
    const requestData = {
      user_id: JSON.parse(localStorage.getItem("user_id")),
      product_category_id: 1,
      address_id: activeAddress,
      expected_delivery_date: formattedDate,
    };
    // console.log(requestData);
    CheckoutService.checkout(requestData)
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          // setpayuRes(res.data.data);
          // setTimeout(() => {
          //   if (myButtonRef.current) {
          //     myButtonRef.current.click();
          //   }
          // });
          const data = res.data.data;
          setpayuRes(data);

          // Create a form dynamically and submit it
          const form = document.createElement("form");
          form.method = "POST";
          form.action = payu_api_url;

          const fields = [
            { name: "key", value: payu_key },
            { name: "txnid", value: data.txnid },
            { name: "amount", value: data.amount },
            { name: "productinfo", value: data.productinfo },
            { name: "firstname", value: data.firstname },
            { name: "lastname", value: data.lastname },
            { name: "email", value: data.email },
            { name: "phone", value: "9198672387" },
            { name: "surl", value: data.surl },
            { name: "furl", value: data.furl },
            { name: "curl", value: data.curl },
            { name: "hash", value: data.hash },
            { name: "udf1", value: data.udf1 },
            { name: "udf2", value: data.udf2 },
            { name: "udf3", value: data.udf3 },
            { name: "udf4", value: data.udf4 },
            { name: "udf5", value: data.udf5 },
          ];

          fields.forEach((field) => {
            const input = document.createElement("input");
            input.type = "hidden";
            input.name = field.name;
            input.value = field.value;
            form.appendChild(input);
          });

          document.body.appendChild(form);
          form.submit();
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };

  const handleCheckboxChange = () => {
    setDatePickerVisible(!isDatePickerVisible);
  };

  const minSelectableDate = new Date();
  minSelectableDate.setDate(minSelectableDate.getDate() + 10);

  useEffect(() => {
    // Calculate the date 10 days from now
    const initialDate = new Date();
    initialDate.setDate(initialDate.getDate() + 10);

    // Set the initial date in the state
    setStartDate(initialDate);
  }, []);

  useEffect(() => {
    // Clear province and city fields when country is changed
    formik.setFieldValue("province_id", "");
    formik.setFieldValue("city_id", "");
  }, [formik.values.country_id]);

  useEffect(() => {
    // Clear city field when province is changed
    formik.setFieldValue("city_id", "");
  }, [formik.values.province_id]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  });

  const { handleChange, handleSubmit } = formik;
  if (loading) {
    return <LazyLoader />;
  }
  return (
    <div className="main-wrapper">
      <section className="header-banner shopOnline-banner">
        <div className="container">
          <h1>Checkout</h1>
        </div>
      </section>
      <section className="contact-page">
        <div className="container">
          <div className="row">
            <form onSubmit={handleSubmit}>
              <div className="contact-wrapper">
                <div className="contact-form">
                  <div className="address-container w-100s">
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        className="orangebtn"
                        onClick={() => setModalOpen(true)}
                      >
                        Add New Address
                      </button>
                    </div>
                    {/* <div className="row justify-content-between mb-4">
                      
                      {addressData?.map((item, i) => {
                        return (
                          <div className="col-12 col-lg-6 p-2">
                            <div
                              className={
                                "card p-3" +
                                (item?.id === activeAddress ? " active" : "")
                              }
                              key={i}
                              onClick={() => [
                                setActiveAddress(item?.id),
                                formik?.setValues(item),
                                getProvince(item?.country_id),
                                getCity(item?.province_id),
                                getZipcodeByCityDeliverable(item?.city_id),
                              ]}
                            >
                              <span className="d-flex justify-content-between align-items-center">
                                <h6 className="mb-2">{item?.address_name}</h6>
                                <input
                                  type="radio"
                                  checked={item?.id === activeAddress}
                                />
                              </span>
                              <p>{item?.first_name + " " + item?.last_name}</p>
                              <p>{item?.primary_contact_number}</p>
                              <p>{item?.address_line_1},</p>
                              <p>{item?.address_line_2}</p>
                              <p>{item?.address_name}</p>
                              <p>
                                {item?.City?.name}, {item?.Province?.name}-
                                {item?.ZipCode?.name}
                              </p>
                            </div>
                          </div>
                        );
                      })}
                    </div> */}

                    <div className="row justify-content-between mb-4">
                      {addressData?.length > 0 ? (
                        addressData.map((item, i) => {
                          return (
                            <div className="col-12 col-lg-6 p-2" key={i}>
                              <div
                                // className={
                                //   "card p-3" +
                                //   (item?.id === activeAddress ? " active" : "")
                                // }
                                className={`card p-3${
                                  item?.id === activeAddress ? " active" : ""
                                }`}
                                onClick={() => {
                                  setActiveAddress(item?.id);
                                  formik?.setValues(item);
                                  getProvince(item?.country_id);
                                  getCity(item?.province_id);
                                  getZipcodeByCityDeliverable(item?.city_id);
                                }}
                              >
                                <span className="d-flex justify-content-between align-items-center">
                                  <h6 className="mb-2">{item?.address_name}</h6>
                                  <input
                                    type="radio"
                                    checked={item?.id === activeAddress}
                                    // checked={
                                    //   activeAddress !== null &&
                                    //   item?.id === activeAddress
                                    // }
                                    readOnly
                                  />
                                </span>
                                <p>
                                  {item?.first_name + " " + item?.last_name}
                                </p>
                                <p>{item?.primary_contact_number}</p>
                                <p>{item?.address_line_1},</p>
                                <p>{item?.address_line_2}</p>
                                <p>{item?.address_name}</p>
                                <p>
                                  {item?.City?.name}, {item?.Province?.name}-
                                  {item?.ZipCode?.name}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-12">
                          <p className="red-text text-center">
                            Please add your address
                          </p>
                        </div>
                      )}
                    </div>

                    {/* <div>
                      {addressData?.length <= 0 && (
                        <p className="red-text text-center">
                          Please add your address
                        </p>
                      )}
                    </div> */}
                  </div>

                  <div className="row" hidden={true}>
                    <div className="col-12 col-md-6 form-group">
                      <label>First Name:</label>
                      <input
                        type="text"
                        placeholder="First Name"
                        className="form-control"
                        onChange={handleChange}
                        value={formik?.values?.first_name}
                        name="first_name"
                      ></input>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Last Name:</label>
                      <input
                        type="text"
                        placeholder="Last Name"
                        className="form-control"
                        name="last_name"
                        onChange={handleChange}
                        value={formik?.values?.last_name}
                      ></input>
                    </div>
                    <div className="col-12 form-group">
                      <label>Street Address:</label>
                      <input
                        type="text"
                        placeholder="Street Address"
                        className="form-control"
                        name="address_line_1"
                        onChange={handleChange}
                        value={formik?.values?.address_line_1}
                      ></input>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Mobile Number:</label>
                      <input
                        type="tel"
                        placeholder="Mobile Number:"
                        className="form-control"
                        name="primary_contact_number"
                        onChange={handleChange}
                        value={formik?.values?.primary_contact_number}
                      ></input>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Email:</label>
                      <input
                        type="email"
                        placeholder="Email:"
                        className="form-control"
                        name="email_id"
                        onChange={handleChange}
                        value={formik?.values?.email_id}
                      ></input>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Select Country:</label>
                      <select
                        name="country_id"
                        className="form-control"
                        value={formik?.values?.country_id}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "country_id",
                            event.target.value
                          );
                          getProvince(event);
                        }}
                      >
                        <option value="" selected disabled>
                          Select Country
                        </option>
                        {countryList?.map((item, i) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Select Province:</label>
                      <select
                        name="province_id"
                        className="form-control"
                        value={formik?.values?.province_id}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "province_id",
                            event.target.value
                          );
                          getCity(event);
                        }}
                      >
                        <option value="" selected disabled>
                          Select Province
                        </option>
                        {provinceList?.map((item, i) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>
                    <div className="col-12 col-md-6 form-group">
                      <label>Select Town/City:</label>
                      <select
                        name="city_id"
                        className="form-control"
                        value={formik?.values?.city_id}
                        // onChange={formik.handleChange}
                        onChange={(event) => {
                          formik.setFieldValue("city_id", event.target.value);
                          getZipcodeByCityDeliverable(event);
                        }}
                      >
                        <option value="" selected disabled>
                          Select City
                        </option>
                        {cityList?.map((item, i) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                    </div>
                    {/* <div className="col-12 col-md-6 form-group">
                    <label>Zipcode:</label>
                    <input
                      type="text"
                      minLength={6}
                      maxLength={6}
                      placeholder="Zipcode"
                      className="form-control"
                      name="zipcode"
                      onChange={handleChange}
                      value={formik?.values?.zipcode}
                    ></input>
                  </div> */}

                    <div className="form-group col-12 col-lg-6">
                      <label htmlFor="zipcode_id">
                        Zipcode{" "}
                        <small>(Below listed zipcodes are deliverable)</small>
                      </label>
                      <select
                        name="zipcode_id"
                        className="form-control"
                        value={formik?.values?.zipcode_id}
                        // onChange={formik.handleChange}
                        onChange={(event) => [
                          formik.setFieldValue(
                            "zipcode_id",
                            event.target.value
                          ),
                        ]}
                      >
                        <option value="" selected disabled>
                          Select Zipcode
                        </option>
                        {zipcodeList?.map((item, i) => {
                          return <option value={item?.id}>{item?.name}</option>;
                        })}
                      </select>
                      {formik?.touched?.zipcode_id &&
                        formik?.errors?.zipcode_id && (
                          <p className="red-text">
                            {formik?.errors?.zipcode_id}
                          </p>
                        )}
                    </div>
                  </div>
                </div>

                <div className="checkout-info">
                  <div className="review-order">
                    <div id="order_review">
                      {cartData && cartData.length > 0 ? (
                        <div className="cart-totals">
                          {/* {cartData?.map((item, i) => {
                          return (
                            <> */}
                          {cartData.map((item, i) => (
                            <>
                              <React.Fragment key={i}>
                                <div
                                  className="cart_item d-flex justify-content-between"
                                  key={i}
                                >
                                  <div className="d-flex align-items-center">
                                    <div className="product-thumbnail ">
                                      <img
                                        src={item?.ProductsVariant?.images[0]}
                                        alt="Product"
                                      />
                                    </div>
                                    <div className="product-desc">
                                      <p className="mb-0">
                                        {item?.Product.name}
                                      </p>
                                      <strong className="product-quantity">
                                        {/* {item?.variant_data?.sell_rate} ×{" "}
  {item?.qty} */}
                                        {item?.ProductsVariant?.sell_rate} ×{" "}
                                        {item?.quantity}
                                      </strong>
                                    </div>
                                  </div>
                                  {/* <div className="d-flex justify-content-between align-items-start"></div> */}
                                  <div className="d-flex justify-content-end">
                                    <span className="price">
                                      <p></p>
                                      <ins className="p-0 text-decoration-none">
                                        <span className="amount">
                                          <bdi>
                                            <span>₹</span>
                                            {/* {item?.qty *
    item?.variant_data?.sell_rate} */}
                                            {item.quantity *
                                              item.ProductsVariant.sell_rate}
                                          </bdi>
                                        </span>
                                      </ins>
                                    </span>
                                  </div>
                                </div>
                              </React.Fragment>
                            </>
                          ))}

                          {/* </> */}
                          {/* ); */}
                          {/* })} */}

                          {/* sub total  */}
                          {/* <div className="order-total d-flex justify-content-between">
                            <p className="text-uppercase m-0">Sub Total</p>
                            <span className="price">
                              <ins className="p-0 text-decoration-none">
                                <span className="amount fw-semibold">
                                  <span>₹</span>
                                  {subAmount}
                                </span>
                              </ins>
                            </span>
                          </div> */}
                          {/* delivery charges  */}
                          <div className="order-total d-flex justify-content-between">
                            <p className="text-uppercase m-0">
                              Delivery Charges
                            </p>
                            <span className="price">
                              <ins className="p-0 text-decoration-none">
                                <span className="amount fw-semibold">
                                  <span>₹</span>
                                  {deliveryCharge}
                                </span>
                              </ins>
                            </span>
                          </div>

                          {/* total charges  */}
                          <div className="order-total d-flex justify-content-between">
                            <p className="text-uppercase m-0">TOTAL</p>
                            <span className="price">
                              <ins className="p-0 text-decoration-none">
                                <span className="amount fw-semibold">
                                  <bdi>
                                    <span>₹</span>
                                    {subAmount + deliveryCharge}
                                  </bdi>
                                </span>
                              </ins>
                            </span>
                          </div>

                          <div className="col-12" style={{ display: "flex" }}>
                            <input
                              type="checkbox"
                              name="is_default_address"
                              id="is_default_address"
                              onChange={handleCheckboxChange}
                              // onChange={() => setDefaultAddress(true)}
                            />
                            {/* Do you have preferred date for delivery */}
                            <label
                              htmlFor="is_default_address"
                              className="ms-2"
                            >
                              Do you have preferred date for delivery
                            </label>
                          </div>

                          {isDatePickerVisible && (
                            <>
                              <div className="col-12">
                                <label>Date : &nbsp;</label>
                                <DatePicker
                                  showIcon
                                  selected={expectedDate}
                                  // selected={startDate}
                                  // onChange={(date) => setStartDate(date)}
                                  onChange={(date) => [
                                    formik.setFieldValue(
                                      "expected_delivery_date",
                                      date
                                    ),
                                    setExpectedDate(date),
                                  ]}
                                  minDate={minSelectableDate}
                                  // showPopperArrow={true}
                                  placeholderText="Select Date"
                                />
                              </div>
                            </>
                          )}

                          <div className="col-md-12">
                            <button
                              type="submit"
                              className="yellowbtn w-100 mt-2"
                            >
                              Proceed to checkout
                            </button>
                          </div>
                          {isCartAvailable &&
                            showErrorMsg &&
                            !activeAddress && (
                              <p className="text-white mb-0 text-center">
                                Please select address detail!
                              </p>
                            )}
                        </div>
                      ) : (
                        <p>Your cart is empty</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
      >
        <button
          className="btn btn-default float-end"
          onClick={() => setModalOpen(false)}
        >
          <FaTimes />
        </button>
        <br />
        <br />
        <form onSubmit={addressForm.handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-6 form-group">
              <label htmlFor="first_name">First Name</label>
              <input
                type="text"
                name="first_name"
                required
                className="form-control"
                value={addressForm?.values?.first_name}
                onChange={addressForm.handleChange}
              />
            </div>
            <div className="col-12 col-md-6 form-group">
              <label htmlFor="last_name">Last Name</label>
              <input
                type="text"
                name="last_name"
                required
                className="form-control"
                value={addressForm?.values?.last_name}
                onChange={addressForm.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 form-group">
              <label htmlFor="primary_contact_code_id">Country Code</label>
              <select
                type="text"
                name="primary_contact_code_id"
                className="form-control"
                required
                value={addressForm?.values?.primary_contact_code_id}
                onChange={addressForm.handleChange}
              >
                <option value={0} selected disabled>
                  Select Country Code
                </option>
                {countryList?.map((item, i) => {
                  return (
                    <option value={item?.id} key={i}>
                      +{item?.phone_code}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="col-12 col-md-6 form-group">
              <label htmlFor="primary_contact_number">Contact Number</label>
              <input
                type="text"
                required
                minLength={10}
                maxLength={10}
                name="primary_contact_number"
                className="form-control"
                value={addressForm?.values?.primary_contact_number}
                onChange={addressForm.handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="address_name">Address Type</label>
            <input
              type="text"
              required
              name="address_name"
              className="form-control"
              placeholder="Home / Office / Others"
              value={addressForm?.values?.address_name}
              onChange={addressForm.handleChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="address_line_1">
              Address Line 1{" "}
              <small>(House no / Floor / Building / Flat Name)</small>
            </label>
            <input
              type="text"
              placeholder="House no / Floor / Building / Flat Name"
              name="address_line_1"
              className="form-control"
              value={addressForm?.values?.address_line_1}
              onChange={addressForm.handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address_line_2">
              Address Line 2<small>(Area / Sector / Locality / Landmark)</small>
            </label>
            <input
              placeholder="Area / Sector / Locality / Landmark"
              type="text"
              name="address_line_2"
              className="form-control"
              value={addressForm?.values?.address_line_2}
              onChange={addressForm.handleChange}
              required
            />
          </div>
          <div className="row">
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="country_id">Country</label>
              <select
                name="country_id"
                className="form-control"
                value={addressForm?.values?.country_id}
                onChange={(event) => [
                  addressForm.setFieldValue("country_id", event.target.value),
                  getProvince(event),
                ]}
                required
              >
                <option value="" selected disabled>
                  Select Country
                </option>
                {countryList?.map((item, i) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
            </div>
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="province_id">Province</label>
              <select
                name="province_id"
                className="form-control"
                value={addressForm?.values?.province_id}
                required
                onChange={(event) => [
                  addressForm.setFieldValue("province_id", event.target.value),
                  getCity(event),
                ]}
              >
                <option value="" selected disabled>
                  Select Province
                </option>
                {provinceList?.map((item, i) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="row">
            <div className="form-group col-12 col-lg-6">
              <label htmlFor="city_id">City</label>
              <select
                name="city_id"
                className="form-control"
                value={addressForm?.values?.city_id}
                required
                // onChange={addressForm.handleChange}
                onChange={(event) => [
                  addressForm.setFieldValue("city_id", event.target.value),
                  getZipcodeByCityDeliverable(event),
                ]}
              >
                <option value="" selected disabled>
                  Select City
                </option>
                {cityList?.map((item, i) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
            </div>
            {/* <div className="form-group col-12 col-lg-6">
              <label htmlFor="zipcode">Zipcode</label>
              <input
                type="text"
                minLength={6}
                maxLength={6}
                name="zipcode"
               
                className="form-control"
                value={addressForm?.values?.zipcode}
                onChange={addressForm.handleChange}
              />
            </div> */}

            <div className="form-group col-12 col-lg-6">
              <label htmlFor="zipcode_id">
                Zipcode <small>(Below listed zipcodes are deliverable)</small>
              </label>
              <select
                name="zipcode_id"
                className="form-control"
                value={addressForm?.values?.zipcode_id}
                required
                // onChange={formik.handleChange}
                onChange={(event) => [
                  addressForm.setFieldValue("zipcode_id", event.target.value),
                ]}
              >
                <option value="" selected disabled>
                  Select Zipcode
                </option>
                {zipcodeList?.map((item, i) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
              {formik?.touched?.zipcode_id && formik?.errors?.zipcode_id && (
                <p className="red-text">{formik?.errors?.zipcode_id}</p>
              )}
            </div>
          </div>
          <div className="form-footer d-flex justify-content-end">
            <button type="submit" className="orangebtn">
              {addressForm?.values?.id ? "Update" : "Add"}
            </button>
          </div>
        </form>
      </Modal>

      <Arasuri />
      <Footer />
    </div>
  );
};

export default Checkout;
