import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './bhadarviPoonamLocation.scss';
import '../../../Css/innerPages.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import LazyLoader from '../../../Components/lazyLoader';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useHeading } from '../header'; // Import the custom hook
import ParkingAreaImg from '../../../Assets/Images/Bhadarvi_poonam/location-parking/parking.svg';

import { NavLink } from 'react-router-dom';

const BhadarviPoonamLocationHadad = () => {
  const [value, setValue] = useState('1'); // Default active tab is '1'
  const heading = useHeading(); // Call the custom hook
  const [selectedTab, setSelectedTab] = useState('PARKING (CAR + HEAVY)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Ambaji Bhadarvi Poonam 2024</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Discover the divine celebration of Ambaji Bhadarvi Poonam 2024 at Ambaji Temple"
        />
        <meta
          name="keywords"
          content="Ambaji Bhadarvi Poonam 2024, Ambaji Temple, Bhadarvi Poonam festival"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner Location-banner">
            <div className="container">
              <div className="container">{heading}</div>
            </div>
          </section>

          <section className="Location">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <NavLink
                    to="/bhadarvi-poonam"
                    className="orangebtn"
                    style={{ alignItems: 'center' }}
                  >
                    પાછા જવા માટે
                  </NavLink>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="tabboxes-location">
                    <TabContext value={value}>
                      <Tabs
                        className="tab-btn-location"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        indicatorColor=" "
                        allowScrollButtonsMobile
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 }
                          }
                        }}
                      >
                        <Tab
                          className={
                            selectedTab === 'PARKING (CAR + HEAVY)'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="1"
                          onClick={() => {
                            setSelectedTab('PARKING (CAR + HEAVY)');
                          }}
                          label="PARKING (CAR + HEAVY)"
                        />
                        <Tab
                          className={
                            selectedTab === 'PARKING (2 WHEELER)'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="2"
                          onClick={() => {
                            setSelectedTab('PARKING (2 WHEELER)');
                          }}
                          label="PARKING (2 WHEELER)"
                        />
                        <Tab
                          className={
                            selectedTab === 'PARKING (2 WHEELER + 4 WHEELER)'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="3"
                          onClick={() => {
                            setSelectedTab('PARKING (2 WHEELER + 4 WHEELER)');
                          }}
                          label="PARKING (2 WHEELER + 4 WHEELER)"
                        />
                      </Tabs>

                      <TabPanel value="1">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.330711,72.882642"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Behind Chikhla Primary School / ચિખલા
                                      પ્રાથમિક શાળા પાછળ
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33268,72.87678"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>Behind Sav Stone / સેવ સ્ટોન પાછળ</p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33385,72.8789"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      In Front Of Kamakshi Devi Temple and
                                      Kanhaiya Marble Art / કામાક્ષી મંદિર અને
                                      કન્હેયા માર્બલ આર્ટની સામે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.333783,72.870862"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Towards New Railway Station Road / નવા
                                      રેલ્વે સ્ટેશન રોડ તરફ
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel value="2">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.333260,72.874157"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Besides Mayur Dwar / મયુર દ્વાર ની બાજુ મા
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33235,72.87242"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p> Green Marble / ગ્રીન માર્બ્લે</p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.332,72.87007"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Besides Mahir Marble Art / માહિર માર્બ્લે
                                      આર્ટ ની બાજુ મા
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33108,72.8697"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      In Front Of Super Marble / સુપર માર્બલની
                                      સામે
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.330344,72.868504"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Besides Siddhi Vinayak Marble / સિદ્ધિ
                                      વિનાયક માર્બલ ની બાજુ મા
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.330767,72.859887"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Besides Prajapati Dharamshala / પ્રજાપતિ
                                      ધર્મશાળા ની બાજુ મા
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.330767,72.859887"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Besides Nandini Hotel / નંદિની હોટેલ ની
                                      બાજુ મા
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel value="3">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.33072,72.85434"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Besides Road Towards Mangalya Van (Land of
                                      Dk Trivedi) / માંગલ્ય વાન તરફના રસ્તા ની
                                      બાજુ (ડી.કે. ત્રિવેદીની જમીન)
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default BhadarviPoonamLocationHadad;
