import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const getAll = () => {
  return http.get(api_urls?.getShakipith);
};

const getById = (id) => {
  return http.get(`${api_urls?.getShakipith}?id=${id}`);
};

const ShakipithService = {
  getAll,
  getById,
};

export default ShakipithService;
