import React, { useRef, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Helmet } from "react-helmet-async";
import Footer from "../../../Components/footer";
import Arasuri from "../../../Components/arasuri";
import "./donationArticleReceipt.scss";
import { useReactToPrint } from "react-to-print";
import ArticleDonationService from "../../../Api_config/Donations/articleDonation";
import { formatDate } from "../Converter/date";
import SignatureService from "../../../Api_config/Home_page/signature";
import { routes } from "../../../_Constants/routes";

const DonationArticleReceipt = () => {
  const navigate = useNavigate();
  const componentRef = useRef();
  const location = useLocation();
  const [transactionData, setTransactionData] = useState();
  const formattedDate = formatDate(transactionData?.createdAt);
  const [signatureData, setSignatureData] = useState([]);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Ambaji Temple Article Donation Receipt",
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    },
  });

  useEffect(() => {
    const id = location?.pathname?.split("/").pop();
    // console.log(location, Number(id))
    ArticleDonationService.getTransactionDetails(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setTransactionData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        //  console.log(e)
      });

    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Amabji Temple - Article Donation Receipt</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details, Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji, Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <div className="booking" style={{ backgroundColor: "#f5f5f5" }}>
        <section className="header-banner temple-banner">
          <div className="container">
            <h1>Donation Article Receipt</h1>
          </div>
        </section>
        <div className="print-container" ref={componentRef}>
          <div className="pdf-btn">
            <div className="container">
              <div className="row">
                <div
                  style={{ width: "800px", margin: "20px auto 5px" }}
                  className="email-container"
                >
                  <table width="100%">
                    <tbody>
                      <tr className="header">
                        <td
                          width="17%"
                          rowSpan="3"
                          align="center"
                          valign="middle"
                        >
                          <div className="logo">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                              style={{ width: "100px", height: "100px" }}
                              alt="Article Donation - Ambaji Temple Banaskatha Gujarat"
                            />
                          </div>
                        </td>
                        <td width="83%">
                          <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                        </td>
                      </tr>
                      <tr className="header">
                        <td>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>
                          <p>
                            <strong>PAN No </strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong> : 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="header"></tr>
                      <tr className="headig-text">
                        <td colSpan="2" align="center" valign="middle">
                          <h2>ARTICLE DONATION RECEIPT</h2>
                        </td>
                      </tr>
                      <tr className="rs-note">
                        <td colSpan="2" align="center">
                          <p>
                            "May the holy mother Maa Ambe Bless you and your
                            family, friends and give you a happy,
                            <br /> prosperous and spiritually enlightened life
                            ahead"
                          </p>
                        </td>
                      </tr>
                      <tr className="header">
                        <td colSpan="2" align="right">
                          <p>
                            <strong>Donation Date</strong> : {formattedDate}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" align="center">
                          <table width="100%" className="date-section">
                            <tbody>
                              <tr>
                                <td width="50%">
                                  <strong>Donation Number</strong>
                                </td>
                                <td width="50%">
                                  {transactionData?.donation_number}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>User Identity</strong>
                                </td>
                                <td>{transactionData?.user_identity}</td>
                              </tr>
                              {transactionData?.user_identity !== "hidden" && (
                                <>
                                  <tr>
                                    <td>
                                      <strong>Name</strong>
                                    </td>
                                    <td>
                                      {" "}
                                      {transactionData?.ArticleDonationDetail
                                        ?.first_name ||
                                      transactionData?.ArticleDonationDetail
                                        ?.last_name
                                        ? `${
                                            transactionData
                                              ?.ArticleDonationDetail
                                              ?.first_name || ""
                                          } ${
                                            transactionData
                                              ?.ArticleDonationDetail
                                              ?.last_name || ""
                                          }`
                                        : "-"}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <strong>Phone Number</strong>
                                    </td>
                                    <td>
                                      {
                                        transactionData?.ArticleDonationDetail
                                          ?.primary_contact_number
                                      }
                                    </td>
                                  </tr>
                                </>
                              )}
                              <tr>
                                <td>
                                  <strong>Article Name</strong>
                                </td>
                                <td>{transactionData?.article_name}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Article Quantity</strong>
                                </td>
                                <td>{transactionData?.quantity}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Approximate value of Article</strong>
                                </td>
                                <td>Rs. {transactionData?.amount}</td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr align="center" valign="middle" className="teg-line">
                        <td colSpan="2">
                          <table
                            cellSpacing="0"
                            cellPadding="0"
                            border="0"
                            width="100%"
                          >
                            <tbody>
                              <tr align="center">
                                <td
                                  width="40%"
                                  valign="middle"
                                  className="barcode"
                                >
                                  <img
                                    src="https://qrcg-free-editor.qr-code-generator.com/main/assets/images/websiteQRCode_noFrame.png"
                                    width="110"
                                    alt="Article Donation - Ambaji Temple Banaskatha Gujarat"
                                  />
                                </td>
                                <td
                                  width="60%"
                                  valign="middle"
                                  className="sign"
                                >
                                  {signatureData?.map((item) => {
                                    return (
                                      <img
                                        alt="Article Donation - Ambaji Temple Banaskatha Gujarat"
                                        src={item.signature_image}
                                        // src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716962038848_MicrosoftTeams-image(7).png"
                                        width="120"
                                        height="100"
                                      />
                                    );
                                  })}
                                  <p>
                                    {signatureData?.length >= 1 &&
                                      signatureData[0]?.name}
                                    <br />
                                    {signatureData?.length >= 1 &&
                                      signatureData[0]?.designation}
                                    <br />
                                    Shree Arasuri Ambaji Mata Devasthan Trust
                                    Ambaji
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <button className="btn orangebtn my-2" onClick={handlePrint}>
            Download Receipt
          </button>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};

export default DonationArticleReceipt;
