import React, { useState, useEffect } from 'react';
import '../Home/home.scss';
import { useNavigate } from 'react-router-dom';

import './bhadarviPoonam.scss';
// import notice from "../../Assets/Images/Bhadarvi_poonam/notice.jpg";
import LazyLoader from '../../Components/lazyLoader';
import pdffile from '../../Assets/Images/Bhadarvi_poonam/pdf.svg';
import Registration from '../../Assets/Images/Bhadarvi_poonam/registrationform.svg';
import bhadarvipoonamlogo from '../../Assets/Images/Bhadarvi_poonam/bhadarvipoonam-logo.png';
import Previewfile from '../../Assets/Images/Bhadarvi_poonam/file.svg';
import Calendar from '../../Assets/Images/Bhadarvi_poonam/calendar.svg';
import downarrow from '../../Assets/Images/Bhadarvi_poonam/down.svg';

// import FreeFoodforDevotees from '../../Assets/Images/Bhadarvi_poonam/Free-Food-for-Devotees.svg';
// import RestShelterforDevotees from '../../Assets/Images/Bhadarvi_poonam/RestShelterforDevotees.svg';
// import Lightdecoration from '../../Assets/Images/Bhadarvi_poonam/Lightdecoration.svg';
// import SoundSystemforDevotees from '../../Assets/Images/Bhadarvi_poonam/SoundSystemforDevotees.svg';
// import CCTVCamerasystem from '../../Assets/Images/Bhadarvi_poonam/CCTV-Camera-system.svg';
// import SafaiandSanitation from '../../Assets/Images/Bhadarvi_poonam/Safai-and-Sanitation.svg';
// import Transportation from '../../Assets/Images/Bhadarvi_poonam/Transportation.svg';
// import StationeryExpenses from '../../Assets/Images/Bhadarvi_poonam/Stationery-Expenses.svg';
// import PhotographyDocumentary from '../../Assets/Images/Bhadarvi_poonam/PhotographyDocumentary.svg';

import ParkingAreaImg from '../../Assets/Images/Bhadarvi_poonam/location-parking/parking.svg';
import MainGate from '../../Assets/Images/Bhadarvi_poonam/location-parking/arch-of-triumph.svg';
import Bhojan from '../../Assets/Images/Bhadarvi_poonam/location-parking/lunchbox.svg';
import ControlSecurity from '../../Assets/Images/Bhadarvi_poonam/location-parking/security-room.svg';
import SheterRoom from '../../Assets/Images/Bhadarvi_poonam/location-parking/bed.svg';
import PublicToilet from '../../Assets/Images/Bhadarvi_poonam/location-parking/public-toilet.svg';
import CulturesProgram from '../../Assets/Images/Bhadarvi_poonam/location-parking/concert.svg';
import locationMapping from '../../Assets/Images/Bhadarvi_poonam/location-parking/global-mobility.svg';

import VIPPlaza from '../../Assets/Images/Bhadarvi_poonam/location-parking/advertisement.svg';
import AmbajiTemple from '../../Assets/Images/Bhadarvi_poonam/location-parking/temple-icon.svg';
import PrasadCenter from '../../Assets/Images/Bhadarvi_poonam/location-parking/ladoo.svg';
import RestCentreShaktiGate from '../../Assets/Images/Bhadarvi_poonam/location-parking/restcentre.svg';
import DrinkWater from '../../Assets/Images/Bhadarvi_poonam/location-parking/drink-water.svg';
import AidKit from '../../Assets/Images/Bhadarvi_poonam/location-parking/aid-kit.svg';
import BusStop from '../../Assets/Images/Bhadarvi_poonam/location-parking/bus-stop.svg';

import Arasuri from '../../Components/arasuri';
import Footer from '../../Components/footer';
import { Helmet } from 'react-helmet-async';
import ProgressBar from '@ramonak/react-progress-bar';
import BhadarviDonationService from '../../Api_config/Bhadarvi_poonam/bhadarviDonation';

// import UnderConstruction from "../Under_Construction/underconstruction";

// function closePopup() {
//   const modal = document.getElementById("myModal");
//   if (modal) {
//     modal.style.display = "none";
//   }
// }

const BhadarviPoonam = (props) => {
  // const { data } = props;
  // const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setLoading] = useState(true);
  const [donations, setDonations] = useState([]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  // year dyanmic
  const currentYear = new Date().getFullYear();
  // document.getElementById("currentYear").textContent = currentYear;

  //-----page-close-UnderConstruction-logic-p-----
  // if (true) {
  //   return <UnderConstruction />;
  // }
  //-----page-close-404-error-logic-p-----

  useEffect(() => {
    BhadarviDonationService.getAllDonation()
      .then((response) => {
        console.log(response);

        if (response.data.success) {
          setDonations(response.data.data);
        }
      })
      .catch((error) => {
        console.error('Error fetching donations:', error);
      });
  }, []);

  return (
    <>
      {/* helmet  */}
      <Helmet>
        <title>Ambaji Bhadarvi Poonam 2024</title>
        <meta
          name="description"
          content="Discover the divine celebration of Ambaji Bhadarvi Poonam 2024 at Ambaji Temple. Join thousands of devotees for an enriching spiritual experience filled with rituals, prayers, and cultural festivities. You can View Temple Schedule, Access to Crucial Information, Seva Camp Registration, Sangh Registration at Ambaji Temple Website"
        />
        <meta
          name="keywords"
          content="Ambaji Bhadarvi Poonam 2024, Ambaji Temple, Bhadarvi Poonam festival, Ambaji pilgrimage, spiritual events, religious festivals India, Ambaji celebrations, Hindu festivals, Ambaji Poonam schedule, visit Ambaji Temple, divine rituals, cultural festivities, sacred events Gujarat, Ambaji event details, Seva Camp Registration, Sangh Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* ------------  */}
      {!isLoading ? (
        <>
          {/* <div id="container">
            <div id="myModal">
              <div id="popUpForm">
                <div id="popContainer">
                  <div className="modal-content">
                    <button id="close" onClick={closePopup}>
                      X
                    </button>
                    <img
                      src={notice}
                      alt="Ambaji Temple Banaskatha Gujarat"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <section className="mainbanner">
            <div className="bannerslider">
              <div className="banner-item">
                <div className="bannerimg bhadarvi-bg">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="main-heading-bhadarvi">
                          <div className="row">
                            <div className="col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 col-1"></div>
                            <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-5 col-sm-6 col-7">
                              <div className="bhadarvi-text">
                                {/* <h1>
                              ભાદરવી પૂનમ<span> {currentYear}</span>
                            </h1> */}
                                <img
                                  src={bhadarvipoonamlogo}
                                  alt="Ambaji Temple Banaskatha Gujarat"
                                ></img>
                              </div>
                            </div>
                          </div>
                          <a className="arrow_scroll_down" href="#bhadarvi">
                            <span id="bhadarvi"> </span>
                            <img src={downarrow} />
                            <p>નીચે સ્ક્રોલ કરો</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="service-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <h3 style={{ margin: '0px 0px 15px' }}>
                    ભક્તો માટે <span>ની સુવિધા સ્થાન/નકશો </span>(Google Map)
                  </h3>
                  <div className="service-list">
                    <div className="row">
                      {/* ------------- */}

                      {/* -------યાત્રિક સુવિધા માર્ગદર્શિકા----------  */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="https://storage.googleapis.com/ambaji-temple.appspot.com/1725613028478_ભાદરવી-પૂનમ-૨૪-final.pdf"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div
                            className="servicebox-bhadarvi"
                            style={{ backgroundColor: '#ae0015' }}
                          >
                            <div className="service-icon">
                              <img
                                src={locationMapping}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4 style={{ color: '#fff' }}>
                              યાત્રિક સુવિધા માર્ગદર્શિકા
                            </h4>
                          </div>
                        </a>
                      </div>
                      {/* ------પાર્કિંગ સુવિધા (હડાદ માર્ગ) સ્થાન/નકશો------- */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="/bhadarvi-poonam-hadad-route-location"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <div className="icon-text-box">
                                <img
                                  src={ParkingAreaImg}
                                  alt="Ambaji Temple Banaskatha Gujarat"
                                />
                                <p style={{ fontSize: '18px' }}>(Parking)</p>
                              </div>
                            </div>
                            <h4>પાર્કિંગ સુવિધા (હડાદ માર્ગ) સ્થાન/નકશો</h4>
                          </div>
                        </a>
                      </div>
                      {/* -----પાર્કિંગ સુવિધા (દાંતા માર્ગ) સ્થાન/નકશો------  */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="/bhadarvi-poonam-danta-route-location"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <div className="icon-text-box">
                                <img
                                  src={ParkingAreaImg}
                                  alt="Ambaji Temple Banaskatha Gujarat"
                                />
                                <p style={{ fontSize: '18px' }}>(Parking)</p>
                              </div>
                            </div>
                            <h4>પાર્કિંગ સુવિધા (દાંતા માર્ગ) સ્થાન/નકશો</h4>
                          </div>
                        </a>
                      </div>
                      {/* ------મુખ્ય યાત્રિક દર્શન પ્રવેશ દ્વાર------- */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="https://maps.app.goo.gl/1vyc7fWPfqXnu5AJ6"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <div className="icon-text-box">
                                <img
                                  src={MainGate}
                                  alt="Ambaji Temple Banaskatha Gujarat"
                                />
                                <p>(Main Darshan Gate Entry)</p>
                              </div>
                            </div>
                            <h4>મુખ્ય યાત્રિક દર્શન પ્રવેશ દ્વાર</h4>
                          </div>
                        </a>
                      </div>
                      {/* ------ભોજનાલય સુવિધા સ્થાન/નકશો------- */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="bhadarvi-poonam-bhojanalaya-location"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <div className="icon-text-box">
                                <img
                                  src={Bhojan}
                                  alt="Ambaji Temple Banaskatha Gujarat"
                                />
                                <p>
                                  (દિવાળી બા ગુરુભવન, અંબિકા ભોજનાલય, ગબ્બર
                                  તલેટી)
                                </p>
                              </div>
                            </div>
                            <h4>વિના મુલ્ય ભોજનાલય સુવિધા</h4>
                          </div>
                        </a>
                      </div>
                      {/* ------સાંસ્કૃતિક કાર્યક્રમ સ્થાન/નકશો------- */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="https://www.google.com/maps?q=24.3319,72.85623"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <div className="icon-text-box">
                                <img
                                  src={CulturesProgram}
                                  alt="Ambaji Temple Banaskatha Gujarat"
                                />
                                <p>
                                  તારીખ : (15/16/17-09-2024)
                                  <br />
                                  બપોરે 3:00PM થી રાત્રે 10:00PM
                                </p>
                              </div>
                            </div>
                            <h4>સાંસ્કૃતિક કાર્યક્રમ સ્થાન/નકશો</h4>
                          </div>
                        </a>
                      </div>
                      {/* ------કંટ્રોલ રૂમનું સ્થાન/નકશો------- */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="/bhadarvi-poonam-control-room-location"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <img
                                src={ControlSecurity}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4>કંટ્રોલ રૂમનું સ્થાન/નકશો</h4>
                          </div>
                        </a>
                      </div>
                      {/* આરામ આશ્રય સ્થાન/નકશો  */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="/bhadarvi-poonam-rest-shelter-location"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <img
                                src={SheterRoom}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4>આરામ આશ્રય સ્થાન/નકશો</h4>
                          </div>
                        </a>
                      </div>
                      {/* ટોયલેટ બ્લોક સ્થાન/નકશો  */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="/bhadarvi-poonam-toilet-location"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <img
                                src={PublicToilet}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4>ટોયલેટ બ્લોક સ્થાન/નકશો</h4>
                          </div>
                        </a>
                      </div>
                      {/* VIP પ્લાઝા (મીડિયા સેન્ટર) */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="https://www.google.com/maps?q=24.336122,72.848156"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <img
                                src={VIPPlaza}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4>VIP પ્લાઝા (મીડિયા સેન્ટર)</h4>
                          </div>
                        </a>
                      </div>
                      {/* --------અંબાજી મુખ્ય મંદિર----- */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="https://www.google.com/maps?q=24.335838,72.850146"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <img
                                src={AmbajiTemple}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4>અંબાજી મુખ્ય મંદિર</h4>
                          </div>
                        </a>
                      </div>
                      {/* ------ પ્રસાદ વિતરણ કેન્દ્ર ------ */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="/bhadarvi-poonam-location-prasad-counter"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <img
                                src={PrasadCenter}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4>પ્રસાદ વિતરણ કેન્દ્ર</h4>
                          </div>
                        </a>
                      </div>
                      {/* ------શક્તિ ગેટની સામે આરામ કેન્દ્ર------- */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="https://www.google.com/maps?q=24.336403,72.847506"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <img
                                src={RestCentreShaktiGate}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4>શક્તિ ગેટની સામે આરામ કેન્દ્ર</h4>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}
                      {/* ------પીવાનું પાણી પોઈન્ટ------ */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="/bhadarvi-poonam-drinking-water-location"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <img
                                src={DrinkWater}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4>પીવાનું પાણી પોઈન્ટ સ્થાન/નકશો</h4>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}
                      {/* ------આરોગ્ય કેન્દ્ર------- */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="bhadarvi-poonam-location-health-center"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <img
                                src={AidKit}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4>આરોગ્ય કેન્દ્ર સ્થાન/નકશો</h4>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}
                      {/* ------------- */}
                      {/* ------આરોગ્ય કેન્દ્ર------- */}
                      <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
                        <a
                          href="/bhadarvi-poonam-location-bus-station"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <div className="servicebox-bhadarvi">
                            <div className="service-icon">
                              <img
                                src={BusStop}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                            <h4>બસ સ્ટેશન સ્થાન/નકશો</h4>
                          </div>
                        </a>
                      </div>
                      {/* ------------- */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="Advertise-business">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <h3 style={{ margin: '0px 0px 15px' }}>
                    ભાદરવી પૂનમ <span>ની ઓનલાઇન સુવિધાઓ</span>
                  </h3>
                </div>
              </div>

              <div className="service-list">
                <div className="row">
                  {/* PARKING  */}

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a href="#popup-open">
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={Calendar}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>ભાદરવી પૂનમ {currentYear} (સમય અનુસૂચિ)</h5>
                      </div>
                    </a>
                  </div>

                  <div id="popup-open" className="overlay-box">
                    <div className="popup-box">
                      <h2>View Schedule</h2>
                      <a className="close" href="#">
                        &times;
                      </a>
                      <hr />
                      <div className="content-show">
                        <div className="table-responsive-1">
                          <table>
                            <thead>
                              <tr>
                                <th colSpan="3">
                                  ભાદરવી પૂનમ {currentYear} (દર્શન-સમય)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <strong>સવારે ૬.૦૦ થી ૧૧.૩૦</strong>
                                </td>
                                <td>
                                  <strong>બપોરે ૧૨.૩૦ થી ૫.૦૦</strong>
                                </td>
                                <td>
                                  <strong>સાંજે ૭.૦૦ થી રાત્રિના ૧૨.૦૦</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table>
                            <thead>
                              <tr>
                                <th colSpan="2">
                                  ભાદરવી પૂનમ {currentYear} (આરતી-સમય)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <strong>સવારે ૬.૦૦ થી ૬.૩૦</strong>
                                </td>
                                <td>
                                  <strong>સાંજે ૭.૦૦ થી ૭.૩૦</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <br />
                        <div className="table-responsive-1">
                          <table>
                            <thead>
                              <tr>
                                <th>તિથિ</th>
                                <th>વાર</th>
                                <th>તારીખ</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>ભાદરવા સુદ-નોમ</td>
                                <td>ગુરુવાર</td>
                                <td>૧૨-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-દશમ</td>
                                <td>શુક્રવાર</td>
                                <td>૧૩-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-અગિયારસ</td>
                                <td>શનિવાર</td>
                                <td>૧૪-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-બારસ</td>
                                <td>રવિવાર</td>
                                <td>૧૫-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-તેરસ</td>
                                <td>સોમવાર</td>
                                <td>૧૬-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-ચૌદસ</td>
                                <td>મંગળવાર</td>
                                <td>૧૭-૦૯-૨૦૨૪</td>
                              </tr>
                              <tr>
                                <td>ભાદરવા સુદ-પૂનમ</td>
                                <td>બુધવાર</td>
                                <td>૧૮-૦૯-૨૦૨૪</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a
                      href="/seva-terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={Registration}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>સેવા કેમ્પ નોંધણી</h5>
                      </div>
                    </a>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a
                      href="/sang-terms-conditions"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={Registration}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>પદયાત્રા સંઘ નોંધણી</h5>
                      </div>
                    </a>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a
                      href="/bhadarvi-poonam-receipt-download"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={pdffile}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>સેવા - કેમ્પ અને સંઘ નોંધણી ની રસીદ</h5>
                      </div>
                    </a>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a
                      href="/ambaji-sthanik-vehicle-pass"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={Registration}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>અંબાજી સ્થાનિક વાહન પાસ નોંધણી</h5>
                      </div>
                    </a>
                  </div>

                  <div className="col-lg-4 col-md-6 col-sm-12">
                    <a
                      href="/search-ambaji-sthanik-vehicle-pass"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="bhadarvi-box-color">
                        <div className="service-icon">
                          <img
                            src={Previewfile}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                        </div>
                        <h5>અંબાજી સ્થાનિક વાહન પાસ - સર્ચ</h5>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="service-section">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-12">
                  <h3 style={{ margin: '0px 0px 15px' }}>
                    ભાદરવી પૂનમ <span>ની સુવિધાઓ માટે દાન </span>
                  </h3>
                  <div className="service-list">
                    <div className="row">
                      {donations.map((donation) => (
                        <div
                          className="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12"
                          key={donation.id}
                        >
                          <div className="servicebox">
                            <div className="service-icon">
                              <img
                                src={donation.thumbnail_image}
                                alt={donation.name}
                              />
                            </div>
                            <div className="donatin-rupees-hed-text">
                              <h4>
                                {donation.name}
                                <span>({donation.description})</span>
                              </h4>
                            </div>
                            <div className="progressbar-devotees">
                              <ProgressBar
                                className="progressbarbox"
                                completed={donation.raised}
                                maxCompleted={donation.goal}
                                customLabel=" "
                                bgColor="#ae0015"
                                baseBgColor="#ae001561"
                              />
                            </div>
                            <div className="donatin-value">
                              <div className="donatin-rupees">
                                <p>
                                  ₹ {donation.raised ? donation.raised : 0}
                                  <span>Raised</span>
                                </p>
                              </div>
                              <div
                                className="donatin-rupees"
                                style={{ textAlign: 'right' }}
                              >
                                <p>
                                  ₹ {donation.goal}
                                  <span>Goal</span>
                                </p>
                              </div>
                            </div>
                            <div className="donation-btn">
                              <button
                                className="orangebtn"
                                style={{ width: '100%' }}
                                onClick={() => {
                                  localStorage.setItem(
                                    'donationName',
                                    donation.name
                                  );

                                  navigate('/bhadarvi-poonam-donation');
                                }}
                              >
                                Donate Now
                              </button>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default BhadarviPoonam;
