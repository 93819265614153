import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const login = (requestData) => {
  return http.post(api_urls?.login, requestData);
};

const LoginService = {
  login,
  //   signUp,
  //   sendOTP,
  //   verifyOTP,
  //   resetPassword,
};
export default LoginService;
