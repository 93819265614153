import React, { useEffect, useRef } from 'react';
import { useState } from 'react';
import { useFormik } from 'formik';
import Select from 'react-select';
import './sevaCamp.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import SevaCampService from '../../../Api_config/Bhadarvi_poonam/sevaCamp';
import UtilsService from '../../../Api_config/Home_page/utils';
import { routes } from '../../../_Constants/routes';
import { SevaCampSchema } from '../../../_Constants/validations';
import { RxCross1 } from 'react-icons/rx';
import { Helmet } from 'react-helmet-async';
import { format, subDays } from 'date-fns';
import { useHeading } from '../../Bhadarvi_poonam/header'; // Import the custom hook
const SevaCamp = (props) => {
  const [backendErrors, setBackendErrors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const { data } = props;
  const [registrationNumber, setRegistrationNumber] = useState('');
  const [vehicleError, setVehicleError] = useState('');
  // const history = useHistory();
  const [showregmsg, setShowRegMessage] = useState(false);

  const navigate = useNavigate();
  const [amount, setAmount] = useState('');
  const [showDetails, setShowDetails] = useState(true);
  const [campList, setCampList] = useState([]);
  const [villageList, setVillageList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [vehicleCategoryList, setVehicleCategoryList] = useState([]);
  const [talukaList, setTalukaList] = useState([]);
  const [isHideIdentity, setIsHideIdentity] = useState(false);
  const [showEnglishForm, setShowEnglishForm] = useState(true);
  const [showGujaratiForm, setShowGujaratiForm] = useState(false);
  // const [validationSchema, setValidationSchema] = useState(sevaCampSchema);
  const [accepted, setAccepted] = useState(false);
  const [arrivalList, setArrivalList] = useState([]);
  const [file, setFile] = useState();
  const [validate, setValidateForm] = useState();

  const [filePreview1, setFilePreview1] = useState();
  const [fileObject1, setFileObject1] = useState();
  const [filePreview2, setFilePreview2] = useState();
  const [filePreview3, setFilePreview3] = useState();
  const [fileError, setFileError] = useState(false);
  const [vehicleCount, setVehicleCount] = useState(0);
  const [isShowToster, setShowToster] = useState(false);
  const [showOtherInput, setShowOtherInput] = useState(false); // Step 1: State variable
  const [phoneCodeList, setPhoneCodeList] = useState([]);
  const [isNumberEditable, setNumberEditable] = useState(true);
  const [otp, setOTP] = useState();
  const [seconds, setSeconds] = useState(30);
  const [isOTPSend, setOTPSend] = useState(false);
  const [isResend, setResend] = useState(false);
  const [isNumberRegistered, setNumberRegistered] = useState(false);
  const imgref1 = useRef();
  const imgref2 = useRef();
  const imgref3 = useRef();
  const [isShowForm, setShowForm] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [userData, setUserData] = useState();
  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState('');
  const [campCategoryList, setCampCategoryList] = useState([]);
  const [error, setError] = useState('');
  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [showOtherDistrictInput, setShowOtherDistrictInput] = useState(false);
  const vehicleNumberPattern1 = /^[A-Z]{1,2}\d{2}[A-Z]{1,2}\d{4}$/;
  const vehicleNumberPattern2 = /^[A-Z]{1,2}\d{2}[A-Z]{1,2}\d{4}$/;
  const vehicleNumberPattern3 = /^[A-Z]{1,2}\d{2}[A-Z]{1,2}\d{4}$/;
  const vehicleNumberPattern4 = /^[A-Z]{1,2}\d{2}[A-Z]{1,2}\d{4}$/;
  const [vehicleError1, setVehicleError1] = useState('');
  const [vehicleError2, setVehicleError2] = useState('');
  const [vehicleError3, setVehicleError3] = useState('');
  const [vehicleError4, setVehicleError4] = useState('');
  const heading = useHeading(); // Call the custom hook
  const fileFields = [
    { field: 'identity_proof', previewSetter: setFilePreview1 }
  ];
  const [otherDistrict, setOtherDistrict] = useState('');
  const [districtDisplayValue, setDistrictDisplayValue] = useState(''); // To manage display value in dropdown
  const [customDistrictValue, setCustomDistrictValue] = useState('');

  const idProofOptions = [
    { value: 'Aadhar Card', label: 'Aadhar Card' },
    // { value: "Pan card", label: "Pan Card" },
    { value: 'Passport', label: 'Passport' },
    { value: 'Driving License', label: 'Driving License' },
    { value: 'Election Card', label: 'Election Card' },
    { value: 'Other', label: 'Other Government Valid ID Proof' }
  ];

  useState(false);
  const validateVehicleNumberOp1 = (value) => {
    if (!vehicleNumberPattern1.test(value)) {
      return 'Vehicle number is invalid';
    }
    return '';
  };
  const validateVehicleNumberOp2 = (value) => {
    if (!vehicleNumberPattern2.test(value)) {
      return 'Vehicle number is invalid';
    }
    return '';
  };
  useEffect(() => {
    const isAccepted = localStorage.getItem('accepted');
    if (!JSON.parse(isAccepted)) {
      window.location.href = 'seva-terms-conditions';
    }
    UtilsService.getAllCountry()
      .then((res) => {
        let countryList = res.data.data.map(function (country) {
          return { value: country.id, label: country.name };
        });
        // console.log(countryList);
        // setCountryList(countryList);
        setPhoneCodeList(res.data.data);
      })
      .catch((e) => {
        navigate(routes?.error500);
        // console.log(e);
      });

    SevaCampService.getAllCampCategory()
      .then((res) => {
        let campCategoryList = res.data.data.map(function (campCategory) {
          return { value: campCategory.id, label: campCategory.name };
        });
        // console.log(campCategoryList);
        setCampCategoryList(campCategoryList);
      })
      .catch((e) => {
        // navigate(routes?.error500);
        // console.log(e);
      });

    SevaCampService.getAllVehicleCategory()
      .then((res) => {
        let vehicleCategoryList = res.data.data.map(function (vehicleCategory) {
          return { value: vehicleCategory.id, label: vehicleCategory.name };
        });
        // console.log(vehicleCategoryList);
        setVehicleCategoryList(vehicleCategoryList);
      })
      .catch((e) => {
        // navigate(routes?.error500);
        // console.log(e);
      });
    SevaCampService.getArivalroute()
      .then((res) => {
        let arrivalList = res.data.data.map(function (arrivalroute) {
          return { value: arrivalroute.id, label: arrivalroute.name };
        });
        // console.log(campCategoryList);
        setArrivalList(arrivalList);
      })
      .catch((e) => {
        // navigate(routes?.error500);
        // console.log(e);
      });
  }, []);
  useEffect(() => {
    fileFields.forEach((fileField) => {
      if (data?.[fileField.field]) {
        fileField.previewSetter(data?.[fileField.field]);
      }
    });
  }, [data, showOtherInput]);
  useEffect(() => {
    if (data?.identity_proof) {
      setFilePreview1(data?.identity_proof);
    }
  }, [data?.identity_proof]);

  useState(false);
  const validateGujaratiText = (value) => {
    const gujaratiPattern = /[\u0A80-\u0AFF]/; // Pattern to detect Gujarati characters
    if (gujaratiPattern.test(value)) {
      return 'Gujarati text is not allowed';
    }
    return '';
  };
  const handleRegisterClick = async () => {
    const isValid = await formik.validateForm();
    formik.handleSubmit();
  };
  const formik = useFormik({
    initialValues: {
      name: '',
      start_date: '',
      end_date: '',
      completion_construction_date: data?.completion_construction_date ?? '',

      trust_name: data?.trust_name ?? '',
      address_line_1: data?.address_line_1 ?? '',
      address_line_2: data?.address_line_2 ?? '',
      village: data?.village ?? '',
      // village_id: data?.village_id ?? "",
      taluka: data?.taluka ?? '',
      // taluka_id: data?.taluka_id ?? "",
      district: data?.district ?? '',

      // district: showOtherDistrictInput ? otherDistrict : data.district,

      // district_id: data?.district_id ?? "",
      representative_name: data?.representative_name ?? '',
      representative_address: data?.representative_address ?? '',
      representative_primary_contact_number:
        data?.representative_primary_contact_number ?? '',
      representative_secondary_contact_number:
        data?.representative_secondary_contact_number ?? '',
      representative_email_id: data?.representative_email_id ?? '',
      seva_camp_type_id: data?.camp ?? '',
      identity_proof_type: data?.identity_proof_type ?? '',
      no_of_vehicles: data?.no_of_vehicles ?? '',
      vehicle1_category_id: data?.vehicle1_category_id ?? '',
      vehicle2_category_id: data?.vehicle2_category_id ?? '',
      arrival_route_id: data?.arrival_route_id ?? '',
      vehicle1_number: data?.vehicle1_number ?? '',
      vehicle2_number: data?.vehicle2_number ?? '',
      identity_proof: data?.identity_proof ?? '',
      // camp_map: data?.camp_map ?? "",
      // electricity_payment_receipt: data?.electricity_payment_receipt ?? "",
      garbage_collection_and_dumping_details:
        data?.garbage_collection_and_dumping_details ?? '',
      representative_whatsapp_code_id: '1',
      representative_contact_code_id: '1',
      // representative_secondary_contact_number: "",
      status_id: '1',
      other_camp_type_details: data?.other_camp_type_details ?? ''
    },
    validationSchema: SevaCampSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    handleRegisterClick,
    onSubmit: (values, { setSubmitting }) => {
      const formData = new FormData();
      // console.log(values);
      if (filePreview1 && accepted) {
        if (file) {
          // console.log(identity_proof);
          // console.log(file);
          formData.append('identity_proof', file);
          // console.log(identity_proof);
        } else if (data) {
          formData.append('identity_proof', data.identity_proof);
          // console.log(data.identity_proof);
        }
        // formData.append("identity_proof", values.identity_proof);
        formData.append('name', values.name);
        formData.append('start_date', values.start_date);
        formData.append(
          'fire_verification_date ',
          values.fire_verification_date
        );
        formData.append('end_date', values.end_date);
        formData.append(
          'completion_construction_date',
          values.completion_construction_date
        );
        formData.append('identity_proof_type', values.identity_proof_type);
        formData.append('trust_name', values.trust_name);
        formData.append('address_line_1', values.address_line_1);
        formData.append('address_line_2', values.address_line_2);
        formData.append('village', values.village);
        formData.append('taluka', values.taluka);
        formData.append('district', values.district);
        formData.append('arrival_route_id', values.arrival_route_id);

        formData.append('representative_name', values.representative_name);
        formData.append(
          'representative_address',
          values.representative_address
        );
        formData.append(
          'representative_primary_contact_number',
          values.representative_primary_contact_number
        );
        formData.append(
          'representative_secondary_contact_number',
          values.representative_secondary_contact_number
        );
        formData.append(
          'representative_email_id',
          values.representative_email_id
        );
        formData.append('seva_camp_type_id', values.seva_camp_type_id);
        formData.append('no_of_vehicles', values.no_of_vehicles);
        formData.append('vehicle1_category_id', values.vehicle1_category_id);
        formData.append('vehicle2_category_id', values.vehicle2_category_id);
        formData.append('vehicle1_number', values.vehicle1_number);
        formData.append('vehicle2_number', values.vehicle2_number);

        formData.append(
          'garbage_collection_and_dumping_details',
          values.garbage_collection_and_dumping_details
        );
        formData.append('representative_whatsapp_code_id', '1');
        formData.append('representative_contact_code_id', '1');
        formData.append('status_id', '1');
        formData.append(
          'other_camp_type_details',
          values.other_camp_type_details
        );
        // Debugging: Log the formData content
        for (let pair of formData.entries()) {
          // console.log(pair[0] + ": " + pair[1]);
        }
        //  main code

        SevaCampService.create(formData)
          .then((res) => {
            // console.log("in create api ");
            if (res.data.success) {
              toast.success(res.data.message, { autoClose: 1000 });
              setSuccessMessage(res.data.message);
              setFileError(false);
              const fetchedRegistrationNumber =
                res.data.data.registration_number;
              setRegistrationNumber(fetchedRegistrationNumber);
              navigate(`/seva-camp-booking-receipt/${res?.data?.data?.id}`, {
                // state: {
                //   mobileNumber: values.representative_primary_contact_number,
                //   vehicleNumber1: values.vehicle1_number,
                //   vehicleNumber2: values.vehicle2_number,
                //   registration_number: fetchedRegistrationNumber,
                // },
              });
            } else {
              setSuccessMessage(res.data.message);
              setFileError(true);
            }
          })
          .catch((error) => {
            let errorMessage = 'An error occurred. Please try again later.';
            if (error.response && error.response.data) {
              const responseData = error.response.data;
              if (responseData.error) {
                const validationErrors = responseData.error;
                errorMessage = Object.values(validationErrors)
                  .map((err) => err.message)
                  .join(', ');
              } else if (responseData.message) {
                errorMessage = responseData.message;
              }
            }
            setBackendErrors(errorMessage);
            toast.error(errorMessage, { autoClose: 1500 });
          });

        setTimeout(() => {
          setSubmitting(false);
        }, 1000);
      } else {
        alert('You must accept declaration.');
        if (!file) {
          setFileError(true);
        }
        if (!accepted) {
          // handleNextButtonClick();
        }
      }
      // navigate(routes?.error500);
    }
  });
  if (!accepted) {
    formik.handleBlur('accepted');
  }

  const fileChange = (e, event) => {
    if (event == 1) {
      const imageUrl1 = URL.createObjectURL(e.target.files[0]);
      setFilePreview1(imageUrl1);
    } else if (event == 2) {
      const imageUrl2 = URL.createObjectURL(e.target.files[0]);
      setFilePreview2(imageUrl2);
    } else if (event == 3) {
      const imageUrl3 = URL.createObjectURL(e.target.files[0]);
      setFilePreview3(imageUrl3);
    }

    setFile(e.target.files[0]);
  };
  // Function to calculate the minimum allowed date based on the selected start date
  const getMinMaxDates = (startDate) => {
    if (!startDate) {
      // Default values if startDate is not available
      return {
        minDate: '',
        maxDate: ''
      };
    }

    const startDateObj = new Date(startDate);
    const minDateObj = subDays(startDateObj, 10); // 10 days before start date
    const maxDateObj = subDays(startDateObj, 1); // 1 day before start date

    return {
      minDate: format(minDateObj, 'yyyy-MM-dd'),
      maxDate: format(maxDateObj, 'yyyy-MM-dd')
    };
  };
  const { minDate, maxDate } = getMinMaxDates(formik.values.start_date);

  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  /**
   * Verify OTP
   * @param representative_primary_contact_number
   */
  const verifyOTP = () => {
    if (otp?.length === 6) {
      formik.setFieldValue('first_name', '');
      formik.setFieldValue('last_name', '');
      formik.setFieldValue('email_id', '');
      formik.setFieldValue('address_line_1', '');
      formik.setFieldValue('address_line_2', '');
      formik.setFieldValue('city_id', '');
      formik.setFieldValue('country_id', '');
      formik.setFieldValue('province_id', '');
      formik.setFieldValue('zipcode', '');
      formik.setFieldValue('pan_number', '');
      // formik?.resetForm()

      SevaCampService.verifyOTP(
        formik?.values?.representative_primary_contact_number,
        otp
      )
        .then((res) => {
          if (res.data.success) {
            if (userData) {
              // setOpenModal(true);
            }
            setShowForm(true);
            setIsOTPVerified(true);
            // setOTPVerified(true);
          }
        })
        .catch((error) => {
          // navigate(routes?.error500);
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    }
  };
  // ----------

  /**
   * Resend OTP
   * @param representative_primary_contact_number
   */
  const resendOTP = () => {
    SevaCampService.sendOTP(
      formik?.values?.representative_primary_contact_number
    )
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        // navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };

  // number registered or not
  const checkForContactNumber = (event) => {
    if (formik?.values?.representative_primary_contact_number) {
      SevaCampService.getBySevaCampKeyword(
        formik?.values?.representative_primary_contact_number
      ).then((res) => {
        if (res.data.success) {
          if (res.data.data.length !== 0) {
            // setError('This contact number is already registered.');
            // toast.error('This contact number is already registered.', {
            //   autoClose: 1500
            // });
            setShowRegMessage(true);
          } else {
            setNumberRegistered(true);
            setNumberEditable(false);
            setUserData(res.data.data[0]);
            setShowRegMessage(false);
            setVerifiedPhoneNumber(
              formik?.values.representative_primary_contact_number
            );

            SevaCampService.sendOTP(
              formik?.values?.representative_primary_contact_number
            )
              .then((res) => {
                if (res.data.success) {
                  setOTPSend(true);
                  setSeconds(30);
                }
              })
              .catch((error) => {
                // navigate(routes?.error500);
                toast.error(error.response?.data?.message, {
                  autoClose: 1500
                });
              });
          }
        }
      });
    }
  };

  const handleNextButtonClick = () => {
    if (accepted) {
      window.location.href = 'seva-camp-receipt';
    } else {
      alert('You must accept declaration.');
    }
  };
  const handleDistrictChange = (selectedOption) => {
    if (selectedOption && selectedOption.value === 'Other') {
      setShowOtherDistrictInput(true);
      formik.setFieldValue('district', ''); // Clear the value for custom input
    } else {
      setShowOtherDistrictInput(false);
      setCustomDistrictValue(''); // Reset custom district value
      formik.setFieldValue(
        'district',
        selectedOption ? selectedOption.value : ''
      );
    }
  };

  const handleOtherDistrictChange = (event) => {
    const value = event.target.value;
    setCustomDistrictValue(value);
    formik.setFieldValue('district', value); // Set custom value without "Other" prefix
  };
  const { handleChange, handleSubmit } = formik;

  return (
    <>
      {/* helmet  */}
      <Helmet>
        <title>Seva Camp Registration | Ambaji Bhadarvi Poonam 2024</title>
        <meta
          name="description"
          content="Register Your Seva Camp for Ambaji Bhadarvi Poonam Fair 2024"
        />
        <meta
          name="keywords"
          content="Ambaji Bharavi Poonam 2024, Ambaji Temple, Bharavi Poonam festival, Ambaji pilgrimage, spiritual events, religious festivals India, Ambaji celebrations, Hindu festivals, Ambaji Poonam schedule, visit Ambaji Temple, divine rituals, cultural festivities, sacred events Gujarat, Ambaji event details, Seva Camp Registration, Sangh Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* helmet  */}
      <div className="sang-wrapper">
        <section className="header-banner seva-camp-bg">
          <div className="container">{heading}</div>
        </section>

        <section className="seva-camp">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h3 style={{ color: '#ae0015' }}>
                  નોંધ : સેવા કેમ્પ ની વિગતો અંગ્રેજી માં ભરવા વિનંતી.
                </h3>
              </div>
            </div>
            <div className="seva-camp-box">
              {!isOTPVerified && (
                <div className="row">
                  <div className="form-group">
                    <div className="row" style={{ rowGap: '10px' }}>
                      <div className="col-xl-7 col-lg-7 col-md-10 col-sm-12">
                        <div className="row " style={{ rowGap: '10px' }}>
                          <div className="col-xl-6 col-lg-6 col-md-7">
                            <label>
                              Organiser's Phone Number /<br /> આયોજકનો સંપર્ક
                              નંબર
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              inputMode="numeric"
                              placeholder="Contact Number"
                              className="form-control"
                              name="representative_primary_contact_number"
                              value={
                                formik?.values
                                  .representative_primary_contact_number
                              }
                              // onChange={handleChange}
                              onChange={(e) => {
                                const value = e.target.value;
                                // Allow only digits and limit length to 10
                                if (/^\d{0,10}$/.test(value)) {
                                  handleChange(e);
                                }
                              }}
                              disabled={!isNumberEditable}
                              maxLength="10"
                              pattern="\d{10}"
                              required
                            />
                            {/* <p className="mb-1 red-text">
                                {formik?.errors?.representative_primary_contact_number}
                              </p> */}
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-5 d-flex align-items-end">
                            {isNumberEditable ? (
                              <button
                                type="button"
                                className="whitebtn"
                                onClick={() => checkForContactNumber()}
                              >
                                Verify Number
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="whitebtn"
                                onClick={() => [
                                  setNumberEditable(true),
                                  setNumberRegistered(false)
                                ]}
                              >
                                Edit Number
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      {showregmsg && (
                        <div style={{ color: '#ae0015' }}>
                          You have already applied for Seva Camp with this
                          contact number.
                        </div>
                      )}
                    </div>
                  </div>
                  {isNumberRegistered && !isNumberEditable && !isShowForm && (
                    <div className="form-group">
                      <div
                        className="row align-items-end"
                        style={{ rowGap: '5px' }}
                      >
                        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-7">
                          <label htmlFor="otp">OTP</label>
                          <input
                            type="text"
                            inputMode="numeric"
                            minLength="6"
                            maxLength="6"
                            pattern="[0-9]*" // Only allow numeric input
                            name="otp"
                            id="otp"
                            className="form-control"
                            placeholder="_ _ _ _ _ _"
                            value={otp}
                            onChange={(event) => {
                              const inputValue = event.target.value.replace(
                                /\D/g,
                                ''
                              ); // Remove non-digit characters
                              setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                              setResend(false); // Reset the resend state if needed
                            }}
                          />
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
                          <button
                            type="button"
                            className="orangebtn"
                            onClick={() => verifyOTP()}
                            disabled={otp?.length !== 6}
                          >
                            Verify OTP
                          </button>

                          {isOTPSend && seconds == '00' && (
                            <button
                              type="button"
                              className="whitebtn ms-1"
                              onClick={() => [
                                resendOTP(),
                                setOTP(''),
                                setResend(true)
                              ]}
                            >
                              Resend OTP
                            </button>
                          )}
                          {/* <button
                        type="button"
                        className="whitebtn ms-3"
                        // onClick={() => [resendOTP(), setOTP(""), setResend(true)]}
                      >
                        Resend OTP
                      </button> */}
                        </div>
                        {isOTPSend && seconds > 0 ? (
                          <p className="mb-0 mt-1">
                            Resend OTP in
                            {seconds < 10 ? ` 0${seconds}` : ` ${seconds}`} sec
                          </p>
                        ) : (
                          <p className="mb-0"></p>
                        )}
                        {!isResend && (otp?.length > 6 || otp?.length < 6) && (
                          <p className="mb-0" style={{ color: '#ae0015' }}>
                            OTP must be a 6 digits number
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {isShowForm && !isNumberEditable ? (
                <div className="row align-items-center">
                  <div className="col-lg-12">
                    <form onSubmit={formik.handleSubmit}>
                      <div className="form-group">
                        <label>
                          Name of Seva Camp /<br /> સેવા કેમ્પનું નામ{' '}
                          <span style={{ color: '#ae0015' }}>*</span>
                        </label>
                        <input
                          type="text"
                          className={`form-control ${
                            formik.touched.name && formik.errors.name
                              ? 'is-invalid'
                              : ''
                          }`}
                          name="name"
                          required
                          placeholder="Name of Seva Camp"
                          value={formik?.values.name}
                          onChange={(e) => {
                            formik.handleChange(e);
                            formik.setFieldTouched('name', true, true); // Ensure field is marked as touched
                          }}
                          onBlur={(e) => {
                            formik.handleBlur(e);
                            formik.setFieldTouched('name', true, true); // Ensure field is marked as touched
                          }}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div style={{ color: '#ae0015' }}>
                            {formik.errors.name}
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Start Date /<br /> સેવા કેમ્પ શરુ કરવાની તારીખ{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="date"
                              // placeholder=" Start Date / સેવા કેમ્પ શરુ કરવાની તારીખ"
                              className={`form-control ${
                                formik.touched.start_date &&
                                formik.errors.start_date
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="start_date"
                              value={formik?.values.start_date}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched(
                                  'start_date',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched(
                                  'start_date',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                              max="2024-09-18"
                              min="2024-09-1"
                            />
                            {formik.touched.start_date &&
                              formik.errors.start_date && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.start_date}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              End Date /<br /> સેવા કેમ્પ પૂર્ણ કરવાની તારીખ{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="date"
                              // placeholder="End Date / સેવા કેમ્પ પૂર્ણ કરવાની તારીખ"
                              className={`form-control ${
                                formik.touched.end_date &&
                                formik.errors.end_date
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="end_date"
                              value={formik?.values.end_date}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched('end_date', true, true); // Ensure field is marked as touched
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched('end_date', true, true); // Ensure field is marked as touched
                              }}
                              max="2024-09-18"
                              min={formik.values.start_date || '2024-09-1'}
                            />
                            {formik.touched.end_date &&
                              formik.errors.end_date && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.end_date}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Date of completion of construction / બાંધકામ પૂર્ણ
                              થયાની તારીખ{' '}
                            </label>
                            <input
                              type="date"
                              className={`form-control ${
                                formik.touched.completion_construction_date &&
                                formik.errors.completion_construction_date
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="completion_construction_date"
                              value={formik.values.completion_construction_date}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              min={minDate} // Set minimum date dynamically
                              max={maxDate} // Set maximum date dynamically
                            />
                            {formik.touched.completion_construction_date &&
                              formik.errors.completion_construction_date && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.completion_construction_date}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Name of the Trust conducting the Seva Camp /{' '}
                              <br />
                              સેવા કેમ્પ કરનાર ટ્રસ્ટનું નામ{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              // placeholder="Name of the Trust conducting the Seva Camp / સેવા કેમ્પ કરનાર ટ્રસ્ટનું નામ"
                              className={`form-control ${
                                formik.touched.trust_name &&
                                formik.errors.trust_name
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="trust_name"
                              value={formik?.values.trust_name}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched(
                                  'trust_name',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched(
                                  'trust_name',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                            />
                            {formik.touched.trust_name &&
                              formik.errors.trust_name && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.trust_name}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Organiser’s Phone Number / <br />
                              આયોજકનો સંપર્ક નંબર{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                inputMode="numeric"
                                disabled
                                // placeholder="Organiser Phone / આયોજકનો સંપર્ક નંબર *"
                                className={`form-control ${
                                  formik.touched
                                    .representative_primary_contact_number &&
                                  formik.errors
                                    .representative_primary_contact_number
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="representative_primary_contact_number"
                                value={verifiedPhoneNumber}
                                // value={
                                //   formik?.values.representative_primary_contact_number
                                // }
                                // onChange={formik.handleChange}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Allow only digits and limit length to 10
                                  if (/^\d{0,10}$/.test(value)) {
                                    handleChange(e);
                                  }
                                }}
                                onBlur={formik.handleBlur}
                                maxLength="10"
                                pattern="\d{10}"
                              />
                            </div>
                            {formik.touched
                              .representative_primary_contact_number &&
                              formik.errors
                                .representative_primary_contact_number && (
                                <div style={{ color: '#ae0015' }}>
                                  {
                                    formik.errors
                                      .representative_primary_contact_number
                                  }
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Secondary Contact Number / <br />
                              વૈકલ્પિક સંપર્ક નંબર{' '}
                            </label>
                            <div className="input-group">
                              <input
                                type="text"
                                inputMode="numeric"
                                // placeholder="9876598765"
                                className={`form-control ${
                                  formik.touched
                                    .representative_secondary_contact_number &&
                                  formik.errors
                                    .representative_secondary_contact_number
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="representative_secondary_contact_number"
                                value={
                                  formik?.values
                                    .representative_secondary_contact_number
                                }
                                onChange={(e) => {
                                  const value = e.target.value;
                                  // Allow only digits and limit length to 10
                                  if (/^\d{0,10}$/.test(value)) {
                                    handleChange(e);
                                  }
                                }}
                                onBlur={formik.handleBlur}
                                maxLength={10}
                              />
                            </div>
                            {formik.touched
                              .representative_secondary_contact_number &&
                              formik.errors
                                .representative_secondary_contact_number && (
                                <div style={{ color: '#ae0015' }}>
                                  {
                                    formik.errors
                                      .representative_secondary_contact_number
                                  }
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Seva Camp Address Line 1 / <br />
                              સેવા કેમ્પ સ્થળનું સરનામું 1{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              // placeholder="Address Line 1"
                              className={`form-control ${
                                formik.touched.address_line_1 &&
                                formik.errors.address_line_1
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="address_line_1"
                              value={formik?.values.address_line_1}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched(
                                  'address_line_1',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched(
                                  'address_line_1',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                            />
                            {formik.touched.address_line_1 &&
                              formik.errors.address_line_1 && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.address_line_1}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Seva Camp Address Line 2 / <br />
                              સેવા કેમ્પ સ્થળનું સરનામું 2
                            </label>
                            <input
                              type="text"
                              // placeholder="Address Line 2"
                              className="form-control"
                              name="address_line_2"
                              value={formik?.values.address_line_2}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched(
                                  'address_line_2',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched(
                                  'address_line_2',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Village / ગામ{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              // placeholder="Village"
                              className={`form-control ${
                                formik.touched.village && formik.errors.village
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="village"
                              value={formik?.values.village}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched('village', true, true); // Ensure field is marked as touched
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched('village', true, true); // Ensure field is marked as touched
                              }}
                            />
                            {formik.touched.village &&
                              formik.errors.village && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.village}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              Taluka / તાલુકો
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              // placeholder="Taluka"
                              className={`form-control ${
                                formik.touched.taluka && formik.errors.taluka
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="taluka"
                              value={formik?.values.taluka}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched('taluka', true, true); // Ensure field is marked as touched
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched('taluka', true, true); // Ensure field is marked as touched
                              }}
                            />
                            {formik.touched.taluka && formik.errors.taluka && (
                              <div style={{ color: '#ae0015' }}>
                                {formik.errors.taluka}
                              </div>
                            )}
                          </div>
                        </div>

                        {/* <div className="col-md-4">
                        <div className="form-group">
                          <label>
                            District / જિલ્લો{" "}
                            <span style={{ color: "#ae0015" }}>*</span>
                          </label>
                          <Select
                            name="district"
                            className={
                              formik.errors.district && formik.touched.district
                                ? "is-invalid"
                                : "valid"
                            }
                            options={[
                              { value: "Banaskantha", label: "Banaskantha" },
                              { value: "Other", label: "Other" },
                            ]}
                            onBlur={() =>
                              formik.setFieldTouched("district", true)
                            }
                            onChange={(selectedOption) => {
                              if (
                                selectedOption &&
                                selectedOption.value === "Other"
                              ) {
                                setShowOtherDistrictInput(true);
                                formik.setFieldValue("district", "");
                              } else {
                                setShowOtherDistrictInput(false);
                                formik.setFieldValue(
                                  "district",
                                  selectedOption ? selectedOption.value : ""
                                );
                              }
                            }}
                            value={
                              formik.values.district
                                ? {
                                    value: formik.values.district,
                                    label: formik.values.district,
                                  }
                                : null
                            }
                            isSearchable={true}
                          />
                          <br />
                          {showOtherDistrictInput && (
                            <input
                              type="text"
                              name="district"
                              value={formik.values.district}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={`form-control ${
                                formik.touched.district &&
                                formik.errors.district
                                  ? "is-invalid"
                                  : ""
                              }`}
                              placeholder="Enter district"
                            />
                          )}
                          {formik.errors.district &&
                            formik.touched.district && (
                              <div
                                className="invalid-feedback"
                                style={{ color: "#ae0015" }}
                              >
                                {formik.errors.district}
                              </div>
                            )}
                        </div>
                      </div> */}
                        <div className="col-md-4">
                          <div className="form-group">
                            <label>
                              District / જિલ્લા{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <Select
                              name="district"
                              className={
                                formik.errors.district &&
                                formik.touched.district
                                  ? 'is-invalid'
                                  : 'valid'
                              }
                              options={[
                                { value: 'Banaskantha', label: 'Banaskantha' },
                                { value: 'Other', label: 'Other' }
                              ]}
                              onBlur={() =>
                                formik.setFieldTouched('district', true)
                              }
                              onChange={handleDistrictChange}
                              value={
                                formik.values.district === 'Other' ||
                                showOtherDistrictInput
                                  ? { value: 'Other', label: 'Other' }
                                  : {
                                      value: formik.values.district,
                                      label: formik.values.district
                                    }
                              }
                              isSearchable={true}
                            />
                            <p className="mt-1 mb-0"></p>
                            {showOtherDistrictInput && (
                              <input
                                type="text"
                                name="otherDistrict"
                                value={customDistrictValue}
                                onChange={handleOtherDistrictChange}
                                onBlur={formik.handleBlur}
                                className={`form-control ${
                                  formik.touched.district &&
                                  formik.errors.district
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                placeholder="Enter district"
                              />
                            )}
                            {formik.errors.district &&
                              formik.touched.district && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.district}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Road of Seva Camp Establishment / સેવા કેમ્પ
                              કરવાનો માર્ગ
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <Select
                              name="arrival_route_id"
                              className={
                                formik?.errors.arrival_route_id &&
                                formik?.touched.arrival_route_id
                                  ? 'is-invalid'
                                  : 'valid'
                              }
                              options={arrivalList}
                              onBlur={() =>
                                formik?.setFieldTouched(
                                  'arrival_route_id',
                                  true
                                )
                              }
                              onChange={(opt) => {
                                formik.setFieldValue(
                                  'arrival_route_id',
                                  opt ? opt.value : ''
                                );
                              }}
                              value={
                                arrivalList.find(
                                  (option) =>
                                    option.value ===
                                    formik.values.arrival_route_id
                                ) || null
                              }
                              isSearchable={true}
                            />
                            {formik.touched.arrival_route_id &&
                              formik.errors.arrival_route_id && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.arrival_route_id}
                                </div>
                              )}
                            {/* {showOtherInput ? (
                            <input
                              type="text"
                              name="other_camp_type_details"
                              value={formik.values.other_camp_type_details}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className="form-control"
                              // placeholder="Enter other camp type"
                            />
                          ) : null}
                          {formik.errors.seva_camp_type_id &&
                            formik.touched.seva_camp_type_id && (
                              <div
                                className="invalid-feedback"
                                style={{ color: "#ae0015" }}
                              >
                                {formik.errors.seva_camp_type_id}
                              </div>
                            )} */}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Name of Head Person of Seva Camp / સેવા કેમ્પના
                              મુખ્ય વ્યક્તિનું નામ{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              // placeholder="Name of Head Person of Seva Camp / સેવા કેમ્પના મુખ્ય વ્યક્તિનું નામ"
                              className={`form-control ${
                                formik.touched.representative_name &&
                                formik.errors.representative_name
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="representative_name"
                              value={formik?.values.representative_name}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched(
                                  'representative_name',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched(
                                  'representative_name',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                            />
                            {formik.touched.representative_name &&
                              formik.errors.representative_name && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.representative_name}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Organization Person Address / સંસ્થા - વ્યક્તિનું
                              સરનામું
                            </label>
                            <input
                              type="text"
                              // placeholder="Organization Person Address / સંસ્થા - વ્યક્તિનું સરનામું"
                              className={`form-control ${
                                formik.touched.representative_address &&
                                formik.errors.representative_address
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="representative_address"
                              value={formik?.values.representative_address}
                              onChange={(e) => {
                                formik.handleChange(e);
                                formik.setFieldTouched(
                                  'representative_address',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                              onBlur={(e) => {
                                formik.handleBlur(e);
                                formik.setFieldTouched(
                                  'representative_address',
                                  true,
                                  true
                                ); // Ensure field is marked as touched
                              }}
                            />
                            {formik.touched.representative_address &&
                              formik.errors.representative_address && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.representative_address}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Organiser’s E-mail / ઈ-મેલ એડ્રેસ</label>
                            <input
                              type="text"
                              placeholder="Ex: info@ambajitemple.in"
                              className={`form-control ${
                                formik.touched.representative_email_id &&
                                formik.errors.representative_email_id
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="representative_email_id"
                              value={formik?.values.representative_email_id}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.representative_email_id &&
                              formik.errors.representative_email_id && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.representative_email_id}
                                </div>
                              )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              ID Proof / Trust Registration Certificate / આઇડી
                              પ્રૂફ <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <Select
                              name="identity_proof_type"
                              className={
                                formik?.errors.identity_proof_type &&
                                formik?.touched.identity_proof_type
                                  ? 'is-invalid'
                                  : 'valid'
                              }
                              options={idProofOptions}
                              onBlur={() =>
                                formik?.setFieldTouched(
                                  'identity_proof_type',
                                  true
                                )
                              }
                              onChange={(opt) => {
                                if (opt && opt.label === 'other') {
                                  setShowOtherInput(true);
                                  formik.setFieldValue(
                                    'identity_proof_type',
                                    opt.value
                                  );
                                } else {
                                  setShowOtherInput(false);
                                  formik.setFieldValue(
                                    'identity_proof_type',
                                    opt ? opt.value : ''
                                  );
                                }
                              }}
                              value={
                                idProofOptions.find(
                                  (option) =>
                                    option.value ===
                                    formik.values.identity_proof_type
                                ) || null
                              }
                              isSearchable={true}
                            />
                            {showOtherInput ? (
                              <input
                                type="text"
                                name="other_camp_type_details"
                                value={formik.values.other_camp_type_details}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control"
                              />
                            ) : null}
                            {formik.errors.identity_proof_type &&
                              formik.touched.identity_proof_type && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.identity_proof_type}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      <div className="row align-items-top">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Please Upload the ID Proof / Trust Registration
                              Certificate / આઈડી પ્રુફ / ટ્રસ્ટ રજીસ્ટ્રેશન
                              સર્ટિફીકેટ (only jpg /jpeg / png / pdf file
                              allowed)
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="file"
                              accept=".jpg, .jpeg, .png, .pdf"
                              multiple
                              className="form-control"
                              name="identity_proof"
                              onBlur={formik.handleBlur}
                              ref={imgref1}
                              onChange={(event) => fileChange(event, 1)}
                            />
                            {/* {formik.touched.identity_proof &&
                            formik.errors.identity_proof && (
                              <div style={{ color: "#ae0015" }}>
                                {formik.errors.identity_proof}
                              </div>
                            )} */}
                            {/* {formik.touched.identity_proof &&
                            formik.errors.identity_proof && (
                              <div style={{ color: "#ae0015" }}>
                                {formik.errors.identity_proof}
                              </div>
                            )} */}
                            {filePreview1 && (
                              <>
                                <div className="mt-2"></div>
                                <img
                                  src={filePreview1}
                                  className="img-preview img"
                                  style={{
                                    height: '125px',
                                    width: '125px',
                                    objectFit: 'cover'
                                  }}
                                />
                                <RxCross1
                                  style={{
                                    position: 'absolute',
                                    color: '#ae0015',
                                    fontSize: '1.5em',
                                    cursor: 'pointer'
                                  }}
                                  onClick={() => {
                                    imgref1.current.value = '';
                                    setFile();
                                    setFilePreview1();
                                  }}
                                />
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Camp Type / સેવા કેમ્પ પ્રકાર{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <Select
                              name="seva_camp_type_id"
                              className={
                                formik?.errors.seva_camp_type_id &&
                                formik?.touched.seva_camp_type_id
                                  ? 'is-invalid'
                                  : 'valid'
                              }
                              options={campCategoryList}
                              onBlur={() =>
                                formik?.setFieldTouched(
                                  'seva_camp_type_id',
                                  true
                                )
                              }
                              onChange={(opt) => {
                                if (opt && opt.label === 'other') {
                                  setShowOtherInput(true);
                                  formik.setFieldValue(
                                    'seva_camp_type_id',
                                    opt.value
                                  );
                                } else {
                                  setShowOtherInput(false);
                                  formik.setFieldValue(
                                    'seva_camp_type_id',
                                    opt ? opt.value : ''
                                  );
                                }
                              }}
                              value={
                                campCategoryList.find(
                                  (option) =>
                                    option.value ===
                                    formik.values.seva_camp_type_id
                                ) || null
                              }
                              isSearchable={true}
                            />
                            {showOtherInput ? (
                              <input
                                type="text"
                                name="other_camp_type_details"
                                value={formik.values.other_camp_type_details}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control"
                                // placeholder="Enter other camp type"
                              />
                            ) : null}
                            {formik.errors.seva_camp_type_id &&
                              formik.touched.seva_camp_type_id && (
                                <div
                                  className="invalid-feedback"
                                  style={{ color: '#ae0015' }}
                                >
                                  {formik.errors.seva_camp_type_id}
                                </div>
                              )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label>
                              Number of Vehicles / સંઘના સીધા સામાન માટેના વાહન
                              સંખ્યા <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <Select
                              name="no_of_vehicles"
                              className={
                                formik?.errors.no_of_vehicles &&
                                formik?.touched.no_of_vehicles
                                  ? 'is-invalid'
                                  : 'valid'
                              }
                              options={[
                                { value: '1', label: '1' },
                                { value: '2', label: '2' }
                              ]}
                              onBlur={() =>
                                formik?.setFieldTouched('no_of_vehicles', true)
                              }
                              onChange={(selectedOption) => {
                                const vehicleCount =
                                  selectedOption.value === '1' ? 1 : 2;
                                setVehicleCount(vehicleCount);

                                formik.handleChange({
                                  target: {
                                    name: 'no_of_vehicles',
                                    value: selectedOption.value
                                  }
                                });
                              }}
                              value={
                                formik.values.no_of_vehicles
                                  ? {
                                      value: formik.values.no_of_vehicles,
                                      label: formik.values.no_of_vehicles
                                    }
                                  : null
                              }
                              isSearchable={false}
                            />

                            {formik.touched.no_of_vehicles &&
                              formik.errors.no_of_vehicles && (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.no_of_vehicles}
                                </div>
                              )}
                          </div>
                        </div>
                      </div>

                      {vehicleCount > 0 && (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Vehicle Category / વાહન શ્રેણી{' '}
                                <span style={{ color: '#ae0015' }}>*</span>
                              </label>
                              <Select
                                name="vehicle1_category_id"
                                className={
                                  formik?.errors.vehicle1_category_id &&
                                  formik?.touched.vehicle1_category_id
                                    ? 'is-invalid'
                                    : 'valid'
                                }
                                options={vehicleCategoryList}
                                onBlur={() =>
                                  formik?.setFieldTouched(
                                    'vehicle1_category_id',
                                    true
                                  )
                                }
                                onChange={(opt) => {
                                  formik.setFieldValue(
                                    'vehicle1_category_id',
                                    opt ? opt.value : ''
                                  );
                                }}
                                value={
                                  vehicleCategoryList.find(
                                    (option) =>
                                      option.value ===
                                      formik.values.vehicle1_category_id
                                  ) || null
                                }
                                isSearchable={true}
                              />
                              {formik.errors.vehicle1_category_id &&
                                formik.touched.vehicle1_category_id && (
                                  <div
                                    className="invalid-feedback"
                                    style={{ color: '#ae0015' }}
                                  >
                                    {formik.errors.vehicle1_category_id}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Vehicle Number / વાહન નંબર{' '}
                                <span style={{ color: '#ae0015' }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Ex:GJ01CP3333"
                                className={`form-control ${
                                  formik.touched.vehicle1_number &&
                                  (formik.errors.vehicle1_number ||
                                    vehicleError)
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="vehicle1_number"
                                value={formik?.values.vehicle1_number}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  const uppercasedValue = value.toUpperCase(); // Convert to uppercase
                                  formik.setFieldValue(name, uppercasedValue); // Update Formik's value
                                  const error =
                                    validateVehicleNumberOp1(uppercasedValue); // Validate the uppercase value
                                  setVehicleError1(error || ''); // Update error state
                                }}
                                onBlur={formik.handleBlur}
                              />
                              {(formik.touched.vehicle1_number &&
                                formik.errors.vehicle1_number) ||
                              vehicleError1 ? (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.vehicle1_number ||
                                    vehicleError1}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}
                      {vehicleCount === 2 && (
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Vehicle Category 2 / વાહન શ્રેણી 2{' '}
                                <span style={{ color: '#ae0015' }}>*</span>
                              </label>
                              <Select
                                name="vehicle2_category_id"
                                options={vehicleCategoryList}
                                onBlur={() =>
                                  formik?.setFieldTouched(
                                    'vehicle2_category_id',
                                    true
                                  )
                                }
                                onChange={(opt) => {
                                  formik.setFieldValue(
                                    'vehicle2_category_id',
                                    opt ? opt.value : ''
                                  );
                                }}
                                value={
                                  vehicleCategoryList.find(
                                    (option) =>
                                      option.value ===
                                      formik.values.vehicle2_category_id
                                  ) || null
                                }
                                isSearchable={true}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label>
                                Vehicle Number 2 / વાહન નંબર 2{' '}
                                <span style={{ color: '#ae0015' }}>*</span>
                              </label>
                              <input
                                type="text"
                                placeholder="Ex:GJ01CP3333"
                                className={`form-control ${
                                  formik.touched.vehicle2_number &&
                                  (formik.errors.vehicle2_number ||
                                    vehicleError2)
                                    ? 'is-invalid'
                                    : ''
                                }`}
                                name="vehicle2_number"
                                value={formik.values.vehicle2_number}
                                onChange={(e) => {
                                  const { name, value } = e.target;
                                  const uppercasedValue = value.toUpperCase(); // Convert to uppercase
                                  formik.setFieldValue(name, uppercasedValue); // Update Formik's value
                                  const error =
                                    validateVehicleNumberOp2(uppercasedValue); // Validate the uppercase value
                                  setVehicleError2(error || ''); // Update error state
                                }}
                                onBlur={formik.handleBlur}
                              />
                              {(formik.touched.vehicle2_number &&
                                formik.errors.vehicle2_number) ||
                              vehicleError2 ? (
                                <div style={{ color: '#ae0015' }}>
                                  {formik.errors.vehicle2_number ||
                                    vehicleError2}
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>
                              Garbage Collection and Dumping Details / <br />
                              કચરો સંગ્રહ અને ડમ્પિંગ વિગતો
                            </label>
                            <input
                              type="text"
                              // placeholder="Garbage collection and dumping details"
                              className={`form-control ${
                                formik.touched
                                  .garbage_collection_and_dumping_details &&
                                formik.errors
                                  .garbage_collection_and_dumping_details
                                  ? 'is-invalid'
                                  : ''
                              }`}
                              name="garbage_collection_and_dumping_details"
                              value={
                                formik.values
                                  .garbage_collection_and_dumping_details
                              }
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched
                              .garbage_collection_and_dumping_details &&
                              formik.errors
                                .garbage_collection_and_dumping_details && (
                                <div style={{ color: '#ae0015' }}>
                                  {
                                    formik.errors
                                      .garbage_collection_and_dumping_details
                                  }
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <label
                          style={{
                            marginBottom: '25px'
                          }}
                        >
                          <div className="d-flex">
                            <span>
                              <input
                                className="checkbox-boxes-seva"
                                type="checkbox"
                                id="accept-checkbox"
                                onChange={handleCheckboxChange}
                              />
                            </span>
                            <span>
                              <b>
                                અમારા દ્વારા ઉપરોક્ત તમામ માહિતી સાચી આપવામાં
                                આવેલ છે તેની હું બાંહેધરી આપું છું. ખોટી માહિતી
                                આપવી એ ગુનો છે તે હું જાણું છું.
                              </b>
                            </span>
                          </div>
                        </label>
                      </div>
                      {/* <button
                    className="orangebtn"
                    type="submit"
                    style={{ width: "100%" }}
                    disabled={!formik.isValid || formik.isSubmitting}
                  >
                    {formik.isSubmitting ? "Submitting..." : "Register"}
                  </button> */}
                      <button
                        className="orangebtn"
                        // type="next"
                        type="next"
                        style={{ width: '100%' }}
                        // onClick={handleRegisterClick}
                      >
                        Register
                      </button>
                      <div className="backend-error">
                        <p style={{ color: '#ae0015' }}>{backendErrors}</p>
                      </div>
                    </form>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </section>
        <Arasuri />
        <Footer />
      </div>
    </>
  );
};
export default SevaCamp;
