import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import './bhadarviPoonamLocation.scss';
import '../../../Css/innerPages.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import LazyLoader from '../../../Components/lazyLoader';
import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import { useHeading } from '../header'; // Import the custom hook
import ParkingAreaImg from '../../../Assets/Images/Bhadarvi_poonam/location-parking/parking.svg';

import { NavLink } from 'react-router-dom';

const BhadarviPoonamLocationDanta = () => {
  const [value, setValue] = useState('1'); // Default active tab is '1'
  const heading = useHeading(); // Call the custom hook
  const [selectedTab, setSelectedTab] = useState('PARKING (CAR + HEAVY)');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  return (
    <>
      <Helmet>
        <title>Ambaji Bhadarvi Poonam 2024</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Discover the divine celebration of Ambaji Bhadarvi Poonam 2024 at Ambaji Temple"
        />
        <meta
          name="keywords"
          content="Ambaji Bhadarvi Poonam 2024, Ambaji Temple, Bhadarvi Poonam festival"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner Location-banner">
            <div className="container">
              <div className="container">{heading}</div>
            </div>
          </section>

          <section className="Location">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <NavLink
                    to="/bhadarvi-poonam"
                    className="orangebtn"
                    style={{ alignItems: 'center' }}
                  >
                    પાછા જવા માટે
                  </NavLink>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="tabboxes-location">
                    <TabContext value={value}>
                      <Tabs
                        className="tab-btn-location"
                        value={value}
                        onChange={handleChange}
                        variant="scrollable"
                        allowScrollButtonsMobile
                        indicatorColor=" "
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 }
                          }
                        }}
                      >
                        <Tab
                          className={
                            selectedTab === 'PARKING (CAR + HEAVY)'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="1"
                          label="PARKING (CAR + HEAVY)"
                          onClick={() => {
                            setSelectedTab('PARKING (CAR + HEAVY)');
                          }}
                        />
                        <Tab
                          className={
                            selectedTab === 'PARKING (2 WHEELER)'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="2"
                          label="PARKING (2 WHEELER)"
                          onClick={() => {
                            setSelectedTab('PARKING (2 WHEELER)');
                          }}
                        />
                        <Tab
                          className={
                            selectedTab === 'PARKING (2 WHEELER + 4 WHEELER)'
                              ? 'btn-active-location'
                              : 'btn-act-location'
                          }
                          value="3"
                          label="PARKING (2 WHEELER + 4 WHEELER)"
                          onClick={() => {
                            setSelectedTab('PARKING (2 WHEELER + 4 WHEELER)');
                          }}
                        />
                      </Tabs>

                      <TabPanel value="1">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.291818,72.839264"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Open Area in Front of Gulabhai Vanchiya
                                      House (In Front of Idli Camp) / ગુલાબભાઈ
                                      વાંછીયા હાઉસની સામે ખુલ્લો વિસ્તાર (ઈડલી
                                      કેમ્પની સામે)
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.294445,72.839811"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      In Front of Ganesh Food Court Besides
                                      Riverbank / નદી કિનારે ગણેશ ફૂડ કોર્ટની
                                      સામે
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.294801,72.839396"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Besides Vanraji Resort Road / વનરાજી
                                      રિસોર્ટ રોડ ની બાજુ મા
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel value="2">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.309758,72.843775"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Ground of Blind Children School / દિવ્યાંગ
                                      બાળકો ની શાળાનું મેદાન
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.32322,72.84634"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>Khakhibaba Marble / ખાખીબાવા માર્બલ</p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.327628,72.8471"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Entrance of Yogeshwar Society / યોગેશ્વર
                                      સોસાયટીનું પ્રવેશદ્વાર
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.32899,72.84783"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Between Shree Hotel & Liliyadi Kathiyavadi
                                      Hotel / શ્રી હોટેલ અને લીલીયાડી કાઠીયાવાડી
                                      હોટલ વચ્ચે
                                    </p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>

                      <TabPanel value="3">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="Location-content">
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.31127,72.84353"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Next to Kalidas Mistry Bhavan / કાલિદાસ
                                      મિસ્ત્રી ભવન પાસે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.31512,72.84538"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Next to Ganesh Gau Seva Ashram / ગણેશ ગૌ
                                      સેવા આશ્રમની બાજુમાં
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.31858,72.84456"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      In Front of New College Gate 2 Besides Raj
                                      Marble / નવી કોલેજ ગેટ 2 ની સામે રાજ
                                      માર્બલ ની બાજુ મા
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.32034,72.83952"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      In the Parking Plot of Aadhyashakti
                                      General Hospital / આધ્યશક્તિ જનરલ
                                      હોસ્પિટલના પાર્કિંગ પ્લોટમાં
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.319733,72.842468"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Parallel to Both Side of the Pathway from
                                      Ambaji New College to Aadhyashakti General
                                      Hospital / અંબાજી ન્યુ કોલેજથી આધ્યશક્તિ
                                      જનરલ હોસ્પિટલ સુધીના માર્ગની બંને બાજુ મા
                                    </p>
                                  </p>
                                </a>
                              </div>

                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.32004,72.84546"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      Open Space in the Place of L K Barad
                                      School /એલ કે બારડ શાળાની ખુલ્લી જગ્યામાં
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.320228,72.845277"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      In Front of Ambaji New College in the
                                      Space of Gurukrupa Marble / અંબાજી ન્યુ
                                      કોલેજની સામે ગુરુકૃપા માર્બલની જગ્યામાં
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.32024,72.84665"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>
                                      In Front of Gurukrupa Marble Besides New
                                      College / નવી કોલેજ ની બાજુ મા ગુરુકૃપા
                                      માર્બલની સામે
                                    </p>
                                  </p>
                                </a>
                              </div>
                              <div className="heading-text">
                                <a
                                  href="https://www.google.com/maps?q=24.32145,72.8457"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p>
                                    <span>
                                      <img
                                        src={ParkingAreaImg}
                                        alt="Ambaji Temple Banaskatha Gujarat"
                                      ></img>
                                    </span>
                                    <p>Ruturaj Marble / રૂતુરાજ માર્બલ</p>
                                  </p>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </TabContext>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default BhadarviPoonamLocationDanta;
