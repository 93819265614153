import Arasuri from "../../../Components/arasuri";
import Footer from "../../../Components/footer";
import "./payment-status.css";
import { FaTimes } from "react-icons/fa";

const MoneyDonationFailed = () => {
  return (
    <>
      <section className="header-banner money-banner">
        <div className="container">
          <h1>Payment Status</h1>
        </div>
      </section>
      <section className="about-page">
        <div className="container">
          <div className="money-donation-failed">
            <div className="check-icon">
              <FaTimes />
            </div>
            <br />
            <h3 className="text-center">Payment Failed</h3>
          </div>
        </div>
      </section>
      <Arasuri />
      <Footer />
    </>
  );
};
export default MoneyDonationFailed;
