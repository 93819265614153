import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const getAll = () => {
  return http.get(api_urls?.getAll);
};

const CmsService = {
    getAll,
  };
  
  export default CmsService;
  