import { json } from "react-router";
import { api_urls } from "../../_Constants/api_urls";
import http from "../../_environment";

const addToCartShopping = (requestData) => {
  return http.post(api_urls?.createShoppingCart, requestData);
};

// const addToCart = (requestData) => {
//   const cart_data = JSON.parse(localStorage.getItem("cart_data"));

//   if (cart_data?.length > 0) {
//     // console.log(cart_data)
//     if (
//       cart_data.some(
//         (el) =>
//           el?.product_id === requestData?.product_id &&
//           el?.product_variant_id === requestData?.product_variant_id
//       )
//     ) {
//       for (let data of cart_data) {
//         if (
//           data?.product_id === requestData?.product_id &&
//           data?.product_variant_id === requestData?.product_variant_id
//         ) {
//           data.qty = requestData?.qty + data?.qty;
//         }
//       }
//       localStorage.setItem("cart_data", JSON.stringify(cart_data));
//     } else {
//       cart_data.push(requestData);
//       localStorage.setItem("cart_data", JSON.stringify(cart_data));
//     }
//   } else {
//     let data = [];
//     data.push(requestData);
//     // console.log(data)
//     localStorage.setItem("cart_data", JSON.stringify(data));
//   }
//   return JSON.parse(localStorage.getItem("cart_data"));
// };

// const getCartData = () => {
//   // return http.get(`${api_urls?.getCartData}?id=${user_id}`);
//   const user_id = localStorage.getItem("user_id");
//   console.log(user_id);
//   if (!user_id) {
//     throw new Error("User is not logged in");
//   }
//   return http.get(`${api_urls.getCartData}?id=${user_id}`);

//   //   return JSON.parse(localStorage.getItem("cart_data"));
// };

const getCartData = () => {
  // Retrieve user_id from localStorage
  const user_id = localStorage.getItem("user_id");

  // Log the user_id for debugging purposes
  // console.log(user_id);

  // Check if user_id is not present
  if (!user_id) {
    throw new Error("User is not logged in");
  }

  // Ensure api_urls and getCartData are defined before using them
  if (!api_urls || !api_urls.getCartData) {
    throw new Error("API URL for getting cart data is not defined");
  }

  // Return the HTTP GET request to fetch cart data
  return http.get(`${api_urls.getCartData}?user_id=${user_id}`);
};

// const removeCartData = (requestData) => {
//   // const cart_data = JSON.parse(localStorage.getItem("cart_data"));

//   // let index = cart_data.findIndex(
//   //   (el) =>
//   //     el?.product_id === requestData?.product_id &&
//   //     el?.product_variant_id === requestData?.product_variant_id
//   // );
//   // cart_data.splice(index, 1)[0];
//   // localStorage.setItem("cart_data", JSON.stringify(cart_data));
//   // return JSON.parse(localStorage.getItem("cart_data"));
// };

const removeCartData = (id) => {
  // return http.delete(api_urls?.removeCartData, data);

  return http.delete(`${api_urls?.removeCartData}?record_id=${id}`);
};

const buyNow = (requestData) => {
  return http.post(api_urls?.buyNow, requestData);
  //   localStorage.setItem("online_prashad", JSON.stringify([requestData]));
  //   return JSON.parse(localStorage.getItem("online_prashad"));
};

const getBuyNowData = (requestData) => {
  // // return JSON.parse(localStorage.getItem("online_prashad")) || [];
  // const user_id = localStorage.getItem("user_id");

  // // Log the user_id for debugging purposes
  // console.log(user_id);

  // // Check if user_id is not present
  // if (!user_id) {
  //   throw new Error("User is not logged in");
  // }

  // // Ensure api_urls and getCartData are defined before using them
  // if (!api_urls || !api_urls.getBuyNowData) {
  //   throw new Error("API URL for getting cart data is not defined");
  // }
  // console.log(requestData);
  // Return the HTTP GET request to fetch cart data
  return http.post(`${api_urls.getBuyNowData}`, requestData);
};

const getPrasadcart = () => {
  // return http.delete(api_urls?.removeCartData, data);
  const user_id = localStorage.getItem("user_id");
  // console.log(user_id);

  return http.get(`${api_urls?.getPrasadcart}?user_id=${user_id}`);
};

// const buyNow = (requestData) => {
//   localStorage.setItem("online_prashad", JSON.stringify([requestData]));
//   return JSON.parse(localStorage.getItem("online_prashad"));
// };

// const getBuyNowData = () => {
//   localStorage.setItem("online_prashad", JSON.stringify(cart_data))
//   return JSON.parse(localStorage.getItem("online_prashad"));
// };

const createPrasadCart = (requestData) => {
  return http.post(api_urls?.createPrasadCart, requestData);
};

const CartService = {
  addToCartShopping,
  getCartData,
  removeCartData,
  buyNow,
  getBuyNowData,
  createPrasadCart,
  getPrasadcart,
};

export default CartService;
