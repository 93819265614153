import React, { useState, useEffect } from 'react';
import './sangTermsConditions.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import SevaCampService from '../../../Api_config/Bhadarvi_poonam/sevaCamp'; // Adjust the path based on your project structure
import { Helmet } from 'react-helmet-async';
import { useHeading } from '../../Bhadarvi_poonam/header'; // Import the custom hook

const SangTermsConditions = () => {
  const [accepted, setAccepted] = useState(false);
  const [termsConditions, setTermsConditions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const heading = useHeading(); // Call the custom hook

  useEffect(() => {
    const fetchTermsConditions = async () => {
      try {
        const res = await SevaCampService.getTC();
        // Adjust mapping based on actual response structure
        setTermsConditions(res.data.data); // Assuming response.data.data is an array of terms
        setLoading(false);
      } catch (e) {
        setError('Failed to fetch terms and conditions.');
        setLoading(false);
      }
    };

    fetchTermsConditions();
  }, []);

  const handleCheckboxChange = (event) => {
    setAccepted(event.target.checked);
    localStorage.setItem('accepted', event.target.checked);
  };

  const handleNextButtonClick = () => {
    if (accepted) {
      window.location.href = 'sang';
    } else {
      alert('You must accept the terms and conditions.');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  // Filter terms and conditions to only include those where sangh is true
  const filteredTermsConditions = termsConditions.filter(
    (term) => term.sangh === true
  );

  return (
    <>
      {/* helmet  */}
      <Helmet>
        <title>Sangh Registration | Ambaji Bhadarvi Poonam 2024</title>
        <meta
          name="description"
          content="Register Your Pad Yatri Sangh for Ambaji Bhadarvi Poonam Fair 2024"
        />
        <meta
          name="keywords"
          content="Ambaji Bharavi Poonam 2024, Ambaji Temple, Bharavi Poonam festival, Ambaji pilgrimage, spiritual events, religious festivals India, Ambaji celebrations, Hindu festivals, Ambaji Poonam schedule, visit Ambaji Temple, divine rituals, cultural festivities, sacred events Gujarat, Ambaji event details, Seva Camp Registration, Sangh Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* helmet  */}

      <div className="seva-wrapper">
        <section className="header-banner seva-camp-bg">
          <div className="container">{heading}</div>
        </section>
        <section className="sang-section">
          <div className="container">
            <div className="sangmain">
              <div className="col-lg-12">
                <div className="sangmain">
                  <h3>ભાદરવી પૂનમ મહામેળો {new Date().getFullYear()}</h3>
                  <h3>Date: 12/09/2024 to 18/09/2024</h3>
                  <h3>સંઘ માટેની શરતો</h3>
                  <div className="terms-conditions-content">
                    {filteredTermsConditions.length ? (
                      <ol>
                        {filteredTermsConditions.map((term, index) => (
                          <li key={index}>{term.name}</li> // Adjust based on actual field name
                        ))}
                      </ol>
                    ) : (
                      <p>No terms and conditions available.</p>
                    )}
                    <label style={{ marginBottom: '25px' }}>
                      <div className="d-flex">
                        <span>
                          <input
                            className="checkbox-boxes-sang"
                            type="checkbox"
                            id="accept-checkbox"
                            onChange={handleCheckboxChange}
                          />
                        </span>
                        <span>
                          <b>
                            મેં ઉપરોક્ત નિયમો અને શરતોને યોગ્ય રીતે વાંચી લીધી
                            છે અને હું આ પોર્ટલ પર મારી નોંધણી કરાવું છું ત્યારે
                            તેનું પાલન કરવા માટે સંમત છું.
                          </b>
                        </span>
                      </div>
                    </label>
                  </div>
                  <button
                    className="orangebtn"
                    type="button"
                    style={{ width: '100%' }}
                    onClick={handleNextButtonClick}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Arasuri />
        <Footer />
      </div>
    </>
  );
};

export default SangTermsConditions;
