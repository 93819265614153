import http from "../../_environment";
// import { format } from 'date-fns';
import { api_urls } from "../../_Constants/api_urls";

// MASTERS GET ALL
const getAllCity = () => {
  return http.get(api_urls?.getCity);
};
const getAllVehicleCategory = () => {
  return http.get(api_urls?.getVehicleCategory);
};
const getAllProvince = () => {
  return http.get(api_urls?.getProvince);
};
const getAllCountry = () => {
  return http.get(api_urls?.getCountry);
};
const getAllZipcode = () => {
  return http.get(api_urls?.getZipcode);
};
const getZipcodeByKeyword = (id) => {
  return http.get(`${api_urls?.getZipcodeByKeyword}?keyword=${id}`);
};

// MASTERS BY ID
const getCountry = () => {
  return http.get(api_urls?.getCountry);
};

const getProvince = (country_id) => {
  return http.get(`${api_urls?.getProvince}?country_id=${country_id}`);
};

const getCity = (province_id) => {
  return http.get(`${api_urls?.getCity}?province_id=${province_id}`);
};

const getZipcodeByCity = (city_id) => {
  return http.get(`${api_urls?.getZipcodeByCity}?city_id=${city_id}`);
};

const getZipcodeByCityDeliverable = (city_id) => {
  return http.get(
    `${api_urls?.getZipcodeByCity}?city_id=${city_id}&is_deliverable=true`
  );
};

const getTithi = () => {
  const date = new Date().toLocaleDateString("en-CA", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  return http.get(`${api_urls?.getTithi}?date=${date}`);
};
// ----------------

// DATE FORMTION
const formatDate = (dateString) => {
  const utcTimestamp = new Date(dateString); // Use your UTC timestamp here

  const options = {
    timeZone: "Asia/Kolkata",
    hour12: true,
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  };

  const formattedDate = utcTimestamp.toLocaleString("en-US", options);

  return formattedDate;
};
// -------------

const UtilsService = {
  getAllCity,
  getAllCountry,
  getAllProvince,
  getAllVehicleCategory,
  getAllZipcode,
  getZipcodeByKeyword,
  getCountry,
  getProvince,
  getCity,
  getZipcodeByCity,
  getTithi,
  formatDate,
  getZipcodeByCityDeliverable,
};
export default UtilsService;
