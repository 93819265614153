import React, { useRef, useState, useEffect } from 'react';
import '././swayamsevakReceipt.scss';
import SwayamSevakService from '../../../Api_config/Swayam_sevak/swayamSevak';
import { useLocation } from 'react-router-dom';
import { formatDate } from '../../Donation/Converter/date';
import SignatureService from '../../../Api_config/Home_page/signature';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import { useReactToPrint } from 'react-to-print';
import { Helmet } from 'react-helmet-async';
import Error404 from '../../../Pages/error404';

const SwayamSevakReceipt = () => {
  const componentRef = useRef();
  const [swayamsevakData, setSwayamSevakData] = useState();
  const [signatureData, setSignatureData] = useState();
  const location = useLocation();
  const formattedDate = formatDate(swayamsevakData?.createdAt);
  const formattedDateforservice = formatDate(swayamsevakData?.date);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Ambaji Swayam Sevak Receipt',
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    }
  });
  useEffect(() => {
    const id = location?.pathname?.split('/').pop();
    // console.log(location, Number(id));
    SwayamSevakService.getBySwayamsevakById(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setSwayamSevakData(res.data.data[0]);
        }
      })
      .catch((e) => {
        // navigate(routes?.error500);
        // console.log(e);
      });

    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);
  // -----page-close-404-error-logic-p-----
  // if (true) {
  //   return <Error404 />;
  // }
  //-----page-close-404-error-logic-p-----
  const getSevaTypeName = (sevaDetails, sevaId) => {
    const sevaDetail = sevaDetails.find((detail) => detail.seva_id === sevaId);
    return sevaDetail ? sevaDetail.SevaType.name : 'Not Available';
  };
  const qrUrl = swayamsevakData?.qr_url;
  const label =
    swayamsevakData?.booking_type === 'group' ? 'Group Leader Name' : 'Name';

  return (
    <>
      {/* HELMET TAG  */}
      <Helmet>
        <title>Ambaji Temple - Swayam Sevak Receipt</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details, Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji, Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {/* -------- */}
      <div className="booking" style={{ backgroundColor: '#f5f5f5' }}>
        <section className="header-banner temple-banner">
          <div className="container">
            <h1>Swayam Sevak Receipt</h1>
          </div>
        </section>
        <div className="print-container" ref={componentRef}>
          <div className="pdf-btn">
            <div className="container">
              <div className="row">
                <div
                  style={{ width: '800px', margin: '0px auto 5px' }}
                  className="email-container"
                >
                  <table width="100%">
                    <tbody>
                      <tr className="header">
                        <td
                          width="17%"
                          rowSpan="3"
                          align="center"
                          valign="middle"
                        >
                          <div className="logo">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                              width="100"
                              height="100"
                            />
                          </div>
                        </td>
                        <td width="83%">
                          <h1>
                            SHREE ARASURI AMBAJI MANDIR DEVASTHAN TRUST, AMBAJI
                          </h1>
                        </td>
                      </tr>
                      <tr className="header">
                        <td>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>
                          <p>
                            <strong>PAN No</strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong>: 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="header"></tr>

                      <tr className="headig-text">
                        <td colSpan="2" align="center" valign="middle">
                          <h2>SWAYAM SEVAK REGISTRATION RECEIPT</h2>
                        </td>
                      </tr>
                      <tr className="rs-note">
                        <td colSpan="2" align="center">
                          <p>
                            "May the holy mother Maa Ambe Bless you and your
                            family, friends and give you a happy,
                            <br />
                            prosperous and spiritually enlightened life ahead"
                          </p>
                        </td>
                      </tr>
                      <tr className="header">
                        <td colSpan="2" align="right">
                          <p>
                            <strong>Registration Date</strong> : {formattedDate}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" align="center">
                          <table width="100%" className="date-section-1">
                            <tbody>
                              <tr>
                                <td width="40%">
                                  <strong>
                                    Swayam Sevak Registration Number
                                  </strong>
                                </td>
                                <td width="60%">
                                  {swayamsevakData?.registration_number}
                                </td>
                              </tr>
                              {/* <tr>
                            <td>
                              <strong>Name</strong>
                            </td>
                            <td>{swayamsevakData?.swayma_sevak_name}</td>
                          </tr> */}
                              {swayamsevakData?.booking_type === 'group' && (
                                <tr>
                                  <td>
                                    <strong>Group Name</strong>
                                  </td>
                                  <td>{swayamsevakData?.group_name}</td>
                                </tr>
                              )}

                              <tr>
                                <td>
                                  <strong>{label}</strong>
                                </td>
                                <td>{swayamsevakData?.swayma_sevak_name}</td>
                              </tr>
                              {swayamsevakData?.booking_type === 'group' && (
                                <tr>
                                  <td>
                                    <strong>Number of Members</strong>
                                  </td>
                                  <td>{swayamsevakData?.no_of_people}</td>
                                </tr>
                              )}
                              <tr>
                                <td>
                                  <strong>Phone number</strong>
                                </td>
                                <td>
                                  {swayamsevakData?.primary_contact_number}
                                </td>
                              </tr>

                              <tr>
                                <td>
                                  <strong>Number of days to Serve</strong>
                                </td>
                                <td>{swayamsevakData?.no_of_days}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Date of Service</strong>
                                </td>
                                <td>{formattedDateforservice}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Type of Seva</strong>
                                </td>
                                <td>
                                  {swayamsevakData?.SevaDetails ? (
                                    <ul
                                      style={{
                                        paddingLeft: '20px',
                                        marginBottom: '0px'
                                      }}
                                    >
                                      {swayamsevakData.SevaDetails.map(
                                        (seva, index) => (
                                          <li key={index}>
                                            {seva?.SevaType?.name ||
                                              'Not Available'}
                                          </li>
                                        )
                                      )}
                                    </ul>
                                  ) : (
                                    'No Details Available'
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                      <tr align="center" valign="middle" className="teg-line">
                        <td colSpan="2">
                          <table
                            cellSpacing="0"
                            cellPadding="0"
                            border="0"
                            width="100%"
                            style={{ marginTop: '20px' }}
                          >
                            <tbody>
                              <tr align="center">
                                <td
                                  width="40%"
                                  valign="middle"
                                  className="barcode"
                                >
                                  <img src={qrUrl} width="110" />
                                </td>
                                <td
                                  width="60%"
                                  valign="middle"
                                  className="sign"
                                >
                                  {signatureData?.map((item, index) => (
                                    <React.Fragment key={index}>
                                      <img
                                        src={item.signature_image}
                                        width="110"
                                        height="auto"
                                      />
                                      <p>
                                        {item.name}
                                        <br />
                                        {item.designation}
                                      </p>
                                    </React.Fragment>
                                  ))}
                                  <p>
                                    Shree Arasuri Ambaji Mata Devasthan Trust
                                    Ambaji
                                  </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container text-center">
          <button className="btn orangebtn my-2" onClick={handlePrint}>
            Receipt Download
          </button>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};
export default SwayamSevakReceipt;
