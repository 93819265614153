import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../Components/footer';
import { routes } from '../../../_Constants/routes';
import ShakipithService from '../../../Api_config/51_shakipith/51Shakipith';
import './51Shaktipith.scss';

import '../../../Css/innerPages.scss';
import Arasuri from '../../../Components/arasuri';
import LazyLoader from '../../../Components/lazyLoader';

import Box from '@mui/material/Box';
import Tabs, { tabsClasses } from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';

const Shaktipith = () => {
  const [isLoading, setLoading] = useState(true);

  const [templeData, setTempleData] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [currentData, setCurrentData] = useState();
  const [value, setValue] = useState('');

  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
    const selectedTemple = templeData.find((item) => item.name === newValue);
    setCurrentData(selectedTemple);
  };

  useEffect(() => {
    ShakipithService.getAll()
      .then((res) => {
        const data = res.data.data;
        setTempleData(data);
        if (data.length > 0) {
          setValue(data[0].name);
          setCurrentData(data[0]);
        }
        setLoading(false);
      })
      .catch((error) => {
        navigate(routes?.error500);
      });

    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [navigate]);

  return (
    <>
      <Helmet>
        <title>Ambaji Temple - 51 Shaktipeeth </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content=" Get detailed information of 51 Shaktipeeth. "
        />
        <meta name="keywords" content="51 Shaktipeeth Ambaji, D" />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner shaktipith-banner">
            <div className="container">
              <h1>51 શક્તિપીઠ</h1>
            </div>
          </section>

          <section className="shaktipith">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="tabboxes">
                    <TabContext value={value}>
                      <Tabs
                        className="tab-btn"
                        value={value}
                        allowScrollButtonsMobile
                        onChange={handleChange}
                        indicatorColor={' '}
                        variant="scrollable"
                        scrollButtons
                        aria-label="visible arrows tabs example"
                        sx={{
                          [`& .${tabsClasses.scrollButtons}`]: {
                            '&.Mui-disabled': { opacity: 0.3 }
                          }
                        }}
                      >
                        {templeData?.map((item) => {
                          return (
                            <Tab
                              className={
                                currentData.name === item?.name
                                  ? 'btn-active'
                                  : 'btn-act'
                              }
                              key={item?.name}
                              label={item?.name}
                              value={item?.name}
                            />
                          );
                        })}
                      </Tabs>
                      {templeData?.map((item) => (
                        <TabPanel key={item?.name} value={item?.name}>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="shaktipith-img">
                                <img
                                  alt="Ambaji Temple Banaskatha Gujarat"
                                  src={item?.thumbnail_image}
                                ></img>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="shaktipith-content">
                                <div className="heading">
                                  <h2>{item?.name}</h2>
                                  <h4>{item?.location}</h4>
                                </div>
                                <p>{item?.description}</p>
                              </div>
                            </div>
                          </div>
                        </TabPanel>
                      ))}
                    </TabContext>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default Shaktipith;
