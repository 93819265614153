import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import './searchSthanicVehiclePass.scss';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import EmployeeVehiclePassService from '../../../Api_config/Bhadarvi_poonam/employeevehiclepass';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import { formatDate } from '../../Donation/Converter/date';
import { useReactToPrint } from 'react-to-print';
import { useHeading } from '../../Bhadarvi_poonam/header'; // Import the custom hook

import SevaCampService from '../../../Api_config/Bhadarvi_poonam/sevaCamp';
const SearchSthanikVehiclePass = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const componentRef = useRef();

  const [otp, setOTP] = useState();
  const [seconds, setSeconds] = useState(30);
  const [isOTPSend, setOTPSend] = useState(false);
  const [isResend, setResend] = useState(false);

  const [userData, setUserData] = useState();
  const heading = useHeading(); // Call the custom hook

  const [isNumberEditable, setNumberEditable] = useState(true);
  const [showregmsg, setShowRegMessage] = useState(false);

  const [verifiedPhoneNumber, setVerifiedPhoneNumber] = useState('');
  const [passData, setPassData] = useState();

  const [isShowForm, setShowForm] = useState(false);

  const [isOTPVerified, setIsOTPVerified] = useState(false);
  const [isNumberRegistered, setNumberRegistered] = useState(false);
  const formattedDate = formatDate(passData?.approval_date);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Seva Camp Booking Receipt',
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    }
  });

  useState(false);

  const formik = useFormik({
    initialValues: {
      primary_contact_number: ''
    }
    // validationSchema: EmpVehiclePass,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
  useEffect(() => {
    const id = location?.pathname?.split('/').pop();
    // console.log(location, Number(id));
    if (isOTPVerified) {
      console.log('hii');

      EmployeeVehiclePassService.getAllEmployeeVehiclePass(Number(id))
        .then((res) => {
          if (res.data.success) {
            // console.log(res.data.data[0]);
            setPassData(res.data.data[0]);
          }
        })
        .catch((e) => {
          // navigate(routes?.error500);
          // console.log(e);
        });
    }
  }, []);
  /**
   * Verify OTP
   * @param primary_contact_number
   */
  const verifyOTP = () => {
    if (otp?.length === 6) {
      formik.setFieldValue('first_name', '');
      formik.setFieldValue('last_name', '');
      formik.setFieldValue('email_id', '');
      formik.setFieldValue('address_line_1', '');
      formik.setFieldValue('address_line_2', '');
      formik.setFieldValue('city_id', '');
      formik.setFieldValue('country_id', '');
      formik.setFieldValue('province_id', '');
      formik.setFieldValue('zipcode', '');
      formik.setFieldValue('pan_number', '');
      // formik?.resetForm()

      SevaCampService.verifyOTP(formik?.values?.primary_contact_number, otp)
        .then((res) => {
          if (res.data.success) {
            if (userData) {
              // setOpenModal(true);
            }
            setShowForm(true);
            setIsOTPVerified(true);
            // setOTPVerified(true);
          }
        })
        .catch((error) => {
          // navigate(routes?.error500);
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    }
  };
  /**
   * Resend OTP
   * @param primary_contact_number
   */
  const resendOTP = () => {
    SevaCampService.sendOTP(formik?.values?.primary_contact_number)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        // navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };
  const checkForContactNumber = (event) => {
    if (formik?.values?.primary_contact_number) {
      EmployeeVehiclePassService.getByEVPKeyword(
        formik?.values?.primary_contact_number
      )
        .then((res) => {
          if (res.data.success) {
            if (res.data.data.length === 0) {
              // Contact number not registered
              setShowRegMessage(true);
            } else {
              // Contact number registered
              setNumberRegistered(true);
              setNumberEditable(false);
              setShowRegMessage(false);
              const passData = res.data.data[0];
              setPassData(passData);
              setVerifiedPhoneNumber(formik?.values.primary_contact_number);

              // Check the status and redirect accordingly
              if (passData.Status?.name === 'Applied') {
                // Redirect to success page with ID
                navigate(
                  `/search-ambaji-sthanik-vehicle-pass/registration/success/${passData.id}`
                );
              } else {
                // Redirect to search page
                navigate('/search-ambaji-sthanik-vehicle-pass');
              }

              // Send OTP if necessary
              SevaCampService.sendOTP(formik?.values?.primary_contact_number)
                .then((otpRes) => {
                  if (otpRes.data.success) {
                    setOTPSend(true);
                    setSeconds(30);
                  }
                })
                .catch((error) => {
                  toast.error(error.response?.data?.message, {
                    autoClose: 1500
                  });
                });
            }
          }
        })
        .catch((error) => {
          toast.error('Error fetching contact number data', {
            autoClose: 1500
          });
        });
    }
  };

  const { handleChange } = formik;

  return (
    <div className="sang-wrapper">
      <section className="header-banner temple-banner">
        <div className="container">{heading}</div>
      </section>
      <section className="seva-camp">
        <div className="container">
          <div>
            {!isOTPVerified && (
              <div className="sang-camp-box">
                <div className="row">
                  <div className="form-group">
                    <div className="row" style={{ rowGap: '10px' }}>
                      <div className="col-xl-7 col-lg-7 col-md-10 col-sm-12">
                        <div
                          className="row align-items-end"
                          style={{ rowGap: '10px' }}
                        >
                          <div className="col-xl-6 col-lg-6 col-md-7">
                            <label>
                              Primary Contact Number / સંપર્ક નંબર
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <input
                              type="text"
                              inputMode="numeric"
                              placeholder="Contact Number"
                              className="form-control"
                              name="primary_contact_number"
                              value={formik?.values.primary_contact_number}
                              // onChange={handleChange}
                              onChange={(e) => {
                                const value = e.target.value;
                                // Allow only digits and limit length to 10
                                if (/^\d{0,10}$/.test(value)) {
                                  handleChange(e);
                                }
                              }}
                              disabled={!isNumberEditable}
                              maxLength="10"
                              pattern="\d{10}"
                              required
                            />

                            {/* <p className="mb-1 red-text">
                                {formik?.errors?.primary_contact_number}
                              </p> */}
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-5 d-flex align-items-end">
                            {isNumberEditable ? (
                              <button
                                type="button"
                                className="whitebtn"
                                onClick={() => checkForContactNumber()}
                              >
                                Verify Number
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="whitebtn"
                                onClick={() => [
                                  setNumberEditable(true),
                                  setNumberRegistered(false)
                                ]}
                              >
                                Edit Number
                              </button>
                            )}

                            {/* Conditionally render the message if the number is
                          already registered
                          {isNumberRegistered &&
                            !isNumberEditable &&
                            !isShowForm && (
                              <p className="text-danger mt-2">
                                You have already applied for Vehicle Pass with
                                this contact number.
                              </p>
                            )} */}
                          </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-7">
                          {showregmsg && (
                            <div className="mt-1" style={{ color: '#ae0015' }}>
                              This contact number is not registered
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {isNumberRegistered && !isNumberEditable && !isShowForm && (
                    <div className="form-group">
                      <div
                        className="row align-items-end"
                        style={{ rowGap: '5px' }}
                      >
                        <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-7">
                          <label htmlFor="otp">OTP</label>
                          <input
                            type="text"
                            inputMode="numeric"
                            minLength="6"
                            maxLength="6"
                            pattern="[0-9]*" // Only allow numeric input
                            name="otp"
                            id="otp"
                            className="form-control"
                            placeholder="_ _ _ _ _ _"
                            value={otp}
                            onChange={(event) => {
                              const inputValue = event.target.value.replace(
                                /\D/g,
                                ''
                              ); // Remove non-digit characters
                              setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                              setResend(false); // Reset the resend state if needed
                            }}
                          />
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
                          <button
                            type="button"
                            className="orangebtn"
                            onClick={() => verifyOTP()}
                            disabled={otp?.length !== 6}
                          >
                            Verify OTP
                          </button>

                          {isOTPSend && seconds == '00' && (
                            <button
                              type="button"
                              className="whitebtn ms-1"
                              onClick={() => [
                                resendOTP(),
                                setOTP(''),
                                setResend(true)
                              ]}
                            >
                              Resend OTP
                            </button>
                          )}
                          {/* <button
                        type="button"
                        className="whitebtn ms-3"
                        // onClick={() => [resendOTP(), setOTP(""), setResend(true)]}
                      >
                        Resend OTP
                      </button> */}
                        </div>
                        {isOTPSend && seconds > 0 ? (
                          <p className="mb-0 mt-1">
                            Resend OTP in
                            {seconds < 10 ? ` 0${seconds}` : ` ${seconds}`} sec
                          </p>
                        ) : (
                          <p className="mb-0"></p>
                        )}
                        {!isResend && (otp?.length > 6 || otp?.length < 6) && (
                          <p className="mb-0" style={{ color: '#ae0015' }}>
                            OTP must be a 6 digits number
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          {isShowForm && !isNumberEditable ? (
            <>
              <div className="print-container" ref={componentRef}>
                <div className="pdf-btn">
                  <div className="search-sthanik">
                    <div className="table-responsive">
                      <table width="500px">
                        <tr bgcolor="#D5AAC8">
                          <td
                            colspan="3"
                            align="center"
                            style={{ padding: '20px' }}
                          >
                            <table
                              width="500px"
                              cellpadding="5"
                              style={{
                                border: '3px solid #ae0015',
                                padding: '10px'
                              }}
                            >
                              <tr align="center" valign="middle">
                                <td>
                                  <img
                                    src="https://storage.googleapis.com/ambaji-temple.appspot.com/1722509886904_Gujarat-Police-Logo.png"
                                    width="70"
                                  />
                                </td>
                                <td className="header">
                                  <strong>
                                    <h1>
                                      <b>
                                        અંબાજી ભાદરવી પૂનમ
                                        <br />
                                        મહામેળો-૨૦૨૪
                                      </b>
                                    </h1>
                                  </strong>
                                </td>
                                <td>
                                  <img
                                    src="https://storage.googleapis.com/ambaji-temple.appspot.com/1722509886904_Gujarat-Police-Logo.png"
                                    width="70"
                                  />
                                </td>
                              </tr>
                              <tr align="center">
                                <td colspan="3" valign="middle">
                                  <strong>
                                    <div className="heding">
                                      અંબાજી સ્થાનિક વાહન પાસ
                                    </div>
                                  </strong>
                                </td>
                              </tr>
                              <tr align="center" valign="middle">
                                <td colspan="3" align="left">
                                  <strong>પાસ નંબર :</strong>
                                  <span>{passData?.registration_number}</span>
                                </td>
                              </tr>
                              <tr align="center" valign="middle">
                                <td colspan="3" align="left">
                                  <strong>નામ :</strong>
                                  <span>
                                    {passData?.first_name || passData?.last_name
                                      ? `${passData?.first_name || ''} ${
                                          passData?.last_name || ''
                                        }`
                                      : '-'}
                                  </span>{' '}
                                  &nbsp; <strong>હોદ્દો :</strong>
                                  <span>{passData?.designation}</span>
                                </td>
                              </tr>
                              <tr align="center" valign="middle">
                                <td colspan="3" align="left">
                                  <strong>વાહનનો નંબર :</strong>{' '}
                                  <span>{passData?.vehicle_number}</span>{' '}
                                  <strong>પ્રકાર</strong>{' '}
                                  <span>{passData?.vehicle_category}</span>{' '}
                                </td>
                              </tr>
                              <tr align="center" valign="middle">
                                <td colspan="3" align="left">
                                  <strong>પાસનો સમય તા.</strong>{' '}
                                  <span>12-09-2024</span>{' '}
                                  <strong> થી તા.</strong>
                                  <span>18-09-2024</span>
                                  <strong> સુઘી </strong>
                                </td>
                              </tr>
                              <tr align="center" valign="middle">
                                <td
                                  colspan="3"
                                  style={{ padding: '5px 0px 5px 0px' }}
                                >
                                  <table width="500px">
                                    <tr valign="bottom">
                                      <td width="50%" align="left">
                                        <strong>પરવાનગી ની તારીખ : </strong>
                                        <br />
                                        <span>{formattedDate}</span>
                                      </td>
                                      <td width="50%" align="center">
                                        <img
                                          src="https://storage.googleapis.com/ambaji-temple.appspot.com/1723097685682_stamp-logo.png"
                                          width="75"
                                        />{' '}
                                        &nbsp; <br />
                                        પોલીસ અધિક્ષક બનાસકાંઠા
                                      </td>
                                    </tr>
                                  </table>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="container text-center">
                <button className="btn orangebtn my-2" onClick={handlePrint}>
                  Download Vehicle Pass
                </button>
              </div>
            </>
          ) : null}
        </div>
      </section>
      <Arasuri />
      <Footer />
    </div>
  );
};

export default SearchSthanikVehiclePass;
