import warning_image from "../Assets/Images/pages/warning.png";
import Footer from "../Components/footer";

const Error500 = () => {
  return (
    <>
      <div className="error_500">
        <img src={warning_image} alt="Internal Server Error" />
        <h2>Internal Server Error</h2>
        <h5>Oops! Something went wrong.</h5>
        <p className="d-block d-md-none">
          The server encountered an internal error or misconfiguration and was
          unable to complete your request.
        </p>
        <p className="d-none d-md-block">
          The server encountered an internal error or misconfiguration <br />
          and was unable to complete your request.{" "}
        </p>
      </div>
      <Footer />
    </>
  );
};
export default Error500;
