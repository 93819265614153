import http from '../../_environment';
import { api_urls } from '../../_Constants/api_urls';

const create = (data) => {
  // console.log(data);
  const headers = {
    'Content-Type': 'multipart/form-data'
  };
  return http.post(api_urls?.createEmployeepass, data, { headers });
};

const getAllEmployeeVehiclePass = (id) => {
  return http.get(`${api_urls?.getAllEmployeeVehiclePass}?id=${id}`);
};

const getByEmployeeVehiclePassById = (id) => {
  return http.get(`${api_urls?.getAllEmployeeVehiclePass}?id=${id}`);
};

// const cronRequest = (data) => {
//   return http.post(api_urls?.sevaCamp_cronRequest, data);
// };
const getByEVPKeyword = (primary_contact_number) => {
  return http.get(
    `${api_urls?.getAllEmployeeVehiclePass}?primary_contact_number=${primary_contact_number} & pass_type="Ambaji Mandir Sthanik Vahan Pass"`
  );
};
const sendOTP = (primary_contact_number) => {
  return http.get(
    `${api_urls?.moneyDonation_sendOTP}?contact_number=${primary_contact_number}`
  );
};
const EmployeeVehiclePassService = {
  create,
  sendOTP,
  getByEVPKeyword,
  getAllEmployeeVehiclePass,
  getByEmployeeVehiclePassById
};

export default EmployeeVehiclePassService;
