import http from '../../_environment';
import { api_urls } from '../../_Constants/api_urls';

const getTransactionDetails = (id) => {
  return http.get(`${api_urls?.getMoneyDonation}?id=${id}`);
};
const getCounterTransactionDetails = (id) => {
  return http.get(`${api_urls?.getMoneyCounter}?id=${id}`);
};

const checkoutDonation = (requestData) => {
  return http.post(api_urls?.createBhadarvipoonamdonation, requestData);
};

// OTP

const sendOTP = (primary_contact_number) => {
  return http.get(
    `${api_urls?.moneyDonation_sendOTP}?contact_number=${primary_contact_number}`
  );
};

const verifyOTP = (primary_contact_number, otp) => {
  return http.get(
    `${api_urls?.moneyDonation_verifyOTP}?contact_number=${primary_contact_number}&otp=${otp}`
  );
};

// USER
const saveUser = (requestData) => {
  return http.post(api_urls?.createUser, requestData);
};

const checkRegisteredNumber = (primary_contact_number) => {
  return http.get(
    `${api_urls?.getUser}?primary_contact_number=${primary_contact_number}`
  );
};

// Donation Category
const getAllDonationCategory = () => {
  return http.get(api_urls?.getAllDonationCategory);
};
const getBhadarviDonationById = (id) => {
  return http.get(`${api_urls?.getAllDonation}?id=${id}`);
};
const DonationService = {
  getTransactionDetails,
  getCounterTransactionDetails,
  checkoutDonation,
  sendOTP,
  verifyOTP,
  saveUser,
  checkRegisteredNumber,
  getAllDonationCategory,
  getBhadarviDonationById
};
export default DonationService;
