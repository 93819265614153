import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

// images
import ambajiTemple from "../../Assets/Images/Home_page/templesSection2/ambajitemple.png";
import gabbar from "../../Assets/Images/Home_page/templesSection2/gabbar.png";
import shaktipith from "../../Assets/Images/Home_page/templesSection2/51shaktipith.jpg";

const TemplesSection2 = () => {
  return (
    <div className="temples__section-2">
      <section className="about-home service-section">
        <div className="container">
          <div className="aboutrow">
            <div className="row">
              <div className="col-lg-12">
                <Swiper
                  slidesPerView={1}
                  spaceBetween={20}
                  autoplay={{
                    delay: 2500,
                    clickable: true,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  // autoplay={true}
                  modules={[Autoplay, Pagination]}
                  className="mySwiper"
                  breakpoints={{
                    0: {
                      slidesPerView: 1,
                    },
                    768: {
                      slidesPerView: 2,
                    },
                    1024: {
                      slidesPerView: 3,
                    },
                  }}
                >
                  {/* ambaji temple slide  */}
                  <SwiperSlide>
                    <div>
                      <div className="aboutimg">
                        <img
                          src={ambajiTemple}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <div className="aboutcontent">
                        <h2>
                          <span>અંબાજી મંદિર</span>
                        </h2>
                        <div>
                          <p>
                            ભારતભરમાં શક્તિપીઠ તરીકે મશહુર અંબાજી યાત્રાધામ
                            ઉત્તર ગુજરાતના બનાસકાંઠા જિલ્લાના દાંતા તાલુકામાં
                            આવેલું છે. અરવલ્લીની ગિરિમાળામાં પ્રકૃતિના
                            સાનિધ્યમાં ૫૧ શક્તિપીઠ પૈકીના હદયસ્થ શક્તિપીઠ
                            અંબાજીમાં દર વર્ષે ૧૨૫ લાખથી પણ વધારે યાત્રાળુઓ માં
                            અંબેના ચરણોમાં વંદન કરી ધન્યતા અનુભવે છે. અંબાજી
                            નગરમાં પ્રવેશ માટે સિંહદ્વાર, મયુરદ્વાર અને ગજદ્વાર
                            બનાવેલ છે. ધર્મ, સંસ્કૃતિ અને અધ્યાત્મના આ દુર્લભ
                            વારસાને વિકાસની ગાથાથી શણગારવા અને સંસ્કૃતિક
                            ધરોહરનું જતન કરી અથાગ પ્રયત્નો કરવા સરકારશ્રી,
                            જીલ્લા વહીવટીતંત્ર બનાસકાંઠા અને શ્રી આરાસુરી અંબાજી
                            માતા દેવસ્થાન ટ્રસ્ટ અંબાજી કટિબદ્ધ છે.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  {/* gabbar slide  */}
                  <SwiperSlide>
                    <div>
                      <div className="aboutimg">
                        <img
                          src={gabbar}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <div className="aboutcontent">
                        <h2>
                          <span>ગબ્બર પર્વત</span>
                        </h2>
                        <div>
                          <p>
                            ગુજરાતીમાં ગબ્બરનો શાબ્દિક અર્થ થાય છે ''પહાડી''
                            ગબ્બર પર્વત ઉપર મા અંબે જ્યોત સ્વરૂપે બિરાજમાન છે.
                            અહીં માતાજીની ''અખંડ જ્યોત'' પ્રજવલિત છે. જમીનથી
                            લગભગ ૫૦૦ મીટરની ઉંચાઈએ ગબ્બર અખંડ જ્યોત અને માતાજીની
                            મૂર્તિ સ્થાપિત છે. ગબ્બર પર્વત ઉપર ચડવા માટે ૯૯૯
                            પગથિયાનું નિમાર્ણ કરવામાં આવેલ છે. રોપ વે ની સુવિધા
                            પણ અહી ઉપલબ્ધ છે. કહેવાય છે કે ભગવાન શ્રી કૃષ્ણના
                            ચૌલ સંસ્કાર અર્થાત બાબરી સંસ્કાર પણ અહી કરવામાં
                            આવેલ. ગબ્બર ટોચ ઉપર આજે પણ માં અંબેના પગની છાપ
                            સાક્ષાત દર્શનરૂપે જોવા મળે છે. અહી આવેલ પારસ પીપળીના
                            દર્શન માત્રથી સર્વે મનોકામના સિદ્ધ થવાની માન્યતા પણ
                            છે.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>

                  {/* 51 shaktipith slide */}
                  <SwiperSlide>
                    <div>
                      <div className="aboutimg">
                        <img
                          src={shaktipith}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                      </div>
                      <div className="aboutcontent">
                        <h2>
                          <span>51 શક્તિપીઠ</span>
                        </h2>
                        <div>
                          <p>
                            અન્ય કોઈ જેવો પરિક્રમા માર્ગ, એક અભૂતપૂર્વ
                            આધ્યાત્મિક યાત્રા અને અંબાજીમાં 51 શાતિપીઠ પરિક્રમા
                            સાથે સંગમિત વિશ્વભરમાં સ્થિત તમામ શક્તિપીઠોનું
                            નિવાસસ્થાન છે. તમામ 51 શક્તિપીઠની ચોક્કસ સ્થાપત્ય
                            પ્રતિકૃતિનું નિર્માણ ગોળાકાર રીતે કરવામાં આવ્યું
                            હતું અને 2014ના વર્ષમાં ઔપચારિક પ્રાણ પ્રતિષ્ઠા
                            કરવામાં આવી હતી.દર વર્ષે ફેબ્રુઆરી મહિનામાં
                            અંબાજીમાં ''51 શતીપીઠ પરિક્રમા મહોત્સવ'' ઉજવવામાં
                            આવે છે.
                          </p>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TemplesSection2;
