import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const checkout = (requestData) => {
  return http.post(api_urls?.checkout, requestData);
};
const calculateDeliveryCharge = (requestData) => {
  // console.log(requestData);
  return http.post(api_urls?.calculateDeliveryCharge, requestData);
};

const CheckoutService = {
  checkout,
  calculateDeliveryCharge,
};
export default CheckoutService;
