// fundamentals
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// components
import Footer from '../../Components/footer';
import '../Home/home.scss';
import './news.scss';
import LazyLoader from '../../Components/lazyLoader';
import Arasuri from '../../Components/arasuri';

// api-config
import NewsService from '../../Api_config/Home_page/news';
// import { align } from "@progress/kendo-drawing";

function NewsDetails() {
  const { id } = useParams();
  const [newsDetails, setNewsDetails] = useState();
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(true);

  // api integration
  useEffect(() => {
    NewsService.getByNewsId(id).then((res) => {
      // console.log(res.data);
      setNewsDetails(res.data.data[0]);

      setFile(
        `                   ${res.data.data[0].file_url}#view=FitH&scrollbar=0&toolbar=0&statusbar=0&messages=0&navpanes=0)`
      );
    });
  }, [id, setNewsDetails, setFile]);
  setTimeout(() => {
    setLoading(false);
  }, [3000]);
  {
    /* ----------  */
  }

  return (
    <>
      {!loading ? (
        <div className="main-wrapper">
          {/* banner  */}
          <section className="header-banner temple-banner">
            <div className="container">
              <h1>સમાચાર</h1>
            </div>
          </section>
          {/* ----------  */}

          {/* details  */}
          <div className="news-1">
            <div className="container">
              <div className="row">
                <div className="col-lg-2"></div>
                <div className="col-lg-8">
                  {newsDetails && (
                    <>
                      <div className="servicebox-hed-text">
                        <h2>{newsDetails.name}</h2>
                      </div>
                      <div className="d-lg-block">
                        {/* <div className="d-none d-lg-block"> */}
                        <iframe
                          src={file}
                          title="PDF Viewer"
                          frameBorder="0"
                          scrolling="auto"
                          height="800px"
                          width="100%"
                        ></iframe>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* ----------  */}

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
}
export default NewsDetails;
