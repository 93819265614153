import { useLocation } from 'react-router-dom';
import React from 'react';

const currentYear = new Date().getFullYear();

export function useHeading() {
  const location = useLocation();

  const getHeading = () => {
    switch (location.pathname) {
      case '/seva-terms-conditions':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સેવા કેમ્પ માટેની શરતો</p>
          </>
        );
      case '/seva-camp':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સેવા કેમ્પ</p>
          </>
        );
      case '/seva-camp-booking-receipt/:id':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <h2>સેવા કેમ્પ નોંધણી ની રસીદ</h2>
          </>
        );
      case '/sang-terms-conditions':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સંઘ માટેની શરતો</p>
          </>
        );
      case '/sang':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>પદયાત્રા સંઘ નોંધણી</p>
          </>
        );
      case '/sangh-booking-receipt/:id':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>પદયાત્રા સંઘ નોંધણી ની રસીદ</p>
          </>
        );
      case '/bhadarvi-search':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સેવા - કેમ્પ અને સંઘ નોંધણી સર્ચ</p>
          </>
        );
      case '/employee-vehicle-pass':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>વાહન પાસ નોંધણી </p>
          </>
        );
      case '/employee-vehicle-pass/registration/success/:id':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>કર્મચારી વાહન પાસ નોંધણી સફળતા</p>
          </>
        );
      case '/ambaji-sthanik-vehicle-pass':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>અંબાજી સ્થાનિક વાહન પાસ નોંધણી</p>
          </>
        );
      case '/ambaji-sthanik-vehicle-pass/registration/success/:id':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>અંબાજી સ્થાનિક વાહન પાસ નોંધણી સફળતા</p>
          </>
        );
      case '/search-ambaji-sthanik-vehicle-pass':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સર્ચ અંબાજી સ્થાનિક વાહન પાસ</p>
          </>
        );
      case '/search-ambaji-sthanik-vehicle-pass/registration/success/:id':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સર્ચ અંબાજી સ્થાનિક વાહન પાસ નોંધણી સફળતા </p>
          </>
        );
      case '/bhadarvi-poonam-receipt-download':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>સેવા - કેમ્પ અને સંઘ નોંધણી ની રસીદ</p>
          </>
        );

      case '/bhadarvi-poonam-hadad-route-location':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>પાર્કિંગ સુવિધા (હડાદ માર્ગ) સ્થાન/નકશો</p>
          </>
        );

      case '/bhadarvi-poonam-danta-route-location':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>પાર્કિંગ સુવિધા (દાંતા માર્ગ) સ્થાન/નકશો</p>
          </>
        );

      case '/bhadarvi-poonam-bhojanalaya-location':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>વિના મુલ્ય ભોજનાલય સુવિધા</p>
          </>
        );

      case '/bhadarvi-poonam-control-room-location':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>કંટ્રોલ રૂમનું સ્થાન/નકશો</p>
          </>
        );

      case '/bhadarvi-poonam-rest-shelter-location':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>આરામ આશ્રય સ્થાન/નકશો</p>
          </>
        );

      case '/bhadarvi-poonam-toilet-location':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>ટોયલેટ બ્લોક સ્થાન/નકશો</p>
          </>
        );

      case '/bhadarvi-poonam-drinking-water-location':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>પીવાનું પાણી પોઈન્ટ સ્થાન/નકશો</p>
          </>
        );

      case '/bhadarvi-poonam-location-health-center':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>આરોગ્ય કેન્દ્ર સ્થાન/નકશો</p>
          </>
        );

      case '/bhadarvi-poonam-location-prasad-counter':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>પ્રસાદ વિતરણ કેન્દ્ર</p>
          </>
        );

      case '/bhadarvi-poonam-location-bus-station':
        return (
          <>
            <h1>ભાદરવી પૂનમ {currentYear}</h1>
            <p>Date: 12/09/2024 to 18/09/2024</p>
            <p>બસ સ્ટેશન સ્થાન/નકશો</p>
          </>
        );

      default:
        return (
          <>
            <h1>Ambaji Bhadarvi Poonam {currentYear}</h1>
          </>
        );
    }
  };

  return getHeading();
}
