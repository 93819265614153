import "./payment-status.scss";
import { FaTimes } from "react-icons/fa";

const OnlinePrasadFailed = () => {
  return (
    <>
      <section className="header-banner money-banner">
        <div className="container">
          <h1>Payment Status</h1>
        </div>
      </section>
      <div className="money-donation-failed">
        <div className="check-icon">
          <FaTimes />
        </div>
        <br />
        <h3 className="text-center">Payment Failed</h3>
      </div>
    </>
  );
};
export default OnlinePrasadFailed;
