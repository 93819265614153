// // for 1000 quantity in cart code

// import React, { useEffect, useState } from "react";
// import { useLocation, useNavigate } from "react-router";
// import ProductService from "../../../Api_config/Products/product";
// import CartService from "../../../Api_config/E_store/cart";
// import { routes } from "../../../_Constants/routes";
// import IconMinus from "../../../Assets/Images/E_store/icon-minus.svg";
// import IconPlus from "../../../Assets/Images/E_store/icon-plus.svg";
// import { ReactComponent as CartIcon } from "../../../Assets/Images/E_store/icon-cart.svg";
// // import "./ProductsDetailed.scss";
// import Gallery from "../../../Components/gallery/gallery";
// import Loader from "../../../Components/lazyLoader";

// const OnlineShoppingDetail = () => {
//   const location = useLocation();
//   const navigate = useNavigate();

//   const [data, setData] = useState(null);
//   const [currentVariant, setCurrentVariant] = useState(null);
//   const [isLoading, setLoading] = useState(true);
//   const [quantity, setQuantity] = useState(25);

//   useEffect(() => {
//     ProductService.getById(location?.state?.id)
//       .then((res) => {
//         if (res.data.success) {
//           setData(res.data.data[0]);
//           setCurrentVariant(res.data.data[0]?.ProductsVariants[0]);
//           setLoading(false);
//         }
//       })
//       .catch((e) => {
//         console.error(e);
//         navigate(routes.internalServerError);
//       });
//   }, []);

//   const changeVariant = (variant) => {
//     setCurrentVariant(variant);
//     setQuantity(25);
//   };
//   // old

//   // const addToCart = async () => {
//   //   try {
//   //     if (!data || !currentVariant) {
//   //       throw new Error("Product data or variant is missing.");
//   //     }

//   //     let quantityToAdd = Math.min(quantity, 1000); // Limit quantity to 1000
//   //     const pricePerUnit = currentVariant.sell_rate;
//   //     const totalPrice = pricePerUnit * quantityToAdd;

//   //     // Calculate delivery charge based on total price
//   //     let deliveryCharge = 0;
//   //     if (totalPrice <= 1000) {
//   //       deliveryCharge = 50;
//   //     } else if (totalPrice <= 5000) {
//   //       deliveryCharge = 100;
//   //     } else {
//   //       deliveryCharge = 200;
//   //     }

//   //     // Add to cart
//   //     const requestData = {
//   //       product_id: data.id,
//   //       product_variants_id: currentVariant.id,
//   //       quantity: quantityToAdd,
//   //       merchant_id: localStorage.getItem("merchant_id"),
//   //     };
//   //     await CartService.addToCart(requestData);

//   //     // Navigate to cart page
//   //     navigate("/cart");
//   //   } catch (error) {
//   //     console.error("Error adding to cart:", error);
//   //   }
//   // };

//   // new
//   // const addToCart = async () => {
//   //   try {
//   //     if (!data || !currentVariant) {
//   //       throw new Error("Product data or variant is missing.");
//   //     }

//   //     let quantityToAdd = Math.min(quantity, 1000); // Limit quantity to 1000
//   //     const pricePerUnit = currentVariant.sell_rate;
//   //     const totalPrice = pricePerUnit * quantityToAdd;

//   //     // Calculate delivery charge based on total price
//   //     let deliveryCharge = 0;
//   //     if (totalPrice <= 1000) {
//   //       deliveryCharge = 50;
//   //     } else if (totalPrice <= 5000) {
//   //       deliveryCharge = 100;
//   //     } else {
//   //       deliveryCharge = 200;
//   //     }

//   //     // Add to cart
//   //     const requestData = {
//   //       product_id: data.id,
//   //       product_variants_id: currentVariant.id,
//   //       quantity: quantityToAdd,
//   //       // user_id: data.user_id,
//   //       user_id: localStorage.getItem("user_id"),
//   //     };

//   //     // Update local storage with the quantity
//   //     localStorage.setItem("quantity", quantityToAdd.toString());

//   //     await CartService.addToCartShopping(requestData);

//   //     // Navigate to cart page
//   //     navigate("/cart");
//   //   } catch (error) {
//   //     console.error("Error adding to cart:", error);
//   //   }
//   // };

//   const addToCart = async () => {
//     try {
//       if (!data || !currentVariant) {
//         throw new Error("Product data or variant is missing.");
//       }

//       let quantityToAdd = Math.min(quantity, 1000); // Limit quantity to 1000
//       const pricePerUnit = currentVariant.sell_rate;
//       const totalPrice = pricePerUnit * quantityToAdd;

//       // Calculate delivery charge based on total price
//       let deliveryCharge = 0;
//       if (totalPrice <= 1000) {
//         deliveryCharge = 50;
//       } else if (totalPrice <= 5000) {
//         deliveryCharge = 100;
//       } else {
//         deliveryCharge = 200;
//       }

//       // Get user_id from localStorage and convert to integer
//       const userId = parseInt(localStorage.getItem("user_id"), 10);

//       if (isNaN(userId)) {
//         throw new Error("Invalid user id.");
//       }

//       // Add to cart
//       const requestData = {
//         product_id: data.id,
//         product_variants_id: currentVariant.id,
//         quantity: quantityToAdd,
//         user_id: userId,
//       };

//       // Update local storage with the quantity
//       localStorage.setItem("quantity", quantityToAdd.toString());

//       await CartService.addToCartShopping(requestData);

//       // Navigate to cart page
//       navigate("/cart");
//     } catch (error) {
//       console.error("Error adding to cart:", error);
//     }
//   };

//   //  old
//   // const updateQuantity = (operation) => {
//   //   if (operation === "decrement" && quantity > 25) {
//   //     setQuantity((prevQuantity) => prevQuantity - 25);
//   //   } else if (operation === "increment" && quantity < 1000) {
//   //     setQuantity((prevQuantity) => prevQuantity + 25);
//   //   }
//   // };

//   // new
//   const updateQuantity = (operation) => {
//     let newQuantity;

//     if (operation === "decrement" && quantity > 25) {
//       newQuantity = quantity - 25;
//     } else if (operation === "increment" && quantity < 1000) {
//       if (!Array.isArray(data)) {
//         console.error("Data is not an array.");
//         return;
//       }
//       const totalQuantityInCart = data.reduce(
//         (total, item) => total + item.quantity,
//         0
//       );
//       const remainingQuantity = 1000 - totalQuantityInCart; // Calculate remaining quantity allowed in the cart
//       const maxIncrement = Math.min(25, remainingQuantity); // Calculate the maximum increment allowed
//       newQuantity = quantity + maxIncrement; // Add the maximum increment
//     } else {
//       return; // Quantity is already at its limit, or operation is not valid, no need to update
//     }

//     // Update state
//     setQuantity(newQuantity);

//     // Update local storage
//     localStorage.setItem("quantity", newQuantity.toString());
//   };

//   return (
//     <>
//       {!isLoading ? (
//         <div className="product-detail">
//           <div className="card px-3 py-3">
//             <div className="card-header d-flex justify-content-between align-items-center">
//               <h4 className="mb-0">Product Details</h4>
//             </div>
//             <main className="container-lg main">
//               <div className="row">
//                 <div className="col-lg-5 col-md-6 col-sm-12">
//                   <Gallery currentVariant={currentVariant} />
//                 </div>
//                 <div className="col-lg-7 col-md-6 col-sm-12">
//                   <h1 className="main__h1">{data?.name}</h1>
//                   <p className="main__p">{data?.description}</p>
//                   <div className="main__price-box">
//                     {currentVariant && currentVariant.note && (
//                       <p>
//                         <strong>Note : {currentVariant.note}</strong>
//                       </p>
//                     )}
//                     <div className="main__current-price">
//                       <h2 className="mb-2">
//                         <strong>₹ {currentVariant?.sell_rate}</strong>
//                       </h2>
//                     </div>
//                   </div>
//                   <div className="main__buttons pt-2">
//                     <div className="main__number-box p-3">
//                       <button
//                         className="main__number-button"
//                         onClick={() => updateQuantity("decrement")}
//                       >
//                         <img src={IconMinus} alt="Ambaji Temple Banaskatha Gujarat" />
//                       </button>
//                       <p className="main__p main__p--number">{quantity}</p>
//                       <button
//                         className="main__number-button"
//                         onClick={() => updateQuantity("increment")}
//                       >
//                         <img src={IconPlus}alt="Ambaji Temple Banaskatha Gujarat" />
//                       </button>
//                     </div>
//                     <button
//                       className="p-3 orangebtn main__buy-button"
//                       onClick={addToCart}
//                     >
//                       <CartIcon className="main__cart" />
//                       ADD TO CART
//                     </button>
//                   </div>
//                 </div>
//               </div>
//             </main>
//           </div>
//         </div>
//       ) : (
//         <Loader />
//       )}
//     </>
//   );
// };

// export default OnlineShoppingDetail;

import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import CartService from "../../../Api_config/E_store/cart";
import Footer from "../../../Components/footer";
import Gallery from "../../../Components/gallery/gallery";
import LazyLoader from "../../../Components/lazyLoader";
import { routes } from "../.././../_Constants/routes";
import IconMinus from "../../../Assets/Images/E_store/icon-minus.svg";
import IconPlus from "../../../Assets/Images/E_store/icon-plus.svg";
import { ReactComponent as Cart } from "../../../Assets/Images/E_store/icon-cart.svg";

// import "./ShopDetailed.scss";
import "../../../Css/gallery.scss";
import { el } from "date-fns/locale";
import Arasuri from "../../../Components/arasuri";
import OnlineShopService from "../../../Api_config/E_store/onlineShop";

const OnlineShoppingDetail = () => {
  // const { name } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [number, setNumber] = useState(1);
  const [data, setData] = useState();
  const [currentVariant, setCurrentVariant] = useState();
  const [isLoading, setLoading] = useState(true);
  const [isOutOfStock, setIsOutOfStock] = useState(false);
  const [amount, setAmount] = useState();

  const [message, setMessage] = useState();
  const [name, setName] = useState("");
  const [id, setId] = useState();

  // LOGIC FOR URL PASS TO THE ANOTHER TAB

  useEffect(() => {
    const parseUrlParams = () => {
      const pathname = window.location.pathname;
      const pathSegments = pathname.split("/");

      // Assuming the URL format is: /online-ambaji-temple-prasad-detail/:name/:id
      if (pathSegments.length >= 1) {
        setName(decodeURIComponent(pathSegments[2]));
        setId(pathSegments[3]);
      }
    };

    parseUrlParams();
  }, []);

  // GET PRODUCT BY ID
  useEffect(() => {
    if (id) {
      setLoading(true); // Set loading to true when starting to fetch data

      OnlineShopService.getById(id)
        .then((res) => {
          if (res.data.success) {
            setData(res.data.data[0]);
            setCurrentVariant(res.data.data[0]?.ProductsVariants[0]);
            setTimeout(() => {
              setLoading(false);
            }, [5000]);
          }
        })
        .catch((e) => {
          // console.log(e);
          navigate(routes?.error500);
        })
        .finally(() => {
          setLoading(false); // Always set loading to false after API call completes
        });
    }
  }, [id]);
  // Extract id from query parameters
  // const searchParams = new URLSearchParams(location.search);
  // const queryParamId = searchParams.get("id");

  // const id = location.state?.id || queryParamId;

  // useEffect(() => {
  //   if (id) {
  //     OnlineShopService.getById(id)
  //       .then((res) => {
  //         if (res.data.success) {
  //           setData(res.data.data[0]);
  //           setCurrentVariant(res.data.data[0]?.ProductsVariants[0]);
  //           setTimeout(() => {
  //             setLoading(false);
  //           }, 5000);
  //         }
  //       })
  //       .catch((e) => {
  //         navigate(routes?.error500);
  //       });
  //   }
  // }, [id, navigate]);

  // Render your component here
  // };

  const changeVariant = (data) => {
    setCurrentVariant(data);
    setMessage("");
    setNumber(1);
  };
  // console.log(currentVariant);
  // console.log(data);
  // const addToCart = () => {
  //   const availableQuantity = currentVariant?.Stocks[0]?.quantity;
  //   if (availableQuantity >= number) {
  //     const requestData = {
  //       product_id: data?.id,
  //       product_variant_id: currentVariant?.id,
  //       product_category_id: data?.category_id,
  //       qty: number,
  //       product_name: data?.name,
  //       variant_data: currentVariant
  //     };
  //     CartService.addToCart(requestData);
  //     navigate("/cart");
  //   } else {
  //     setMessage(`Only ${availableQuantity} is available`);
  //   }
  // };

  // working
  // const addToCart = () => {
  //   // const availableQuantity = currentVariant?.stock_value[0]?.quantity;
  //   const cartData = CartService.getCartData();
  //   const existingQuantityInCart = cartData
  //     .filter((item) => item.product_variant_id === currentVariant?.id)
  //     .reduce((total, item) => total + item.quantity, 0);

  //   if (existingQuantityInCart + number) {
  //     // if (availableQuantity >= existingQuantityInCart + number) {
  //     const requestData = {
  //       product_id: data?.id,
  //       product_variants_id: currentVariant?.id,
  //       // product_category_id: data?.category_id,
  //       quantity: number,
  //       // product_name: data?.name,
  //       // variant_data: currentVariant,
  //       user_id: parseInt(localStorage.getItem("user_id")),
  //     };
  //     CartService.addToCartShopping(requestData);
  //     navigate("/cart");
  //   } else {
  //     // setMessage(
  //     //   `Only ${availableQuantity - existingQuantityInCart} more can be added`
  //     // );
  //   }
  // };

  const addToCart = () => {
    const userId = parseInt(localStorage.getItem("user_id"));
    localStorage.setItem("previous_url", "/online-shopping-detail");
    if (userId) {
      const requestData = {
        product_id: data?.id,
        product_variants_id: currentVariant?.id,
        quantity: number,
        user_id: userId,
      };
      localStorage.setItem("cartData", JSON.stringify(requestData));

      CartService.addToCartShopping(requestData)
        .then((response) => {
          if (response.data.success) {
            // Handle successful addition to cart
            navigate(routes.Cart);
          } else {
            // Handle the case where the response indicates failure
            console.error("Failed to add to cart:", response.data.message);
            // Optionally set an error message to display to the user
            setMessage("Failed to add to cart.");
          }
        })
        .catch((error) => {
          // Handle error
          // console.error("Failed to add to cart:", error);
          // Optionally set an error message to display to the user
          setMessage("An error occurred. Please try again.");
          navigate(routes?.error500);
        });
    } else {
      // Redirect to the login page
      navigate(routes.login);
    }
  };

  // const addToCart = () => {
  //   const requestData = {
  //     product_id: data?.id,
  //     product_variant_id: currentVariant?.id,
  //     product_category_id: data?.category_id,
  //     qty: number,
  //     product_name: data?.name,
  //     variant_data: currentVariant,
  //     product_name: data?.name,
  //   };
  //   const response = CartService.addToCart(requestData);
  //   navigate("/cart");
  // };

  // working
  // const addProduct = (data) => {
  //   // console.log("kkkk", currentVariant, data);
  //   if (data == "minus") {
  //     // if (currentVariant?.stock_value?.length != 0) {
  //     //   if (currentVariant?.stock_value[0]?.quantity >= number) {
  //         // setAmount((prev) => prev + 1);
  //         setNumber((prevValue) => prevValue - 1);
  //         setMessage("");
  //       } else {
  //         // setMessage(
  //         //   `Only ${currentVariant?.stock_value[0]?.quantity} is available`
  //         // );
  //       }
  //     }
  // }
  //   else if ((data = "plus")) {
  //     // console.log(currentVariant?.stock_value?.length != 0);
  //     // if (currentVariant?.stock_value?.length != 0) {
  //     //   if (currentVariant?.stock_value[0]?.quantity >= number + 1) {
  //     // setAmount((prev) => prev + 1);
  //     setNumber((prevValue) => prevValue + 1);
  //   } else {
  //     // setMessage(
  //     //   `Only ${currentVariant?.stock_value[0]?.quantity} is available`,
  //     // );
  //     setMessage(
  //       `Not more than ${currentVariant?.stock_value[0]?.quantity} can be added`
  //     );
  //   }
  // };
  // }
  // };

  // out of stock    old

  // const handleIncrease = () => {
  //   if (!isOutOfStock) {
  //     setNumber((prevValue) => prevValue + 1);
  //   }
  // };

  // const handleDecrease = () => {
  //   if (number > 1) {
  //     setNumber((prevValue) => prevValue - 1);
  //   }
  // };

  const addProduct = (action) => {
    if (action === "plus") {
      setNumber((prevNumber) => prevNumber + 1);
    } else if (action === "minus") {
      if (number > 1) {
        setNumber((prevNumber) => prevNumber - 1);
      }
    }
  };

  return (
    <>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner shopDetailed-banner">
            <div className="container">
              <h1>{data?.name}</h1>
            </div>
          </section>
          <section className="shop-page">
            <main className="container">
              <div className="row">
                <div className="col-lg-5 col-md-6 col-sm-12">
                  <Gallery currentVariant={currentVariant} />
                </div>
                <div className="col-lg-7 col-md-6 col-sm-12">
                  <h1 className="main__h1">{data?.name}</h1>
                  <p className="main__p">{data?.description}</p>
                  <div className="main__price-box">
                    <div className="main__current-price">
                      <h2 className="mb-0">₹{currentVariant?.sell_rate}</h2>
                      {/* <h2 className="mb-0">₹{currentVariant?.sell_rate}</h2> */}
                      {/* {data?.ProductsVariants[0]?.mrp !==
                        data?.ProductsVariants[0]?.sell_rate && (
                        <div className="main__previous-price">
                          {data?.ProductsVariants[0]?.mrp}
                        </div>
                      )} */}
                    </div>
                  </div>
                  <div className="d-flex mb-3" style={{ gap: "0.5rem" }}>
                    {data?.ProductsVariants?.map((item, i) => {
                      return (
                        <p
                          className={
                            "main__p--discount" +
                            (currentVariant?.id === item?.id
                              ? " main__p--discount-active"
                              : "")
                          }
                          onClick={() => changeVariant(item)}
                        >
                          {item?.value_in_uom +
                            " " +
                            item?.UnitOfMeasurement?.name}
                        </p>
                      );
                    })}
                  </div>

                  <div>
                    {/* {currentVariant ? (
                      currentVariant?.Stocks[0]?.quantity === 0 ||
                      currentVariant?.Stocks?.length === 0 ? (
                        <div className="stock-out">
                          <p>Out of stock</p>
                        </div>
                      ) : null
                    ) : null} */}
                    <h5 className="text-red mb-3">{message}</h5>
                  </div>

                  <div className="main__buttons">
                    {/* <button
                      className="main__number-button"
                      onClick={() => {
                        if (number === 1) return;
                        setNumber((prevValue) => prevValue - 1);
                      }}
                    >
                      <img src={IconMinus} alt="Ambaji Temple Banaskatha Gujarat" />
                    </button>
                    <p className="main__p main__p--number">{number}</p>
                    <button
                      className="main__number-button"
                      onClick={() => {
                        setNumber((prevValue) => prevValue + 1);
                      }}
                    >
                      <img src={IconPlus}alt="Ambaji Temple Banaskatha Gujarat" />
                    </button> */}
                    <div className="main__number-box p-3">
                      <button
                        className="main__number-button"
                        // onClick={() => {
                        //   if (number === 1) return;
                        //   // setNumber((prevValue) => prevValue - 1),
                        //   addProduct("minus");
                        // }}
                        onClick={() => addProduct("minus")}
                      >
                        <img
                          src={IconMinus}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        />
                      </button>
                      <p className="main__p main__p--number">{number}</p>
                      <button
                        className="main__number-button"
                        // onClick={() => {
                        //   // setNumber((prevValue) => prevValue + 1),
                        //   addProduct("plus");
                        // }}
                        onClick={() => addProduct("plus")}
                        // disabled={
                        //   currentVariant?.Stocks?.length > 0
                        //     ? currentVariant?.Stocks[0]?.quantity < number
                        //       ? true
                        //       : false
                        //     : true
                        // }
                      >
                        <img
                          src={IconPlus}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        />
                      </button>
                    </div>
                    {/* <button
                      disabled={
                        currentVariant?.Stocks?.length > 0
                          ? currentVariant?.Stocks[0]?.quantity <= number
                            ? true
                            : false
                          : true
                      }
                      className="p-3 orangebtn main__buy-button"
                      onClick={() => addToCart()}
                    >
                      <Cart
                        className="main__cart"
                      //  disabled={
                      //   currentVariant?.Stocks?.length > 0
                      //     ? currentVariant?.Stocks[0]?.quantity >= number
                      //       ? true
                      //       : false
                      //     : true
                      // }
                      />
                      શોપિંગ કાર્ટ
                    </button> */}
                    <button
                      //   disabled={
                      //     !currentVariant?.Stocks?.length ||
                      //     currentVariant?.Stocks[0]?.quantity <= 0
                      //   }
                      className="p-3 orangebtn main__buy-button"
                      onClick={() => addToCart()}
                    >
                      <Cart className="main__cart" />
                      શોપિંગ કાર્ટ
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default OnlineShoppingDetail;
