import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TouristService from "../../../Api_config/Home_page/touristAttraction";

import Footer from "../../../Components/footer";
import Arasuri from "../../../Components/arasuri";
import LazyLoader from "../../../Components/lazyLoader";
const TouristAttractionDetails = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  const [touristData, setTouristData] = useState();
  const [item, setItem] = useState({ images: [] });

  const location = useLocation();
  useEffect(() => {
    if (location?.state?.id) {
      TouristService.getById(location.state.id).then((res) => {
        // console.log(res.data.data);
        setTouristData(res.data.data);
      });
    }
  }, [setTouristData, location]);

  useEffect(() => {
    const id = 1;

    const fetchItemData = async (id) => {
      try {
        const response = await TouristService.getById(id);
        // console.log(response.data);

        // Assuming the response data contains an array of image URLs
        const images = response.data.images;

        setItem({ images });
      } catch (error) {
        // console.error("Error fetching item data:", error);
      }
    };

    // Replace with the desired ID value
    fetchItemData(id);
  }, []);

  return (
    <>
      {!isLoading ? (
        <div className="main-wrapper">
          {touristData?.map((item) => {
            return (
              <>
                <section className="header-banner tourist-banner">
                  <div className="container">
                    <h1>{item.name}</h1>
                  </div>
                </section>
                <section
                  className="attractions-page"
                  style={{ marginTop: "10%" }}
                >
                  <div className="container">
                    <div className="about-main">
                      <div className="templetop">
                        <div className="row">
                          <div className="col-lg-5">
                            <div className="templeimg pe-0">
                              <img
                                src={item.thumbnail_image}
                                alt="Ambaji Temple Banaskatha Gujarat"
                              ></img>
                            </div>
                          </div>
                          <div className="col-lg-7">
                            <h2>
                              <span>{item.name}</span>
                            </h2>
                            <p>{item.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </>
            );
          })}
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default TouristAttractionDetails;
