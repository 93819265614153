// fundamentals
import React, { useState, useEffect } from 'react';

// components
import './termsAndConditions.scss';
import Footer from '../../Components/footer';
import '../Home/home.scss';

// Images

import Arasuri from '../../Components/arasuri';
import LazyLoader from '../../Components/lazyLoader';

function TermsAndConditions() {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  return (
    <>
      {!isLoading ? (
        <div className="main-wrapper">
          {/* banner  */}
          <section className="header-banner temple-banner">
            <div className="container">
              <h1>નિયમો અને શરતો</h1>
            </div>
          </section>
          {/* ----------  */}

          {/* details  */}
          <div className="terms-conditions">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="terms-text">
                    <h2>હેતુ</h2>
                    <p>
                      હેતુ લિટલ એન્ડ બીગ તમારી ગોપનીયતાના રક્ષણ માટે પ્રતિબદ્ધ
                      છે કારણ કે અમે લોકોનું મૂલ્યાંકન કરવા માટે પ્રતિબદ્ધ છીએ.
                      નીચે આપેલ અમારી ગોપનીયતા નીતિ તમારી વ્યક્તિગત માહિતી કેવી
                      રીતે એકત્રિત કરવામાં આવે છે, ઉપયોગમાં લેવાય છે અને
                      સુરક્ષિત છે તે નક્કી કરે છે. અમને પણ લાગુ પડે છે.
                    </p>
                    <p>
                      આ ગોપનીયતા નીતિ તમારી વ્યક્તિગત માહિતીના સંગ્રહ, હોલ્ડિંગ,
                      ઉપયોગ અને જાહેરાત અંગેની અમારી નીતિઓ અને પ્રક્રિયાઓનું
                      વર્ણન કરે છે અને અમારા નિયમો અને શરતો સાથે વાંચવી જોઈએ.
                      તમારી અંગત માહિતી પ્રદાન કરીને તમે આ ગોપનીયતા નીતિ અનુસાર
                      તે માહિતીના અમારા સંગ્રહ, ઉપયોગ અને જાહેરાત માટે સંમતિ આપો
                      છો.
                    </p>
                  </div>
                  <div className="terms-text">
                    <h2>વ્યક્તિગત ડેટા શું છે?</h2>
                    <p>
                      જ્યારે આ નીતિમાં ઉપયોગ કરવામાં આવે છે, ત્યારે 'વ્યક્તિગત
                      માહિતી' નો અર્થ ગોપનીયતા અધિનિયમમાં આપવામાં આવેલ છે.
                      સામાન્ય રીતે, તેનો અર્થ એવી કોઈપણ માહિતી અથવા અભિપ્રાય છે
                      જેનો ઉપયોગ તમને ઓળખવા માટે થઈ શકે છે.
                    </p>
                  </div>
                  <div className="terms-text">
                    <h2>વ્યક્તિગત ડેટા એકત્રિત કર્યો</h2>
                    <p>
                      નીચેના હેતુઓ માટે અને તેનો ઉપયોગ કરીને એકત્રિત કરવામાં
                      આવેલ વ્યક્તિગત ડેટા
                    </p>
                    <p>
                      નીચેની સેવાઓ : ગૂગલ એનાલિટિક્સ : કૂકીઝ ; વપરાશ ડેટા સંપર્ક
                    </p>
                    <p>
                      ફોર્મ: ઇમેઇલ સરનામું; પ્રથમ નામ; ફોન નંબર મેઇલિંગ સૂચિ
                      અથવા
                    </p>
                    <p> ન્યૂઝલેટર: ઇમેઇલ સરનામું; પ્રથમ નામ</p>
                  </div>
                  <div className="terms-text">
                    <h2>તમારા વ્યક્તિગત ડેટાને ઍક્સેસ કરી રહ્યાં છીએ</h2>
                    <p>
                      તમે અમારા દ્વારા એકત્રિત કરેલી તમારી વ્યક્તિગત માહિતીની
                      ઍક્સેસની વિનંતી કરી શકો છો અને અમે તે વ્યક્તિગત માહિતીને
                      સુધારવા માટે કહી શકો છો. તમે અમારો સંપર્ક કરીને ઍક્સેસ
                      અથવા સુધારણા માટે પૂછી શકો છો અને અમે સામાન્ય રીતે 30
                      દિવસની અંદર જવાબ આપીશું. જો અમે તમને તમારી અંગત માહિતીની
                      ઍક્સેસ આપવાનો અથવા તેને સુધારવાનો ઇનકાર કરીએ છીએ, તો અમે
                      તમને કારણો સુયોજિત કરીને લેખિતમાં સૂચિત કરીશું.
                    </p>
                  </div>
                  <div className="terms-text">
                    <h2>ફરિયાદો </h2>
                    <p>
                      જો તમે માનતા હોવ કે તમારી ગોપનીયતાનો ભંગ થયો છે અથવા અમે
                      તમારી અંગત માહિતીને કેવી રીતે હેન્ડલ કરી છે તે અંગે તમને
                      ફરિયાદ છે, તો કૃપા કરીને લેખિતમાં અમારો સંપર્ક કરો. અમે
                      વાજબી સમયગાળામાં (સામાન્ય રીતે 30 દિવસની અંદર) જવાબ
                      આપીશું.
                    </p>
                  </div>
                  <div className="terms-text">
                    <h2>માલિક અને ડેટા કંટ્રોલર</h2>
                    <p>
                      <b>સરનામું :</b> અંબાજી, તા.દાંતા, જિલ્લો.બનાસકાઠા-385110.
                    </p>
                    <p>
                      <b>ઈમેલ :</b>{' '}
                      <a
                        href="mailto:info@ambajitemple.in"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        info@ambajitemple.in
                      </a>
                    </p>
                    <p>
                      <b>ફોન :</b>{' '}
                      <a
                        href="tel:02749-262236"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        02749-262236
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Arasuri />
          <Footer />
          {/* ----------  */}
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
}

export default TermsAndConditions;
