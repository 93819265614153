import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router";
import Toast from "reactstrap";
import { Container, Row, Col } from "reactstrap";
import Modal from "react-modal";
import "./profile.scss";
import Footer from "../../Components/footer";
import { routes } from "../../_Constants/routes";
import Arasuri from "../../Components/arasuri";
import UserProfile from "./UserProfile/userProfile";
import AddressDetails from "./AddressDetails/addressDetails";
import Transaction from "./Transaction/transaction";
import Subscription from "./Subscription/subscription";
import LogOutConfirmationModal from "../Auth/logOutConfirmationModal";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
    padding: "0px ",
    border: "none",
  },
};

const Profile = () => {
  const navigate = useNavigate();
  const [activeTabId, setActiveTabId] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // const token = localStorage.getItem("token");
    // if (!JSON.parse(localStorage.getItem("token"))) {
    //   localStorage.setItem("previous_url", location?.pathname);
    //   navigate(routes?.login);
    // }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token || token === "undefined" || !isValidJSON(token)) {
      localStorage.setItem("previous_url", location?.pathname);
      navigate(routes?.login);
    }
  }, [location, navigate]);

  const isValidJSON = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };

  const btnClick = (id) => {
    setActiveTabId(id);
  };

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_id");
    setOpenModal(false);
    navigate(routes?.login);
  };

  return (
    <>
      <div className="profile">
        <section className="header-banner contactus-banner">
          <div className="container">
            <h1>Profile</h1>
          </div>
        </section>
        <div className="container-xl">
          <div className="section__Jobs-container">
            <Row>
              <Col sm="12" md="12" lg="2" xl="2" xxl="3">
                <div className="section__Jobs-styledTab">
                  <ul className="section__Jobs-styledTabList">
                    <li style={{ listStyle: "none", textAlign: "left" }}>
                      <button
                        className="section__Jobs-buttonCompany"
                        onClick={() => btnClick(1)}
                        style={
                          activeTabId === 1
                            ? { color: "#ae0015" }
                            : { color: "#000" }
                        }
                      >
                        Profile
                      </button>
                    </li>
                    <li style={{ listStyle: "none", textAlign: "left" }}>
                      <button
                        className="section__Jobs-buttonCompany"
                        onClick={() => btnClick(2)}
                        style={
                          activeTabId === 2
                            ? { color: "#ae0015" }
                            : { color: "#000" }
                        }
                      >
                        Addresses
                      </button>
                    </li>
                    <li style={{ listStyle: "none", textAlign: "left" }}>
                      <button
                        className="section__Jobs-buttonCompany"
                        onClick={() => btnClick(3)}
                        style={
                          activeTabId === 3
                            ? { color: "#ae0015" }
                            : { color: "#000" }
                        }
                      >
                        Transaction
                      </button>
                    </li>

                    {/* <li style={{ listStyle: "none", textAlign: "left" }}>
                      <button
                        className="section__Jobs-buttonCompany"
                        onClick={() => btnClick(4)}
                        style={
                          activeTabId === 4
                            ? { color: "#ae0015" }
                            : { color: "#000" }
                        }
                      >
                        Subscription
                      </button>
                    </li> */}
                    <li style={{ listStyle: "none", textAlign: "left" }}>
                      <button
                        className="section__Jobs-buttonCompany"
                        onClick={() => setOpenModal(true)}
                      >
                        Log out
                      </button>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col sm="12" md="12" lg="10" xl="10" xxl="9">
                <div
                  className="section__Jobs-styledContent"
                  style={
                    activeTabId === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <UserProfile />
                </div>
                <div
                  className="section__Jobs-styledContent"
                  style={
                    activeTabId === 2
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <AddressDetails />
                </div>
                <div
                  className="section__Jobs-styledContent"
                  style={
                    activeTabId === 3
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <Transaction />
                </div>
                <div
                  className="section__Jobs-styledContent"
                  style={
                    activeTabId === 4
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <Subscription />
                </div>
              </Col>
            </Row>
            <span
              className={
                activeTabId === 0
                  ? "index1-chosen"
                  : activeTabId === 1
                  ? "index2-chosen"
                  : "index3-chosen"
              }
            >
              &nbsp;
            </span>
          </div>
        </div>
      </div>

      <Arasuri />
      <Footer />
      <Modal
        isOpen={openModal}
        onRequestClose={() => setOpenModal(false)}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
      >
        <LogOutConfirmationModal closeModal={() => setOpenModal(false)} />
      </Modal>
    </>
  );
};
export default Profile;
