import React, { useRef, useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Footer from "../../../Components/footer";
import Arasuri from "../../../Components/arasuri";
import "./donationCounterMoneyReceipt.scss";
import { useReactToPrint } from "react-to-print";
import { isMobile } from "react-device-detect";
import { useLocation, useNavigate } from "react-router-dom";
import MoneyDonationService from "../../../Api_config/Donations/moneyDonation";
import { numberToWords } from "../Converter/numberToWords";
import { formatNumberWithCommas } from "../Converter/numberWithCommas";
import { formatDate } from "../Converter/date";
import SignatureService from "../../../Api_config/Home_page/signature";
import { routes } from "../../../_Constants/routes";

const DonationCounterMoneyReceipt = () => {
  const componentRef = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState();
  const amountInWords = numberToWords(transactionData?.amount);
  const formattedAmount = formatNumberWithCommas(transactionData?.amount);
  const formattedDate = formatDate(transactionData?.createdAt);
  const [signatureData, setSignatureData] = useState();
  useEffect(() => {
    const id = location?.pathname?.split("/").pop();
    // console.log(location, Number(id));
    MoneyDonationService.getCounterTransactionDetails(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setTransactionData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        // console.log(e);
      });

    SignatureService.getSignatureAll().then((res) => {
      // console.log(res.data);
      setSignatureData(res.data.data);
    });
  }, []);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Donate Money",
    onAfterPrint: () => {
      const styles = document.querySelectorAll("style[media='print']");
      styles.forEach((style) => style.remove());
    },
  });

  return (
    <>
      <Helmet>
        <title>Ambaji Temple - Money Donation Receipt</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details, Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji, Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      <div className="booking" style={{ backgroundColor: "#f5f5f5" }}>
        <section className="header-banner money-banner">
          <div className="container">
            <h1>Donation Receipt</h1>
          </div>
        </section>
        <div
          style={
            isMobile
              ? {
                  position: "relative",
                  overflow: "hidden",
                }
              : { position: "static" }
          }
        >
          <div
            className="print-container"
            ref={componentRef}
            style={isMobile ? { position: "absolute" } : { position: "static" }}
          >
            <div className="pdf-btn">
              <div className="container">
                <div className="row">
                  <div
                    className="email-container-box"
                    style={{ width: "800px", margin: "0px auto" }}
                  >
                    <table width="100%">
                      <tr className="header-boeres">
                        <td
                          width="17%"
                          rowSpan="3"
                          align="center"
                          valign="middle"
                        >
                          <div className="logo">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                              alt="Ambaji Temple Banaskatha Gujarat"
                            />
                          </div>
                        </td>
                        <td width="83%">
                          <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                        </td>
                      </tr>
                      <tr className="header-boeres">
                        <td>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>
                          <p>
                            <strong>PAN No </strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong> : 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="header-boeres"></tr>
                      <tr className="headig-text-mon">
                        <td colSpan="2" align="center" valign="middle">
                          <h2>DONATION RECEIPT</h2>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" align="center" valign="middle">
                          <table width="100%" className="date-section-box-mon">
                            <tr>
                              <td width="100%" align="left">
                                <strong>Donation Number : </strong>
                                {`${transactionData?.donation_number}`}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" align="center" valign="middle">
                          <table width="100%" className="date-section-box-mon">
                            <tr>
                              <td width="52%" align="left">
                                <strong>Donation Purpose : </strong>
                                {transactionData?.MoneyDonationType?.name}
                              </td>
                              <td width="48%" align="right">
                                <strong>Date : </strong>
                                {formattedDate} &nbsp; &nbsp;
                                <span></span>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                          <table width="100%">
                            <tr>
                              <td width="55%">
                                <table width="100%">
                                  <tr>
                                    <td
                                      width="30%"
                                      height="0"
                                      valign="middle"
                                      className="rs-box-mon"
                                    >
                                      <p>
                                        <span>Rs.</span>
                                        {formattedAmount} /-
                                        {/* {transactionData?.amount} /- */}
                                      </p>
                                    </td>
                                  </tr>
                                </table>
                                <div className="rs-note-mon">
                                  <p>
                                    અંકે રૂપિયા{" "}
                                    <span style={{ margin: "0px auto" }}>
                                      {amountInWords}
                                    </span>
                                  </p>
                                  <p>શ્રી માતાજી આપને સદા સુખી રાખે ...</p>
                                </div>
                              </td>
                              <td
                                width="50%"
                                align="right"
                                className="rs-note-mon"
                              >
                                {transactionData?.user_identity !==
                                  "hidden" && (
                                  <p>
                                    <span>{`${transactionData?.CounterMoneyDonationDetail?.first_name} ${transactionData?.CounterMoneyDonationDetail?.last_name}`}</span>
                                    <br />
                                    રહેવાસી{" "}
                                    <span>
                                      {
                                        transactionData
                                          ?.CounterMoneyDonationDetail?.City
                                          ?.name
                                      }
                                      ,{" "}
                                      {
                                        transactionData
                                          ?.CounterMoneyDonationDetail?.Province
                                          ?.name
                                      }
                                    </span>{" "}
                                    તરફથી મળ્યા છે.
                                    <br />
                                    જે આભાર સહ સ્વીકારવામાં આવે છે.
                                  </p>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td width="55%" className="sign-trust-mon">
                                {transactionData?.user_identity !==
                                  "hidden" && (
                                  <p>
                                    Exempted U/s. 80(G) of I.T. Act Vide <br />
                                    Commissioner of Income Tax, Gandhinagar{" "}
                                    <br />
                                    No. DIT (E) 80G (5)/98/07-08, Date:
                                    10/05/2007 <br />
                                    Valid from: 01-04-2007
                                  </p>
                                )}
                              </td>
                              <td
                                width="50%"
                                align="center"
                                className="sign-vahivat-mon"
                              >
                                {signatureData?.map((item) => {
                                  return (
                                    <>
                                      <img
                                        src={item.signature_image}
                                        alt="Money Donation - Ambaji Temple Banaskatha Gujarat"
                                        // src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716962038848_MicrosoftTeams-image(7).png"
                                        width="120"
                                        height="100"
                                      />
                                      <br />
                                      <p>
                                        {item.name}
                                        <br />
                                        {item.designation}
                                      </p>
                                    </>
                                  );
                                })}
                                <p>
                                  Shree Arasuri Ambaji Mata Devasthan Trust
                                  Ambaji
                                  {/* વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                                  શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                                  અંબાજી */}
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr className="teg-line-mon">
                        <td colSpan="2">
                          <p style={{ color: "#a30018" }}>
                            Donation Received in Corpus fund account is received
                            with a specific direction that the donation paid in
                            corpus fund account will be kept intact and only
                            interest income will be spent for object of the
                            trust.
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div
                    className="border-dest"
                    style={{ width: "800px", margin: "0px auto" }}
                  >
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716205370014_scissorleft.png"
                      alt="Ambaji Temple Banaskatha Gujarat"
                    />{" "}
                    - - - - - - - - - - - - - -{" "}
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716205370014_scissorleft.png"
                      alt="Ambaji Temple Banaskatha Gujarat"
                    />{" "}
                    - - - - - - - - - - - - - -{" "}
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716204812542_scissor.png"
                      alt="Ambaji Temple Banaskatha Gujarat"
                    />{" "}
                    - - - - - - - - - - - - - -{" "}
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716204812542_scissor.png"
                      alt="Ambaji Temple Banaskatha Gujarat"
                    />
                  </div>
                  <div
                    className="email-container-box"
                    style={{ width: "800px", margin: "0px auto" }}
                  >
                    <table width="100%">
                      <tr className="header-boeres">
                        <td
                          width="17%"
                          rowSpan="3"
                          align="center"
                          valign="middle"
                        >
                          <div className="logo">
                            <img
                              src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                              alt="Ambaji Temple Banaskatha Gujarat"
                            />
                          </div>
                        </td>
                        <td width="83%">
                          <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                        </td>
                      </tr>
                      <tr className="header-boeres">
                        <td>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>
                          <p>
                            <strong>PAN No </strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong> : 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="header-boeres"></tr>
                      <tr className="headig-text-mon">
                        <td colSpan="2" align="center" valign="middle">
                          <h2>LETTER FROM THE DONEE</h2>
                        </td>
                      </tr>
                      <tr className="rs-note-mon">
                        <td colSpan="2">
                          <p>
                            <strong>Donation Number : </strong>
                            {`${transactionData?.donation_number}`}
                          </p>
                        </td>
                      </tr>

                      <tr>
                        <td colSpan="2" align="center" valign="middle">
                          <table width="100%" className="date-section-box-mon">
                            <tr>
                              <td className="rs-note-mon">
                                <p>
                                  To,
                                  <br />
                                  The Administrator,
                                  <br />
                                  Shree Arasuri Ambaji Mandir Mata Devasthan
                                  Trust, Ambaji.
                                </p>
                              </td>
                              <td className="date-section-box-mon">
                                <p>
                                  {transactionData?.user_identity !==
                                    "hidden" && (
                                    <span>
                                      <strong>From : </strong>
                                      {`${transactionData?.CounterMoneyDonationDetail?.first_name} ${transactionData?.CounterMoneyDonationDetail?.last_name}`}
                                      ,{" "}
                                      {
                                        transactionData
                                          ?.CounterMoneyDonationDetail?.City
                                          ?.name
                                      }
                                      <br />
                                      <strong>Phone : </strong>
                                      {
                                        transactionData
                                          ?.CounterMoneyDonationDetail
                                          ?.primary_contact_number
                                      }
                                      <br />
                                    </span>
                                  )}
                                  <strong>Date : </strong>
                                  {formattedDate} &nbsp; &nbsp; <span></span>
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>

                      <tr className="teg-line-mon">
                        <td colSpan="2">
                          <p>Dear Sir / Mam,</p>
                        </td>
                      </tr>
                      <tr className="rs-note-mon">
                        <td colSpan="2" align="center">
                          <p>
                            <span>
                              {" "}
                              Ref: Donation to be given in Corpus Fund account.{" "}
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr className="rs-note-mon">
                        <td colSpan="2">
                          <p>
                            Kindly acknowledge the Rs.{" "}
                            <span style={{ margin: "0px" }}>
                              {" "}
                              {formattedAmount}
                            </span>{" "}
                            <span style={{ margin: "0px" }}>
                              ({amountInWords})
                            </span>{" "}
                            <strong>Online Donation</strong> to the trust's
                            corpus account.
                          </p>
                        </td>
                      </tr>
                      <tr className="teg-line-mon">
                        <td colSpan="2">
                          <p>
                            Donation Received in Corpus fund account is received
                            with a specific direction that the donation paid in
                            corpus fund account will be kept intact and only
                            interest income will be spent for object of the
                            trust.
                          </p>
                        </td>
                      </tr>
                      <tr className="teg-line-mon">
                        <td>
                          <p>Thank you</p>
                        </td>
                        <td align="right">
                          <p>Yours Faithfully</p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {isMobile && (
          <>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div
                    style={{ width: "100%" }}
                    className="email-container-boxers"
                  >
                    <table width="100%">
                      <tr align="center" className="header-hed">
                        <td>
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                            width="100"
                            height="98"
                          />
                          <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>
                          <p>
                            <strong>PAN No </strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong> : 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="headig-text-mon-desige">
                        <td align="center" valign="middle">
                          <h2>DONATION RECEIPT</h2>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" align="center" valign="middle">
                          <table width="100%" className="date-section-box-mon">
                            <tr>
                              <td width="100%" align="left">
                                <strong>Donation Number : </strong>
                                {`${transactionData?.donation_number}`}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2" align="center" valign="middle">
                          <table width="100%" className="date-section-box-mon">
                            <tr>
                              <td width="100%" align="left">
                                <strong>Donation Purpose : </strong>
                                {transactionData?.MoneyDonationType?.name}
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr className="date-section-box-mon">
                        <td>
                          <strong>Date : </strong>
                          {formattedDate} &nbsp; &nbsp;
                          <span></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <table width="18%" className="table-1-box">
                            <tr>
                              <td
                                width="30%"
                                height="0"
                                valign="middle"
                                className="rs-box-mon"
                              >
                                <p>
                                  <span>Rs.</span>
                                  {formattedAmount} /-
                                </p>
                              </td>
                            </tr>
                          </table>
                        </td>
                      </tr>
                      <tr className="rs-notees-mon">
                        <td>
                          <p>
                            અંકે રૂપિયા{" "}
                            <span style={{ margin: "0px auto" }}>
                              {amountInWords}
                            </span>
                          </p>
                          {transactionData?.user_identity !== "hidden" && (
                            <span>
                              <p>
                                <span>{`${transactionData?.CounterMoneyDonationDetail?.first_name} ${transactionData?.CounterMoneyDonationDetail?.last_name}`}</span>{" "}
                                રહેવાસી{" "}
                                <span>
                                  {
                                    transactionData?.CounterMoneyDonationDetail
                                      ?.City?.name
                                  }
                                  ,{" "}
                                  {
                                    transactionData?.CounterMoneyDonationDetail
                                      ?.Province?.name
                                  }
                                </span>{" "}
                                તરફથી મળ્યા છે.
                                <br />
                                જે આભાર સહ સ્વીકારવામાં આવે છે.
                              </p>
                            </span>
                          )}
                          <p>શ્રી માતાજી આપને સદા સુખી રાખે ...</p>
                        </td>
                      </tr>

                      <tr>
                        <td className="sign-trust-mon">
                          {transactionData?.user_identity !== "hidden" && (
                            <p>
                              Exempted U/s. 80(G) of I.T. Act Vide <br />
                              Commissioner of Income Tax, Gandhinagar <br />
                              No. DIT (E) 80G (5)/98/07-08, Date: 10/05/2007{" "}
                              <br />
                              Valid from: 01-04-2007
                            </p>
                          )}
                        </td>
                      </tr>

                      <tr align="right">
                        <td valign="middle" className="sign-vahivat-mon-box">
                          {signatureData?.map((item) => {
                            return (
                              <>
                                <img
                                  src={item.signature_image}
                                  alt="Money Donation - Ambaji Temple Banaskatha Gujarat"
                                  // src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716962038848_MicrosoftTeams-image(7).png"
                                  width="120"
                                  height="100"
                                />
                                <br />
                                <p>
                                  {item.name}
                                  <br />
                                  {item.designation}
                                </p>
                              </>
                            );
                          })}
                          <p>
                            Shree Arasuri Ambaji Mata Devasthan Trust Ambaji
                            {/* વહીવટદાર અને અધિક કલેક્ટર વતી <br />
                                  શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                                  અંબાજી */}
                          </p>
                        </td>
                      </tr>
                      <tr align="center">
                        <td valign="middle" className="teg-line-mon-hed">
                          <p style={{ color: "#a30018" }}>
                            Donation Received in Corpus fund account is received
                            with a specific direction that the donation paid in
                            corpus fund account will be kept intact and only
                            interest income will be spent for object of the
                            trust.
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="border-dest-tex" style={{ width: "100%" }}>
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716205370014_scissorleft.png"
                      alt="Ambaji Temple Banaskatha Gujarat"
                    />
                    <span className="border-dest-tex1">
                      - - - - - - - - - -
                    </span>
                    <span className="border-dest-tex2">
                      - - - - - - - - - - -
                    </span>
                    <img
                      src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716204812542_scissor.png"
                      alt="Ambaji Temple Banaskatha Gujarat"
                    />
                  </div>
                  <div
                    style={{ width: "100%" }}
                    className="email-container-boxers"
                  >
                    <table width="100%">
                      <tr align="center" className="header-hed">
                        <td>
                          <img
                            src="https://storage.googleapis.com/ambaji-temple.appspot.com/1716201772258_HeaderEnglish.png"
                            alt="Ambaji Temple Banaskatha Gujarat"
                            width="100"
                            height="98"
                          />
                          <h1>ARASURI AMBAJI MATA DEVSTAN TRUST, AMBAJI</h1>
                          <p>
                            <strong>Ta.</strong> Danta, &nbsp;
                            <strong>Dist.</strong> Banaskantha, &nbsp; Gujarat -
                            385110.
                          </p>
                          <p>
                            <strong>Phone No</strong> : 02749-262236 &nbsp;
                            <strong>Email </strong>:
                            ambajitempletrust@gmail.com, &nbsp;
                            www.ambajitemple.in
                          </p>
                          <p>
                            <strong>PAN No </strong>: AACTS5457M &nbsp;
                            <strong>GST No</strong> : 24AACTS5457M2Z0
                          </p>
                        </td>
                      </tr>
                      <tr className="headig-text-mon-desige">
                        <td colSpan="2" align="center" valign="middle">
                          <h2>LETTER FROM THE DONEE</h2>
                        </td>
                      </tr>
                      <tr className="rs-notees-mon">
                        <td colSpan="2">
                          <p>
                            <strong>Donation Number : </strong>
                            {`${transactionData?.donation_number}`}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td className="date-section-sec">
                          <p>
                            {transactionData?.user_identity !== "hidden" && (
                              <span>
                                <strong>From : </strong>
                                {`${transactionData?.CounterMoneyDonationDetail?.first_name} ${transactionData?.CounterMoneyDonationDetail?.last_name}`}
                                ,{" "}
                                {
                                  transactionData?.CounterMoneyDonationDetail
                                    ?.City?.name
                                }
                                <br />
                                <strong>Phone : </strong>
                                {
                                  transactionData?.CounterMoneyDonationDetail
                                    ?.primary_contact_number
                                }
                                <br />
                              </span>
                            )}
                            <strong>Date : </strong>
                            {formattedDate} &nbsp; &nbsp;
                            {/* <span>12:25 PM</span> */}
                          </p>
                        </td>
                      </tr>
                      <tr align="right">
                        <td className="rs-notees-mon">
                          <p>
                            <strong>To</strong>,<br />
                            The Administrator,
                            <br />
                            Shree Arasuri Ambaji Mandir Mata Devasthan Trust,
                            Ambaji.
                          </p>
                        </td>
                      </tr>
                      <tr className="teg-line-mon-hed">
                        <td colSpan="2">
                          <p>Dear Sir / Mam,</p>
                        </td>
                      </tr>
                      <tr className="rs-notees-mon">
                        <td colSpan="2" align="center">
                          <p>
                            <span>
                              Ref : Donation to be given in Corpus Fund account.
                            </span>
                          </p>
                        </td>
                      </tr>
                      <tr className="rs-notees-mon">
                        <td colSpan="2">
                          <p>
                            Kindly acknowledge the Rs.
                            <span>{formattedAmount}</span>
                            <span style={{ margin: "0px auto" }}>
                              ({amountInWords}){" "}
                            </span>
                            <strong>Online Donation </strong>
                            to the trust's corpus account.
                          </p>
                        </td>
                      </tr>
                      <tr className="teg-line-mon-hed">
                        <td colSpan="2">
                          <p>
                            The donation is given with a specific direction that
                            the said amount of donation will be kept intact as
                            corpus fund of the trust and only interest income
                            received from the said donation will be utilized for
                            the objects of the trust.
                          </p>
                        </td>
                      </tr>
                      <tr className="teg-line-mon-hed">
                        <td colSpan="2" align="right">
                          <p>Thank you</p>
                          <p>
                            <strong>Yours Faithfully</strong>
                          </p>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="container text-center">
          <button className="btn orangebtn my-2" onClick={handlePrint}>
            Download Receipt
          </button>
        </div>
      </div>
      <Arasuri />
      <Footer />
    </>
  );
};

export default DonationCounterMoneyReceipt;
