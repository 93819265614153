import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import UtilsService from '../../Api_config/Home_page/utils';
import Footer from '../../Components/footer';
// import { useFormik } from "formik";
import { routes } from '../../_Constants/routes';
import { payu_api_url, payu_key } from '../../_Constants/api_urls';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './dhajaBooking.scss';
import Arasuri from '../../Components/arasuri';
import YagnaBookingService from '../../Api_config/Yagna_booking/yagnaBooking';
import LazyLoader from '../../Components/lazyLoader';
import Error404 from '../../Pages/error404';
import DhajaBookingService from '../../Api_config/Dhaja_booking/dhajaBooking';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const DhajaBooking = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  const navigate = useNavigate();
  const myButtonRef = useRef(null);
  const [date, setStartDate] = useState();
  const [mobNumber, setMobNumber] = useState();

  const [slot, setSlot] = useState([]);

  const [dhajaList, setDhajaList] = useState([]);
  // const [YagnaCategory, setYagnaCategory] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [isAvailable, setAvailability] = useState(false);
  const [isOTPSend, setOTPSend] = useState(false);
  // const [isOTPVerified, setOTPVerified] = useState(false);
  const [slotAvailableMsg, setSlotAvailableMsg] = useState('');
  const [payUData, setPayUData] = useState();
  const [allAvailableTemple, setAvailableTemple] = useState([]);
  // const [templeData, setTempleData] = useState();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isModalOpen1, setModalOpen1] = useState(false);
  const [userData, setUserData] = useState();
  const [userAddress, setUserAddress] = useState();
  const [isNumberEditable, setNumberEditable] = useState(true);
  const [isResend, setResend] = useState(false);
  const [otp, setOTP] = useState();
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedSlot, setSelectedSlot] = useState('');
  const [isNumberRegistered, setNumberRegistered] = useState(false);
  const [isShowForm, setShowForm] = useState(false);
  const today = new Date();
  const [timeslot, setTimeSlot] = useState('');
  const [initialValues, setInitialValues] = useState({
    date: '',
    slot: '',
    dhaja_id: '',
    booking_category: '',
    time_slot: timeslot,
    first_name: '',
    last_name: '',
    email_id: '',
    price: '',
    primary_contact_number: '',
    address_line_1: '',
    address_line_2: '',
    country_id: '',
    province_id: '',
    city_id: '',
    zipcode_id: ''
  });
  const [availabilityIntervalId, setAvailabilityIntervalId] = useState();
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    /** Get Temple */
    DhajaBookingService.getDhaja().then((res) => {
      if (res.data.success) {
        setDhajaList(res.data.data);
      }
    });

    /** Get Country */
    UtilsService.getCountry()
      .then((res) => {
        if (res.data.success) {
          setCountryList(res.data.data);
        }
      })
      .catch((error) => {
        // navigate(routes?.error500);
        // console.log(error);
      });
  }, [setDhajaList, setCountryList, setAvailableTemple]);
  // GET PROVINCE API INTEGRATION
  const getProvince = (event) => {
    UtilsService.getProvince(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data);
        }
      })
      .catch((error) => {
        // navigate(routes?.error500);
        // console.log(error);
      });
  };

  // GET CITY API INTEGRATION
  const getCity = (event) => {
    UtilsService.getCity(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setCityList(res.data.data);
        }
      })
      .catch((error) => {
        // navigate(routes?.error500);
        // console.log(error)
      });
  };

  // GET ZIPCODE BY CITY API INTEGRATION

  const getZipcodeByCityDeliverable = (event) => {
    UtilsService.getZipcodeByCityDeliverable(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setZipcodeList(res.data.data);
        }
      })
      .catch((error) => {
        // navigate(routes?.error500);
        // console.log(error);
      });
  };

  const formatDate = (date) => {
    // Check if date is a timestamp (integer) or a Date object
    const d = new Date(typeof date === 'number' ? date : new Date(date));
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const year = d.getFullYear();
    return `${year}-${month}-${day}`;
  };
  // const fromateddate = formatDate(selectedDate);

  const checkForAvailability = () => {
    if (selectedDate && selectedSlot) {
      const fromateddate = formatDate(selectedDate);

      // setYagnaDate(date, slot);

      DhajaBookingService.getSlots({ date: fromateddate, slot: selectedSlot }) // Pass both date and slot
        .then((res) => {
          if (res.data.success) {
            setAvailableTemple(res.data.data);
            setSlotAvailableMsg(
              res.data.data.length > 0 ? '' : 'No Data Found'
            );
            if (res.data.data?.length <= 0) {
              setSlotAvailableMsg(res.data?.message);
            }
          } else {
            setSlotAvailableMsg('No Data Found');
          }
        })
        .catch((error) => {
          if (
            error?.response?.data?.code === 401 ||
            error?.response?.data?.code === 500
          ) {
            // navigate(routes?.error500);
          } else {
            setSlotAvailableMsg(error?.response?.data?.message);
          }
        });

      if (date && slot && !isAvailable) {
        const intervalId = setInterval(() => {
          checkForAvailability(date, slot); // Pass both date and slot
        }, 120000);
        setAvailabilityIntervalId(intervalId);
      }
    } else {
      // Handle case where date or slot is not selected
      alert('Please select a date and slot.');
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(availabilityIntervalId);
    };
  }, [availabilityIntervalId]);
  /**
   * Resend OTP
   * @param primary_contact_number
   */
  const resendOTP = () => {
    DhajaBookingService.sendOTP(mobNumber)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        // navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };

  /**
   * Send OTP
   * @param {*} primary_contact_number
   */
  // const sendOTP = (primary_contact_number) => {
  //   if (isOTPSend) setOTP("");
  //   YagnaBookingService.sendOTP(primary_contact_number)
  //     .then((res) => {
  //       if (res.data.success) {
  //         setOTPSend(true);
  //         setSeconds(30);
  //       }
  //     })
  //     .catch((error) => {
  //       // navigate(routes?.error500);
  //       toast.error(error.response?.data?.message, { autoClose: 1500 });
  //     });
  // };
  const checkForContactNumber = (event) => {
    // console.log(initialValues);
    if (mobNumber) {
      DhajaBookingService.checkRegisteredNumber(mobNumber).then((res) => {
        if (res.data.success) {
          setNumberRegistered(true);
          setNumberEditable(false);
          setUserData(res.data.data[0]);
          // console.log(res.data.data[0].UserAddresses);
          if (res?.data?.data[0]?.UserAddresses?.length > 0) {
            setUserAddress(res?.data?.data[0]?.UserAddresses[0]);
          }
          DhajaBookingService.sendOTP(mobNumber)
            .then((res) => {
              if (res.data.success) {
                setOTPSend(true);
                setSeconds(30);
              }
            })
            .catch((error) => {
              toast.error(error.response?.data?.message, { autoClose: 1500 });
              // navigate(routes?.error500);
            });
        }
      });
    }
  };
  // DATA BINDIG FOR USERDATA
  const bindData = () => {
    // console.log("helo");

    getProvince(userAddress.country_id);
    getCity(userAddress.province_id);
    getZipcodeByCityDeliverable(userAddress.city_id);
    setInitialValues({
      // date: "",
      // slot: "",
      // dhaja_id: "",
      // booking_category: "",
      // time_slot: "",
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      email_id: userData?.email_id,
      // price: "",
      primary_contact_number: userData?.primary_contact_number,
      address_line_1: userAddress?.address_line_1,
      address_line_2: userAddress?.address_line_2,
      country_id: userAddress?.country_id,
      province_id: userAddress?.province_id,
      city_id: userAddress?.city_id,
      zipcode_id: userAddress?.zipcode_id
    });
    // console.log(userAddress);
    // console.log(userAddress?.Province?.name);
    // console.log(userAddress?.province_id);

    // setInitialValues("first_name", userData?.first_name);
    // setInitialValues("last_name", userData?.last_name);
    // setInitialValues("email_id", userData?.email_id);
    // setInitialValues("address_line_1", userAddress?.address_line_1);
    // setInitialValues("address_line_2", userAddress?.address_line_2);
    // setInitialValues("city_id", userAddress?.city_id);
    // // setInitialValues("country_id", userAddress?.country_id ?? 1);
    // // setInitialValues("province_id", userAddress?.province_id ?? 11);
    // setInitialValues("country_id", userAddress?.country_id);
    // setInitialValues("province_id", userAddress?.province_id);
    // setInitialValues("zipcode_id", userAddress?.zipcode_id);
    // // setInitialValues("pan_number", userData?.pan_number);
    // let index = cityList.findIndex((city) => city.value === userData?.city_id);
    // setSelectedCity(index);
    setModalOpen1(false);
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  /**
   * Verify OTP
   * @param {*} OTP
   * @param {*} primary_contact_number
   */
  const verifyOTP = (primary_contact_number) => {
    if (otp?.length === 6) {
      setInitialValues({
        // date: "",
        // slot: "",
        // dhaja_id: "",
        // booking_category: "",
        // time_slot: "",
        first_name: '',
        last_name: '',
        email_id: '',
        price: '',
        primary_contact_number: '',
        address_line_1: '',
        address_line_2: '',
        country_id: '',
        province_id: '',
        city_id: '',
        zipcode_id: ''
        // pan_number: "",
      });
      YagnaBookingService.verifyOTP(mobNumber, otp)
        .then((res) => {
          if (res.data.success) {
            setModalOpen1(true);
          }
          setShowForm(true);
        })
        .catch((error) => {
          // navigate(routes?.error500);
          toast.error(error.response?.data?.message, { autoClose: 1500 });
        });
    }
  };
  const handleCheckSlots = () => {
    const slots = checkForAvailability(selectedDate, selectedSlot);
    setAvailableTemple(slots);
  };

  const handleDateChange = (date) => {
    setStartDate(date);
    setAvailability(false); // Reset availability when the date changes
    setAvailableTemple([]); // Reset available slots when the date changes
  };
  const getPriceByDhajaId = (dhaja_id) => {
    const selectedDhaja = dhajaList.find(
      (dhaja) => dhaja.id === Number(dhaja_id)
    );
    return selectedDhaja ? selectedDhaja.price : 0;
  };

  const onSubmit = (values) => {
    const price = getPriceByDhajaId(values.dhaja_id);
    const fromateddate = formatDate(selectedDate);

    Object.assign(values, {
      date: fromateddate,
      slot: Number(selectedSlot),
      dhaja_id: Number(values?.dhaja_id),
      booking_category: 'website',
      time_slot: timeslot,
      // time_slot: values?.time_slot,
      first_name: values?.first_name,
      last_name: values?.last_name,
      email_id: values?.email_id,
      price: Number(price),
      address_line_1: values?.address_line_1,
      address_line_2: values?.address_line_2,
      city_id: Number(values?.city_id),
      zipcode_id: Number(values?.zipcode_id),
      province_id: Number(values?.province_id),
      country_id: Number(values?.country_id),
      primary_contact_number: Number(values?.primary_contact_number)
    });
    // console.log(values);

    DhajaBookingService.payU(values)
      .then((res) => {
        // console.log(res);
        // if (res.data.success) {
        //   navigate(`/dhaja-booking-receipt/${res?.data?.data?.id}`);
        // } else {
        //   navigate(`/yagna/booking-failed/`);

        //   toast.error("Cash payment failed.", { autoClose: 1500 });
        // }
        if (res.data.success) {
          setPayUData(res.data.data);
          setTimeout(() => {
            // setProcessing(false);
            if (myButtonRef.current) {
              myButtonRef.current.click();
            }
          }, 200);

          // navigate(routes.moneyDonationSuccess);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message, { autoClose: 1500 });
        navigate(routes.dhajaBookingReceipt);
      });
  };

  // -----page-close-404-error-logic-p-----
  // if (true) {
  //   return <Error404 />;
  // }
  //-----page-close-404-error-logic-p-----

  return (
    <>
      <Helmet>
        <title>Ambaji Mandir Dhaja Booking | ધજા બુકિંગ </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Shri 51 Shaktipeeth Parikrama Mahotsav Yagna Booking. Book Gabbar Yagna and Shaktipeeth Yagna at Ambaji Temple. "
        />
        <meta
          name="keywords"
          content="Ambaji Yajna Booking , Gabbar Yagna Booking , Shaktipeeth Yagna Booking , Parikrama Mahotsav Yagna Booking, Shri 51 Shaktipeeth Parikrama Mahotsav Yagna Booking "
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <div className="booking">
          <section className="dhaja-booking">
            <div className="container">
              <h1>ધજા બુકિંગ </h1>
            </div>
          </section>
          <section className="dhaja-page">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="dhaja-booking-form card">
                    <Formik
                      initialValues={initialValues}
                      enableReinitialize={true}
                      validateOnChange={true}
                      validateOnBlur={false}
                      onSubmit={onSubmit}
                    >
                      {(formik) => (
                        <Form>
                          <div className="row mb-4" style={{ rowGap: '15px' }}>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                              <div className="form-group mb-0">
                                <div>
                                  <label htmlFor="date">
                                    Select Date / તારીખ પસંદ કરો
                                  </label>
                                </div>
                                <DatePicker
                                  style={{ width: '100%' }}
                                  showIcon
                                  selected={date}
                                  onChange={(date) => {
                                    handleDateChange(date);
                                    setSelectedDate(date);
                                  }}
                                  icon={
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="1em"
                                      height="1em"
                                      viewBox="0 0 48 48"
                                    >
                                      <mask id="ipSApplication0">
                                        <g
                                          fill="none"
                                          stroke="#fff"
                                          strokeLinejoin="round"
                                          strokeWidth="4"
                                        >
                                          <path
                                            strokeLinecap="round"
                                            d="M40.04 22v20h-32V22"
                                          ></path>
                                          <path
                                            fill="#fff"
                                            d="M5.842 13.777C4.312 17.737 7.263 22 11.51 22c3.314 0 6.019-2.686 6.019-6a6 6 0 0 0 6 6h1.018a6 6 0 0 0 6-6c0 3.314 2.706 6 6.02 6c4.248 0 7.201-4.265 5.67-8.228L39.234 6H8.845l-3.003 7.777Z"
                                          ></path>
                                        </g>
                                      </mask>
                                      <path
                                        fill="currentColor"
                                        d="M0 0h48v48H0z"
                                        mask="url(#ipSApplication0)"
                                      ></path>
                                    </svg>
                                  }
                                  minDate={today} // Add this line to restrict past dates
                                />
                                <ErrorMessage
                                  component="div"
                                  name="date"
                                  className="red-text"
                                />
                              </div>
                            </div>
                            <div className="col-xl-4 col-lg-5 col-md-5 col-sm-12">
                              <div className="form-group mb-0">
                                <div>
                                  <label htmlFor="date">
                                    Select Slot Time / સ્લોટ સમય પસંદ કરો
                                  </label>
                                </div>
                                <div className="selected-time-slot">
                                  <select
                                    name="slot"
                                    onChange={(e) => {
                                      setSelectedSlot(e.target.value);
                                    }}
                                  >
                                    <option value="">Select Slot</option>
                                    <option value="1">
                                      07:00 AM - 11:30 AM
                                    </option>
                                    <option value="2">
                                      12:30 PM - 04:30 PM
                                    </option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-xl-4 col-lg-3 col-md-3 col-sm-12 d-flex"
                              style={{
                                alignItems: 'flex-end',
                                justifyContent: 'end'
                              }}
                            >
                              <button
                                type="button"
                                className="orangebtn"
                                onClick={handleCheckSlots}
                              >
                                Check Slots
                              </button>
                            </div>
                          </div>
                          <div className="row">
                            {allAvailableTemple?.length > 0 &&
                              allAvailableTemple?.map((item, i) => {
                                return (
                                  <div
                                    className="col-12 col-lg-6 col-md-12 col-sm-12"
                                    key={i}
                                    hidden={
                                      isAvailable &&
                                      formik?.values?.date !== item?.date
                                    }
                                  >
                                    <div className="card p-3 mb-4">
                                      <div className="d-flex justify-content-between align-items-center">
                                        <h6
                                          className="mb-0"
                                          style={{
                                            lineHeight: '23px',
                                            fontSize: '15px'
                                          }}
                                        >
                                          Time : <br /> {item}
                                        </h6>
                                        <button
                                          type="button"
                                          className="orangebtn"
                                          style={{ width: '150px' }}
                                          onClick={() => {
                                            // Set the selected time slot in Formik state
                                            setTimeSlot('time_slot', item);

                                            // If you need to also set the date in Formik, uncomment the next line
                                            // formik.setFieldValue("date", item.date);

                                            // Update the component state with the selected time slot
                                            setTimeSlot(item);

                                            // Set other relevant states based on the selected temple
                                            setAvailability(true);
                                            setAvailableTemple([item]);
                                          }}
                                        >
                                          Book
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                          {isAvailable ? (
                            <>
                              <fieldset>
                                <div
                                  className="row d-flex"
                                  style={{ rowGap: '10px' }}
                                >
                                  <div className="form-group mb-0 col-12 col-lg-6">
                                    <div className="row">
                                      <div className="form-group mb-0 col-4">
                                        <label htmlFor="primary_contact_code_id">
                                          Code
                                        </label>
                                        <Field
                                          as="select"
                                          className="form-control"
                                          name="primary_contact_code_id"
                                          // disabled={isOTPVerified}
                                        >
                                          {countryList?.map((item, i) => {
                                            return (
                                              <option value={item?.id} key={i}>
                                                +{item?.phone_code}
                                              </option>
                                            );
                                          })}
                                        </Field>
                                        <ErrorMessage
                                          component="div"
                                          name="primary_contact_code_id"
                                          className="red-text"
                                        />
                                      </div>
                                      <div className="form-group mb-0 col-8">
                                        <label htmlFor="primary_contact_number">
                                          Mobile No
                                        </label>
                                        <Field
                                          type="text"
                                          className="form-control"
                                          name="primary_contact_number"
                                          disabled={!isNumberEditable}
                                          value={mobNumber}
                                          onChange={(e) => {
                                            setMobNumber(e.target.value);
                                          }}
                                          // maxLength="10"
                                          // pattern="\d{10}"
                                          // required
                                          // onChange={(e) => {
                                          //   const value = e.target.value;
                                          //   // Allow only digits and limit length to 10
                                          //   if (/^\d{0,10}$/.test(value)) {
                                          //   }
                                          // }}
                                        />
                                        <ErrorMessage
                                          component="div"
                                          name="primary_contact_number"
                                          className="red-text"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group mb-0 col-12 col-lg-6 d-flex align-items-end">
                                    {isNumberEditable ? (
                                      <button
                                        type="button"
                                        className="whitebtn"
                                        onClick={() => checkForContactNumber()}
                                      >
                                        Verify Number
                                      </button>
                                    ) : (
                                      <button
                                        type="button"
                                        className="whitebtn"
                                        onClick={() => [
                                          setNumberEditable(true),
                                          setNumberRegistered(false)
                                        ]}
                                      >
                                        Edit Number
                                      </button>
                                    )}
                                  </div>
                                  {isNumberRegistered &&
                                    !isNumberEditable &&
                                    !isShowForm && (
                                      <div className="form-group mb-0">
                                        <div
                                          className="row align-items-end"
                                          style={{ rowGap: '10px' }}
                                        >
                                          <div className="col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12">
                                            <label htmlFor="otp">OTP</label>
                                            <input
                                              type="text"
                                              inputMode="numeric"
                                              minLength="6"
                                              maxLength="6"
                                              pattern="[0-9]*" // Only allow numeric input
                                              name="otp"
                                              id="otp"
                                              className="form-control"
                                              placeholder="_ _ _ _ _ _"
                                              value={otp}
                                              onChange={(event) => {
                                                const inputValue =
                                                  event.target.value.replace(
                                                    /\D/g,
                                                    ''
                                                  ); // Remove non-digit characters
                                                setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                                                setResend(false); // Reset the resend state if needed
                                              }}
                                            />
                                          </div>
                                          <div className="col-xl-8 col-lg-8 col-md-9 col-sm-12">
                                            <button
                                              type="button"
                                              className="orangebtn"
                                              onClick={() => verifyOTP()}
                                              disabled={otp?.length !== 6}
                                            >
                                              Verify OTP
                                            </button>

                                            {isOTPSend && seconds == '00' && (
                                              <button
                                                type="button"
                                                className="whitebtn ms-1"
                                                onClick={() => [
                                                  resendOTP(),
                                                  setOTP(''),
                                                  setResend(true)
                                                ]}
                                              >
                                                Resend OTP
                                              </button>
                                            )}
                                            {/* <button
                        type="button"
                        className="whitebtn ms-3"
                        // onClick={() => [resendOTP(), setOTP(""), setResend(true)]}
                      >
                        Resend OTP
                      </button> */}
                                          </div>
                                          {isOTPSend && seconds > 0 ? (
                                            <p>
                                              Resend OTP in
                                              {seconds < 10
                                                ? ` 0${seconds}`
                                                : ` ${seconds}`}{' '}
                                              sec
                                            </p>
                                          ) : (
                                            <p></p>
                                          )}
                                          {!isResend &&
                                            (otp?.length > 6 ||
                                              otp?.length < 6) && (
                                              <p className="red-text">
                                                OTP must be a 6 digits number
                                              </p>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                </div>
                              </fieldset>
                              {isShowForm && !isNumberEditable ? (
                                <fieldset>
                                  {/* <div className="">
                                    <button
                                      type="button"
                                      className="whitebtn mb-1"
                                      onClick={() => setModalOpen1(true)}
                                    >
                                      pop modal
                                    </button>
                                  </div> */}
                                  <br />
                                  <div className="row">
                                    <div className="form-group col-12 col-md-6 col-lg-6">
                                      <label htmlFor="address">
                                        Length of Dhaja
                                        <span className="red-text">*</span>
                                      </label>
                                      {dhajaList.map((dhaja) => (
                                        <div
                                          key={dhaja.id}
                                          className="form-check"
                                        >
                                          <Field
                                            type="radio"
                                            className="form-check-input"
                                            name="dhaja_id" // Ensure the name is consistent
                                            value={dhaja.id}
                                            required
                                            onChange={() => {
                                              formik.setFieldValue(
                                                'dhaja_id',
                                                dhaja.id
                                              );
                                              // formik.setFieldValue(
                                              //   "dhaja_length",
                                              //   dhaja.length
                                              // ); // Set the length as well
                                            }}
                                          />
                                          <label className="form-check-label">
                                            {dhaja.name}
                                          </label>
                                        </div>
                                      ))}

                                      <ErrorMessage
                                        component="div"
                                        name="length_of_dhaja"
                                        className="red-text"
                                      />
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                      <label htmlFor="first_name">
                                        First Name / પ્રથમ નામ
                                        <span className="red-text">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="first_name"
                                        required
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="first_name"
                                        className="red-text"
                                      />
                                    </div>
                                    <div className="form-group col-12 col-md-6 col-lg-4">
                                      <label htmlFor="last_name">
                                        Last Name / અટક
                                        <span className="red-text">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="last_name"
                                        required
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="last_name"
                                        className="red-text"
                                      />
                                    </div>
                                    <div className="form-group col-12 col-md-12 col-lg-4">
                                      <label htmlFor="email_id">
                                        Email Id / ઈમેલ આઈડી
                                        <span className="red-text">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="email_id"
                                        required
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="email_id"
                                        className="red-text"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="form-group col-12 col-md-6 col-lg-6">
                                      <label htmlFor="address">
                                        Address Line 1 / એડ્રેસ લાઇન 1
                                        <span className="red-text">*</span>
                                      </label>
                                      <Field
                                        type="text"
                                        required
                                        className="form-control"
                                        name="address_line_1"
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="address_line_1"
                                        className="red-text"
                                      />
                                    </div>

                                    <div className="form-group col-12 col-md-6 col-lg-6">
                                      <label htmlFor="address">
                                        Address Line 2 / એડ્રેસ લાઇન 2
                                      </label>
                                      <Field
                                        type="text"
                                        className="form-control"
                                        name="address_line_2"
                                      />
                                      <ErrorMessage
                                        component="div"
                                        name="address_line_2"
                                        className="red-text"
                                      />
                                    </div>
                                  </div>

                                  <div className="row">
                                    <div className="form-group col-lg-3 col-md-3 col-12">
                                      <label htmlFor="country_id">
                                        Country / દેશ
                                        <span className="red-text">*</span>
                                      </label>
                                      <select
                                        name="country_id"
                                        className="form-control"
                                        value={formik.values.country_id} // Access formik values properly
                                        required
                                        onChange={(event) => {
                                          const selectedCountryId =
                                            event.target.value;
                                          formik.setFieldValue(
                                            'country_id',
                                            selectedCountryId
                                          ); // Update formik's value
                                          getProvince(selectedCountryId); // Call getProvince with the selected country ID
                                        }}
                                      >
                                        <option value="" disabled>
                                          Select Country
                                        </option>
                                        {countryList?.map((item, i) => (
                                          <option key={i} value={item?.id}>
                                            {item?.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>

                                    <div className="form-group col-lg-3 col-md-3 col-12">
                                      <label htmlFor="province_id">
                                        Province / રાજ્ય
                                        <span className="red-text">*</span>
                                      </label>
                                      <select
                                        name="province_id"
                                        className="form-control"
                                        required
                                        value={formik?.values?.province_id}
                                        onChange={(event) => [
                                          formik.setFieldValue(
                                            'province_id',
                                            event.target.value
                                          ),
                                          getCity(event)
                                        ]}
                                      >
                                        <option value="" selected disabled>
                                          Select State
                                        </option>
                                        {provinceList?.map((item, i) => {
                                          return (
                                            <option value={item?.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>

                                    <div className="form-group col-lg-3 col-md-3 col-12">
                                      <label htmlFor="city_id">
                                        City / શહેર
                                        <span className="red-text">*</span>
                                      </label>
                                      <select
                                        name="city_id"
                                        className="form-control"
                                        required
                                        value={formik?.values?.city_id}
                                        onChange={(event) => [
                                          formik.setFieldValue(
                                            'city_id',
                                            event.target.value
                                          ),
                                          getZipcodeByCityDeliverable(event)
                                        ]}
                                        // onChange={formik.handleChange}
                                      >
                                        <option value="" selected disabled>
                                          Select City
                                        </option>
                                        {cityList?.map((item, i) => {
                                          return (
                                            <option value={item?.id}>
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>

                                    <div className="form-group col-lg-3 col-md-3 col-12">
                                      <label htmlFor="zipcode_id">
                                        Zipcode / પિનકોડ
                                        <span className="red-text">*</span>
                                      </label>
                                      <select
                                        name="zipcode_id"
                                        required
                                        className="form-control"
                                        value={formik?.values?.zipcode_id || ''}
                                        onChange={formik.handleChange}
                                      >
                                        <option value="" selected disabled>
                                          Select Zipcode
                                        </option>
                                        {zipcodeList?.map((item, i) => {
                                          return (
                                            <option
                                              key={item?.id}
                                              value={item?.id}
                                            >
                                              {item?.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between">
                                    <button
                                      type="button"
                                      className="whitebtn mb-1"
                                      onClick={() => setModalOpen(true)}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="submit"
                                      className="orangebtn"
                                      // onClick={() =>
                                      //   navigate("/dhaja-booking-receipt")
                                      // }
                                    >
                                      Proceed to Book
                                    </button>
                                  </div>
                                </fieldset>
                              ) : (
                                <></>
                              )}
                            </>
                          ) : (
                            <p className="text-center red-text">
                              {slotAvailableMsg}
                            </p>
                          )}
                        </Form>
                      )}
                    </Formik>

                    <form method="POST" action={payu_api_url}>
                      <input type="hidden" name="key" value={payu_key} />
                      <input
                        type="hidden"
                        name="txnid"
                        value={payUData?.txnid}
                      />
                      <input
                        type="hidden"
                        name=" amount"
                        value={payUData?.amount}
                      />
                      <input
                        type="hidden"
                        name="productinfo"
                        value={payUData?.productinfo}
                      />
                      <input
                        type="hidden"
                        name=" firstname"
                        value={payUData?.firstname}
                      />
                      <input
                        type="hidden"
                        name="lastname"
                        value={payUData?.lastname}
                      />
                      <input
                        type="hidden"
                        name="email"
                        value={payUData?.email}
                      />
                      <input type="hidden" name="phone" value="9198672387" />
                      <input type="hidden" name="surl" value={payUData?.surl} />
                      <input type="hidden" name="furl" value={payUData?.furl} />
                      <input type="hidden" name="curl" value={payUData?.curl} />
                      <input type="hidden" name="hash" value={payUData?.hash} />
                      <input type="hidden" name="udf1" value={payUData?.udf1} />
                      <input type="hidden" name="udf2" value={payUData?.udf2} />
                      <input type="hidden" name="udf3" value={payUData?.udf3} />
                      <input type="hidden" name="udf4" value={payUData?.udf4} />
                      <input type="hidden" name="udf5" value={payUData?.udf5} />
                      <button hidden={true} ref={myButtonRef}>
                        Submit
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />

          <Modal
            isOpen={isModalOpen1}
            onRequestClose={() => setModalOpen1(false)}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Example Modal"
          >
            {userData && (
              <div className="row">
                <h4>Use the following details as registred with us?</h4>
                <div className="row">
                  <div className="col-sm-4">
                    <b>First Name:</b>
                  </div>
                  <div className="col-sm-6">{userData?.first_name || '-'}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <b>Last Name:</b>
                  </div>
                  <div className="col-sm-6">{userData?.last_name || '-'}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <b>Email Id:</b>
                  </div>
                  <div className="col-sm-6">{userData?.email_id || '-'}</div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <b>Address Line 1:</b>
                  </div>
                  <div className="col-sm-6">
                    {userAddress?.address_line_1 || '-'}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <b>Address Line 2:</b>
                  </div>
                  <div className="col-sm-6">
                    {userAddress?.address_line_2 || '-'}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <b>Country:</b>
                  </div>
                  <div className="col-sm-6">
                    {userAddress?.Country?.name || '-'}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <b>Province:</b>
                  </div>
                  <div className="col-sm-6">
                    {userAddress?.Province?.name || '-'}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <b>City:</b>
                  </div>
                  <div className="col-sm-6">
                    {userAddress?.City?.name || '-'}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-4">
                    <b>Zipcode:</b>
                  </div>
                  <div className="col-sm-6">
                    {userAddress?.ZipCode?.name || '-'}
                  </div>
                </div>
              </div>
            )}
            <br />
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="whitebtn"
                onClick={() => setModalOpen1(false)}
              >
                No
              </button>
              <button
                type="button"
                className="orangebtn"
                onClick={() => bindData()}
              >
                Yes
              </button>
            </div>
          </Modal>

          <Modal
            isOpen={isModalOpen}
            onRequestClose={() => setModalOpen(false)}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Example Modal"
          >
            <div className="">
              <h4>Are you sure, you want to cancel the booking proccess?</h4>
              <div className="card-footer d-flex justify-content-between">
                <button
                  type="button"
                  className="whitebtn"
                  onClick={() => setModalOpen(false)}
                >
                  No
                </button>
                <button
                  type="button"
                  className="btn orangebtn"
                  onClick={() => [setModalOpen(false), setAvailability(false)]}
                >
                  Yes
                </button>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};
export default DhajaBooking;
