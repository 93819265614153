import { api_urls } from "../../_Constants/api_urls";
import http from "../../_environment";

const getAll = () => {
  return http.get(
    `${
      api_urls?.getProduct
    }?is_shown_on_website=${true}&exclude_category_id=${1}`
  );
};

const getAllPrasad = () => {
  return http.get(
    `${api_urls?.getProduct}?is_shown_on_website=${true}&category_id=${1}`
  );
};

const getById = (id) => {
  return http.get(`${api_urls?.getProduct}?id=${id}`);
};

const ProductService = {
  getAll,
  getById,
  getAllPrasad,
};

export default ProductService;
