import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

const create = (data) => {
  return http.post(api_urls?.createInquiry, data);
};

const getAllInquiryType = () => {
  return http.get(api_urls?.getAllInquiryType);
};

const getAllInquiryTypeQue = () => {
  return http.get(api_urls?.getAllInquiryTypeQue);
};

const InquiryService = {
  create,
  getAllInquiryType,
  getAllInquiryTypeQue,
};

export default InquiryService;
