import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
// import "./payment-status.scss";
// import UtilsService from "../../../Api_config/Home_page/utils";
import EmployeeVehiclePassService from '../../../Api_config/Bhadarvi_poonam/employeevehiclepass';
import { routes } from '../../../_Constants/routes';
import Arasuri from '../../../Components/arasuri';
import Footer from '../../../Components/footer';
import info from '../../../Assets/Images/Bhadarvi_poonam/information-button.svg';
import { useHeading } from '../../Bhadarvi_poonam/header'; // Import the custom hook

const SearchSthanikVehiclePassSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState();
  const heading = useHeading(); // Call the custom hook

  useEffect(() => {
    const id = location?.pathname?.split('/').pop();
    // console.log(location, Number(id));
    EmployeeVehiclePassService.getAllEmployeeVehiclePass(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setTransactionData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        // console.log(e);
      });
  }, []);

  return (
    <>
      <section className="header-banner temple-banner">
        <div className="container">{heading}</div>
      </section>
      <section className="about-page">
        <div className="container">
          <div
            className="money-donation-success"
            style={{ backgroundColor: '#ffffff70' }}
          >
            <div className="check-icon">
              <img src={info} style={{ height: '90px' }} />
            </div>
            <br />
            <h3 className="text-center">
              Your Application is Still Under Review
            </h3>
            <br />
            <h4 className="text-center">
              <strong>
                Your Registration Number : <br />
                <p style={{ color: '#ae0015' }}>
                  {transactionData?.registration_number}
                </p>
              </strong>{' '}
            </h4>

            <h4 className="text-center p-3">
              Your application is till under review by the Banaskatha District
              Authorities. You will be notified via SMS or Email once the
              application status is updates.
            </h4>
          </div>
        </div>
      </section>
      <Arasuri />
      <Footer />
    </>
  );
};
export default SearchSthanikVehiclePassSuccess;
