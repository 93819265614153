import http from "../../_environment";
import { api_urls } from "../../_Constants/api_urls";

/** Address */
const saveAddress = (requestData) => {
  return http.post(api_urls?.createAddress, requestData);
};

const getAddress = () => {
  return http.get(
    `${api_urls?.getAddress}?user_id=${JSON.parse(
      localStorage.getItem("user_id")
    )}`
  );
};

const deleteAddress = (id) => {
  // const requestData = {
  //   record_id: id,
  // };
  return http.delete(`${api_urls?.deleteAddress}?record_id=${id}`);
};

const AddressDetailsService = {
  saveAddress,
  getAddress,
  deleteAddress,
};
export default AddressDetailsService;
